import gql from 'graphql-tag';
import { cx } from '@linaria/core';
import { isValidList } from '../../../utils/contentUtils';
import { linkWithArrowFragment, StyledLinkWithArrow as LinkWithArrow } from '../LinkWithArrow.jsx';
import { StyledAVCarousel as AVCarousel, avCarouselFragment } from '../AVCarousel';
import { StyledAutoTopicHeader as AutoTopicHeader, darkAutoTopicHeaderStyles, autoTopicHeaderFragment } from '../TopicClusterAutomatic/AutoTopicHeader.jsx';
import { makeMemoFragment } from '../../../utils/graphql';
import { noop } from '../../../utils/commons';

export const topicClusterAVFragment = avCarouselFragment;

export const topicClusterAVConfigFragment = makeMemoFragment({
  name: 'TopicClusterAVConfig',
  fragment() {
    return gql`fragment ${this.name} on CompositionComponent {
        configuration {
          sourceAssociations {
            targetId
            target {
              ... on DeliveryAspect {
                autoDelivery
              }
              ...${linkWithArrowFragment.name}
              ...${autoTopicHeaderFragment.name}
            }
          }
        }
      }
      ${linkWithArrowFragment.fragment()}
      ${autoTopicHeaderFragment.fragment()}
    `;
  },
});

const makeBottomLinkFn = autoTopicContent => () => ((
  <LinkWithArrow
    content={autoTopicContent}
    linkTranslation="content_block.topic_cluster_automatic.all" />
));

export const TopicClusterAV = ({
  contents = [],
  configuration = {},
  className,
  pageSectionId = 'topic-cluster-av',
  headlineTranslation = 'content_block.topic_cluster.headline',
}) => {
  if (!isValidList(contents)) {
    return null;
  }

  const autoTopicContent = configuration.sourceAssociations[0].target;

  return (
    <AVCarousel
      className={cx(className, darkAutoTopicHeaderStyles)}
      bottomElementFn={autoTopicContent.autoDelivery ? makeBottomLinkFn(autoTopicContent) : noop}
      headlineTranslation={headlineTranslation}
      pageSectionId={pageSectionId}
      contents={contents}
      preListSlotFn={
        () => <AutoTopicHeader autoTopic={autoTopicContent} />
      }
    />
  );
};

export const StyledTopicClusterAV = TopicClusterAV;
