import { styled } from '@linaria/react';
import { css } from '@linaria/core';
import { usePageContext } from '../../PageEmbeddingContext.jsx';
import { mediaMin } from '../../../utils/css';
import { StyledMediaTag as MediaTag } from '../../MediaTag.jsx';
import { useContentTeaserContext } from '../../ContentTeaserSnippets/ContentTeaserPartials/ContentTeaserContext';
import { LinkDefaultNarrow, LinkDefaultNarrowItalic } from '../../Links';
import { resetBoostrapAndBrowserDefaultLineHeight } from '../../../utils/commons';

export const TopStoryContentTeaserTitle = ({ className }) => {
  const pageContext = usePageContext();
  const { content } = useContentTeaserContext();

  const { title } = content;
  const Tag = pageContext.isOnDetailPage ? 'h2' : 'h3';
  const TeaserLink = content.isOpinion ? LinkDefaultNarrowItalic : LinkDefaultNarrow;

  return (
    <Tag className={className}>
      <TeaserLink className={LinkDefaultNarrow.darkStyles} to={content.namedUrl} title={title} skipTitleForInternal>
        <MediaTag contentType={content.__typename} />
        {title}
      </TeaserLink>
    </Tag>
  );
};

// TODO linaria-next css`` class?
export const topStoryTitleMargin = `
  margin-top: 47px;

  ${mediaMin.sm`
    margin-top: 60px;
  `}

  ${mediaMin.md`
    margin-top: 70px;
  `}

  ${mediaMin.lg`
    margin-top: 85px;
  `}

  ${mediaMin.xl`
    margin-top: 94px;
  `}
`;
// @VisibleForTesting
export const topStoryTitleMarginClass = css`${topStoryTitleMargin}`;

export const StyledTopStoryContentTeaserTitle = styled(TopStoryContentTeaserTitle)`
    word-break: break-word;
    margin-bottom: 5px;
    font-size: 2.4rem;
    ${resetBoostrapAndBrowserDefaultLineHeight}

    ${mediaMin.sm`
      font-size: 3rem;
    `}

    ${mediaMin.md`
      font-size: 3.8rem;
    `}

    ${mediaMin.lg`
      font-size: 4.6rem;
    `}
`;
