import { useEffect } from 'react';
import globals from '../../utils/globals';
import { useEventListener } from './useEventListener';
import { useLocalStorage } from './useLocalStorage';
import { useMobileAppContext } from './mobileAppContext';
import { isHomePage } from '../../utils/contentUtils';

export const useLanguageNotification = navigation => {
  const isHomePageNav = isHomePage(navigation);
  const { isMobileMode } = useMobileAppContext();
  const [userLanguageDetected,, closeLanguageNotification] = useLocalStorage('com.dw.userLanguageDetected', { isPrimitive: true });

  const isLangDetected = userLanguageDetected === 'true';

  // clear the flag if language has been auto detected when unmounting (navigating away from homepage - user seems fine in that lang)
  useEffect(() => () => {
    if (isLangDetected) {
      closeLanguageNotification();
    }
  }, [isLangDetected, closeLanguageNotification, isHomePageNav]);

  useEventListener(globals.window, 'pagehide', closeLanguageNotification, { once: true });

  return {
    isLanguageNotificationShown: isLangDetected && isHomePageNav && !isMobileMode,
    closeLanguageNotification,
  };
};
