import { styled } from '@linaria/react';

export const EndLineAlignmentContainer = ({ className, ...restProps }) => (
  <div className={className} {...restProps}/>
);

export const StyledEndLineAlignmentContainer = styled(EndLineAlignmentContainer)`
    margin-top: 15px;
    display: flex;
    justify-content: flex-end;
`;
