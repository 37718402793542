import { useSyncExternalStore, useEvent } from './preact-deps';
import globals from '../utils/globals';
import { useGlobalsContext } from '../components/GlobalsContext';
import { scrollToTop } from '../utils/commons';
import { makeUrl } from './locationToUrlConverter';

/**
 * History API docs @see https://developer.mozilla.org/en-US/docs/Web/API/History
 */
const eventPopstate = 'popstate';
const eventPushState = 'pushState';
const eventReplaceState = 'replaceState';
const eventHashchange = 'hashchange';
export const events = [
  eventPopstate,
  eventPushState,
  eventReplaceState,
  eventHashchange,
];

const subscribeToLocationUpdates = callback => {
  events.forEach(event => {
    globals.window.addEventListener(event, callback);
  });

  return () => {
    events.forEach(event => {
      globals.window.removeEventListener(event, callback);
    });
  };
};

export const unescape = str => {
  try {
    return decodeURIComponent(str);
  } catch (_e) {
    // fail-safe mode: if string can't be decoded do nothing
    return str;
  }
};

// eslint-disable-next-line no-unused-vars
export const useLocationProperty = (fn, ssrFn) => useSyncExternalStore(subscribeToLocationUpdates, fn, fn);

export const navigate = (to, { replace = false, isMobileMode = false, scrollTop = false } = {}) => {
  // eslint-disable-next-line fp/no-let
  const url = makeUrl(to, { isMobileMode });
  globals.history[replace ? eventReplaceState : eventPushState](to.state, '', url);
  if (scrollTop) {
    globals.window.setTimeout(scrollToTop, 0);
  }
};

const toSnap = location => ({
  pathname: unescape(location.pathname), search: unescape(location.search), hash: location.hash,
});

// the 2nd argument of the `useLocation` return value is a function
// that allows to perform a navigation.
//
// the function reference should stay the same between re-renders, so that
// it can be passed down as an element prop without any performance concerns.
// (This is achieved via `useEvent`.)
export const useLocation = () => {
  const ssrCompatLocation = useGlobalsContext().window.location;

  const cache = {}; // super tricky marker! It looks lie cache could be removed here but ... you would get surprised :P

  const locationSnap = toSnap(ssrCompatLocation);
  const cacheableLocationSnap = JSON.stringify(locationSnap);

  if (!cache[cacheableLocationSnap]) {
    // eslint-disable-next-line fp/no-mutation
    cache[cacheableLocationSnap] = locationSnap;
  }

  return [
    useLocationProperty(() => cache[JSON.stringify(toSnap(ssrCompatLocation))]),
    useEvent((to, navOpts) => navigate(to, navOpts)),
  ];
};

// While History API does have `popstate` event, the only
// proper way to listen to changes via `push/replaceState`
// is to monkey-patch these methods.
//
// See https://stackoverflow.com/a/4585031
if (typeof globals.history !== 'undefined') {
  [eventPushState, eventReplaceState].forEach(type => {
    const original = globals.history[type];
    // TODO: we should be using unstable_batchedUpdates to avoid multiple re-renders,
    // however that will require an additional peer dependency on react-dom.
    // See: https://github.com/reactwg/react-18/discussions/86#discussioncomment-1567149

    // eslint-disable-next-line fp/no-mutation
    globals.history[type] = function monkeyPatch(...args) {
      const result = original.apply(this, args);
      const event = new Event(type);
      // eslint-disable-next-line fp/no-mutation
      event.arguments = args;

      dispatchEvent(event);
      return result;
    };
  });
}
