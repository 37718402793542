import gql from 'graphql-tag';
import { makeMemoFragment } from '../../utils/graphql';
import { usePageContext } from '../PageEmbeddingContext.jsx';
import { TwitterMetadata, twitterMetadataFragment } from './TwitterMetadata.jsx';
import { FacebookMetadata } from './FacebookMetadata.jsx';

export const socialMediaMetadataFragment = makeMemoFragment({
  name: 'SocialMediaMetadata',
  fragment() {
    return gql`fragment ${this.name} on Content {
          ...${twitterMetadataFragment.name}
        }
        ${twitterMetadataFragment.fragment()}
    `;
  },
});

export const SocialMediaMetadata = ({ content }) => {
  const { isOnDetailPage } = usePageContext();

  return (
    <>
      {isOnDetailPage && <TwitterMetadata content={content} />}
      <FacebookMetadata />
    </>
  );
};
