import { isExternalDwLink } from '../../utils/url';
import { useTranslation } from '../hooks/useTranslation.jsx';

export const ExternalLink = ({
  to, title, children, ...restProps
}) => {
  const externalLinkPrefix = useTranslation('general.link.external_prefix');
  const isDwUrl = isExternalDwLink(to);
  return (
    // eslint-disable-next-line react/jsx-no-target-blank
    <a
      href={to}
      target="_blank"
      rel={!isDwUrl ? 'noopener noreferrer nofollow' : undefined }
      title={ title ? `${externalLinkPrefix} — ${title}` : undefined }
      {...restProps}
    >
      {children}
    </a>
  );
};
