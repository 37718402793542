import { cx } from '@linaria/core';
import {
  StyledListDefaultUnifiedBigAvText as ListDefaultUnifiedBigAvTextTemplate,
  listDefaultUnifiedBigAvTextFragment,
  darkListDefaultUnifiedBigAvTextStyles,
} from '../../ContentTeaserSnippets/Templates/ListDefaultUnifiedBigAvText.jsx';

export const avShowPlayerFragment = listDefaultUnifiedBigAvTextFragment;

export const AVShowPlayer = ({
  className,
  contents = [],
  isAVNews = false,
  ...restProps
}) => {
  const contentType = contents[0]?.__typename.toLowerCase();
  const translationKey = `content_block.${isAVNews ? 'av_news' : 'av_magazine'}.${contentType}.headline`;

  return (
    <ListDefaultUnifiedBigAvTextTemplate
      className={cx(className, darkListDefaultUnifiedBigAvTextStyles)}
      headlineTranslation={translationKey}
      contents={contents}
      hideKicker
      {...restProps}
    />
  );
};

export const StyledAVShowPlayer = AVShowPlayer;
