import { cx } from '@linaria/core';
import { StyledExpandableDetailRichText as ExpandableDetailRichText, expandableDetailRichTextFragment, darkExpandableDetailRichTextStyles } from '../ExpandableDetailRichText.jsx';

export const programDetailRichTextFragment = expandableDetailRichTextFragment;

export const ProgramDetailRichText = ({ program, isOpen: initialIsOpen = false, className }) => {
  if (!program.text) {
    return null;
  }
  return (
    <ExpandableDetailRichText
      className={cx(className, darkExpandableDetailRichTextStyles)}
      content={program}
      translationKeyPrefix="component_detail.program_detail.read_more"
      isOpen={initialIsOpen}
    />
  );
};

export const StyledProgramDetailRichText = ProgramDetailRichText;
