import { useEffect, useState } from 'react';

import { loadInstagramApi } from './api';

export const useLoadInstagramApiEffect = ({ inView }) => {
  const [instagramApi, setInstagramApi] = useState({ instgrm: null });

  useEffect(() => {
    const loadAndSaveInstagramApi = async () => {
      const { instgrm } = await loadInstagramApi();
      setInstagramApi({ instgrm });
    };
    if (inView && !instagramApi.instgrm) {
      loadAndSaveInstagramApi();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView]);

  return instagramApi;
};
