import { css } from '@linaria/core';
import {
  StyledTextSemiBold,
  StyledTextMedium,
  italicClass,
  textColors,
} from './TextBase';
import { TextDefault } from './Text.jsx';
import { extendWithClassNames } from '../../utils/extendWithClassNames.jsx';
import { globalCssVariables } from '../Page/PageGlobalStyles';

const headlineLineHeight = css`
  line-height: var(${globalCssVariables.lineHeight});
`;

// SemiBold   DW_DARK_BLUE --> WHITE
export const HeadlineSemiBoldDwDarkBlueAndWhite = extendWithClassNames(StyledTextSemiBold)(
  headlineLineHeight,
  css`${textColors.DW_DARK_BLUE_AND_WHITE.lightStyles}`,
);
HeadlineSemiBoldDwDarkBlueAndWhite.darkStyles = textColors.DW_DARK_BLUE_AND_WHITE.darkStyles;
export const HeadlineSemiBoldItalicDwDarkBlueAndWhite = extendWithClassNames(StyledTextSemiBold)(
  headlineLineHeight,
  italicClass,
  css`${textColors.DW_DARK_BLUE_AND_WHITE.lightStyles}`,
);
HeadlineSemiBoldItalicDwDarkBlueAndWhite.darkStyles = HeadlineSemiBoldDwDarkBlueAndWhite.darkStyles;

// SemiBold   BLUE_GREY_04 --> BLUE_GREY_03
export const HeadlineSemiBoldBlueGrey04AndBlueGrey03 = extendWithClassNames(StyledTextSemiBold)(
  headlineLineHeight,
  css`${textColors.BLUE_GREY_04_AND_BLUE_GREY_03.lightStyles}`,
);
HeadlineSemiBoldBlueGrey04AndBlueGrey03.darkStyles = textColors.BLUE_GREY_04_AND_BLUE_GREY_03.darkStyles;

// Medium     BLUE_GREY_04 --> DW_LIGHT_BLUE
export const HeadlineMediumBlueGrey04AndDwLightBlueNew = extendWithClassNames(StyledTextMedium)(
  headlineLineHeight,
  css`${textColors.BLUE_GREY_04_AND_DW_LIGHT_BLUE.lightStyles}`,
);
HeadlineMediumBlueGrey04AndDwLightBlueNew.darkStyles = textColors.BLUE_GREY_04_AND_DW_LIGHT_BLUE.darkStyles;

// SemiBold   DARK_BLUE_GREY_02 --> BLUE_GREY_01
export const HeadlineSemiBoldDarkBlueGrey02AndBlueGrey01 = extendWithClassNames(StyledTextSemiBold)(
  headlineLineHeight,
  css`${textColors.DARK_BLUE_GREY_02_AND_BLUE_GREY_01.lightStyles}`,
);
HeadlineSemiBoldDarkBlueGrey02AndBlueGrey01.darkStyles = textColors.DARK_BLUE_GREY_02_AND_BLUE_GREY_01.darkStyles;

// SemiBold   DARK_BLUE_GREY_01 --> BLUE_GREY_02
export const HeadlineSemiBoldDarkBlueGrey01AndBlueGrey02 = extendWithClassNames(StyledTextSemiBold)(
  headlineLineHeight,
  css`${textColors.DARK_BLUE_GREY_01_AND_BLUE_GREY_02.lightStyles}`,
);
HeadlineSemiBoldDarkBlueGrey01AndBlueGrey02.darkStyles = textColors.DARK_BLUE_GREY_01_AND_BLUE_GREY_02.darkStyles;

// Regular    DARK_BLUE_GREY_02 --> BLUE_GREY_01
export const HeadlineWideRegularDarkBlueGrey02AndBlueGrey01 = extendWithClassNames(TextDefault)(
  headlineLineHeight,
);
HeadlineWideRegularDarkBlueGrey02AndBlueGrey01.darkStyles = TextDefault.darkStyles;
