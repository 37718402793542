import gql from 'graphql-tag';
import { cx } from '@linaria/core';

import { darkContentBlockListStyles, StyledContentBlockList as ContentBlockList } from '../Types/ContentBlockList.jsx';
import { darkExpandableStyles, StyledExpandable as Expandable } from '../../Expandable';
import { StyledDefaultLayout as LimitedItemsLayout } from '../../ContentTeaserSnippets/Layouts/DefaultLayout';
import { UnlimitedItemsColumnLayout } from './UnlimitedItemsColumnLayout';
import { isValidList } from '../../../utils/contentUtils';
import { makeMemoFragment } from '../../../utils/graphql';
import { expandAdapterForDossierItems, adapterForDossierItemsFragment, darkExpandAdapterForDossierItemsStyles } from './expandAdapter';

export const INITIAL_TEASER_AMOUNT = 12;

export const dossierContentItemsFragment = makeMemoFragment({
  name: 'DossierContentItems',
  fragment() {
    return gql`fragment ${this.name} on AssociationsAspect {
        dossierContents {
          ... on ModelAspect {
            id
          }
          ...${adapterForDossierItemsFragment.name}
        }
      }
      ${adapterForDossierItemsFragment.fragment()}
    `;
  },
});

export const LimitedTeasers = ({ contents = [], children }) => (
  <LimitedItemsLayout>
    {children(contents)}
  </LimitedItemsLayout>
);

export const UnlimitedTeasers = ({ contents = [], children, initialListCount }) => (
  <Expandable
    dataArray={contents}
    showMoreBtnTranslation="content_block_detail.dossier_content_items.show_more"
    initialListCount={initialListCount}
  >
    {(...expandableData) => (
      <UnlimitedItemsColumnLayout>
        {children(...expandableData)}
      </UnlimitedItemsColumnLayout>
    )}
  </Expandable>
);

export const DossierContentItems = ({ content, className, initialListCount = INITIAL_TEASER_AMOUNT }) => {
  const { dossierContents } = content;

  if (!isValidList(dossierContents)) {
    return null;
  }

  const isExpandable = dossierContents.length > initialListCount;
  const DynamicTeaserLayout = isExpandable ? UnlimitedTeasers : LimitedTeasers;

  return (
    <ContentBlockList
      headlineTranslation="content_block_detail.dossier_content_items.headline"
      headlineIsA="h2"
      className={className}
      pageSectionId="dossier-content-items"
    >
      <DynamicTeaserLayout
        contents={dossierContents}
        initialListCount={initialListCount}
      >
        {expandAdapterForDossierItems}
      </DynamicTeaserLayout>
    </ContentBlockList>
  );
};

export const darkDossierContentItemsStyles = cx(
  darkContentBlockListStyles,
  darkExpandableStyles,
  darkExpandAdapterForDossierItemsStyles,
);

export const StyledDossierContentItems = DossierContentItems;
