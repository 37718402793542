import { extendWithClassNames } from '../../../utils/extendWithClassNames.jsx';

import {
  StyledTeaserComposition as TeaserComposition,
  extendWithDefaultTeaserComposition,
  extendDefaultTeaserCompositionWithStandardTeaserBasicLooks,
  extendTeaserCompositionWithBottomAlignmentLooks,
  extendTeaserCompositionWithStandardBigTextTeaserLooks,
  darkBigTextTeaserStyles,
} from '../TeaserComposition';

export const darkDefaultDarkBigTextLayoutStyles = darkBigTextTeaserStyles;

export const StyledDefaultDarkBigTextLayout = extendWithClassNames(TeaserComposition)(
  extendTeaserCompositionWithBottomAlignmentLooks,
  extendTeaserCompositionWithStandardBigTextTeaserLooks,
  extendWithDefaultTeaserComposition,
  extendDefaultTeaserCompositionWithStandardTeaserBasicLooks,
);
