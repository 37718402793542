import { BrowserOnly } from '../BrowserOnly.jsx';
import { suspendedComponentFactory } from '../SuspendedComponent/suspendedComponentFactory.jsx';

// @VisibleForTesting
export const { SlideChangeTracking: LazySlideChangeTracking } = suspendedComponentFactory(() => import('./Lazy/SlideChangeTracking.jsx'));

export const EnableAsyncSlideChangeTracking = ({ shouldPush, slideEventData, content }) => (
  <BrowserOnly>
    <LazySlideChangeTracking shouldPush={shouldPush} slideEventData={slideEventData} content={content} />
  </BrowserOnly>
);
