import { styled } from '@linaria/react';
import { mediaMin } from '../../../utils/css';
import { TextDefault } from '../../Text';
import { useTranslation } from '../../hooks/useTranslation.jsx';

export const FocusLabel = ({ className, isA }) => {
  const focusMenuLabel = useTranslation('component.focus_navigation.headline');

  return (
    <TextDefault isA={isA} className={className}>{focusMenuLabel}</TextDefault>
  );
};

export const StyledFocusLabel = styled(FocusLabel)`
  font-size: 1.1rem;
  text-transform: uppercase;
  letter-spacing: 0.16rem;
  white-space: nowrap;
  ${mediaMin.md`
    font-size: 1.2rem;
  `}
  ${mediaMin.lg`
    font-size: 1.3rem;
  `}
`;
