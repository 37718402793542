import gql from 'graphql-tag';
import { cx } from '@linaria/core';

import { StyledSlideShow as SlideShow, slideShowFragment } from './slideshow';
import { StyledBasicLayout as BasicLayout } from './layouts/BasicLayout';
import {
  darkImageGalleryDetailsHeaderStyles,
  imageGalleryDetailsHeaderFragment,
  StyledImageGalleryDetailsHeader as ImageGalleryDetailsHeader,
} from './ContentDetailHeader/ImageGalleryDetailsHeader.jsx';
import {
  observedContentDetailActionsFragment,
  ObservedContentDetailActions,
  darkObservedContentDetailActionStyles,
} from './ObservedContentDetailActions.jsx';
import {
  contentDetailRichTextFragment,
  darkDetailRichTextStyles,
  StyledContentDetailRichText as ContentDetailRichText,
} from './ContentDetailRichText';
import {
  contentDetailFooterFragment,
  darkContentDetailFooterStyles,
  StyledContentDetailFooter as ContentDetailFooter,
} from './ContentDetailFooter';
import { BeforeRichTextGptSlot } from './GptSlot';
import { mediaTopSlotConfig } from './GptSlot/slotConfigs';
import { makeMemoFragment } from '../utils/graphql';

export const imageGalleryDetailsFragment = makeMemoFragment({
  name: 'ImageGalleryDetails',
  fragment() {
    return gql`fragment ${this.name} on ImageGallery {
            ...${slideShowFragment.name}
            ...${imageGalleryDetailsHeaderFragment.name}
            ...${contentDetailRichTextFragment.name}
            ...${observedContentDetailActionsFragment.name}
            ...${contentDetailFooterFragment.name}
        }
        ${slideShowFragment.fragment()}
        ${imageGalleryDetailsHeaderFragment.fragment()}
        ${contentDetailRichTextFragment.fragment()}
        ${observedContentDetailActionsFragment.fragment()}
        ${contentDetailFooterFragment.fragment()}
    `;
  },
});

export const SlideShowHero = ({ content, children }) => (
  <>
    <SlideShow imageGallery={content}/>
    {children}
  </>
);

export const ImageGalleryDetails = ({ content = {}, className }) => (
  <BasicLayout
    className={className}
    heroCmpProps={{ content }}
    HeroCmp={SlideShowHero}
  >
    <ImageGalleryDetailsHeader content={content}/>
    <ObservedContentDetailActions content={content} />
    <BeforeRichTextGptSlot slotConfig={mediaTopSlotConfig}/>
    <ContentDetailRichText content={content}/>
    <ContentDetailFooter content={content}/>
  </BasicLayout>
);

export const darkImageGalleryDetailsStyles = cx(
  darkImageGalleryDetailsHeaderStyles,
  darkObservedContentDetailActionStyles,
  BeforeRichTextGptSlot.darkStyles,
  darkDetailRichTextStyles,
  darkContentDetailFooterStyles,
);

export const StyledImageGalleryDetails = ImageGalleryDetails;
