import { styled } from '@linaria/react';
import { cx } from '@linaria/core';
import { mediaMin } from '../../utils/css';
import { useTranslation } from '../hooks/useTranslation.jsx';
import { StyledFeedback as FeedbackIcon } from '../icons';
import { ButtonTertiary } from '../buttons/Button';
import { hideOnPrint } from '../GlobalPrintStyles';

export const FeedbackButton = ({ className, ...restProps }) => {
  const feedbackLabel = useTranslation('content_block_detail.feedback_button.label');

  return (
    <ButtonTertiary className={cx(hideOnPrint, className)} {...restProps}>
      {feedbackLabel}
      <FeedbackIcon/>
    </ButtonTertiary>
  );
};

// TODO linaria-next: I hope these styles can be removed. Check with philipp
export const StyledFeedbackButton = styled(FeedbackButton)`
  ${mediaMin.sm`
    gap: 7px;
  `}
`;

StyledFeedbackButton.darkStyles = ButtonTertiary.darkStyles;
