import { styled } from '@linaria/react';
import { colors, mediaMin } from '../utils/css';
import { noop } from '../utils/commons';
import { InfoLabelWhite } from './Text/Information.jsx';
import { useTranslation } from './hooks/useTranslation.jsx';

export const Badge = ({
  textIsA = InfoLabelWhite, className, iconSlotFn = noop, translationKey, ...restProps
}) => {
  const iconSlot = iconSlotFn();
  const labelText = useTranslation(translationKey);
  const BadgeTag = textIsA;
  return (
    <div className={className}>
      {iconSlot}
      <BadgeTag className="label" {...restProps}>{labelText}</BadgeTag>
    </div>
  );
};

export const StyledBadge = styled(Badge)`
  display: inline-flex;
  align-items: center;
  padding: 1px 5px;
  column-gap: 5px;

  .label {
    font-size: 1.3rem;
    letter-spacing: 0.1rem;
    text-transform: uppercase;
  }

  ${mediaMin.lg`
    padding-top: 2px;
    padding-bottom: 2px;

    .label {
      font-size: 1.5rem;
      letter-spacing: 0.115rem;
    }
  `}
`;

export const cssVariables = {
  background: '--badge-background-color',
};

// @VisibleForTesting
export const BadgeWithWhiteLabel = props => <StyledBadge textIsA={InfoLabelWhite} {...props}/>;

export const StyledBreakingRedBadge = styled(BadgeWithWhiteLabel)`
  background-color: var(${cssVariables.background},${colors.BREAKING_RED});
`;
