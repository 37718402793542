import { css, cx } from '@linaria/core';
import { contentBlockPadding, contentBlockWidth } from '../../ContentBlocks/ContentBlock';
import { mediaMin } from '../../../utils/css';
import { teaserNoImageSelector } from '../TeaserComposition';

export const CarouselTeaserContainer = ({
  className,
  isHidden = false,
  children,
  ...restProps
}) => (
  <div
    className={cx(className, 'teaser-wrap')}
    aria-hidden={isHidden}
    {...restProps}
  >
    {children}
  </div>
);

const contentBlockPaddingSum = size => contentBlockPadding[size] * 2;

export const carouselTeaserContainerClassName = css`
  width: 100vw;
  padding: 0 40px;
  display: flex;
  flex-direction: column;

  ${mediaMin.sm`
    padding: 0 65px;
  `};

  ${mediaMin.md`
    width: 50vw;
    padding: 0 15px;

    &:nth-child(odd) {
      padding-inline-start: 60px;
    }

    &:nth-child(even) {
      padding-inline-end: 60px;
    }
  `};

  ${mediaMin.lg`
    width: ${(contentBlockWidth.lg - contentBlockPaddingSum('lg')) / 2}px;
  `};
 
  ${mediaMin.xl`
    &:nth-child(odd) {
      padding-inline-start: 65px;
    }

    &:nth-child(even) {
      padding-inline-end: 65px;
    }

    width: ${(contentBlockWidth.xl - contentBlockPaddingSum('lg')) / 2}px;
  `};
`;

export const featuredCarouselTeaserContainerClassName = css`
  width: 100vw;
  padding: 0 15px;   
  
  ${mediaMin.sm`
    padding: 0 30px;
  `};

  ${mediaMin.md`
    padding: 0 40px;

    .title {
      font-size: 2.8rem;
    }
  `};

  ${mediaMin.lg`
    padding: 0;
    
    .title {
      font-size: 3.2rem;
    }
    ${teaserNoImageSelector}{
      max-width: 50%;

      .title {
        font-size: 2.8rem;
      }
    }
    &.teaser-wrap {
      .teaser {
        flex-direction: row;
      }
      .teaser-image-wrap,
      .teaser-av-wrap{
        max-width: 66.666666%;
      }
      .teaser-data-wrap {
        max-width: 33.333333%;
      }
    }
  `};
 
  ${mediaMin.xl`
    .title {
      font-size: 3.6rem;
    }
    ${teaserNoImageSelector} .title {
      font-size: 3.2rem;
    }
  `};
`;
