import gql from 'graphql-tag';
import { cx } from '@linaria/core';
import { StyledFooterSectionMainGroup as FooterSectionMainGroup, footerSectionFragment } from './FooterSectionMainGroup.jsx';
import { isValidList } from '../../utils/contentUtils';
import { makeMemoFragment } from '../../utils/graphql';
import { hideOnPrint } from '../GlobalPrintStyles';

export const footerSectionMainFragment = makeMemoFragment({
  name: 'FooterSectionMain',
  fragment() {
    return gql`fragment ${this.name} on Footer {
      sections {
        ...${footerSectionFragment.name}
      }
    }
    ${footerSectionFragment.fragment()}
  `;
  },
});

export const FooterSectionMain = ({ className, footer }) => {
  if (!isValidList(footer.sections)) {
    return null;
  }
  return (
    <section className={cx(hideOnPrint, className)} data-tracking-name="footer-section-main">
      <div className='row' >
        {footer.sections.map((section, index) => (
          isValidList(section.links) && (
            <FooterSectionMainGroup
              key={index}
              section={section}
              className="col-6 col-lg-3"
            />
          )))}
      </div>
    </section>
  );
};

export const StyledFooterSectionMain = FooterSectionMain;
