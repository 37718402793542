import gql from 'graphql-tag';
import { cx } from '@linaria/core';
import { isValidList } from '../../utils/contentUtils';
import { makeMemoFragment } from '../../utils/graphql';

import {
  darkPersonContentItemsStyles,
  personContentItemsFragment,
  StyledPersonContentItems as PersonContentItems,
} from '../ContentBlocks/PersonalContent/PersonContentItems';
import {
  darkPersonProfileTopicsStyles,
  personProfileTopicsFragment,
  StyledPersonProfileTopics as PersonProfileTopics,
} from '../ContentBlocks/PersonalContent/PersonProfileTopics';

export const personRecommendFragment = makeMemoFragment({
  name: 'PersonRecommend',
  fragment() {
    return gql`fragment ${this.name} on Person {
      ...${personContentItemsFragment.name}
      ...${personProfileTopicsFragment.name}
    }
    ${personContentItemsFragment.fragment()}
    ${personProfileTopicsFragment.fragment()}
    `;
  },
});

export const PersonRecommend = ({ person }) => (
  <>
    {isValidList(person.profileTopics) && (
      <PersonProfileTopics person={person}/>
    )}
    {isValidList(person.moreContent) && (
      <PersonContentItems person={person}/>
    )}
  </>
);

export const darkPersonRecommendStyles = cx(
  darkPersonProfileTopicsStyles,
  darkPersonContentItemsStyles,
);
