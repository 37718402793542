import { styled } from '@linaria/react';
import classNames from 'classnames';
import Chevron from './svg/chevron.svg?react';

export const StyledChevron = styled(Chevron)`
  width: 40px;
`;

export const ChevronOpenClose = ({ className, pointsDown }) => (
  <StyledChevron className={classNames(className, { down: pointsDown })}/>
);

export const StyledChevronOpenClose = styled(ChevronOpenClose)`
  transition: transform 450ms cubic-bezier(0.43, 0, 0.09, 1); 
  transform: rotate(0deg);
  
  &.down {
    transform: rotate(0deg);
  }
  &:not(.down) {
    transform: rotate(180deg);
  }
`;
