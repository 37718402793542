import gql from 'graphql-tag';
import { styled } from '@linaria/react';

import { LinkBase } from '../../Links/LinkBase';
import { StyledResponsiveDwPicture as ResponsiveDwPicture, responsiveDwPictureFragment } from '../../ResponsiveDwPicture';
import { alwaysSquarePictureConfig } from '../../ResponsiveDwPicture/pictureConfigs';
import { mediaMin } from '../../../utils/css';
import { makeMemoFragment } from '../../../utils/graphql';

export const authorLinkedImageFragment = makeMemoFragment({
  name: 'AuthorLinkedImage',
  fragment() {
    return gql`fragment ${this.name} on Person {
        namedUrl
        fullName
        autoDelivery
        mainContentImageLink {
          targetId
          additionalInformation
          target {
            ...${responsiveDwPictureFragment.name}
          }
        }
      }
      ${responsiveDwPictureFragment.fragment()}
    `;
  },
});

export const AuthorLinkedImage = ({ className, person = {} }) => {
  const { target, additionalInformation } = person.mainContentImageLink || {};

  if (!target) {
    return null;
  }

  const Container = person.autoDelivery
    ? {
      Tag: LinkBase,
      props: {
        to: person.namedUrl,
        title: person.fullName,
        tabIndex: -1,
      },
    }
    : {
      Tag: 'div',
      props: {},
    };

  return (
    <Container.Tag className={className} {...Container.props}>
      <ResponsiveDwPicture
        image={target}
        alt={additionalInformation}
        formatConfig={alwaysSquarePictureConfig}
      />
    </Container.Tag>
  );
};

export const StyledAuthorLinkedImage = styled(AuthorLinkedImage)`
  width: 40px;
  height: 40px;

  overflow: hidden;
  border-radius: 50%;
  -webkit-mask-image: -webkit-radial-gradient(white, black);
  

  ${mediaMin.sm`
    width: 50px;
    height: 50px;
  `}
`;
