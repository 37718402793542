import gql from 'graphql-tag';
import { styled } from '@linaria/react';
import { cx } from '@linaria/core';

import { darkDetailHeaderHeadlineStyles, StyledDetailHeaderHeadline as DetailHeaderHeadline } from '../DetailHeaderHeadline.jsx';
import { darkTeaserTextStyles, StyledTeaserText as TeaserText } from '../TeaserText.jsx';
import { authorDetailsFragment, darkAuthorDetailsStyles, StyledAuthorDetails as AuthorDetails } from './AuthorDetails.jsx';
import { darkTimeAreaStyles, StyledTimeArea as TimeArea, timeAreaFragment } from './TimeArea.jsx';
import {
  StyledContentDetailKicker as ContentDetailKicker,
  contentDetailKickerFragment,
  darkContentDetailKickerStyles,
} from './ContentDetailKicker.jsx';
import { StyledContentStatusHeaderBadge as ContentStatusHeaderBadge, contentStatusBadgeFragment } from '../ContentStatusBadge.jsx';
import { makeMemoFragment } from '../../utils/graphql';

export const articleDetailHeaderFragment = makeMemoFragment({
  name: 'ArticleDetailHeader',
  fragment() {
    return gql`fragment ${this.name} on Content {
          ... on NamedAspect {
            title
          }
          ... on TextualAspect {
            teaser
          }
            ...${contentStatusBadgeFragment.name}
            ...${timeAreaFragment.name}
            ...${authorDetailsFragment.name}
            ...${contentDetailKickerFragment.name}
        }
        ${contentStatusBadgeFragment.fragment()}
        ${timeAreaFragment.fragment()}
        ${authorDetailsFragment.fragment()}
        ${contentDetailKickerFragment.fragment()}
    `;
  },
});

export const ArticleDetailHeader = ({
  content,
  className,
}) => (
  <header className={className}>
    <ContentStatusHeaderBadge content={content}/>
    <ContentDetailKicker content={content} />
    <DetailHeaderHeadline>{content.title}</DetailHeaderHeadline>
    <AuthorDetails content={content} className='author-details' />
    <TimeArea className="time-area" content={content} />
    {content.teaser &&
      <TeaserText className="teaser-text">{content.teaser}</TeaserText>
    }
  </header>
);

export const darkArticleDetailHeaderStyles = cx(
  darkContentDetailKickerStyles,
  darkDetailHeaderHeadlineStyles,
  darkAuthorDetailsStyles,
  darkAuthorDetailsStyles,
  darkTimeAreaStyles,
  darkTeaserTextStyles,
);

export const StyledArticleDetailHeader = styled(ArticleDetailHeader)`
  .time-area, .author-details {
    margin-top: 5px;
  }

  .teaser-text {
    margin-bottom: 10px;
    margin-top: 10px;
  }
`;
