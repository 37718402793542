import { styled } from '@linaria/react';
import { TextEmphasized } from './Text';
import { mediaMin } from '../utils/css';

export const TeaserText = props => (
  <TextEmphasized isA="p" { ...props }/>
);

export const darkTeaserTextStyles = TextEmphasized.darkStyles;

export const StyledTeaserText = styled(TeaserText)`
  font-size: 1.6rem;
  margin-bottom: 0;

  ${mediaMin.md`
    font-size: 1.8rem;
  `}
`;
