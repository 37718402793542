import gql from 'graphql-tag';
import { styled } from '@linaria/react';

import { AuthorName, authorNameFragment, darkAuthorNameStyles } from './ContentDetailHeader/AuthorsLine/AuthorName.jsx';
import { authorLinkedImageFragment, StyledAuthorLinkedImage as AuthorLinkedImage } from './ContentDetailHeader/AuthorsLine/AuthorLinkedImage.jsx';
import { makeMemoFragment } from '../utils/graphql';

export const singleAuthorFragment = makeMemoFragment({
  name: 'SingleAuthor',
  fragment() {
    return gql`fragment ${this.name} on Person {
        ...${authorNameFragment.name}
        ...${authorLinkedImageFragment.name}
      }
      ${authorLinkedImageFragment.fragment()}
      ${authorNameFragment.fragment()}
    `;
  },
});

export const SingleAuthor = ({
  className,
  person = {},
}) => (
  <div className={className}>
    <AuthorLinkedImage className="author-image" person={person} />
    <div className="author-name">
      <AuthorName
        withShortProfile
        withTwitterLink
        person={person}
      />
    </div>
  </div>
);

export const darkSingleAuthorStyles = darkAuthorNameStyles;

export const StyledSingleAuthor = styled(SingleAuthor)`
  font-size: 1.3rem;
  padding-bottom: 20px;
  display: flex;

  .author-image {
    flex-shrink: 0;
    width: 50px;
    height: 50px;
    margin-top: 6px;
    margin-inline-end: 15px;
  }
`;
