import { css, cx } from '@linaria/core';
import { mediaMin, selector } from '../../../../utils/css';
import { teaserNoImageSelector } from './DefaultTeaserComposition';

export const extendWithFocusTopThreeTeaserComposition = css`
  ${mediaMin.md`
    .teaser-wrap:first-child {
      flex: 0 0 100%;
      max-width: 100%;
    }

    .teaser-wrap${selector.justNElements(2)},
    .teaser-wrap${selector.justNElements(2)} ~ .teaser-wrap {
      flex: 0 0 50%;
      max-width: 50%;
    }
  `}

  ${mediaMin.lg`
    .teaser-wrap:first-child {
      flex: 0 0 33.333333%;
      max-width: 33.333333%;
    }

    .teaser-wrap${selector.justNElements(1)}{
      flex: 0 0 100%;
      max-width: 100%;
    }
  `}
`;

export const extendFocusTopThreeTeaserCompositionWithMandatoryBasicLooks = css`
  ${mediaMin.md`
    .teaser-wrap:first-child {
      .title {
        font-size: 2.8rem;
      }
    }

    .teaser-wrap${selector.justNElements(2)},
    .teaser-wrap${selector.justNElements(2)} ~ .teaser-wrap {
      .title {
        font-size: 2.4rem;
      }
    }
  `}

  ${mediaMin.lg`
    .teaser-wrap:first-child {
      .title {
        font-size: 2.4rem;
      }
    }

    .teaser-wrap${selector.justNElements(1)}{
      .title {
        font-size: 3.2rem;
      }
    }

    .teaser-wrap${selector.justNElements(2)},
    .teaser-wrap${selector.justNElements(2)} ~ .teaser-wrap {
      .title {
        font-size: 2.8rem;
      }
    }
  `}

    ${mediaMin.xl`
     .teaser-wrap${selector.justNElements(1)}{
       .title {
        font-size: 3.6rem;
       }
    }

    .teaser-wrap${selector.justNElements(2)},
    .teaser-wrap${selector.justNElements(2)} ~ .teaser-wrap {
      .title {
        font-size: 3.2rem;
      }
    }
  `}
`;

export const extendFocusTopThreeTeaserCompositionWithNoImageBasicLooks = css`
  ${mediaMin.lg`
    .teaser-wrap${selector.justNElements(1)}{
      ${teaserNoImageSelector}{
        max-width: 50%;
        .title {
          font-size: 2.8rem;
        }
      }
    }
  `}

    ${mediaMin.xl`
     .teaser-wrap${selector.justNElements(1)}{
       ${teaserNoImageSelector} .title {
        font-size: 3.2rem;
      }
    }
  `}
`;

export const extendFocusTopThreeTeaserCompositionWithStandardTeaserBasicLooks = cx(
  extendFocusTopThreeTeaserCompositionWithMandatoryBasicLooks,
  extendFocusTopThreeTeaserCompositionWithNoImageBasicLooks,
  css`
  ${mediaMin.lg`
    .teaser-wrap${selector.justNElements(1)}{
      .teaser {
        flex-direction: row;
      }
      .teaser-image-wrap {
        max-width: 66.666666%;
      }
      .teaser-data-wrap {
        max-width: 33.333333%;
      }
    }
  `}
`,
);
