import gql from 'graphql-tag';
import { StyledContentBlockList as ContentBlockList } from '../../ContentBlocks/Types/ContentBlockList.jsx';
import { StyledDefaultLayout as DefaultLayout } from '../Layouts/DefaultLayout';
import { StyledHeadlineContentTeaser as HeadlineContentTeaser, headlineContentTeaserFragment } from '../ContentTeasers/HeadlineContentTeaser.jsx';
import { noop } from '../../../utils/commons';
import { makeMemoFragment } from '../../../utils/graphql';

export const listDefaultUnifiedHeadlineFragment = makeMemoFragment({
  name: 'ListDefaultUnifiedHeadline',
  fragment() {
    return gql`fragment ${this.name} on Content {
        ... on ModelAspect {
          id
        }
        ...${headlineContentTeaserFragment.name}
      }
      ${headlineContentTeaserFragment.fragment()}
    `;
  },
});

export const ListDefaultUnifiedHeadline = ({ contents = [], preListSlotFn = noop, ...restProps }) => {
  const preListSlot = preListSlotFn();
  return (
    <ContentBlockList headlineIsA='h2' {...restProps}>
      {preListSlot}
      <DefaultLayout>
        {contents.map(content => (
          <HeadlineContentTeaser
            key={content.id}
            content={content}
            titleIsA={preListSlot ? 'h4' : 'h3'}
            isCurrent={content.isCurrent}
          />
        ))}
      </DefaultLayout>
    </ContentBlockList>
  );
};

export const StyledListDefaultUnifiedHeadline = ListDefaultUnifiedHeadline;
