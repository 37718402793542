import globals from '../../utils/globals';
import { refreshEventName } from './constants';

export const dispatchFlyingFocusRefresh = (options = { delay: 0 }) => {
  const { documentElement } = globals.document;
  documentElement.dispatchEvent(new CustomEvent(refreshEventName, { detail: options }));
};

// having this utility function here makes things easier and allows to avoid mistakes when function
// used e.g. directly as listener callback
export const dispatchFlyingFocusRefreshNoDelay = () => dispatchFlyingFocusRefresh();
