import { styled } from '@linaria/react';

export const BootstrapLtrOverrideGlobalStyles = styled.div`
:global() {
  body {
    direction: inherit;
    text-align: start;
  }
}
`;
