import gql from 'graphql-tag';
import { Helmet } from 'react-helmet-async';
import { makeMemoFragment } from '../utils/graphql';

export const podcastMetadataFragment = makeMemoFragment({
  name: 'PodcastMetadata',
  fragment() {
    return gql`fragment ${this.name} on RssFeedAspect {
      podcast {
        url
        title
      }
    }
    `;
  },
});

export const PodcastMetadata = ({ content }) => {
  const { podcast } = content;

  if (!podcast) {
    return null;
  }

  const { title, url } = podcast;

  return (
    <Helmet>
      <link type="application/rss+xml" rel="alternate" title={title} href={url}/>
    </Helmet>
  );
};
