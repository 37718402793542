import { styled } from '@linaria/react';
import { resetBoostrapAndBrowserDefaultLineHeight } from '../../utils/commons';

export const NavigationItem = ({ children, className }) => (
  <li className={className}>
    {children}
  </li>
);

export const StyledNavigationItem = styled(NavigationItem)`
  ${resetBoostrapAndBrowserDefaultLineHeight}
  font-size: 1.6rem;
`;
