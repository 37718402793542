import globals from '../globals';
import { loadScriptPromise } from '../staticResourceHelpers';

const retrieveInstagramJsScript = async () => {
  await loadScriptPromise({
    url: 'https://www.instagram.com/static/bundles/metro/EmbedSDK.js/33cd2c5d5d59.js',
    scriptId: 'instagram-sdk-script',
    vendorId: 's14',
  });
  return new Promise(resolve => {
    const { instgrm, document } = globals.window;
    instgrm && document && resolve({ instgrm, document });
  });
};

// eslint-disable-next-line fp/no-let
let loadingApiPromise = null;

// needed only for tests at the moment
export const resetInstagramApi = () => {
  globals.window.instgrm = null; // eslint-disable-line fp/no-mutation
  // eslint-disable-next-line fp/no-mutation
  loadingApiPromise = null;
};

export const loadInstagramApi = async () => {
  if (!loadingApiPromise) {
    // eslint-disable-next-line fp/no-mutation
    loadingApiPromise = retrieveInstagramJsScript();
  }
  return loadingApiPromise;
};
