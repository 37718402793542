import { styled } from '@linaria/react';
import { css, cx } from '@linaria/core';

import { mediaMin, colors, hexToRgba } from '../../../utils/css';
import { contentBlockPadding } from '../ContentBlock';
import { StyledTopicImageWithCustomTitle as TopicImageWithCustomTitle, darkTopicImageWithCustomTitleStyles, topicImageWithCustomTitleFragment } from '../TopicHeader/TopicImageWithCustomTitle.jsx';
import { darkTopicClusterTitleStyles, StyledTopicClusterTitle as TopicClusterTitle } from './TopicClusterTitle.jsx';
import { darkTopicTeaserTextStyles, StyledTopicTeaserText as TopicTeaserText } from './TopicTeaserText.jsx';
import { topStoryTitleMargin } from '../TopStory/TopStoryContentTeaserTitle.jsx';

export const mainTopicFragment = topicImageWithCustomTitleFragment;

export const MainTopic = ({
  content,
  className,
  topicTitleSelector,
  topicTeaserSelector,
  aboutTopicTitleTranslation,
}) => (
  <div className={className}>
    <TopicImageWithCustomTitle
      className='image-with-title'
      content={content}
      titleSlotFn={() => (
        <TopicClusterTitle
          className='custom-topic-title'
          title={aboutTopicTitleTranslation}
        >
          {topicTitleSelector(content)}
        </TopicClusterTitle>
      )}
    />
    <TopicTeaserText>{topicTeaserSelector(content)}</TopicTeaserText>
  </div>
);

export const cssVariables = {
  gradient: '--topic-gradient-color',
  background: '--topic-background-color',
};

export const darkMainTopicStyles = cx(
  darkTopicImageWithCustomTitleStyles,
  darkTopicClusterTitleStyles,
  darkTopicTeaserTextStyles,
  css`
    ${cssVariables.gradient}: linear-gradient(to bottom, ${hexToRgba(colors.DARK_BLUE_GREY_01, 0)}, ${hexToRgba(colors.DARK_BLUE_GREY_01, 0.85)} 90%);
    ${cssVariables.background}: ${hexToRgba(colors.DARK_BLUE_GREY_01, 0.85)};
  `,
);

export const StyledMainTopic = styled(MainTopic)`
  .image-with-title {
    margin-inline: -${contentBlockPadding.xs}px;

    .topic-gradient {
      background-image: var(${cssVariables.gradient}, linear-gradient(to bottom, ${hexToRgba(colors.BLUE_GREY_02, 0)}, ${hexToRgba(colors.BLUE_GREY_02, 0.85)} 90%));
    }

    .topic-background {
      background-color: var(${cssVariables.background}, ${hexToRgba(colors.BLUE_GREY_02, 0.85)});
    }

    .custom-topic-title {
      ${topStoryTitleMargin}
    }
    .topic-title:first-child .custom-topic-title {
      margin-top: 0;
    }
  }



  ${mediaMin.sm`
    .image-with-title {
      margin-inline: -${contentBlockPadding.sm}px;
    }
  `}

  ${mediaMin.md`
    .image-with-title {
      margin-inline: -${contentBlockPadding.md}px;

      .custom-topic-title {
        margin-bottom: 10px;
      }
    }

  `}

  ${mediaMin.lg`
    .image-with-title {
      margin-inline: 0;
    }
  `}
`;
