import gql from 'graphql-tag';
import { cx } from '@linaria/core';
import { authorLinkFragment, StyledAuthorLink as AuthorLink, darkAuthorLinkStyles } from '../../../ContentDetailHeader/AuthorsLine/AuthorLink.jsx';
import { darkOpinionMetadataStyles, opinionMetadataFragment, StyledOpinionMetadata as OpinionMetadata } from './OpinionMetadata.jsx';
import { authorLinkedImageFragment, StyledAuthorLinkedImage as AuthorLinkedImage } from '../../../ContentDetailHeader/AuthorsLine/AuthorLinkedImage.jsx';
import { useContentTeaserContext } from '../ContentTeaserContext';
import { makeMemoFragment } from '../../../../utils/graphql';

export const authorDataFragment = makeMemoFragment({
  name: 'AuthorData',
  fragment() {
    return gql`fragment ${this.name} on Content {
        ... on AssociationsAspect {
          firstPersonArray: persons(amount: 1) {
            personType
            ...${authorLinkedImageFragment.name}
            ...${authorLinkFragment.name}
          }
        }
        ...${opinionMetadataFragment.name}
      }
      ${authorLinkedImageFragment.fragment()}
      ${authorLinkFragment.fragment()}
      ${opinionMetadataFragment.fragment()}
    `;
  },
});

export const AuthorData = ({ children, className, forceHideAuthorLinkedImage = false }) => {
  const {
    content: {
      firstPersonArray: [person],
    },
    config: {
      hide: {
        opinionMetadata: hideOpinionMetadata,
      },
    },
  } = useContentTeaserContext();

  return (
    <>
      {!forceHideAuthorLinkedImage && (
        <AuthorLinkedImage className="author-image" person={person}/>
      )}
      <div className={cx(className, 'author-data')}>
        {children}
        <AuthorLink person={person} />
        { !hideOpinionMetadata &&
          <OpinionMetadata className="opinion-metadata"/>
        }
      </div>
    </>
  );
};

export const darkAuthorDataStyles = cx(
  darkAuthorLinkStyles,
  darkOpinionMetadataStyles,
);

export const StyledAuthorData = AuthorData;
