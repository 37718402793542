import { styled } from '@linaria/react';

import { mediaMin } from '../../utils/css';

export const VerticalColumnsList = ({ className, ...restProps }) => (
  <ul className={className} {...restProps}/>
);
// TODO linaria-next: css``
export const commonVerticalColumnsListStyles = `
  list-style: none;
  margin: 0;
  padding: 0;
  > * {
    padding-bottom: 15px;
    break-inside: avoid;
  }
  column-gap: 30px;

  ${mediaMin.md`
    column-count: 2;
  `}
  ${mediaMin.xl`
    column-count: 3;
  `}
`;

export const StyledVerticalColumnsList = styled(VerticalColumnsList)`
  ${commonVerticalColumnsListStyles}
`;
