/* eslint-disable react/no-unknown-property */
import { Helmet } from 'react-helmet-async';
import { resolutions } from '../../utils/css';
import { format, isInternalDwImageUrl, replaceFormatInUrl } from '../../utils/imgUtils';
import { contentAreaWidth } from '../layouts/ContentMoveUpLayout/ContentArea';
import { useFrontendConfig } from '../FrontendConfigContext.jsx';

const getFormatFitsForCurrentBreakpointRange = ({ maxWidth, minWidth, formatsForCurrentGroup }) => {
  const formatsThatFitBreakpoint = formatsForCurrentGroup
    .filter(imgFormat => imgFormat.width >= minWidth && imgFormat.width <= maxWidth)
    .map((imgFormat, index, imgFormats) => {
      const prevImgWidth = imgFormats[index - 1]?.width;
      const mediaMin = prevImgWidth ? prevImgWidth + 1 : minWidth;
      const mediaMax = imgFormat.width;

      return {
        ...imgFormat,
        mediaMin,
        mediaMax,
      };
    });

  if (formatsThatFitBreakpoint.length === 0) {
    return formatsThatFitBreakpoint;
  }
  const lastImgFormatForBreakpoint = formatsThatFitBreakpoint.slice(-1)[0];
  if (lastImgFormatForBreakpoint.width < maxWidth && maxWidth !== resolutions.max.wide_xl) {
    const nextFormat = formatsForCurrentGroup.find(groupFormat => groupFormat.width > lastImgFormatForBreakpoint.width);
    if (nextFormat) {
      return [
        ...formatsThatFitBreakpoint,
        {
          ...nextFormat,
          mediaMin: lastImgFormatForBreakpoint.mediaMax + 1,
          mediaMax: maxWidth,
        },
      ];
    }
  }
  return formatsThatFitBreakpoint;
};

export const HeroImagePreloader = ({ imageUrl, formatConfig, urlReplacementFunction = replaceFormatInUrl }) => {
  const { staticBaseHost } = useFrontendConfig();

  if (!imageUrl || !formatConfig) {
    return null;
  }

  if (!isInternalDwImageUrl({ imageUrl, staticBaseHost })) {
    return (
      <Helmet>
        <link rel="preload" as="image" fetchpriority="high" href={imageUrl} />
      </Helmet>
    );
  }

  const imgFormats = formatConfig
    .map(({ formatGroupName, minWidth, maxWidth }) => {
      const formatsForCurrentGroup = format[formatGroupName].formats;

      const formatsThatFitBreakpoint = getFormatFitsForCurrentBreakpointRange({
        formatsForCurrentGroup,
        minWidth,
        maxWidth: Math.min(maxWidth, resolutions.max.lg), // hero elements always limited in size since xl
      });
      if (maxWidth >= resolutions.min.xl) {
        const xlFixed = {
          ...formatsForCurrentGroup.find(imgFormat => imgFormat.width >= contentAreaWidth.xl),
          mediaMin: resolutions.min.xl,
          mediaMax: resolutions.max.wide_xl,
        };

        const formatsThatFitBreakpointFixedHighRes = [
          ...formatsThatFitBreakpoint,
          xlFixed,
        ];

        return formatsThatFitBreakpointFixedHighRes;
      }
      return formatsThatFitBreakpoint;
    }).flat().filter(Boolean);

  return (
    <Helmet>
      {imgFormats.map((imgFormat, index) => {
        const notLast = index !== imgFormats.length - 1;
        return (
          <link key={index} rel="preload" as="image" fetchpriority="high"
            media={`(min-width: ${imgFormat.mediaMin}px) ${notLast ? `and (max-width: ${imgFormat.mediaMax}px)` : ''}`}
            href={urlReplacementFunction(imageUrl, imgFormat)}
          />
        );
      })}
    </Helmet>
  );
};
