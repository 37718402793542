import { StyledBigImageTextContentTeaser as BigImageTextContentTeaser, bigImageTextContentTeaserFragment, darkBigImageTextContentTeaserStyles } from '../../ContentTeaserSnippets/ContentTeasers/BigImageTextContentTeaser.jsx';
import { extendCarouselWithBigTextTeaserLooks } from '../../ContentTeaserSnippets/TeaserComposition/TeaserLayoutRelated/BigTeasers/BigTextTeaser';
import { extendWithClassNames } from '../../../utils/extendWithClassNames.jsx';

export const avCarouselTeaserFragment = bigImageTextContentTeaserFragment;

export const AVCarouselTeaser = props => (
  <BigImageTextContentTeaser
    {...props}
    hideDescription
  />
);

export const darkAVCarouselTeaserStyles = darkBigImageTextContentTeaserStyles;

export const StyledAVCarouselTeaser = extendWithClassNames(AVCarouselTeaser)(
  extendCarouselWithBigTextTeaserLooks,
);
