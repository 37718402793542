import { styled } from '@linaria/react';

export const ScreenReaderOnly = ({
  className,
  children,
}) => {
  if (!children) {
    return null;
  }
  return (
    <span className={`sr-only ${className}`}>{children}</span>
  );
};

// TODO linaria-next utils class css``
export const screenReaderOnly = `
    position: absolute;
    top: 0;
    clip: rect(1px, 1px, 1px, 1px);
    clip-path: inset(50%);
    height: 1px;
    width: 1px;
    overflow: hidden;
    margin: -1px;
    white-space: nowrap;
`;

export const StyledScreenReaderOnly = styled(ScreenReaderOnly)`
  ${screenReaderOnly}
`;
