import {
  listDefaultUnifiedBigImageTextFragment,
  ListDefaultUnifiedBigImageTextTemplate,
} from '../../ContentTeaserSnippets/Templates/ListDefaultUnifiedBigImageTextTemplate.jsx';

export const breakingNewsFragment = listDefaultUnifiedBigImageTextFragment;

export const BreakingNews = ({ contents, className }) => {
  if (!contents) {
    return null;
  }
  return (
    <ListDefaultUnifiedBigImageTextTemplate
      headlineTranslation = "content_block.breaking_news.headline"
      className={className}
      contents={contents.slice(0, 1)}
      pageSectionId='breaking-news'
    />
  );
};

export const StyledBreakingNews = BreakingNews;
