import { styled } from '@linaria/react';
import { format } from '../../utils/imgUtils';
import { buildSrcSet, getImageUrlReplacementFunction, getSourcesMeta } from './utils';
import { colors } from '../../utils/css';
import { HeroImagePreloader } from '../HeroImagePreloader';

const NOT_SUPPORTED_IMG_FORMATS = ['90X', 'FREE', 'LEGACY'];

export const HeroImage = ({
  image, alt, formatConfig, className,
}) => {
  if (!image || !formatConfig || formatConfig.find(({ formatGroupName }) => NOT_SUPPORTED_IMG_FORMATS.includes(formatGroupName))) {
    return null;
  }

  const fallbackFormatGroupName = '60X';
  const fallbackFormat = format[fallbackFormatGroupName];

  return (
    <>
      <HeroImagePreloader imageUrl={image.staticUrl} formatConfig={formatConfig} urlReplacementFunction={getImageUrlReplacementFunction('webp')}/>
      <picture className={className}>
        {getSourcesMeta({ formatConfig, staticUrlTemplate: image.staticUrl, ext: 'webp' }).map((sourceMeta, index) => (
          <source key={index} type="image/webp" {...sourceMeta} width="100"/>
        ))}
        {getSourcesMeta({ formatConfig, staticUrlTemplate: image.staticUrl }).map((sourceMeta, index) => (
          <source key={index} type="image/jpeg" {...sourceMeta} width="100"/>
        ))}

        <img
          // eslint-disable-next-line react/no-unknown-property
          fetchpriority="high"
          src=""
          srcSet={buildSrcSet({ staticUrlTemplate: image.staticUrl, formatGroupName: fallbackFormatGroupName })}
          width="100"
          height={fallbackFormat.aspectRatio}
          title={alt}
          alt={alt}
        />
      </picture>
    </>
  );
};

export const StyledHeroImage = styled(HeroImage)`
  img {
    display: block;
    height: auto;
    width: 100%;
    object-fit: cover;
    background-color: ${colors.BLUE_GREY_02};
  }
`;
