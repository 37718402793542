import { css } from '@linaria/core';
import { darkTeaserTextStyles, StyledTeaserText as TeaserText } from '../../TeaserText.jsx';
import { mediaMin } from '../../../utils/css';
import { extendWithClassNames } from '../../../utils/extendWithClassNames.jsx';

export const darkTopicTeaserTextStyles = darkTeaserTextStyles;

const topicTestTextStyles = css`
  text-align: center;
  margin-top: 20px;

  ${mediaMin.lg`
    margin-top: 30px;
    padding-inline: 80px;
  `}

  ${mediaMin.xl`
    padding-inline: 95px;
  `}
`;

export const StyledTopicTeaserText = extendWithClassNames(TeaserText)(
  topicTestTextStyles,
);
