import gql from 'graphql-tag';
import { styled } from '@linaria/react';
import { cx } from '@linaria/core';
import { StyledTeaserFooter as TeaserFooter, teaserFooterFragment, darkTeaserFooterStyles } from './TeaserFooter';
import { StyledOpinion as Opinion, opinionFragment, darkOpinionStyles } from './Opinion.jsx';
import { makeMemoFragment } from '../../../../utils/graphql';

export const teaserDataFragment = makeMemoFragment({
  name: 'TeaserData',
  fragment() {
    return gql`fragment ${this.name} on Content {
        ...${opinionFragment.name}
        ...${teaserFooterFragment.name}
      }
      ${opinionFragment.fragment()}
      ${teaserFooterFragment.fragment()}
    `;
  },
});

export const TeaserData = ({ children, className }) => (
  <>
    <div className="teaser-data">
      {children}
    </div>
    <Opinion className={`${className} opinion-wrapper`}/>
    <TeaserFooter className={`${className} teaser-footer-wrapper`}/>
  </>
);

export const darkTeaserDataStyles = cx(
  darkOpinionStyles,
  darkTeaserFooterStyles,
);

export const StyledTeaserData = styled(TeaserData)`
  &.opinion-wrapper,
  &.teaser-footer-wrapper:not(:empty){
    margin-top: 15px;
  }
`;
