import { StyledListDefaultUnifiedBigAvText as ListDefaultUnifiedBigAvTextTemplate, listDefaultUnifiedBigAvTextFragment, darkListDefaultUnifiedBigAvTextStyles } from '../../ContentTeaserSnippets/Templates/ListDefaultUnifiedBigAvText.jsx';
import { extendWithClassNames } from '../../../utils/extendWithClassNames.jsx';

export const latestEpisodeFragment = listDefaultUnifiedBigAvTextFragment;

export const LatestEpisode = ({
  className,
  content,
  ...restProps
}) => {
  if (!content) {
    return null;
  }
  return (
    <ListDefaultUnifiedBigAvTextTemplate
      className={className}
      headlineTranslation="content_block_detail.latest_episode.headline"
      contents={[content]}
      hideKicker
      {...restProps}
    />
  );
};

export const StyledLatestEpisode = extendWithClassNames(LatestEpisode)(darkListDefaultUnifiedBigAvTextStyles);
