import gql from 'graphql-tag';
import { makeMemoFragment } from '../../../../utils/graphql';
import {
  darkExpandableListDefaultHighlightSmallImageText,
  expandableListDefaultHighlightSmallImageTextFragment,
  StyledExpandableListDefaultHighlightSmallImageText as ExpandableListDefaultHighlightSmallImageText,
} from '../../../ContentTeaserSnippets/Templates/ExpandableListDefaultHighlightSmallImageText.jsx';

export const personContentItemsFragment = makeMemoFragment({
  name: 'PersonContentItems',
  fragment() {
    return gql`fragment ${this.name} on Person {
      fullName
      moreContent {
        ...${expandableListDefaultHighlightSmallImageTextFragment.name}
      }
    }
    ${expandableListDefaultHighlightSmallImageTextFragment.fragment()}
    `;
  },
});

export const PersonContentItems = ({ person, className }) => (
  <ExpandableListDefaultHighlightSmallImageText
    contents={person.moreContent}
    className={className}
    showMoreBtnTranslation="content_block_detail.person_content_items.show_more"
    headlineTranslation={{
      key: 'content_block_detail.person_content_items.headline',
      parameters: { fullName: person.fullName },
    }}
    pageSectionId='person-content-items' />
);

export const darkPersonContentItemsStyles = darkExpandableListDefaultHighlightSmallImageText;

export const StyledPersonContentItems = PersonContentItems;
