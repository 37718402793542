import { useEffect, useState } from 'react';
import { isServer } from '../../utils/ssr';
import globals from '../../utils/globals';

const readSessionValue = (key, initialValue) => {
  if (isServer()) {
    return null;
  }
  const stored = globals.sessionStorage.getItem(key);
  if (!stored) {
    return initialValue;
  }
  return JSON.parse(stored);
};

const setSessionValue = (key, value) => {
  if (!isServer()) {
    globals.sessionStorage.setItem(key, JSON.stringify(value));
  }
};

export const useSessionStorage = (key, initialValue) => {
  const [value, setValue] = useState(readSessionValue(key, initialValue));

  useEffect(() => {
    setSessionValue(key, value);
  }, [key, value]);

  return [value, setValue];
};
