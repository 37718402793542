import {
  createContext, useContext,
} from 'react';
import { useWindowInnerWidthContext } from '../WindowInnerWidthContext';

export const CarouselContext = createContext([{ itemsPerSlide: 1 }, {}]);

export const useCarouselContext = () => useContext(CarouselContext);

export const ITEMS_PER_SLIDE_CONFIG = {
  default: [{ res: 0, itemsPerSlide: 1 }, { res: 768, itemsPerSlide: 2 }],
  featured: [{ res: 0, itemsPerSlide: 1 }],
};

export const CarouselContextProvider = ({
  children,
  itemsPerSlideConfig = ITEMS_PER_SLIDE_CONFIG.default,
  onDragEndWithoutSlideChange,
}) => {
  const innerWidth = useWindowInnerWidthContext({ isAlwaysReset: true });

  // Please note: we use sort function instead of toSorted here to keep compatibility for older browsers (e. g. Huawei browser v14).
  // eslint-disable-next-line fp/no-mutating-methods
  const configSorted = [...itemsPerSlideConfig].sort((config1, config2) => config2.res - config1.res);

  const { itemsPerSlide } = configSorted
    .find(cfg => innerWidth >= cfg.res) || configSorted[configSorted.length - 1];

  return (
    <CarouselContext.Provider value={[{ itemsPerSlide, onDragEndWithoutSlideChange }]}>
      {children}
    </CarouselContext.Provider>
  );
};
