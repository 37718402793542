import gql from 'graphql-tag';
import { styled } from '@linaria/react';
import { makeMemoFragment } from '../../../utils/graphql';

import { StyledContentBlockList as ContentBlockList } from '../Types/ContentBlockList.jsx';
import { currentNewsItemFragment, darkCurrentNewsItemStyles, StyledCurrentNewsItem as CurrentNewsItem } from './CurrentNewsItem.jsx';
import { StyledExpandable as Expandable } from '../../Expandable';
import { DEFAULT_TEASER_SPACING } from '../../ContentTeaserSnippets/TeaserComposition';

export const currentNewsFragment = currentNewsItemFragment;

export const currentNewsConfigFragment = makeMemoFragment({
  name: 'CurrentNewsConfig',
  fragment() {
    return gql`fragment ${this.name} on CompositionComponent {
        configuration {
          numContentItems
        }
      }
    `;
  },
});

export const INITIAL_TEASER_AMOUNT = 6;

export const CurrentNews = ({
  contents = [],
  className,
  pageSectionId,
  configuration = {
    numContentItems: INITIAL_TEASER_AMOUNT,
  },
}) => (
  <ContentBlockList
    headlineTranslation="content_block.news.headline"
    className={className}
    headlineIsA="h2"
    pageSectionId={pageSectionId}
  >
    <Expandable
      dataArray={contents}
      initialListCount={configuration.numContentItems}
      focusContainerClass="news-item"
      className="news"
    >
      {renderedContents => renderedContents.map(newsItem => newsItem && (
        <CurrentNewsItem className="news-item" key={newsItem.id} newsItem={newsItem}/>
      ))}

    </Expandable>
  </ContentBlockList>
);

export const darkCurrentNewsStyles = darkCurrentNewsItemStyles;

export const StyledCurrentNews = styled(CurrentNews)`
  .news {
    display: flex;
    flex-wrap: wrap;
    row-gap: ${DEFAULT_TEASER_SPACING};
  }
`;
