import gql from 'graphql-tag';
import { makeMemoFragment } from '../../../../utils/graphql';
import {
  StyledHubDefaultUnifiedBigImageText as HubDefaultUnifiedBigImageTextTemplate,
  hubDefaultUnifiedBigImageTextFragment,
} from '../../../ContentTeaserSnippets/Templates/HubDefaultUnifiedBigImageText.jsx';
import { geoRegionStoriesConfigurationFragment } from '../HighPriorityStories';

export const storiesHubFragment = makeMemoFragment({
  name: 'StoriesHub',
  fragment() {
    return gql`fragment ${this.name} on CocoContent {
        configuration {
          sourceAssociations {
            targetId
            target {
              ... on NamedAspect {
                name
              }
            }
          }
        }
        content {
          ...${hubDefaultUnifiedBigImageTextFragment.name}
        }
      }
      ${hubDefaultUnifiedBigImageTextFragment.fragment()}
    `;
  },
});

export const storiesHubGeoRegionFragment = makeMemoFragment({
  name: 'StoriesHubGeoRegion',
  fragment() {
    return gql`fragment ${this.name} on CocoContent {
        configuration {
          ...${geoRegionStoriesConfigurationFragment.name}
        }  
        content {
          ...${hubDefaultUnifiedBigImageTextFragment.name}
        }
      }
      ${geoRegionStoriesConfigurationFragment.fragment()}
      ${hubDefaultUnifiedBigImageTextFragment.fragment()}
    `;
  },
});

export const defaultHeadlineFn = cocoContent => {
  const firstSourceAssociation = cocoContent.configuration.sourceAssociations[0];
  return firstSourceAssociation && firstSourceAssociation.target &&
    firstSourceAssociation.target.name;
};

export const StoriesHub = ({ contents: cocoContents = [], hubHeadlineFn = defaultHeadlineFn, ...restProps }) => (
  <HubDefaultUnifiedBigImageTextTemplate hubContents={cocoContents} hubHeadlineFn={hubHeadlineFn} {...restProps}/>
);

export const StyledStoriesHub = StoriesHub;
