import {
  createContext, useContext,
} from 'react';

// eslint-disable-next-line fp/no-let
let isSilent = false;

const getIsSilent = () => isSilent;

// eslint-disable-next-line fp/no-mutation
const setIsSilent = v => { isSilent = v; };

export const SilentRedirectContext = createContext({ getIsSilent, setIsSilent });

export const useSilentRedirectContext = () => useContext(SilentRedirectContext);

export const SilentRedirectContextProvider = ({ children }) => (
  <SilentRedirectContext.Provider value={{ getIsSilent, setIsSilent }}>
    {children}
  </SilentRedirectContext.Provider>
);
