import { cx } from '@linaria/core';
import {
  StyledListColumnUnifiedSmallImageMix as ListColumnUnifiedSmallImageMix,
  listColumnUnifiedSmallImageMixFragment,
  darkListColumnUnifiedSmallImageMixStyles,
} from '../../ContentTeaserSnippets/Templates/ListColumnUnifiedSmallImageMix.jsx';

export const headlinesListFragment = listColumnUnifiedSmallImageMixFragment;

export const HeadlinesList = ({
  className, contents, headlineTranslation, pageSectionId,
}) => (
  <ListColumnUnifiedSmallImageMix
    className={className}
    contents={contents}
    headlineTranslation={headlineTranslation}
    pageSectionId={pageSectionId}
    hideDescription
  />
);

export const darkHeadlinesListStyles = cx(
  darkListColumnUnifiedSmallImageMixStyles,
);

HeadlinesList.darkStyles = darkHeadlinesListStyles;
