import { css, cx } from '@linaria/core';
import { colors } from '../../../utils/css';
import { isExternalLink } from '../../../utils/url';
import { ExternalLink } from '../ExternalLink.jsx';
import { InternalLink } from '../InternalLink';
import {
  StyledText,
  wideClass,
} from '../../Text/TextBase';
import { TextDefault } from '../../Text';
import { extendWithClassNames } from '../../../utils/extendWithClassNames.jsx';
import { globalCssVariables } from '../../Page/PageGlobalStyles';

export const LinkBase = ({
  className,
  to,
  title,
  skipTitleForInternal,
  ...restProps
}) => {
  const isExternalUrl = isExternalLink(to);

  if (isExternalUrl) {
    return (
      <StyledText
        isA={ExternalLink}
        className={className}
        to={to}
        title={title}
        {...restProps}
      />
    );
  }
  return (
    <StyledText
      isA={InternalLink}
      className={className}
      to={to}
      title={skipTitleForInternal ? undefined : title }
      {...restProps}
    />
  );
};

export const cssVariables = {
  color: '--link-color',
  visited: '--link-visited-color',
  hover: '--link-hover-color',
  active: '--link-active-color',
};

// TODO linaria-next all these could be css`` classes ?! or not?!
export const hoverStyles = `
  color: var(${cssVariables.hover}, ${colors.DW_DARK_BLUE});
  text-decoration: underline;
`;

export const activeStyles = `
  color: var(${cssVariables.active}, ${colors.DARK_BLUE_GREY_01});
`;

export const baseLinkStyles = `
  text-decoration: none;
  cursor: pointer;

  &, & > * {
    line-height: var(${globalCssVariables.lineHeight});
  }
  svg {
    fill: currentColor;
  }

  color: var(${cssVariables.color}, ${colors.DW_DARK_BLUE});

  &:visited {
    color: var(${cssVariables.visited}, ${colors.BLUE_GREY_04});
  }
  &:hover {
    ${hoverStyles}
  }
  &:active {
    ${activeStyles}
  }
`;

export const baseLinkClass = css`${baseLinkStyles}`;

export const extendedLinkStyles = `
  &:focus {
    outline: none;
  }
`;
export const extendedLinkClass = css`${extendedLinkStyles}`;

export const lightLinkStyles = css`
  ${cssVariables.color}: ${colors.DW_DARK_BLUE};
  ${cssVariables.visited}: ${colors.BLUE_GREY_04};
  ${cssVariables.hover}: ${colors.DW_DARK_BLUE};
  ${cssVariables.active}: ${colors.DARK_BLUE_GREY_01};
`;

export const darkLinkStyles = cx(
  TextDefault.darkStyles,
  css`
    ${cssVariables.color}: ${colors.WHITE};
    ${cssVariables.visited}: ${colors.BLUE_GREY_03};
    ${cssVariables.hover}: ${colors.WHITE};
    ${cssVariables.active}: ${colors.BLUE_GREY_02};
  `,

);

export const StyledLinkBase = extendWithClassNames(LinkBase)(baseLinkClass, extendedLinkClass, wideClass);
StyledLinkBase.darkStyles = darkLinkStyles;
