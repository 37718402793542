import { StyledChevronOpenClose as ChevronOpenClose } from '../icons';
import { ButtonTertiaryWithIconOnly } from '../buttons/Button';

export const MoreActionsButton = ({
  className,
  onClick,
  title,
  isOpen,
}) => (
  <ButtonTertiaryWithIconOnly className={className}
    onClick={onClick}
    title={title}
    aria-expanded={isOpen}
  >
    <ChevronOpenClose pointsDown={!isOpen}/>
  </ButtonTertiaryWithIconOnly>
);

export const darkMoreActionsButtonStyles = ButtonTertiaryWithIconOnly.darkStyles;

export const StyledMoreActionsButton = MoreActionsButton;
