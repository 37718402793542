import { styled } from '@linaria/react';
import { mediaMin, resolutions } from '../../../utils/css';
import {
  smallImageContentTeaserFragment,
  StyledSmallImageContentTeaser as SmallImageContentTeaser,
  darkSmallImageContentTeaserStyles,
} from '../../ContentTeaserSnippets/ContentTeasers/SmallImageContentTeaser.jsx';

export const explainerTeaserFragment = smallImageContentTeaserFragment;

const explainerTeaserImageConfig = [
  {
    minWidth: resolutions.min.xs,
    maxWidth: resolutions.max.xs,
    formatGroupName: '80X',
  },
  {
    minWidth: resolutions.min.sm,
    maxWidth: resolutions.max.sm,
    formatGroupName: '60X',
  },
  {
    minWidth: resolutions.min.md,
    maxWidth: resolutions.max.md,
    formatGroupName: '80X',
  },
  {
    minWidth: resolutions.min.lg,
    maxWidth: resolutions.max.wide_xl,
    formatGroupName: '60X',
  },
];

export const ExplainerTeaser = ({
  content,
  className,
}) => {
  if (!content) {
    return null;
  }
  return (
    <div className={className}>
      <SmallImageContentTeaser
        content={content}
        imageFormatConfig={explainerTeaserImageConfig}
        hideDescription
      />
    </div>
  );
};

export const darkExplainerTeaserStyles = darkSmallImageContentTeaserStyles;

export const StyledExplainerTeaser = styled(ExplainerTeaser)`
  .teaser{
    display: flex;
  }

  .teaser-image-wrap {
    max-width: 80px;
    max-height: 80px;
    padding: 0;
    margin-inline-end: 15px;
  }

  .teaser-data-wrap {
    flex: initial;
    display: flex;
    flex-direction: column;
    padding: 0;

    .teaser-data {
      flex-grow: 1;
    }
  }

  .title {
    font-size: 1.8rem;
    font-style: normal;
  }

  .teaser-footer-wrapper {
    margin-top: 10px;
  }

  ${mediaMin.sm`
    .teaser-image-wrap {
      max-width: 150px;
      max-height: 85px;
    }
    .title {
      font-size: 2rem;
    }
  `};

  ${mediaMin.md`
    .teaser-image-wrap {
      max-width: 90px;
      max-height: 90px;
    }
  `};

  ${mediaMin.lg`
    .teaser-image-wrap {
      max-width: 130px;
      max-height: 80px;
    }
  `};

  ${mediaMin.xl`
    .teaser-image-wrap {
      max-width: 160px;
      max-height: 90px;
    }
  `};
`;
