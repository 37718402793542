import { css, cx } from '@linaria/core';
import { useParams } from '../../dwouter';
import { useTranslation } from '../hooks/useTranslation.jsx';
import { mediaMin } from '../../utils/css';
import { AzIndexTopicGroupLink } from './AzIndexTopicGroupLink.jsx';

export const AzIndexTopicGroupSelector = ({ azIndexTopicGroups = [], className, innerRef }) => {
  const { selectedTopicGroupId } = useParams();
  const ariaLabelNavigation = useTranslation({
    key: 'content_block.topic_group_selector.pagination_nav.label',
    parameters: {
      topicGroupLabel: selectedTopicGroupId?.toUpperCase(),
    },
  });
  return (
    <nav role="navigation" aria-label={ariaLabelNavigation} className={cx(azIndexTopicGroupSelectorClass, className)} ref={innerRef}>
      <ul>
        {azIndexTopicGroups.map(group => (
          <li key={group.id}>
            <AzIndexTopicGroupLink group={group} />
          </li>
        ))}
      </ul>
    </nav>
  );
};

AzIndexTopicGroupSelector.darkStyles = AzIndexTopicGroupLink.darkStyles;

export const azIndexTopicGroupSelectorClass = css`
  ul {
    padding: 30px 0 0;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    gap: 18px;
    justify-content: center;
    li {
      list-style-type: none;
      white-space: nowrap;
    }
  }
  ${mediaMin.lg`
    ul {
      max-width: 772px;
    }
  `}
  ${mediaMin.xl`
    ul {
      max-width: 920px;
    }
  `}
`;
