import { styled } from '@linaria/react';
import { cx } from '@linaria/core';
import { StyledOpinion as Opinion, darkOpinionStyles } from '../../ContentTeaserSnippets/ContentTeaserPartials/Partials/Opinion.jsx';
import { StyledTeaserFooter as TeaserFooter, darkTeaserFooterStyles } from '../../ContentTeaserSnippets/ContentTeaserPartials/Partials/TeaserFooter';
import { mediaMin, selector } from '../../../utils/css';

export const TopStoryContentTeaserData = ({ className }) => (
  <div className={className}>
    <Opinion className={cx('opinion', darkOpinionStyles)}/>
    <TeaserFooter className={cx('teaser-footer', darkTeaserFooterStyles)}/>
  </div>
);

export const StyledTopStoryContentTeaserData = styled(TopStoryContentTeaserData)`
  .opinion{
    padding-bottom: 10px;
  }

  .teaser-footer {
    & > *${selector.notLast} {
      &:after {
        font-size: 1rem;
      }
    }
    .date-time {
      time {
        letter-spacing: 0.085rem;
      }
    }
  }


  ${mediaMin.md`
    .teaser-footer{
      font-size: 1.1rem;
    }
  `}

  ${mediaMin.lg`
    .teaser-footer{
      font-size: 1.3rem;
      & > *${selector.notLast} {
        &:after {
          font-size: 1.2rem;
          letter-spacing: 0.09rem;
        }
      }
    }
  `}
`;
