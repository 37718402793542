import { styled } from '@linaria/react';
import { useTranslation } from '../hooks/useTranslation.jsx';

export const A11yAriaLive = ({
  className,
  translation,
  restProps,
}) => {
  const text = useTranslation(translation);
  return (
    <span className={className} aria-live='polite' {...restProps}>
      {text}
    </span>
  );
};

export const StyledA11yAriaLive = styled(A11yAriaLive)`
  position: absolute;
  inset-inline-start: -9999px;
`;
