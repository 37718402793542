import { WithGraphQLContentHandling } from '../components/commons/WithGraphQLContentHandling';
import { contentDetailsFragmentFactory, ContentDetailsWithTheme } from '../components/ContentDetails';
import { useParams } from '../dwouter';
import { camelCaseToKebabCase } from '../utils/commons';
import { isArticle } from '../utils/contentUtils';
import { StyledAdvertorialContentDetailsWithTheme as AdvertorialContentDetails } from '../components/ContentDetails/AdvertorialContentDetails';

export const contentQueryOpts = type => ({
  name: 'getContentDetails',
  fragmentDef: contentDetailsFragmentFactory({ type }),
});

export const ContentDetailsPage = ({ type }) => {
  const { contentId, langCode } = useParams();
  const apiType = camelCaseToKebabCase(type);
  const queryDef = {
    path: `${langCode}/content/${apiType}/${contentId}`,
    depts: [langCode, contentId, apiType],
  };
  return (
    <WithGraphQLContentHandling queryDef={queryDef}>
      {({ content }) => {
        if (isArticle(content) && content.subdomain === 'ADVERTISING_PARTNERS') { // for tests withgraphql content handling has subdomain
          return (
            <AdvertorialContentDetails content={content} />
          );
        }
        return (
          <ContentDetailsWithTheme content={content} />
        );
      }}
    </WithGraphQLContentHandling>
  );
};
