import { suspendedComponentFactory } from '../../../SuspendedComponent/suspendedComponentFactory.jsx';

export { StyledSmFacebook } from './Facebook.jsx';
export { StyledSmInstagram } from './Instagram.jsx';
export { StyledSmTwitter } from './Twitter.jsx';
export { StyledSmYoutube } from './Youtube.jsx';

export const { StyledSmBalatarin } = suspendedComponentFactory(() => import('./Balatarin.jsx'));
export const { StyledSmDouban } = suspendedComponentFactory(() => import('./Douban.jsx'));
export const { StyledSmEmail } = suspendedComponentFactory(() => import('./Email.jsx'));
export const { StyledSmFacebookMessenger } = suspendedComponentFactory(() => import('./FacebookMessenger.jsx'));
export const { StyledSmFlipboard } = suspendedComponentFactory(() => import('./Flipboard.jsx'));
export const { StyledSmLine } = suspendedComponentFactory(() => import('./Line.jsx'));
export const { StyledSmLinkedIn } = suspendedComponentFactory(() => import('./LinkedIn.jsx'));
export const { StyledSmMeneame } = suspendedComponentFactory(() => import('./Meneame.jsx'));
export const { StyledSmMoikrug } = suspendedComponentFactory(() => import('./Moikrug.jsx'));
export const { StyledSmOdnoklassniki } = suspendedComponentFactory(() => import('./Odnoklassniki.jsx'));
export const { StyledSmQzone } = suspendedComponentFactory(() => import('./Qzone.jsx'));
export const { StyledSmReddit } = suspendedComponentFactory(() => import('./Reddit.jsx'));
export const { StyledSmRenren } = suspendedComponentFactory(() => import('./Renren.jsx'));
export const { StyledSmSms } = suspendedComponentFactory(() => import('./Sms.jsx'));
export const { StyledSmTelegram } = suspendedComponentFactory(() => import('./Telegram.jsx'));
export const { StyledSmTwoo } = suspendedComponentFactory(() => import('./Twoo.jsx'));
export const { StyledSmViber } = suspendedComponentFactory(() => import('./Viber.jsx'));
export const { StyledSmVkontakte } = suspendedComponentFactory(() => import('./Vkontakte.jsx'));
export const { StyledSmWechat } = suspendedComponentFactory(() => import('./Wechat.jsx'));
export const { StyledSmWeibo } = suspendedComponentFactory(() => import('./Weibo.jsx'));
export const { StyledSmWhatsapp } = suspendedComponentFactory(() => import('./Whatsapp.jsx'));
export const { StyledSmSharechat } = suspendedComponentFactory(() => import('./Sharechat.jsx'));
export const { StyledSmMoj } = suspendedComponentFactory(() => import('./Moj.jsx'));
export const { StyledSmTiktok } = suspendedComponentFactory(() => import('./Tiktok.jsx'));

export const { StyledComments } = suspendedComponentFactory(() => import('./Comments.jsx'));
export const { StyledPrint } = suspendedComponentFactory(() => import('./Print.jsx'));
export const { StyledTextSize } = suspendedComponentFactory(() => import('./TextSize.jsx'));
