import { styled } from '@linaria/react';
import { cx } from '@linaria/core';
import gql from 'graphql-tag';

import { StyledTeaserText as TeaserText, darkTeaserTextStyles } from '../TeaserText.jsx';
import { mediaMin } from '../../utils/css';
import { StyledTwitterLink as TwitterLink, darkTwitterLinkStyles } from '../ContentDetailHeader/AuthorsLine/TwitterLink.jsx';
import { StyledFacebookLink as FacebookLink, darkFacebookLinkStyles } from '../ContentDetailHeader/AuthorsLine/FacebookLink.jsx';
import { makeMemoFragment } from '../../utils/graphql';
import { StyledDetailHeaderHeadline as DetailHeaderHeadline, darkDetailHeaderHeadlineStyles } from '../DetailHeaderHeadline.jsx';

export const personDetailHeaderFragment = makeMemoFragment({
  name: 'PersonDetailHeader',
  fragment() {
    return gql`fragment ${this.name} on Person {
        fullName
        twitterHandle
        facebookUserName
        facebookPageName
        expertise
        teaser
      }
    `;
  },
});

export const darkPersonDetailHeaderStyles = cx(
  darkDetailHeaderHeadlineStyles,
  darkTeaserTextStyles,
  darkTwitterLinkStyles,
  darkFacebookLinkStyles,
);

export const PersonDetailHeader = ({ person, className }) => {
  const facebookName = person.facebookPageName || person.facebookUserName;
  return (
    <div className={className}>
      <DetailHeaderHeadline>{person.fullName}</DetailHeaderHeadline>
      <TeaserText className='expertise'>{person.expertise}</TeaserText>
      {(person.twitterHandle || facebookName) && (
        <address className='social-area'>
          {person.twitterHandle && (
            <TwitterLink className='social-link' twitterHandle={person.twitterHandle}/>
          )}
          {facebookName && (
            <FacebookLink className='social-link' facebookName={facebookName}/>
          )}
        </address>
      )}
      {person.teaser && (
        <TeaserText className='biography'>{person.teaser}</TeaserText>
      )}
    </div>
  );
};

export const StyledPersonDetailHeader = styled(PersonDetailHeader)`
  address {
    margin: 0;
  }

  .expertise {
    margin-bottom: 20px;
  }

  .biography {
    margin-bottom: 2px;
  }

  .social-area {
    display: flex;
    flex-direction: column;
    padding-top: 0.5px;
    padding-bottom: 15.5px;

    .social-link {
      font-size: 1.6rem;
      letter-spacing: 0.05rem;
      margin: 2.5px;

      svg {
        width: 22px;
        height: 22px;
        margin-inline-end: 5px;
      }
    }
  }


  ${mediaMin.sm`
    .social-area {
      flex-direction: row;
      flex-wrap: wrap;

      .social-link {
        margin-inline-end: 10px;
      }
    }
  `}

  ${mediaMin.md`
    .social-area {
      padding-top: 2.5px;
      padding-bottom: 17.5px;
    }
  `}
`;
