import gql from 'graphql-tag';
import { makeMemoFragment } from '../../utils/graphql';

import { LinkMonoColor } from '../Links';
import { StyledNavigationItem as NavigationItem } from './NavigationItem.jsx';
import { StyledVerticalColumnsList as VerticalColumnsList } from '../VerticalColumnsList';

export const navigationListFragment = makeMemoFragment({
  name: 'NavigationList',
  fragment() {
    return gql`fragment ${this.name} on Navigation {
          id
          name
          namedUrl
        }
    `;
  },
});

export const NavigationList = ({ navigations = [], className }) => (
  <VerticalColumnsList className={className}>
    {navigations.map(({ id, name, namedUrl }, itemCounter) => (
      <NavigationItem key={id}>
        <LinkMonoColor
          to={namedUrl}
          role="menuitem"
          aria-setsize={navigations.length}
          aria-posinset={itemCounter + 1}
        >
          {name}
        </LinkMonoColor>
      </NavigationItem>
    ))}
  </VerticalColumnsList>
);

export const StyledNavigationList = NavigationList;
