import gql from 'graphql-tag';
import { Helmet } from 'react-helmet-async';
import { makeMemoFragment } from '../utils/graphql';

export const videoOpenGraphFragment = makeMemoFragment({
  name: 'VideoOpenGraphMetadata',
  fragment() {
    return gql`fragment ${this.name} on OpenGraphAspect {
      openGraphMetadata {
        url
        width
        height
        duration
      }
    }
    `;
  },
});

export const VideoOpenGraphMetadata = ({ content }) => {
  if (!content.openGraphMetadata) {
    return null;
  }

  const {
    url, width, height, duration,
  } = content.openGraphMetadata;

  return (
    <Helmet>
      <meta property="og:video" content={url} />
      <meta property="og:video:secure_url" content={url} />
      <meta property="og:type" content="video.episode" />
      <meta property="og:video:type" content="video/mp4"/>
      <meta property="og:video:width" content={width}/>
      <meta property="og:video:height" content={height}/>
      <meta property="og:video:duration" content={duration}/>
    </Helmet>

  );
};
