import { PageGlobalStyles } from '../PageGlobalStyles';
import { DisableOutlineGlobalStyle } from '../../FlyingFocus'; // remove if lazy load one works fine
import { SuspendedGlobalPrintStyles as GlobalPrintStyles } from '../../GlobalPrintStyles/SuspendedGlobalPrintStyles.jsx';
import { BrowserOnly } from '../../BrowserOnly.jsx';
import { GreekAccentedReplacer } from '../../GreekAccentedReplacer';
import { useEventListener } from '../../hooks/useEventListener';
import globals from '../../../utils/globals';
import { useScrollToLocationHashElement } from '../../hooks/useScrollToLocationHashElement';
import { usePatchRogueReferrer } from '../../hooks/usePatchRogueReferrer';

// @VisibleForTesting
export const GlobalHooksContainer = () => {
  useEventListener(globals.window, 'pagehide', () => {
    // eslint-disable-next-line fp/no-mutating-methods
    globals.window.dataLayer?.push({ event: 'onPageHide' });
  }, { once: true });
  useScrollToLocationHashElement();
  usePatchRogueReferrer();
  return null;
};

export const GlobalComponents = () => (
  <>
    <PageGlobalStyles/>
    <DisableOutlineGlobalStyle/>
    <GlobalPrintStyles/>
    <BrowserOnly>
      <GlobalHooksContainer/>
      <GreekAccentedReplacer/>
    </BrowserOnly>
  </>
);
