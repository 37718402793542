import gql from 'graphql-tag';
import { cx } from '@linaria/core';
import { partialFragments, Partials, StyledContentTeaser as ContentTeaser } from '../ContentTeaserPartials';
import { StyledAuthorData as AuthorData, authorDataFragment } from '../ContentTeaserPartials/Partials/AuthorData.jsx';
import { makeMemoFragment } from '../../../utils/graphql';

export const bigImageOpinionContentTeaserFragment = makeMemoFragment({
  name: 'BigImageOpinionContentTeaser',
  fragment() {
    return gql`fragment ${this.name} on Content {
      ... on UrlAspect {
        namedUrl
      }
      ... on NamedAspect {
        title
      }
      ...${authorDataFragment.name}
      ...${partialFragments.contentStatusTeaserBadgeFragment.name}
      ...${partialFragments.linkedTitleFragment.name}
      ...${partialFragments.teaserFooterFragment.name}
    }
    ${authorDataFragment.fragment()}
    ${partialFragments.contentStatusTeaserBadgeFragment.fragment()}
    ${partialFragments.linkedTitleFragment.fragment()}
    ${partialFragments.teaserFooterFragment.fragment()}
    `;
  },
});

export const BigImageOpinionContentTeaser = ({
  content = {},
  className,
  titleIsA,
}) => (
  <ContentTeaser
    className={cx(className, 'row')}
    content={content}
  >
    <Partials.MainContentArea>
      <AuthorData>
        <Partials.ContentStatusTeaserBadge />
        <Partials.LinkedTitle isA={titleIsA} forceItalic/>
      </AuthorData>
    </Partials.MainContentArea>
    <Partials.TeaserFooter className="teaser-footer-wrapper col-12"/>
  </ContentTeaser>
);

export const StyledBigImageOpinionContentTeaser = BigImageOpinionContentTeaser;
