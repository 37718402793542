import { useReducer } from 'react';
import { decreaseIndexUntil, increaseIndexUntil } from '../../utils/rotateIndexUtils';

export const slideTimeOutAutoPlay = 4000;

export const useSliderLogic = (minIndex, maxIndex, initial = { currentIndex: 0 }) => {
  const decreaseIndex = decreaseIndexUntil(minIndex);
  const increaseIndex = increaseIndexUntil(maxIndex);
  const reducer = (state, action) => {
    switch (action.type) {
      case 'PLAY': {
        return {
          ...state,
          isPlaying: true,
        };
      }
      case 'PAUSE': {
        return {
          ...state,
          isPlaying: false,
        };
      }
      case 'PREV': {
        if (state.currentIndex === minIndex) {
          return state;
        }
        return {
          ...state,
          currentIndex: decreaseIndex(state.currentIndex),
        };
      }
      case 'PROGRESS':
      case 'NEXT': {
        if (state.currentIndex === maxIndex) {
          return state;
        }
        return {
          ...state,
          isPlaying: action.type === 'PROGRESS',
          currentIndex: increaseIndex(state.currentIndex),
        };
      }
      case 'GOTO': {
        if (action.index > maxIndex) {
          return {
            ...state,
            currentIndex: increaseIndex(action.index),
          };
        }
        if (action.index < minIndex) {
          return {
            ...state,
            currentIndex: decreaseIndex(action.index),
          };
        }
        return {
          ...state,
          currentIndex: action.index,
        };
      }
      default: {
        // eslint-disable-next-line fp/no-throw
        throw new Error('Unexpected action!');
      }
    }
  };

  const [state, dispatch] = useReducer(reducer, initial);
  return [
    state,
    {
      next: () => dispatch({ type: 'NEXT' }),
      prev: () => dispatch({ type: 'PREV' }),
      goTo: toIndex => dispatch({ type: 'GOTO', index: toIndex }),
      play: () => dispatch({ type: 'PLAY' }),
      pause: () => dispatch({ type: 'PAUSE' }),
      progress: () => dispatch({ type: 'PROGRESS' }),
      isFirstSlide: () => state.currentIndex === minIndex,
      isLastSlide: () => state.currentIndex === maxIndex,
      isActiveSlide: index => state.currentIndex === index,
    },
  ];
};
