import { extendWithClassNames } from '../../../../utils/extendWithClassNames.jsx';
import {
  StyledTeaserComposition as TeaserComposition,
  extendWithFocusTopThreeTeaserComposition,
  extendFocusTopThreeTeaserCompositionWithMandatoryBasicLooks,
  extendTeaserCompositionWithBottomAlignmentLooks,
  extendTeaserCompositionWithHeadlineOpinionTeaserLooks,
} from '../../TeaserComposition';

export const StyledFocusTopThreeHeadlineOpinionLayout = extendWithClassNames(TeaserComposition)(
  extendTeaserCompositionWithBottomAlignmentLooks,
  extendTeaserCompositionWithHeadlineOpinionTeaserLooks,
  extendWithFocusTopThreeTeaserComposition,
  extendFocusTopThreeTeaserCompositionWithMandatoryBasicLooks,
);
