import {
  StyledListDefaultUnifiedBigImageOpinion as ListDefaultUnifiedBigImageOpinionTemplate,
  listDefaultUnifiedBigImageOpinionFragment,
} from '../../ContentTeaserSnippets/Templates/ListDefaultUnifiedBigImageOpinion.jsx';

export const opinionFragment = listDefaultUnifiedBigImageOpinionFragment;

export const Opinion = props => (
  <ListDefaultUnifiedBigImageOpinionTemplate {...props}/>
);

export const StyledOpinion = Opinion;
