import {
  useEffect, useRef, useState, useCallback,
} from 'react';
import { styled } from '@linaria/react';
import { isValidList } from '../../utils/contentUtils';
import { StyledShowMoreButton as ShowMoreButton } from '../buttons/ShowMoreButton/ShowMoreButton.jsx';
import { setFocusInList } from '../../utils/listFocusUtils';
import { isForwardTab, keyCodes } from '../../utils/keyCodes';
import { usePrev } from '../hooks/usePrev';
import { StyledButtonBar as ButtonBar } from '../buttons/Button/ButtonBar.jsx';

export const DEFAULT_FOCUS_CONTAINER_CLASS = 'teaser';

function useToggleArraySlicer(array, initialSliceAmount) {
  const [slicedArray, updateSlicedArray] = useState(array.slice(0, initialSliceAmount));

  useEffect(() => {
    updateSlicedArray(array.slice(0, initialSliceAmount));
  }, [array, initialSliceAmount]);

  const isOpen = array.length === slicedArray.length;
  const toggleMore = () => (
    isOpen
      ? updateSlicedArray(array.slice(0, initialSliceAmount))
      : updateSlicedArray(array.slice(0, array.length))
  );
  return [{ slicedArray, isOpen }, toggleMore];
}

export const ExpandableAll = ({
  className,
  dataArray = [],
  visibleTeaserCount = 3,
  focusContainerClass = DEFAULT_FOCUS_CONTAINER_CLASS,
  children,
}) => {
  const [{ slicedArray: renderedContents, isOpen }, toggleMore] = useToggleArraySlicer(dataArray, visibleTeaserCount);
  const [focus, setFocus] = useState(false);
  const teaserListRef = useRef(null);
  const toggleMoreButtonTranslationKey = isOpen ? 'content_block_detail.more_on_topic.show_less' : 'content_block_detail.more_on_topic.show_more';
  const prevRenderedContentLength = usePrev(renderedContents.length);

  const onKeyDown = useCallback(e => {
    if (isForwardTab(e) && focus && isOpen) {
      e.preventDefault();
      setFocusInList({
        listElement: teaserListRef?.current,
        indexToFocus: prevRenderedContentLength,
        listEntryClass: focusContainerClass,
      });
    }
    if (e.key === keyCodes.TAB) {
      setFocus(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [focus, prevRenderedContentLength]);

  return (
    <div ref={teaserListRef} className={className}>
      { isValidList(renderedContents) && children(renderedContents) }
      { dataArray.length > visibleTeaserCount && (
        <ButtonBar>
          <ShowMoreButton
            className="toggle-more-button"
            translation={toggleMoreButtonTranslationKey}
            onKeyDown={onKeyDown}
            onClick={() => {
              toggleMore();
              setFocus(true);
            }}
            isOpen={isOpen}
          />
        </ButtonBar>
      )}
    </div>
  );
};

export const darkExpandableAllStyles = ShowMoreButton.darkStyles;
// TODO linaria-next: css``
export const expandableAllStyles = `
  .toggle-more-button {
    margin-top: 30px;
  }
`;

export const StyledExpandableAll = styled(ExpandableAll)`
  ${expandableAllStyles}
`;
