import {
  createContext, useState, useContext, useEffect, useCallback,
} from 'react';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

import { useLocation } from '../../dwouter';
import { noop } from '../../utils/commons';
import globals from '../../utils/globals';
import { dispatchFlyingFocusRefreshNoDelay } from '../FlyingFocus/eagerEventBindings';

function disableBodyScrollForBody(ref) {
  const options = {
    reserveScrollBarGap: true,
  };
  disableBodyScroll(ref, options);
}

const INITIAL_SCROLL_WIDTH = '0px';

function toggleBodyScrollLock({ isScrollDisabled, ref, setScrollbarWidth }) {
  if (isScrollDisabled) {
    disableBodyScrollForBody(ref);
    globals.window.setTimeout(() => {
      setScrollbarWidth(globals.document.body.style.paddingRight);
    });
    ref?.addEventListener('scroll', dispatchFlyingFocusRefreshNoDelay);
  } else {
    clearAllBodyScrollLocks();
    setScrollbarWidth(INITIAL_SCROLL_WIDTH);
    ref?.removeEventListener('scroll', dispatchFlyingFocusRefreshNoDelay);
  }
}

// --- Context setup ---

export const ScrollbarContext = createContext([
  { scrollbarWidth: INITIAL_SCROLL_WIDTH },
  { setScrollbarWidth: noop, toggleScrollLockAndSetWidth: noop },
]);

// --- Context subscribe hook ---

export const useScrollbarContext = ({ onReset = noop } = {}) => {
  const [state, actions] = useContext(ScrollbarContext);

  const resetLock = () => {
    clearAllBodyScrollLocks();
    actions.setScrollbarWidth(INITIAL_SCROLL_WIDTH);
    onReset();
  };

  const [{ pathname }] = useLocation();
  useEffect(() => {
    resetLock();
    return () => resetLock();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  return [state, actions];
};

// --- Context provider ---

export const BodyScrollContext = ({ children }) => {
  const [scrollbarWidth, setScrollbarWidth] = useState(INITIAL_SCROLL_WIDTH);
  const toggleScrollLockAndSetWidth = useCallback(({ isScrollDisabled, ref }) => {
    toggleBodyScrollLock({ isScrollDisabled, ref, setScrollbarWidth });
  }, []);

  return (
    <ScrollbarContext.Provider value={[
      { scrollbarWidth },
      { toggleScrollLockAndSetWidth, setScrollbarWidth },
    ]}>
      {children}
    </ScrollbarContext.Provider>
  );
};
