import gql from 'graphql-tag';
import { cx } from '@linaria/core';
import {
  useReducer,
} from 'react';

import { StyledLoadingMessage as LoadingMessage } from '../commons/LoadingMessage.jsx';
import { StyledFeedbackButton as FeedbackButton } from './FeedbackButton.jsx';
import { StyledFeedbackSidebar as FeedbackSidebar } from './FeedbackSidebar.jsx';
import { focusElementById } from '../../utils/metaDataHelpers';
import globals from '../../utils/globals';
import { useEventListener } from '../hooks/useEventListener';
import { useFeedbackDetector } from './utils';
import { makeMemoFragment } from '../../utils/graphql';
import { suspendedComponentFactory } from '../SuspendedComponent/suspendedComponentFactory.jsx';
import { ids } from '../../utils/staticElementIds';
import { StyledButtonBar as ButtonBar } from '../buttons/Button/ButtonBar.jsx';

// @VisibleForTesting
export const { DwFeedbackFormWithSubmitAndTheme } = suspendedComponentFactory(
  () => import('../forms/DwFeedbackForm'),
  { fallback: <LoadingMessage/> },
);
const actionTypes = {
  openFeedback: 'OPEN_FEEDBACK',
  closeFeedback: 'CLOSE_FEEDBACK',
};

// Do not move fragment definition to lazy loaded module, to avoid
// issues with importing module by accident to early. Feedback form has
// no big data requirements, so it should be safe to ask for those
// together with all other page data.
export const feedbackFragment = makeMemoFragment({
  name: 'Feedback',
  fragment() {
    return gql`fragment ${this.name} on Content {
      ... on AssociationsAspect {
        firstNavArray: navigations(amount: 1) {
          feedbackEmail
        }
      }
      ... on FeedbackAspect {
        feedbackTypes
      }
      ... on UrlAspect {
        namedUrl
      }
      ... on ModelAspect{
        isLive
      }
    }
    `;
  },
});

const initialState = Object.freeze({
  isFeedbackOpen: false,
  hasUpload: false,
  openingElementId: null,
  prefilledContent: {},
});
const feedbackReducer = (state, action) => {
  switch (action.type) {
    case actionTypes.openFeedback: {
      const { prefilledContent, openingElementId, hasUpload } = action.payload;
      return {
        isFeedbackOpen: true,
        prefilledContent,
        hasUpload,
        openingElementId,
      };
    }
    case actionTypes.closeFeedback: {
      return initialState;
    }
    default: {
      console.error(`Unhandled action type in feedback form: ${action.type}`);
      return state;
    }
  }
};

const useFeedbackSidebarState = () => {
  const [
    {
      isFeedbackOpen, prefilledContent, hasUpload, openingElementId,
    },
    dispatch,
  ] = useReducer(feedbackReducer, initialState);

  const openFeedback = ({ prefilledData = {}, upload, elementId = ids.feedbackForm.openBtn } = {}) => {
    dispatch({
      type: actionTypes.openFeedback,
      payload: { prefilledContent: prefilledData, hasUpload: upload === 'true', openingElementId: elementId },
    });
  };
  const closeFeedback = () => {
    dispatch({ type: actionTypes.closeFeedback });
    focusElementById(openingElementId);
  };

  return [{ isFeedbackOpen, prefilledContent, hasUpload }, { openFeedback, closeFeedback }];
};

const useEventBasedOpenFeedbackEffect = onOpenFeedback => {
  const handleOpenFeedbackLink = event => {
    const { subject, message, upload } = event.target.dataset;
    const elementId = event.target.getAttribute('id');

    onOpenFeedback({ prefilledData: { subject, message }, upload, elementId });
  };

  useEventListener(globals.document, 'openFeedback', handleOpenFeedbackLink);
};

export const Feedback = ({ className, content }) => {
  const { showFeedbackButton } = useFeedbackDetector(content);
  const [{ isFeedbackOpen, prefilledContent, hasUpload }, { openFeedback, closeFeedback }] = useFeedbackSidebarState();
  useEventBasedOpenFeedbackEffect(openFeedback);

  return (
    <div className={className}>
      {showFeedbackButton && (
        <ButtonBar>
          <FeedbackButton onClick={openFeedback} id={ids.feedbackForm.openBtn} data-focusdelay="600" />
        </ButtonBar>
      )}
      <FeedbackSidebar isFeedbackOpen={isFeedbackOpen} onCloseFeedback={closeFeedback}>
        {isFeedbackOpen && (
          <DwFeedbackFormWithSubmitAndTheme
            content={content}
            onCloseFeedback={closeFeedback}
            prefilledContent={prefilledContent}
            hasUpload={hasUpload}
          />
        )}
      </FeedbackSidebar>
    </div>
  );
};

export const darkFeedbackStyles = cx(
  FeedbackButton.darkStyles,
  FeedbackSidebar.darkStyles,
);

export const StyledFeedback = Feedback;
