import { styled } from '@linaria/react';
import { mediaMin } from '../../../utils/css';
import { HeadlineMediumBlueGrey04AndDwLightBlueNew } from '../../Text/Headline.jsx';

export const darkHubHeadlineStyles = HeadlineMediumBlueGrey04AndDwLightBlueNew.darkStyles;

export const HubHeadline = styled(HeadlineMediumBlueGrey04AndDwLightBlueNew)`
  font-size: 1.4rem;
  text-transform: uppercase;
  letter-spacing: 0.11rem;
  margin-bottom: 15px;

  ${mediaMin.lg`
    font-size: 1.6rem;
    letter-spacing: 0.12rem;
  `}
`;
