import { createRef, Component } from 'react';
import { styled } from '@linaria/react';
import globals from '../utils/globals';
import { BrowserOnly } from './BrowserOnly.jsx';

const copyWithClipboardApi = textToCopy => {
  globals.navigator.clipboard.writeText(textToCopy);
};

const copyWithSelectionApi = textContainerRef => {
  const selection = globals.document.getSelection();
  const range = globals.document.createRange();
  selection.removeAllRanges();
  range.selectNode(textContainerRef);
  selection.addRange(range);

  globals.document.execCommand('copy');

  selection.removeAllRanges();
};

const isSupportedByBrowser = () => {
  if (globals.navigator.clipboard) {
    return true;
  }
  if (globals.document.queryCommandSupported && globals.document.queryCommandSupported('copy')) {
    return true;
  }
  return false;
};

// TODO: reimplement to functional component
// this.props.textToCopy holds the string that gets copied
export class WithClipboardEvent extends Component {
  constructor(props) {
    super(props);
    // create a ref to the DOM element the text is stored in
    this.textContainerRef = createRef();
  }

  triggerCopy = () => {
    if (globals.navigator.clipboard) {
      return copyWithClipboardApi(this.props.textToCopy);
    }
    return copyWithSelectionApi(this.textContainerRef.current);
  };

  render() {
    const { children, className } = this.props;
    return (
      <>
        <span ref={this.textContainerRef} className={className}>{this.props.textToCopy}</span>
        <BrowserOnly>
          {children({
            triggerCopy: this.triggerCopy,
            isSupportedByBrowser,
          })}
        </BrowserOnly>
      </>
    );
  }
}

export const StyledWithClipboardEvent = styled(WithClipboardEvent)`
  clip: rect(0, 0, 0, 0);
  position: fixed;
  top: 0;
  user-select: text;
  white-space: pre;
`;
