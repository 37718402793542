// watch out - arrays not supported ;) Maybe in future
// we should replace this small function with something more powerful
// but let us wait till it is really needed.
export function accessPath(pathStr, obj) {
  if (!pathStr) {
    return obj;
  }
  return pathStr.split('.').reduce(
    (result, current) => (result && result[current]) || null,
    obj,
  );
}
