import { useEffect } from 'react';

const richTextFeedbackButtonSelector = '.rich-text button.feedback';

export const useFeedbackButtonEffect = ({ richTextRef }) => {
  useEffect(() => {
    richTextRef.current?.querySelectorAll(richTextFeedbackButtonSelector)
      .forEach(buttonElem => {
        buttonElem.addEventListener('click', () => {
          buttonElem.dispatchEvent(
            new Event('openFeedback', { bubbles: true }),
          );
        });
      });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
