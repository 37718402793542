import gql from 'graphql-tag';
import { makeMemoFragment } from '../../../../utils/graphql';

import {
  ListDefaultUnifiedBigImageTextTemplate,
  listDefaultUnifiedBigImageTextFragment, darkListDefaultUnifiedBigImageTextTemplateStyles,
} from '../../../ContentTeaserSnippets/Templates/ListDefaultUnifiedBigImageTextTemplate.jsx';

export const personProfileTopicsFragment = makeMemoFragment({
  name: 'PersonProfileTopics',
  fragment() {
    return gql`fragment ${this.name} on Person {
      fullName
      profileTopics {
        ...${listDefaultUnifiedBigImageTextFragment.name}
      }
    }
    ${listDefaultUnifiedBigImageTextFragment.fragment()}
    `;
  },
});

export const PersonProfileTopics = ({ className, person = {} }) => (
  <ListDefaultUnifiedBigImageTextTemplate
    headlineTranslation = {{
      key: 'content_block_detail.person_profile_topic_content_items.headline',
      parameters: { fullName: person.fullName },
    }}
    className={className}
    contents={person.profileTopics}
    pageSectionId='person-profile-topics'
  />
);

export const darkPersonProfileTopicsStyles = darkListDefaultUnifiedBigImageTextTemplateStyles;

export const StyledPersonProfileTopics = PersonProfileTopics;
