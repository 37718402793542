import { useMemo } from 'react';
import { useAsync } from 'react-async-hook';
import { useFetchContext } from '../FetchContext';
import globals from '../../utils/globals';
import config from '../../config';
import { isServer } from '../../utils/ssr';

// SSR or rehydration resolved, since gqlPromise.status only set in these cases
const isPromiseResolved = gqlPromise => ['fulfilled', 'rejected'].includes(gqlPromise.status);

export const makeApiPath = path => `${config.graphApi.baseUrl}/${path}`;

const useGraphqlPromise = apiPath => {
  const { fetch, renderPromises } = useFetchContext();
  const gqlPromise = useMemo(
    () => {
      // eslint-disable-next-line no-underscore-dangle
      const appState = globals.window.__APP_STATE__;
      if (appState?.[apiPath]) {
        const directResolvePromise = Promise.resolve(appState[apiPath]);
        // eslint-disable-next-line fp/no-mutation
        directResolvePromise.status = 'fulfilled';
        // eslint-disable-next-line fp/no-mutation
        directResolvePromise.value = appState[apiPath];
        return directResolvePromise;
      }
      return renderPromises?.getSSRPromise(apiPath) || fetch(apiPath)
        .then(res => {
          const gqlJsonPromise = res.json();
          if (renderPromises) {
            // eslint-disable-next-line fp/no-mutating-methods
            renderPromises.addResponseHeaders(apiPath, res.headers);
          }
          return gqlJsonPromise;
        });
    },
    [apiPath, renderPromises, fetch],
  );

  if (renderPromises && !isPromiseResolved(gqlPromise)) {
    // eslint-disable-next-line fp/no-mutating-methods
    renderPromises.addQueryPromise(apiPath, gqlPromise);
  }

  return gqlPromise;
};

export const useGqlFetch = ({ path, depts = [], disableAppStateCache }) => {
  const apiPath = makeApiPath(path);
  const gqlPromise = useGraphqlPromise(apiPath);
  const meta = useAsync(() => gqlPromise, depts);

  if (isPromiseResolved(gqlPromise)) {
    return {
      loading: false,
      data: gqlPromise.value?.data,
      error: gqlPromise.reason || gqlPromise.value.error,
    };
  }

  const error = meta.error || meta.result?.error;
  if (!meta.loading && !error && !disableAppStateCache && !isServer()) {
    // eslint-disable-next-line no-unused-expressions, fp/no-mutation, no-underscore-dangle
    globals.window.__APP_STATE__ = globals.window.__APP_STATE__ ?? {};
    // eslint-disable-next-line no-unused-expressions, fp/no-mutation, no-underscore-dangle
    globals.window.__APP_STATE__[apiPath] = meta.result;
  }

  return {
    loading: meta.loading,
    data: meta.result?.data,
    error,
  };
};
