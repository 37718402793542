import { StyledTextOverlay as TextOverlay } from './TextOverlay.jsx';
import { StyledResponsiveSlideImage as SlideResponsiveImage } from './SlideResponsiveImage.jsx';
import { StyledCopyrightInfo as CopyrightInfo } from './CopyrightInfo.jsx';

export const CoverImage = ({
  title,
  teaser,
  galleryImage,
  className,
  priority,
  ...restProps
}) => (
  <div className={`${className} cover`} {...restProps}>
    <TextOverlay className="text-overlay" title={title} teaser={teaser} priority={priority}/>
    <CopyrightInfo galleryImage={galleryImage} />
    <SlideResponsiveImage galleryImage={galleryImage} preload />
  </div>
);

export const StyledCoverImage = CoverImage;
