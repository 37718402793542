import gql from 'graphql-tag';

import { PageHeadline } from '../../PageHeadline.jsx';
import { StyledHiddenHeadline as HiddenHeadline } from '../../HiddenHeadline.jsx';
import { CoCoComponentSelector, coCoComponentFragment, getCoCoCosWithContents } from '../CoCoComponentSelector.jsx';
import { StyledA11yLinkTargetMarker as A11yLinkTargetMarker } from '../../a11y/A11yLinkTargetMarker.jsx';
import { isHomePage, isValidList } from '../../../utils/contentUtils';
import { CoCoComponentBackgroundSelectorWithTheme as CoCoCoBackgroundSelectorWithTheme } from '../CoCoComponentBackgroundSelectorWithTheme.jsx';
import { makeMemoFragment } from '../../../utils/graphql';
import { ids } from '../../../utils/staticElementIds';

export const navigationCoCoFragment = makeMemoFragment({
  name: 'NavigationCoCo',
  fragment() {
    return gql`fragment ${this.name} on Navigation {
      id
      pageHeadline
      isMediaTemplate
      contentComposition {
        informationSpaces {
          id
          ...${coCoComponentFragment.name}
        }
      }
      ... on AssociationsAspect {
        topStoriesNavigation {
          id
        }
      }
    }
    ${coCoComponentFragment.fragment()}
    `;
  },
});

export const NavigationCoCo = ({ navigation, className }) => {
  const {
    pageHeadline,
    isMediaTemplate,
    contentComposition: { informationSpaces },
  } = navigation;

  const isHomePageNav = isHomePage(navigation);
  return (
    <A11yLinkTargetMarker className={className} id={ids.quickNav.mainContent}>
      {isHomePageNav
        ? <HiddenHeadline isA="h1" translation="pages.homepage.page_title"/>
        : pageHeadline && <PageHeadline>{pageHeadline}</PageHeadline>
      }
      <CoCoCoBackgroundSelectorWithTheme hasTheme={isMediaTemplate}>
        {informationSpaces.filter(infoSpace => isValidList(getCoCoCosWithContents(infoSpace)))
          .map((infoSpace, index) => (
            <CoCoComponentSelector key={infoSpace.id}
              infoSpace={infoSpace}
              isFirst={index === 0}
            />
          ))}
      </CoCoCoBackgroundSelectorWithTheme>
    </A11yLinkTargetMarker>
  );
};

NavigationCoCo.darkStyles = PageHeadline.darkStyles;
