import globals from '../globals';
import { loadScriptPromise } from '../staticResourceHelpers';

const retrieveTwitterJsScript = async () => {
  await loadScriptPromise({
    url: 'https://platform.twitter.com/widgets.js',
    scriptId: 'twitter-widgets-script',
    vendorId: 's34',
  });
  return new Promise(resolve => {
    const { twttr, document } = globals.window;
    twttr.ready(() => resolve({ twttr, document }));
  });
};

// eslint-disable-next-line fp/no-let
let loadingApiPromise = null;

// needed only for tests at the moment
export const resetTwitterApi = () => {
  globals.window.twttr = null; // eslint-disable-line fp/no-mutation
  // eslint-disable-next-line fp/no-mutation
  loadingApiPromise = null;
};

export const loadTwitterApi = async () => {
  if (!loadingApiPromise) {
    // eslint-disable-next-line fp/no-mutation
    loadingApiPromise = retrieveTwitterJsScript();
  }
  return loadingApiPromise;
};
