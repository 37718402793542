import gql from 'graphql-tag';
import { responsiveDwPictureFragment } from './index';
import { makeMemoFragment } from '../../utils/graphql';

export const mainImageWithCaptionFragment = makeMemoFragment({
  name: 'MainImageWithCaption',
  fragment() {
    return gql`fragment ${this.name} on AssociationsAspect {
        mainContentImageLink {
            targetId
            additionalInformation
            description
            target {
            ...on Image {
                id
                licenserSupplement
                imageType
            }
            ...${responsiveDwPictureFragment.name}
            }
        }
        }
        ${responsiveDwPictureFragment.fragment()}
      `;
  },
});
