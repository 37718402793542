/* eslint-disable no-underscore-dangle */
import { useEffect } from 'react';
import { useFrontendConfig } from '../FrontendConfigContext.jsx';
import { useFixCmpPreviews } from './useFixCmpPreviews';
import { useLocationQueryParam } from '../hooks/useLocationQueryParam';

let scriptPromise = null; // eslint-disable-line fp/no-let
let loadingForConsentID = null; // eslint-disable-line fp/no-let

const loadCmpScript = async ({ consentManagerId, cmpimport }) => {
  // need to import with inline option because we deal with shadow dom that makes things harder ;)
  // we should not inject css into the page, but only into the correct shadow root
  // eslint-disable-next-line import/no-unresolved
  const { default: customCmpStyles } = await import('./cmp-style-overwrite.scss?inline');
  const { initCmpScript } = await import('../../utils/consentScript');
  initCmpScript({
    consentManagerId,
    customCmpStyles,
    cmpimport,
  });
};

const loadCmpScriptOnlyOnce = async ({ consentManagerId, cmpimport }) => {
  if (!scriptPromise || consentManagerId !== loadingForConsentID) {
    // eslint-disable-next-line fp/no-mutation
    scriptPromise = loadCmpScript({ consentManagerId, cmpimport });
    // eslint-disable-next-line fp/no-mutation
    loadingForConsentID = consentManagerId;
  }
  return loadCmpScript;
};

export const ConsentManagement = () => {
  const { consentManagerId } = useFrontendConfig();
  const cmpimport = useLocationQueryParam('cmpimport');
  useEffect(() => {
    if (consentManagerId) {
      loadCmpScriptOnlyOnce({ consentManagerId, cmpimport });
    }
  }, [cmpimport, consentManagerId]);

  useFixCmpPreviews();

  return null;
};
