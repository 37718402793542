import gql from 'graphql-tag';
import { Helmet } from 'react-helmet-async';
import { makeMemoFragment } from '../../utils/graphql';

export const twitterMetadataFragment = makeMemoFragment({
  name: 'TwitterMetadata',
  fragment() {
    return gql`fragment ${this.name} on Content {
            ... on NamedAspect {
              title
            }
            ... on SocialMediaAspect {
              twitterAccount
              sharingDescription
            }
        }
    `;
  },
});

export const TwitterMetadata = ({ content }) => {
  const { title, twitterAccount, sharingDescription } = content;
  return (
    <Helmet>
      <meta name="twitter:card" content="summary_large_image"/>
      {title && <meta name="twitter:title" content={title} />}
      {sharingDescription && <meta name="twitter:description" content={sharingDescription} />}
      {twitterAccount && <meta name="twitter:site" content={`@${twitterAccount}`} />}
    </Helmet>
  );
};
