import { styled } from '@linaria/react';
import gql from 'graphql-tag';

import { StyledTimeIndicator as TimeIndicator } from './TimeIndicator.jsx';
import { InformationDefault, InfoEmphasized } from '../Text/Information.jsx';
import { StyledClockIcon as ClockIcon } from './ClockIcon.jsx';
import { selector } from '../../utils/css';
import { makeMemoFragment } from '../../utils/graphql';

export const timeAreaFragment = makeMemoFragment({
  name: 'TimeArea',
  fragment() {
    return gql`fragment ${this.name} on DeliveryAspect {
            contentDate
            localizedContentDate
            localizedContentDateSr
            firstPublicationDate
            localizedFirstPublicationDate
            localizedFirstPublicationDateSr
            contentDateMode
        }
    `;
  },
});

export const TimeArea = ({
  content: {
    contentDate,
    localizedContentDate,
    localizedContentDateSr,
    additionalInformation,
    contentDateMode,
    firstPublicationDate,
    localizedFirstPublicationDate,
    localizedFirstPublicationDateSr,
  },
  isA = InformationDefault,
  className,
}) => {
  if (!contentDateMode || contentDateMode === 'NO_DATE') {
    return null;
  }
  const { publicationTimeIndicator, lastUpdateTimeIndicator } = getTimeIndicators({
    localizedFirstPublicationDate,
    localizedFirstPublicationDateSr,
    contentDate,
    localizedContentDate,
    localizedContentDateSr,
    additionalInformation,
    contentDateMode,
    firstPublicationDate,
    isA,
  });
  const TextComponent = isA;
  return (
    <TextComponent className={className}>
      {publicationTimeIndicator}
      {lastUpdateTimeIndicator}
      {additionalInformation &&
        <span className="read-time">
          <span className="clock-icon">
            <ClockIcon />
          </span>
          <span>
            {additionalInformation}
          </span>
        </span>
      }
    </TextComponent>
  );
};

const timeIndicatorFactory = ({
  date,
  localizedDate,
  ...restProps
}) => {
  if (!date || !localizedDate) {
    return null;
  }
  return (
    <TimeIndicator
      date={date}
      localizedDate={localizedDate}
      {...restProps}
    />
  );
};

const getTimeIndicators = ({
  localizedFirstPublicationDate,
  localizedFirstPublicationDateSr,
  contentDate,
  localizedContentDate,
  localizedContentDateSr,
  contentDateMode,
  firstPublicationDate,
  isA,
}) => {
  if (contentDateMode === 'FIRST_AND_LAST_PUBLICATION_DATE' && contentDate > firstPublicationDate) {
    const publicationTimeIndicator = timeIndicatorFactory({
      date: firstPublicationDate,
      localizedDate: localizedFirstPublicationDate,
      localizedDateSr: localizedFirstPublicationDateSr,
      className: 'publication',
      label: 'component_detail.content_detail.publication_date_label',
      isA,
    });
    const lastUpdateTimeIndicator = timeIndicatorFactory({
      date: contentDate,
      localizedDate: localizedContentDate,
      localizedDateSr: localizedContentDateSr,
      className: 'last-update',
      label: 'component_detail.content_detail.lastUpdate_date_label',
      isA,
    });
    return { publicationTimeIndicator, lastUpdateTimeIndicator };
  }
  const publicationTimeIndicator = timeIndicatorFactory({
    date: contentDate,
    localizedDate: localizedContentDate,
    localizedDateSr: localizedContentDateSr,
    className: 'publication',
    isA,
  });
  return { publicationTimeIndicator, lastUpdateTimeIndicator: null };
};

export const darkTimeAreaStyles = InformationDefault.darkStyles;

export const StyledTimeArea = styled(TimeArea)`
    font-size: 1.3rem;
    letter-spacing: 0.04rem;

    & > *${selector.notLast} {
      &:after {
        content: '\u00A0|\u00A0';
      }
    }

    .publication, .last-update, .read-time {
      white-space: nowrap;
      display: inline-block;
    }

    .clock-icon {
      height: 20px;
      width: 20px;
      vertical-align: text-bottom;
      display: inline-block;
      margin-inline-start: -5px;

      svg path {
        fill: currentColor;
      }
    }
`;

export const StyledTimeAreaEmphasized = props => <StyledTimeArea isA={InfoEmphasized} {...props} />;

StyledTimeAreaEmphasized.darkStyles = InfoEmphasized.darkStyles;
