export const ContentType = Object.freeze({
  Article: 'Article',
  Liveblog: 'Liveblog',
  LiveblogPost: 'LiveblogElement',
  Person: 'Person',
  ImageGallery: 'ImageGallery',
  Audio: 'Audio',
  Video: 'Video',
  Livestream: 'Livestream',
  Channel: 'Channel',
  Infographics: 'Infographics',
  Interactive: 'Interactive',
  UnifiedProgram: 'UnifiedProgram',
  AutoTopic: 'AutoTopic',
});

const isOfType = expectedType => ({ __typename } = {}) => __typename === expectedType;

export const isArticle = isOfType(ContentType.Article);

export const isLiveblog = isOfType(ContentType.Liveblog);

export const isLiveblogPost = isOfType(ContentType.LiveblogPost);

export const isImageGallery = isOfType(ContentType.ImageGallery);

export const isAudio = isOfType(ContentType.Audio);

export const isVideo = isOfType(ContentType.Video);

export const isPerson = isOfType(ContentType.Person);

export const isChannel = isOfType(ContentType.Channel);

export const isLivestream = isOfType(ContentType.Livestream);

export const isInfographics = isOfType(ContentType.Infographics);

export const isInteractive = isOfType(ContentType.Interactive);

export const isUnifiedProgram = isOfType(ContentType.UnifiedProgram);

export const isAutoTopic = isOfType(ContentType.AutoTopic);

export const isValidList = list => Array.isArray(list) && list.length > 0;

export const isHomePage = navigation => navigation.id === navigation.topStoriesNavigation?.id;

export const isFocusMenuShown = navigation => {
  const validTargetContents = navigation?.focusNav?.contentLinks
    ?.map(link => link?.target)
    .filter(target => target && target.visibleInWebApp !== false);

  return isValidList(validTargetContents);
};

export const isMediaGroupShown = navigation => isValidList(navigation?.latestMediaChildren);

export const hasPermalinkSupport = content => !isChannel(content);
