import { memo } from 'react';
import { styled } from '@linaria/react';
import classnames from 'classnames';
import { mediaMin, colors } from '../../../utils/css';
import { slideTimeOutAutoPlay } from '../../hooks/useSliderLogic';
import { contentAreaPaddingStyles } from '../../layouts/ContentMoveUpLayout/ContentArea';
import { globalCssVariables } from '../../Page/PageGlobalStyles';

const removeAnimationGlitch = 100;
const translationTime = slideTimeOutAutoPlay - removeAnimationGlitch;

export const Indicator = memo(({
  className,
  currentSlideIndex,
  amountOfSlides,
  withAnimation,
}) => (
  <div className={className}>
    {amountOfSlides > 50
      ? (
        <div className='indicator-fluid'>
          <div className='bar' style={{
            transform: `scaleX(${(currentSlideIndex + 1) / amountOfSlides})`,
            transition: `transform linear ${withAnimation ? translationTime : 1000}ms`,
          }} />
        </div>
      ) : [...Array(amountOfSlides)].map((e, i) => (
        <div key={i} className="indicator-fragment">
          <div className={classnames('bar', {
            'with-animation': withAnimation,
            active: i <= currentSlideIndex,
          })} />
        </div>
      ))}
  </div>
));

export const StyledIndicator = styled(Indicator)`
  position: absolute;
  z-index: 3;
  top: 0;
  padding-top: 15px;
  ${contentAreaPaddingStyles}
  left: 50%;
  transform: translate(-50%, 0);
  width: 100%;
  max-width: 1110px;
  display: flex;

  .indicator-fragment,
  .indicator-fluid {
    background-color: rgb(255,255,255,.3);
    height: 2px;
    width: 100%;

    .bar {
      height: 100%;
      background-color: ${colors.WHITE};
    }
  }

  .indicator-fluid {
    .bar {
      transform-origin: calc(50% - 50% * var(${globalCssVariables.flowDirection})) center;
    }
  }

  .indicator-fragment {
    .bar {
      transition: opacity 1.5s;
      opacity: 0;

      &.with-animation {
        opacity: 1;
        transform-origin: calc(50% - 50% * var(${globalCssVariables.flowDirection})) center;
        transform: scaleX(0);
      }
      &.active {
        opacity: 1;
        &.with-animation {
          transition: transform ease-in-out ${translationTime}ms;
          transform: scaleX(1);
        }
      }

    }
    & + .indicator-fragment {
      margin-inline-start: 3px;

      ${mediaMin.lg`
        margin-inline-start: 5px;
      `}

     }
  }
`;
