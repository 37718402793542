import gql from 'graphql-tag';
import { styled } from '@linaria/react';
import { useContentTeaserContext } from '../ContentTeaserContext';
import { InformationDefault, InformationItalic } from '../../../Text/Information.jsx';
import { useOpinionSpecificType } from '../../../hooks/useOpinionSpecificType';
import { makeMemoFragment } from '../../../../utils/graphql';

export const opinionMetadataFragment = makeMemoFragment({
  name: 'OpinionMetadata',
  fragment() {
    return gql`fragment ${this.name} on Content {
      ...on MetadataAspect {
        genre
      }
      ... on AssociationsAspect {
        opinionSeries {
          name
        }
      }
    }
    `;
  },
});

export const OpinionMetadata = ({ className }) => {
  const { content } = useContentTeaserContext();
  const translatedOpinionType = useOpinionSpecificType(content);

  const [series] = content.opinionSeries;
  const seriesName = series?.name;

  return (
    <div className={className}>
      <InformationDefault className="opinion-type">{translatedOpinionType}</InformationDefault>
      {seriesName && <InformationItalic className="series-name">{seriesName}</InformationItalic>}
    </div>
  );
};

export const darkOpinionMetadataStyles = InformationDefault.darkStyles;

export const StyledOpinionMetadata = styled(OpinionMetadata)`
  font-size: 1.3rem;

  .opinion-type:after {
    content: ' ';
  }
  .series-name {
    display: inline-block;
  }
`;
