import { styled } from '@linaria/react';
import { LinkDefault } from '../../Links';
import { StyledExternalLink as ExternalLinkIcon, StyledRightChevron as RightChevron } from '../../icons';

import { isExternalLink, isExternalDwLink } from '../../../utils/url';
import { useContentTeaserContext } from '../../ContentTeaserSnippets/ContentTeaserPartials/ContentTeaserContext';
import { useTranslation } from '../../hooks/useTranslation.jsx';

export const PromoLink = ({ className }) => {
  const { content } = useContentTeaserContext();
  const url = content.namedUrl;
  const isExternalNotDwLink = isExternalLink(url) && !isExternalDwLink(url);
  const translatedText = useTranslation('content_block.crosspromotion.link');

  const PromoLinkIcon = isExternalNotDwLink ? ExternalLinkIcon : RightChevron;
  return (
    <LinkDefault
      className={className}
      to={url}
      title={content.title}
      skipTitleForInternal
      tabIndex={-1}
    >
      {translatedText}
      <PromoLinkIcon />
    </LinkDefault>
  );
};

export const StyledPromoLink = styled(PromoLink)`
  display: flex;
  justify-content: flex-end;
  padding-top: 15px;
  column-gap: 5px;

  font-size: 1.4rem;

  svg {
    width: 14px;
    height: 21px;
  }
`;
