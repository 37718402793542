import gql from 'graphql-tag';
import { cx } from '@linaria/core';
import {
  darkPartialStyles, partialFragments, Partials, StyledContentTeaser as ContentTeaser,
} from '../ContentTeaserPartials';
import { StyledOpinionMetadata as OpinionMetadata, opinionMetadataFragment, darkOpinionMetadataStyles } from '../ContentTeaserPartials/Partials/OpinionMetadata.jsx';
import { makeMemoFragment } from '../../../utils/graphql';

export const headlineOpinionContentTeaserFragment = makeMemoFragment({
  name: 'HeadlineOpinionContentTeaser',
  fragment() {
    return gql`fragment ${this.name} on Content {
      ... on UrlAspect {
        namedUrl
      }
      ... on NamedAspect {
        title
      }
      ...${partialFragments.contentStatusTeaserBadgeFragment.name}
      ...${partialFragments.linkedTitleFragment.name}
      ...${partialFragments.teaserFooterFragment.name}
      ...${opinionMetadataFragment.name}
    }
    ${partialFragments.contentStatusTeaserBadgeFragment.fragment()}
    ${partialFragments.linkedTitleFragment.fragment()}
    ${partialFragments.teaserFooterFragment.fragment()}
    ${opinionMetadataFragment.fragment()}
    `;
  },
});

export const HeadlineOpinionContentTeaser = ({
  content = {},
  className,
  titleIsA,
}) => (
  <ContentTeaser
    className={cx(className, 'row')}
    content={content}
    hideOpinion
  >
    <Partials.MainContentArea>
      <Partials.Data>
        <Partials.ContentStatusTeaserBadge />
        <Partials.LinkedTitle isA={titleIsA}/>
        <OpinionMetadata className="opinion-metadata" />
      </Partials.Data>
    </Partials.MainContentArea>
  </ContentTeaser>
);

export const darkHeadlineOpinionContentTeaserStyles = cx(
  darkPartialStyles.darkLinkedTitleStyles,
  darkPartialStyles.darkTeaserDataStyles,
  darkOpinionMetadataStyles,
);

export const StyledHeadlineOpinionContentTeaser = HeadlineOpinionContentTeaser;
