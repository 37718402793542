import { useRef } from 'react';
import { styled } from '@linaria/react';
import { useInView } from 'react-intersection-observer';

import {
  dwPicStyles, dwPicImgStyles, INTERSECTION_OBSERVER_SETTINGS,
} from '../LazyLoadDwPicture.jsx';
import { getAspectRatioStyles, responsivePicCss } from '../ResponsiveDwPicture';

export const ExternalImage = ({
  className, url, formatConfig,
}) => {
  const ownRef = useRef();
  const [inViewRef, inView] = useInView(INTERSECTION_OBSERVER_SETTINGS);

  if (!url) {
    return null;
  }
  return (
    <figure
      ref={ownRef}
      className={`external-image ${className}`}
      style={getAspectRatioStyles({ formatConfig })}>
      <img ref={inViewRef} src={inView ? url : ''} alt="external" />
    </figure>
  );
};

export const StyledExternalImage = styled(ExternalImage)`
  ${dwPicStyles}
  ${responsivePicCss}

  img {
    ${dwPicImgStyles}
    height: 100%;
    object-fit: cover;
  }
`;
