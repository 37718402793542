import { styled } from '@linaria/react';
import { cx } from '@linaria/core';

import { HeadlineWideRegularDarkBlueGrey02AndBlueGrey01 } from '../Text/Headline.jsx';
import { mediaMin } from '../../utils/css';

export const FooterSectionHeadline = ({ className, ...restProps }) => (
  <HeadlineWideRegularDarkBlueGrey02AndBlueGrey01 className={cx(HeadlineWideRegularDarkBlueGrey02AndBlueGrey01.darkStyles, className)} isA="h2" {...restProps}/>
);

export const StyledFooterSectionHeadline = styled(FooterSectionHeadline)`
  font-size: 1.1rem;
  letter-spacing: 0.16rem;
  text-transform: uppercase;
  margin-bottom: 15px;

  ${mediaMin.md`
    font-size: 1.2rem;
  `}

  ${mediaMin.lg`
    font-size: 1.3rem;
  `}
`;
