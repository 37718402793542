import gql from 'graphql-tag';
import { styled } from '@linaria/react';
import { isValidList } from '../../utils/contentUtils';
import { makeMemoFragment } from '../../utils/graphql';
import { GptSlot } from '../GptSlot';
import { opinionZoneSlotConfig, panoramaZoneSlotConfig, topStoriesZoneSlotConfig } from '../GptSlot/slotConfigs';
import { isTopStoryCoCoCo } from './coCoCoDetector';

export const cocoAdFragment = makeMemoFragment({
  name: 'CoCoAd',
  fragment() {
    return gql`fragment ${this.name} on InformationSpace {
        name
      }
    `;
  },
});

const zoneConfigs = {
  topStory: {
    name: 'T',
    slotConfig: topStoriesZoneSlotConfig,
  },
  opinion: {
    name: 'M',
    slotConfig: opinionZoneSlotConfig,
  },
  Panorama: {
    name: 'Pa',
    slotConfig: panoramaZoneSlotConfig,
  },
};

export const CoCoAd = ({ infoSpace, compositionComponents, className }) => {
  const { slotConfig = null } = Object.values(zoneConfigs).find(({ name }) => name === infoSpace.name) || {};
  if (!slotConfig || !isValidList(compositionComponents)) {
    return null;
  }
  if (infoSpace.name === zoneConfigs.topStory.name) {
    const [firstCoCoCo] = compositionComponents;
    if (isTopStoryCoCoCo(firstCoCoCo) && compositionComponents.length === 1) {
      return null;
    }
  }

  return (
    <GptSlot slotConfig={slotConfig} className={className} />
  );
};

export const StyledCoCoAd = styled(CoCoAd)`
  padding-bottom: 40px;
`;
