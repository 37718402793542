import { isFocusMenuShown } from '../../utils/contentUtils';
import { resolutions } from '../../utils/css';
import { headerHeight } from '../Header/headerUtils';
import { useWindowInnerWidthContext } from '../WindowInnerWidthContext';

const zeros = '0px 0px 0px';

const makeNegativeTopOffset = top => `-${top}px ${zeros}`;

export const useTopOffsetHeaderThreshold = (content = {}) => {
  const innerWidth = useWindowInnerWidthContext();
  const focusMenuShown = isFocusMenuShown(content.topStoriesNavigation);

  const isSmallerThanMd = innerWidth <= resolutions.max.sm;
  const isLargerThanMd = innerWidth >= resolutions.min.lg;
  if (isLargerThanMd) {
    return makeNegativeTopOffset(headerHeight.lg);
  }
  if (isSmallerThanMd) {
    return makeNegativeTopOffset(focusMenuShown ? headerHeight.xs.wide : headerHeight.xs.thin);
  }
  return makeNegativeTopOffset(headerHeight.md);
};
