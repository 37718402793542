import { useBrowserOnly } from './hooks/useBrowserOnly';

export const BrowserOnly = ({
  children, fallback, isHidden,
}) => {
  const { isBrowser } = useBrowserOnly();

  if (!isBrowser || isHidden) {
    return fallback || null;
  }

  return children;
};
