import {
  StyledBigImageTextContentTeaser as BigImageTextContentTeaser, bigImageTextContentTeaserFragment, darkBigImageTextContentTeaserStyles,
} from '../../ContentTeasers/BigImageTextContentTeaser.jsx';

export const expandAdapterFragment = bigImageTextContentTeaserFragment;

export const expandAdapter = renderedContents => (
  renderedContents.map(renderedContent => (
    <BigImageTextContentTeaser
      key={renderedContent.id}
      content={renderedContent}
      titleIsA="h3"
    />
  ))
);

export const darkExpandAdapterStyles = darkBigImageTextContentTeaserStyles;
