import gql from 'graphql-tag';
import { cx } from '@linaria/core';
import { makeMemoFragment } from '../../../utils/graphql';
import { StyledContentBlockList as ContentBlockList, darkContentBlockListStyles } from '../../ContentBlocks/Types/ContentBlockList.jsx';
import { StyledBigImageTextContentTeaser as BigImageTextContentTeaser, bigImageTextContentTeaserFragment, darkBigImageTextContentTeaserStyles } from '../ContentTeasers/BigImageTextContentTeaser.jsx';
import { StyledDefaultLayout as DefaultLayout } from '../Layouts/DefaultLayout/index';

export const listDefaultUnifiedBigImageTextFragment = makeMemoFragment({
  name: 'ListDefaultUnifiedBigImageTextTemplate',
  fragment() {
    return gql`fragment ${this.name} on Content {
        ... on ModelAspect {
          id
        }
        ...${bigImageTextContentTeaserFragment.name}
      }
      ${bigImageTextContentTeaserFragment.fragment()}
    `;
  },
});

export const ListDefaultUnifiedBigImageTextTemplate = ({
  contents = [], hideKicker, hideTimeIndicator, ...restProps
}) => (
  <ContentBlockList headlineIsA='h2' {...restProps}>
    <DefaultLayout>
      {contents.map(content => (
        <BigImageTextContentTeaser
          key={content.id}
          content={content}
          titleIsA='h3'
          hideKicker={hideKicker}
          hideTimeIndicator={hideTimeIndicator}
        />
      ))}
    </DefaultLayout>
  </ContentBlockList>
);

export const darkListDefaultUnifiedBigImageTextTemplateStyles = cx(
  darkContentBlockListStyles,
  darkBigImageTextContentTeaserStyles,
);

ListDefaultUnifiedBigImageTextTemplate.darkStyles = darkListDefaultUnifiedBigImageTextTemplateStyles;
