import { getPathnameFromUrlPath, getSearchFromUrlPath } from '../utils/url';

export const makeUrl = (to, { isMobileMode = false } = {}) => {
  // eslint-disable-next-line fp/no-let
  let url = null;
  if (typeof to === 'object') {
    const { pathname, search = '' } = to;
    // eslint-disable-next-line fp/no-mutation
    url = `${pathname}${search}`;
  } else {
    // eslint-disable-next-line fp/no-mutation
    url = to;
  }
  if (isMobileMode) {
    const searchParams = new URLSearchParams(getSearchFromUrlPath(url));
    searchParams.delete('mobileApp');
    searchParams.append('mobileApp', 'true');
    // eslint-disable-next-line fp/no-mutation
    url = `${getPathnameFromUrlPath(url)}?${searchParams.toString()}`;
  }
  return url;
};

export const getLocationAsObj = location => {
  if (typeof location === 'object') {
    return location;
  } return {
    pathname: getPathnameFromUrlPath(location),
    search: getSearchFromUrlPath(location),
  };
};
