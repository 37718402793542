import { styled } from '@linaria/react';
import classnames from 'classnames';
import { StyledQuickNavigationItem as QuickNavigationItem } from './QuickNavigationItem.jsx';
import { mediaMax, mediaMin } from '../../utils/css';
import { headerHeight } from '../Header/headerUtils';
import { isFocusMenuShown } from '../../utils/contentUtils';
import { ids } from '../../utils/staticElementIds';

export const quickNavigationItems = Object.freeze({
  mainContent: Object.freeze({
    id: ids.quickNav.mainContent,
    translation: 'component.a11y.quick_navigation.content',
  }),
  navigation: Object.freeze({
    id: ids.quickNav.navigationToggle,
    translation: 'component.a11y.quick_navigation.navigation',
  }),
  footer: Object.freeze({
    id: ids.quickNav.footer,
    translation: 'component.a11y.quick_navigation.footer',
  }),
});

export const QuickNavigationList = ({ className, content }) => {
  const focusMenuShown = isFocusMenuShown(content.topStoriesNavigation);

  return (
    <ol className={classnames(className, { 'focus-menu-shown': focusMenuShown })}>
      {
        Object.values(quickNavigationItems).map(navItem => (
          <li key={navItem.id}>
            <QuickNavigationItem
              pageSectionId={navItem.id}
              translation={navItem.translation}/>
          </li>
        ))
      }
    </ol>
  );
};

export const StyledQuickNavigationList = styled(QuickNavigationList)`
  list-style: none;
  position: fixed;
  top: ${headerHeight.xs.thin}px;
  width: 100%;
  z-index: 11;

  ${mediaMax.sm`
    &.focus-menu-shown {
      top: ${headerHeight.xs.wide}px;
    }
  `}
  &, li {
    margin: 0;
    padding: 0;
  }

  a {
    max-width: 350px;
    width: 100%;
  }

  ${mediaMin.md`
      top: ${headerHeight.md}px;
  `}

  ${mediaMin.lg`
      top: ${headerHeight.lg}px;
  `}
`;
