import globals from './globals';

export const getTitle = ({ title, date }) => [title, 'DW', date].filter(v => !!v).join(' \u2013 ');

export const isColumn = content => content.genre === 'COLUMN';

export const isDossier = content => content.genre === 'DOSSIER';

export const getColumnOpinionSeries = content => content.opinionSeries?.find(isColumn);

export const getAutoDeliveredColumnOpinionSeries = content => content.opinionSeries?.find(s => isColumn(s) && s.autoDelivery);

export const focusElementById = elementId => globals.document.getElementById(elementId).focus();
