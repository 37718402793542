import { styled } from '@linaria/react';

import { StyledLogoWhite as LogoIconWhite, StyledLogoDarkBlue as LogoIconDarkBlue } from '../icons';
import { mediaMin } from '../../utils/css';
import { useTranslation } from '../hooks/useTranslation.jsx';
import { LinkBase } from '../Links/LinkBase';

export const Logo = ({ className, isBlue = false, href = '/' }) => {
  const labelText = useTranslation('component.site_header.logo.label');
  return (
    <LinkBase
      className={className}
      to={href}
      title={labelText}
    >
      <div className="logo-icon" >
        {isBlue
          ? <LogoIconDarkBlue />
          : <LogoIconWhite />
        }
      </div>
    </LinkBase>
  );
};

export const StyledLogo = styled(Logo)`
  .logo-icon {
    width:44px;
    height:25px;
  }

  ${mediaMin.md`
    .logo-icon {
      width:53px;
      height:30px;
    }
  `}

  ${mediaMin.lg`
    .logo-icon {
      width:63px;
      height:36px;
    }
  `}
`;
