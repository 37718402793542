import gql from 'graphql-tag';
import { styled } from '@linaria/react';
import { cx } from '@linaria/core';
import { makeMemoFragment } from '../../utils/graphql';
import { darkTeaserTextStyles, StyledTeaserText as TeaserText } from '../TeaserText.jsx';
import { authorDetailsFragment, darkAuthorDetailsStyles, StyledAuthorDetails as AuthorDetails } from './AuthorDetails.jsx';
import { darkTimeAreaStyles, StyledTimeArea as TimeArea, timeAreaFragment } from './TimeArea.jsx';

export const mediaDetailsHeaderFragment = makeMemoFragment({
  name: 'MediaDetailsHeader',
  fragment() {
    return gql`fragment ${this.name} on Content {
          ... on TextualAspect {
            teaser
          }
          ...${authorDetailsFragment.name}
          ...${timeAreaFragment.name}
        }
        ${authorDetailsFragment.fragment()}
        ${timeAreaFragment.fragment()}
    `;
  },
});

export const MediaDetailsHeader = ({
  content,
  className,
}) => (
  <header className={className}>
    <AuthorDetails content={content} />
    <TimeArea className="time-area" content={content}/>
    <TeaserText className="teaser-text">{content.teaser}</TeaserText>
  </header>
);

export const darkMediaDetailHeaderStyles = cx(
  darkAuthorDetailsStyles,
  darkTimeAreaStyles,
  darkTeaserTextStyles,
);

// TODO linaria-next: the margin-top on the .time-area below is useless since its a <span> which is display:inline and cant have margin-top
export const StyledMediaDetailsHeader = styled(MediaDetailsHeader)`
  .time-area {
    margin-top: 5px;
  }
  .teaser-text {
    margin-bottom: 10px;
    margin-top: 10px;
  }
`;
