import { Fragment } from 'react';
import { styled } from '@linaria/react';
import { StyledContentArea as ContentArea } from '../ContentMoveUpLayout/ContentArea';
import { mediaMin } from '../../../utils/css';

export const BasicLayout = ({
  children,
  HeroCmp = Fragment,
  heroCmpProps,
  className,
}) => (
  <article className={className}>
    <HeroCmp {...heroCmpProps}>
      <ContentArea className='content-area'>
        {children}
      </ContentArea>
    </HeroCmp>
  </article>
);

// TODO linaria-next: css``
export const centeredContent = `
  width: 1110px;
  margin-inline: auto;
`;

export const StyledBasicLayout = styled(BasicLayout)`
  overflow-x: hidden;

  ${mediaMin.xl`
    .content-area {
      ${centeredContent}
    }
  `}
`;
