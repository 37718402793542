import { styled } from '@linaria/react';
import gql from 'graphql-tag';

import { cx } from '@linaria/core';
import { colors, mediaMin } from '../../../utils/css';
import { partialFragments, Partials, StyledContentTeaser as ContentTeaser } from '../../ContentTeaserSnippets/ContentTeaserPartials';
import { StyledPromoLink as PromoLink } from './PromoLink.jsx';

import { StyledContentBlock as ContentBlock } from '../ContentBlock';

import { squareConfigFactory } from '../../ResponsiveDwPicture/pictureConfigs';
import { makeMemoFragment } from '../../../utils/graphql';
import { lightLinkStyles } from '../../Links/LinkBase';

export const crossPromotionFragment = makeMemoFragment({
  name: 'CrossPromotion',
  fragment() {
    return gql`fragment ${this.name} on Reference {
        referenceUrl
        title
        shortTeaser
        ...${partialFragments.imageWrapFragment.name}
      }
      ${partialFragments.imageWrapFragment.fragment()}
    `;
  },
});

const isValidCrossPromo = ({ mainContentImageLink, referenceUrl } = {}) => referenceUrl && mainContentImageLink?.target;
const getTeaserLikeReferenceContent = referenceContent => ({ ...referenceContent, namedUrl: referenceContent.referenceUrl });

export const CrossPromotion = ({ className, contents = [] }) => {
  const [firstReferenceContent] = contents;
  if (!isValidCrossPromo(firstReferenceContent)) {
    return null;
  }

  const referenceContent = getTeaserLikeReferenceContent(firstReferenceContent);

  return (
    <ContentBlock className={cx(lightLinkStyles, className)}
      headlineIsA="h2"
      pageSectionId="cross-promotion"
      headlineTranslation="content_block.crosspromotion.headline"
    >
      <ContentTeaser
        formatConfig={squareConfigFactory()}
        content={referenceContent}
        className='row'
      >
        <Partials.LinkedImage className="col-lg-8"/>
        <Partials.MainContentArea className="col-lg-4">
          <Partials.Data>
            <Partials.LinkedTitle isA="h3"/>
            <Partials.LinkedDescription />
            <PromoLink className="promo-link" />
          </Partials.Data>
        </Partials.MainContentArea>
      </ContentTeaser>
    </ContentBlock>
  );
};

export const StyledCrossPromotion = styled(CrossPromotion)`
.teaser {
  padding-inline: 15px;
  row-gap: 0;
  background-color: ${colors.DW_LIGHT_BLUE};
  background-clip: content-box;

  .teaser-data-wrap {
    display: flex;
    padding: 15px 15px 12px 15px;

    .teaser-data {
      display: flex;
      flex-direction: column;
      width: 100%;
      .promo-link {
        margin-top: auto;
      }
      a:visited {
        color: ${colors.DARK_BLUE_GREY_01}
      }
    }

    .title {
      font-size: 2.4rem;
    }
  }

  .teaser-image-wrap {
    padding-inline: 0;
  }
}

${mediaMin.md`
  .teaser {
    .teaser-data-wrap {
      .title {
        font-size: 2.8rem;
      }
    }
  }
`}

${mediaMin.lg`
  .teaser {
    .teaser-data-wrap {
      padding-inline-start: 30px;
      .title {
        font-size: 3.2rem;
      }
    }
  }
`}

${mediaMin.xl`
  .teaser {
    .teaser-data-wrap
      .title {
        font-size: 3.6rem;
      }
    }
  }
`}

`;
