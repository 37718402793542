import { StyledA11yInternalLinkTarget as A11yInternalLinkTarget } from './A11yInternalLinkTarget.jsx';

export const A11yLinkTargetMarker = ({
  className, id, children,
}) => (
  <div className={className} >
    <A11yInternalLinkTarget id={id}/>
    {children}
  </div>
);

export const StyledA11yLinkTargetMarker = A11yLinkTargetMarker;
