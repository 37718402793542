import globals from '../../utils/globals';

const { window, document } = globals;
const { documentElement, body } = document;

const isValidHtmlElement = elem => elem && elem instanceof HTMLElement;

function isFixed(elem) {
  if (!isValidHtmlElement(elem)) {
    return false;
  }
  const pos = window.getComputedStyle(elem).position;
  return pos === 'fixed';
}

function hasParentWithFixedPosition(elem, acc = false) {
  if (!isValidHtmlElement(elem)) {
    return acc;
  }
  return hasParentWithFixedPosition(elem.parentNode, acc || isFixed(elem));
}

function offsetOf(elem, hasFixedParent = false) {
  const rect = elem.getBoundingClientRect();
  const clientLeft = documentElement.clientLeft || body.clientLeft;
  const clientTop = documentElement.clientTop || body.clientTop;

  if (hasFixedParent) {
    return {
      top: rect.top - clientTop,
      left: rect.left - clientLeft,
    };
  }
  const scrollLeft = window.pageXOffset || documentElement.scrollLeft || body.scrollLeft;
  const scrollTop = window.pageYOffset || documentElement.scrollTop || body.scrollTop;
  return {
    top: rect.top + scrollTop - clientTop,
    left: rect.left + scrollLeft - clientLeft,
  };
}

function getMeasurableElem(elem) {
  if (!isValidHtmlElement(elem)) {
    return null;
  }
  if (elem.offsetWidth > 0) {
    return elem;
  }
  const firstChildElem = elem.children[0];
  return getMeasurableElem(firstChildElem);
}

const toPx = obj => Object.entries(obj).reduce((acc, [key, value]) => ({ ...acc, [key]: `${value}px` }), {});

export const focusBorderWidth = 2;

export function positionAndRadiusOf(focusedTarget) {
  const measurableElem = getMeasurableElem(focusedTarget);
  if (!isValidHtmlElement(measurableElem)) {
    return {
      ...toPx({
        width: 0,
        height: 0,
        borderRadius: 0,
      }),
    };
  }
  const hasFixedParent = hasParentWithFixedPosition(focusedTarget);
  const offset = offsetOf(measurableElem, hasFixedParent);
  const position = hasFixedParent ? 'fixed' : 'absolute';
  const radius = +(window.getComputedStyle(measurableElem).borderRadius.slice(0, -2));

  return {
    position,
    transform: `translate3d(${Math.round(offset.left)}px,${Math.round(offset.top)}px,0px)`,
    ...toPx({
      width: measurableElem.offsetWidth + focusBorderWidth * 2,
      height: measurableElem.offsetHeight + focusBorderWidth * 2,
      borderRadius: radius === 0 ? 0 : radius + focusBorderWidth,
    }),
  };
}

export function getZIndex(elem, acc = 0) {
  if (!isValidHtmlElement(elem)) {
    return acc;
  }
  const z = window.getComputedStyle(elem).getPropertyValue('z-index');
  if (isNaN(z)) {
    return getZIndex(elem.parentNode, acc);
  }
  return getZIndex(elem.parentNode, acc + +z);
}
