import { styled } from '@linaria/react';
import { InfoSystemFont, InfoSystemFontItalic } from './Text/Information.jsx';
import { useTranslation } from './hooks/useTranslation.jsx';

export const CopyrightInfo = ({ isA = InfoSystemFont, className, licenserSupplement }) => {
  const imageCopyrightPrefix = useTranslation('general.image.copyright_prefix');
  if (!licenserSupplement) {
    return null;
  }
  const Tag = isA;
  return (
    <Tag isA='small' className={className}>
      {imageCopyrightPrefix} {licenserSupplement}
    </Tag>
  );
};

export const darkCopyrightInfoStyles = InfoSystemFont.darkStyles;

export const StyledCopyrightInfo = styled(CopyrightInfo)`
  font-size: 1rem;
`;

export const StyledItalicCopyrightInfo = props => <StyledCopyrightInfo {...props} isA={InfoSystemFontItalic}/>;
