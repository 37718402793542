import { useEffect } from 'react';
import { useParams } from '../../dwouter';

import { isHomePage } from '../../utils/contentUtils';
import { useLocalStorage } from './useLocalStorage';

export const useSetPreferredLanguageEffect = navigation => {
  const { langCode } = useParams();
  const [, setUserLanguage] = useLocalStorage('com.dw.userLanguage', { isPrimitive: true });

  useEffect(() => {
    if (isHomePage(navigation)) {
      setUserLanguage(langCode);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigation.id]);
};
