import { useEffect, useState } from 'react';
import { addResizeEventListener, loadFacebookApi } from './api';

export const useLoadFacebookApiEffect = ({ inView }) => {
  const [facebookApi, setFacebookApi] = useState({ FB: null });

  const loadAndSaveFacebookApi = async () => {
    const { FB } = await loadFacebookApi();
    setFacebookApi({ FB });
    addResizeEventListener({ FB });
  };

  useEffect(() => {
    if (inView && !facebookApi.FB) {
      loadAndSaveFacebookApi();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView]);

  return facebookApi;
};
