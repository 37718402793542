import { extendWithClassNames } from '../../../utils/extendWithClassNames.jsx';

import {
  StyledTeaserComposition as TeaserComposition,
  extendWithDefaultTeaserComposition,
  extendTeaserCompositionWithBottomAlignmentLooks,
  extendTeaserCompositionWithBigImageOpinionTeaserLooks,
  extendDefaultTeaserCompositionWithMandatoryBasicLooks,
} from '../TeaserComposition';

export const StyledDefaultBigImageOpinionLayout = extendWithClassNames(TeaserComposition)(
  extendTeaserCompositionWithBottomAlignmentLooks,
  extendWithDefaultTeaserComposition,
  extendDefaultTeaserCompositionWithMandatoryBasicLooks,
  extendTeaserCompositionWithBigImageOpinionTeaserLooks,
);
