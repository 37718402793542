import { styled } from '@linaria/react';
import { StyledReturn as ReturnIcon } from '../icons';
import { colors } from '../../utils/css';
import { HeadlineSemiBoldDwDarkBlueAndWhite } from '../Text/Headline.jsx';
import { useTranslation } from '../hooks/useTranslation.jsx';

export const QuickNavigationItem = ({
  className, pageSectionId, translation, ...restProps
}) => {
  const translatedText = useTranslation(translation);
  return (
    <a href={`#${pageSectionId}`} className={className} { ...restProps }>
      <HeadlineSemiBoldDwDarkBlueAndWhite>{translatedText}</HeadlineSemiBoldDwDarkBlueAndWhite>
      <ReturnIcon/>
    </a>
  );
};

export const StyledQuickNavigationItem = styled(QuickNavigationItem)`
  position: absolute;
  inset-inline-start: 2px;
  top: 2px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 40px;
  background-color: ${colors.ACCENT_GREEN};
  padding-inline: 30px 15px;
  opacity: 0;
  pointer-events: none;
  z-index: 4;

  > * {
    font-size: 1.7rem;
    letter-spacing: 0.057rem;
  }

  svg {
    margin-inline-start: 15px;
    min-width: 20px;
  }

  &:focus{
    outline: 0;
    opacity: 1;
    pointer-events: auto;
    box-shadow: 0px 0px 0px 2px ${colors.ACCENT_GREEN};
  }

  &:hover{
    text-decoration: none;
  }
`;
