import { styled } from '@linaria/react';
import gql from 'graphql-tag';
import { cx } from '@linaria/core';
import { LinkMonoColor } from '../../Links';
import { StyledAudio as AudioIcon, StyledVideo as VideoIcon } from '../../icons';
import { mediaMax, mediaMin } from '../../../utils/css';
import { makeMemoFragment } from '../../../utils/graphql';
import { interactionCss } from '../../buttons/Button/ButtonBase.jsx';

export const mediaItemFragment = makeMemoFragment({
  name: 'MediaItem',
  fragment() {
    return gql`fragment ${this.name} on Navigation {
          name
          namedUrl
          templateType
        }
    `;
  },
});

const templateTypeToMediaIcon = Object.freeze({
  WEBAPP_AUDIO: AudioIcon,
  WEBAPP_VIDEO: VideoIcon,
});

export const MediaItem = ({ className, mediaNav }) => {
  const IconTag = templateTypeToMediaIcon[mediaNav.templateType];

  return (
    <LinkMonoColor
      className={className}
      to={mediaNav.namedUrl}
      aria-label={mediaNav.name}
    >
      <IconTag className={cx('media-icon', interactionCss)}/>
      <span className="label">{mediaNav.name}</span>
    </LinkMonoColor>
  );
};

export const StyledMediaItem = styled(MediaItem)`
  font-size: 1.6rem;
  display: inline-flex;
  align-items: center;
  column-gap: 14px;

  .media-icon {
    width: 16px;
    height: 16px;
  }
`;

export const StyledCompactMediaItem = styled(StyledMediaItem)`
  .label {
    display: none;
  }

  ${mediaMax.md`
    .media-icon {
      width: 30px;
      height: 30px;
      padding: 7px;
    }
  `}

  ${mediaMin.lg`
    .media-icon:hover {
      box-shadow: none;    
    }
    .label {
      display: inline;
    }
  `}
`;
