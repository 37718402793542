import { Helmet } from 'react-helmet-async';
import { useLanguageContext } from '../commons/WithGraphQLContentHandling/LanguageContext/LanguageContext.jsx';

import {
  PangeaArabic,
  PangeaCyrillic,
  PangeaGreek,
  PangeaLatinBasicLetters,
  PangeaLatinBasicSymbols,
  PangeaLatinExtended,
} from '../../fonts/Pangea';
import {
  NotoNaskhArabic,
  NotoSansLatinBasicLetters,
  NotoSansLatinBasicSymbols,
  NotoSansLatinExtended,
} from '../../fonts/NotoSans';
import { useLocation, useParams } from '../../dwouter';
import { getLangCodeFromUrlPath } from '../../utils/url';

const fontFaceBaseTemplate = ({
  fontWeight, fontStyle, family, unicodeRange,
}) => `
  font-display : swap;
  font-family  : ${family};
  font-style   : ${fontStyle};
  font-weight  : ${fontWeight};
  unicode-range: ${unicodeRange};
`;

/*
  NOTE:
    If some day a new language with noto sans would be added, please think about amp and adjust `const langFonts` in file
    `/src/server/amp/fonts/notoSansInjector.js`, too.
*/
export const pangeaFontFaces = [
  /* DW Pangea VAR */
  /* --- Albanian, Bosnian, Bulgarian, Croatian, English, French, German, Greek, Hausa, Indonesian,
   Kiswahili, Macedonian, Polish, Portuguese, Romanian, Russian, Serbian, Spanish, Turkish, Ukrainian ---*/
  { font: PangeaLatinBasicLetters, unicodeRanges: ['U+041-05A', 'U+061-07A'] }, /* Basic Latin - Letters */
  { font: PangeaLatinBasicSymbols, unicodeRanges: ['U+020-040', 'U+05B-060', 'U+07B-07E', 'U+0A0', 'U+0A9'] }, /* Basic Latin - Symbols (+ nbsp, + ©) */
  { font: PangeaLatinExtended, unicodeRanges: ['U+0A1-0A8', 'U+0AA-24F'] }, /* Latin-1 Supplement + Latin Extended-A + Latin Extended-B */
  { font: PangeaGreek, unicodeRanges: ['U+370-3FF'] }, /* Greek and Coptic */
  { font: PangeaCyrillic, unicodeRanges: ['U+400-52F'] }, /* Cyrillic + Cyrillic Supplement */
  /* --- Arabic, Dari, Pashto, Persian, Urdu ---*/
  { font: PangeaArabic, unicodeRanges: ['U+600-6FF', 'U+750-77F', 'U+8A0-8FE', 'U+FB50-FDFF', 'U+FE70-FEFF'] }, /* Arabic + Arabic Supplement + Arabic Extended-A + Arabic Presentation Forms-A + Arabic Presentation Forms-B */
];

// ----- PANGEA -----
const generatePangeaFontFace = ({ font, unicodeRanges }) => {
  const unicodeRange = unicodeRanges.join();
  return `
    @font-face {
        ${fontFaceBaseTemplate({
    family: 'Pangea', fontWeight: '300 700', fontStyle: 'normal, italic', unicodeRange,
  })}
        font-optical-sizing: none;
        src:  local('DW Pangea VAR Light'),
              url(${font.all}) format('woff2 supports variations'),
              url(${font.all}) format('woff2-variations');
    }`;
};

// ----- NOTOSANS -----

export const notoSansFontFaces = [
  /* NotoSans */
  /* --- Albanian, Bosnian, Bulgarian, Croatian, English, French, German, Greek, Hausa, Indonesian,
   Kiswahili, Macedonian, Polish, Portuguese, Romanian, Russian, Serbian, Spanish, Turkish, Ukrainian ---*/
  { font: NotoSansLatinBasicLetters, unicodeRanges: ['U+041-05A', 'U+061-07A'] }, /* Basic Latin - Letters */
  { font: NotoSansLatinBasicSymbols, unicodeRanges: ['U+020-040', 'U+05B-060', 'U+07B-07E', 'U+0A0', 'U+0A9'] }, /* Basic Latin - Symbols (+ nbsp, + ©) */
  { font: NotoSansLatinExtended, unicodeRanges: ['U+0A1-0A8', 'U+0AA-24F'] }, /* Latin-1 Supplement + Latin Extended-A + Latin Extended-B */
  // { font: NotoSansGreek, unicodeRanges: ['U+370-3FF'] }, /* Greek and Coptic */
  // { font: NotoSansCyrillic, unicodeRanges: ['U+400-52F'] }, /* Cyrillic + Cyrillic Supplement */
  // /* --- Amharic ---*/
  // { font: NotoSansEthiopic, unicodeRanges: ['U+1200-137F'], src: 'Noto Sans Ethiopic' }, /* Ethiopic */
  /* --- Arabic, Dari, Pashto, Persian, Urdu ---*/
  { font: NotoNaskhArabic, unicodeRanges: ['U+600-6FF', 'U+750-77F', 'U+8A0-8FE', 'U+FB50-FDFF', 'U+FE70-FEFF'], src: 'Noto Naskh Arabic' }, /* Arabic + Arabic Supplement + Arabic Extended-A + Arabic Presentation Forms-A + Arabic Presentation Forms-B */
  // /* --- Bengali ---*/
  // { font: NotoSansBengali, unicodeRanges: ['U+980-9FF'], src: 'Noto Sans Bengali' }, /* Bengali */
  // /* --- Hindi ---*/
  // { font: NotoSansDevanagari, unicodeRanges: ['U+900-97F'], src: 'Noto Sans Devanagari' }, /* Devanagari */
];

export const generateNotoSansFontFace = ({
  font,
  src = 'Noto Sans',
  unicodeRanges,
}) => {
  const unicodeRange = unicodeRanges.join();
  return `
    @font-face {
        ${fontFaceBaseTemplate({
    family: 'NotoSans', fontWeight: '400', fontStyle: 'normal', unicodeRange,
  })}
        src:  local('${src}'),
              url(${font.regular}) format('woff2');
    }
    @font-face {
        ${fontFaceBaseTemplate({
    family: 'NotoSans', fontWeight: '700', fontStyle: 'normal', unicodeRange,
  })}
        src:  local('${src}'),
              url(${font.bold}) format('woff2');
    }
  `;
};

const generateFontFaceNormalized = fontConfig => (
  fontConfig.font.all
    ? generatePangeaFontFace(fontConfig)
    : generateNotoSansFontFace(fontConfig)
);

export const cssVariables = {
  italicForSystemFont: '--italic-for-system-font',
  systemFontFamily: '--system-font-family',
};

const FontFacesAndFamily = ({ fonts }) => {
  const {
    isEuropeanLang,
    isAsianOrAfricanLang,
    isChineseLang,
  } = useLanguageContext();
  const { langCode } = useParams();
  const isSystemFontLanguageWithItalics = systemFontLanguagesWithItalics.includes(langCode);

  // TODO linaria-next maybe this can be a regular global styles now?
  return (
    <Helmet>
      <style type="text/css">{`
        ${fonts?.map(generateFontFaceNormalized)
      .join('')
    }

        :root {
          font-synthesis-style: none;

          ${cssVariables.italicForSystemFont}: ${isEuropeanLang || isSystemFontLanguageWithItalics ? 'italic' : 'none'};
          ${cssVariables.systemFontFamily}: Arial, Helvetica, sans-serif;
        }
        :root[lang="bn"], [lang="hi"], [lang="zh"] {
          font-synthesis-style: auto;
        }
        html body {
          font-family: Pangea, Arial, sans-serif;
          ${isAsianOrAfricanLang ? 'font-family: NotoSans, Noto Sans, Arial, Helvetica;' : ''}
          ${isChineseLang ? `font-family: "MS Song", "MS Hei", var(${cssVariables.systemFontFamily});` : ''}
        }`}
      </style>
    </Helmet>
  );
};

export const systemFontLanguages = ['am', 'bn', 'hi', 'zh', 'zh-hant'];
const systemFontLanguagesWithItalics = ['bn', 'hi', 'zh', 'zh-hant'];

export const GlobalFonts = () => {
  const [{ pathname }] = useLocation();
  const langCode = getLangCodeFromUrlPath(pathname);

  const fontFaces = langCode === 'fa-ir' ? notoSansFontFaces : pangeaFontFaces;

  return <FontFacesAndFamily fonts={systemFontLanguages.includes(langCode) ? [] : fontFaces} />;
};
