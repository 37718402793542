import { styled } from '@linaria/react';

import gql from 'graphql-tag';
import { cx } from '@linaria/core';
import { StyledCarousel as Carousel } from '../../Carousel';

import { darkContentBlockListStyles, StyledContentBlockList as ContentBlockList } from '../../ContentBlocks/Types/ContentBlockList.jsx';
import { contentBlockPadding } from '../../ContentBlocks/ContentBlock';
import { isValidList } from '../../../utils/contentUtils';
import { noop } from '../../../utils/commons';
import { mediaMax, mediaMin } from '../../../utils/css';
import { extendTeaserCompositionWithBottomAlignmentLooksForXS } from '../TeaserComposition/TeaserLayoutRelated/BottomAlignment';
import { CarouselTeaserContainer } from '../ContentTeaserPartials/CarouselTeaserContainer.jsx';
import { makeMemoFragment } from '../../../utils/graphql';

export const listCarouselFragment = makeMemoFragment({
  name: 'ListCarousel',
  fragment() {
    return gql`fragment ${this.name} on Content {
        ... on ModelAspect {
          id
        }
      }
    `;
  },
});

export const ListCarousel = ({
  className,
  contents = [],
  preListSlotFn = noop,
  headlineTranslation,
  bottomElementFn,
  pageSectionId,
  teaserIsA,
  teaserContainerClassName,
  isPreloadFirstTeaserImg,
}) => {
  const preListSlot = preListSlotFn();

  if (!isValidList(contents)) {
    return null;
  }

  const TeaserTag = teaserIsA;
  return (
    <ContentBlockList
      className={cx(
        className,
        extendTeaserCompositionWithBottomAlignmentLooksForXS,
        darkContentBlockListStyles,
      )}
      headlineTranslation={headlineTranslation}
      headlineIsA="h2"
      pageSectionId={pageSectionId}
      bottomElementFn={bottomElementFn}
    >
      {preListSlot}
      <Carousel className="carousel" >
        {contents.map((content, index) => (
          <CarouselTeaserContainer
            key={content.id}
            className={teaserContainerClassName}
          >
            <TeaserTag
              content={content}
              isPreloadImage={isPreloadFirstTeaserImg && index === 0}
              titleIsA={preListSlot ? 'h4' : 'h3'}
            />
          </CarouselTeaserContainer>
        ))}
      </Carousel>
    </ContentBlockList>
  );
};

export const StyledListCarousel = styled(ListCarousel)`
  .slider {
    margin-top: 0;
    padding-bottom: 0;

    &.has-indicator {
      padding-bottom: 17px;
    }
  }
  .carousel-icons {
    bottom: 0;
  }

  .content-block {
    display: grid;
    > *:not(.carousel) {
      padding-inline: ${contentBlockPadding.xs}px;
    }
  }

  ${mediaMin.sm`
    .content-block {
      > *:not(.carousel) {
        padding-inline: ${contentBlockPadding.sm}px;
      }
    }
  `}

  ${mediaMin.md`
    .content-block {
      > *:not(.carousel) {
        padding-inline: ${contentBlockPadding.md}px;
      }
    }

    .slider.has-indicator {
      padding-bottom:22px;
    }
  `}

  ${mediaMax.md`
    .content-block {
      padding-inline: 0;
    }
  `}

  ${mediaMin.lg`
    .content-block {
      > *:not(.carousel) {
        padding-inline: 0;
      }
    }
  `}
`;
