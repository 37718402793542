import { useRef } from 'react';

import { css } from '@linaria/core';
import globals from '../../utils/globals';

const forcedOffsetParentForHeaderToggleClassName = css`
  position: relative;
`;

const hasEntryLeftTheRootBounds = entry => entry.boundingClientRect.top - Math.abs(entry.rootBounds.top) < 0;
const isEntryAboveCurrentScrollPosition = entry => globals.window.scrollY > entry.target.offsetTop;

const hasLeftFromTop = ({ entry }) => hasEntryLeftTheRootBounds(entry) && isEntryAboveCurrentScrollPosition(entry);

export const makeHeaderBlueEvent = 'custom-make-header-blue';
export const makeHeaderWhiteEvent = 'custom-make-header-white';

export const useHeaderColorToggle = () => {
  const intersected = useRef(false);
  const isLeavingFromTop = useRef(false);

  const elementEnteringViewport = ({ inViewport }) => {
    intersected.current = true; // eslint-disable-line fp/no-mutation
    if (isLeavingFromTop && inViewport) {
      globals.window.dispatchEvent(new Event(makeHeaderBlueEvent));
    }
  };

  const elementLeavingViewport = ({ entry }) => {
    intersected.current = false; // eslint-disable-line fp/no-mutation
    globals.window.setTimeout(() => {
      isLeavingFromTop.current = hasLeftFromTop({ entry }); // eslint-disable-line fp/no-mutation

      if (isLeavingFromTop.current) {
        globals.window.dispatchEvent(new Event(makeHeaderWhiteEvent));
      }
    }, 0);
  };

  return {
    onChangeInViewListenerForHeaderSwitch: (inViewport, entry) => {
      if (!intersected.current && inViewport) {
        elementEnteringViewport({ inViewport });
      }
      if (entry && !inViewport) {
        elementLeavingViewport({ entry });
      }
    },
    forcedOffsetParentForHeaderToggleClassName,
  };
};
