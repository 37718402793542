const fragmentCache = {};

export const makeMemoFragment = fragDef => ({
  name: fragDef.name,
  fragment() {
    const cachedFrag = fragmentCache[fragDef.name];
    if (cachedFrag) {
      return cachedFrag;
    }
    const frag = fragDef.fragment();
    // eslint-disable-next-line fp/no-mutation
    fragmentCache[fragDef.name] = frag;
    return frag;
  },
});
