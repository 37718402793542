import gql from 'graphql-tag';

import { makeMemoFragment } from '../../../utils/graphql';
import { darkAuthorLinkStyles, StyledAuthorLink as AuthorLink } from './AuthorLink.jsx';

export const legacyAuthorFragment = makeMemoFragment({
  name: 'LegacyAuthor',
  fragment() {
    return gql`fragment ${this.name} on DeliveryAspect {
          legacyAuthor
        }
    `;
  },
});

export const LegacyAuthor = ({
  fullName,
  className,
}) => (
  <AuthorLink
    className={className}
    person={{ fullName, autoDelivery: 0 }}
  />
);

export const darkLegacyAuthorStyles = darkAuthorLinkStyles;

export const StyledLegacyAuthor = LegacyAuthor;
