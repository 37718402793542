import { styled } from '@linaria/react';
import { cx } from '@linaria/core';
import gql from 'graphql-tag';

import { useParams } from '../../dwouter';
import { LinkDefault } from '../Links';
import { InformationItalic } from '../Text/Information.jsx';
import { useTranslation } from '../hooks/useTranslation.jsx';
import { toLangCode } from '../../utils/langMapper';
import { makeMemoFragment } from '../../utils/graphql';
import { resetBoostrapAndBrowserDefaultLineHeight } from '../../utils/commons';

export const footerLinkFragment = makeMemoFragment({
  name: 'FooterLink',
  fragment() {
    return gql`fragment ${this.name} on FooterLinkedContent {
      text
      url
      targetLanguage
    }
  `;
  },
});

export const FooterLink = ({ className, link }) => {
  const { langCode = toLangCode('ENGLISH') } = useParams();
  const targetLangCode = toLangCode(link.targetLanguage) || langCode;
  const targetLangIsDifferent = targetLangCode !== langCode;

  const hrefLang = targetLangIsDifferent ? targetLangCode : undefined;
  const languageHintTranslation = useTranslation(`component.footer.language_hint.${targetLangCode}.label`);
  const title = targetLangIsDifferent ? `${link.text} — ${languageHintTranslation}` : link.text;
  const skipTitleForInternal = !targetLangIsDifferent;

  return (
    <li className={className}>
      <LinkDefault className={cx('footer-link', LinkDefault.darkStyles)} skipTitleForInternal={skipTitleForInternal} to={link.url} title={title} hrefLang={hrefLang}>
        {link.text}
      </LinkDefault>
      {targetLangIsDifferent && (
        <InformationItalic className={cx('language-hint', InformationItalic.darkStyles)}>
          {languageHintTranslation}
        </InformationItalic>
      )}
    </li>
  );
};

export const StyledFooterLink = styled(FooterLink)`
  font-size: 1.1rem;
  margin: 10px 0;
  ${resetBoostrapAndBrowserDefaultLineHeight}

  .footer-link {
    &:after {
      content: ' ';
    }
  }

  .language-hint {
    display: inline-block;

    &::before {
      content: '(';
    }
    &::after {
      content: ')';
    }
  }
`;
