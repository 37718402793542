import { useEffect, useState } from 'react';

import { useFrontendConfig } from '../../../components/FrontendConfigContext.jsx';
import { loadEinbliq } from './loadEinbliqApi';

export const useLoadEinbliqLibEffect = ({
  content, allowLoadEinbliq,
}) => {
  const { einbliqCustomerId } = useFrontendConfig();
  const [einbliqLibState, setEinbliqLibState] = useState({ initEinbliqSession: null });

  useEffect(() => {
    let didCancel = false; // eslint-disable-line fp/no-let

    async function loadEinbliqLib() {
      const { initEinbliqSession } = await loadEinbliq();

      if (!didCancel) {
        setEinbliqLibState({ initEinbliqSession });
      }
    }

    if (allowLoadEinbliq && !einbliqLibState?.initEinbliqSession) {
      loadEinbliqLib();
    }
    return () => {
      didCancel = true; // eslint-disable-line fp/no-mutation
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [content, allowLoadEinbliq, einbliqCustomerId, einbliqLibState]);

  return einbliqLibState;
};
