import gql from 'graphql-tag';
import { StyledContentBlockList as ContentBlockList } from '../../ContentBlocks/Types/ContentBlockList.jsx';
import { StyledDefaultHighlightSmallImageTextLayout as DefaultHighlightSmallImageTextLayout } from '../Layouts/DefaultHighlightSmallImageTextLayout';
import {
  smallImageTextContentTeaserFragment,
  StyledSmallImageTextContentTeaser as SmallImageTextContentTeaser,
} from '../ContentTeasers/SmallImageTextContentTeaser.jsx';
import { squareConfigFactory } from '../../ResponsiveDwPicture/pictureConfigs';
import { noop } from '../../../utils/commons';
import { makeMemoFragment } from '../../../utils/graphql';

export const listDefaultHighlightSmallImageTextFragment = makeMemoFragment({
  name: 'ListDefaultHighlightSmallImageText',
  fragment() {
    return gql`fragment ${this.name} on Content {
        ... on ModelAspect {
          id
        }
        ...${smallImageTextContentTeaserFragment.name}
      }
      ${smallImageTextContentTeaserFragment.fragment()}
    `;
  },
});

export const ListDefaultHighlightSmallImageText = ({ contents = [], preListSlotFn = noop, ...restProps }) => {
  const preListSlot = preListSlotFn();
  return (
    <ContentBlockList headlineIsA='h2' {...restProps}>
      {preListSlot}
      <DefaultHighlightSmallImageTextLayout>
        {contents.map((content, i) => (
          <SmallImageTextContentTeaser
            key={content.id}
            content={content}
            imageFormatConfig={i > 0 ? squareConfigFactory() : undefined}
            titleIsA={preListSlot ? 'h4' : 'h3'}
          />
        ))}
      </DefaultHighlightSmallImageTextLayout>
    </ContentBlockList>
  );
};

export const StyledListDefaultHighlightSmallImageText = ListDefaultHighlightSmallImageText;
