import gql from 'graphql-tag';
import { styled } from '@linaria/react';
import classnames from 'classnames';
import { StyledMediaTag as MediaTag } from '../../../MediaTag.jsx';
import { mediaMin } from '../../../../utils/css';
import { useContentTeaserContext } from '../ContentTeaserContext';
import { useContentSpecificAriaLabel } from '../../../hooks/useContentSpecificAriaLabel';
import { makeMemoFragment } from '../../../../utils/graphql';
import { LinkDefaultNarrow, LinkDefaultNarrowItalic } from '../../../Links';

export const linkedTitleFragment = makeMemoFragment({
  name: 'LinkedTitle',
  fragment() {
    return gql`fragment ${this.name} on Content {
        ... on OpinionAspect {
          isOpinion
        }
      }
    `;
  },
});

const LinkedTitleWithMediaTag = ({
  contentType, title, isItalic,
  ...restProps
}) => {
  const LinkTag = isItalic ? LinkDefaultNarrowItalic : LinkDefaultNarrow;
  return (
    <LinkTag
      { ...restProps}
    >
      <MediaTag contentType={contentType}/>
      {title}
    </LinkTag>
  );
};

export const darkLinkedTitleStyles = LinkDefaultNarrow.darkStyles;

const LinkedTitle = ({
  className,
  isA = 'h2',
  forceSkipTabbing = false,
  'aria-hidden': ariaHidden = false,
  forceItalic = false,
}) => {
  const {
    config: {
      isHidden,
    },
    content,
    link,
  } = useContentTeaserContext();

  const Tag = isA;
  const title = link.name || content.title;
  const url = link.url || content.namedUrl;
  const ariaLabel = useContentSpecificAriaLabel(content.__typename, title);
  const isItalic = content.isOpinion || forceItalic;
  const skipTabbing = forceSkipTabbing || isHidden;

  if (!title) {
    return null;
  }

  return (
    <Tag
      className={classnames(className, 'title')}
      aria-hidden={ariaHidden}
    >
      <LinkedTitleWithMediaTag
        skipTitleForInternal
        to={url}
        tabIndex={skipTabbing ? -1 : 0}
        aria-label={ariaLabel}
        contentType={content.__typename}
        title={title}
        isItalic={isItalic}
      />
    </Tag>
  );
};

export const StyledLinkedTitle = styled(LinkedTitle)`
  margin-bottom: 0;
  font-size: 2.2rem;
  word-break: break-word;

  & > * {
    display: block;
  }

  ${mediaMin.md`
    font-size: 2.4rem;
  `};
`;
