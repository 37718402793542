import gql from 'graphql-tag';
import { css } from '@linaria/core';

import { StyledTopStory as TopStory, topStoryFragment } from '../../ContentBlocks/TopStory';
import { GptSlot } from '../../GptSlot';
import { topStoriesInternalSlotConfig } from '../../GptSlot/slotConfigs';
import { usePageContext } from '../../PageEmbeddingContext.jsx';
import { mediaMin } from '../../../utils/css';
import { makeMemoFragment } from '../../../utils/graphql';

export const topStoryAdapterFragment = makeMemoFragment({
  name: 'TopStoryInterfaceAdapter',
  fragment() {
    return gql`fragment ${this.name} on Content {
        ... on MetadataAspect {
          topStory
        }
        ...${topStoryFragment.name}
      }
      ${topStoryFragment.fragment()}
    `;
  },
});

const topStoryGptSlotStyles = css`
  padding-top: 17px;
  padding-bottom: 40px;
  ${mediaMin.lg`
    padding-top: 27px;
    padding-bottom: 0;
  `}
`;

export const TopStoryInterfaceAdapter = ({ contents = [], ...props }) => {
  const [content, ...moreStoryContents] = contents; // eslint-disable-line fp/no-rest-parameters
  const pageContext = usePageContext();

  if (!content?.topStory) {
    return null;
  }
  return (
    <>
      <TopStory
        content={content}
        moreStoryContents={moreStoryContents}
        {...props}
      />
      {!pageContext.isOnDetailPage && (
        <GptSlot className={topStoryGptSlotStyles} slotConfig={topStoriesInternalSlotConfig} />
      )}
    </>
  );
};

export const darkTopStoryInterfaceAdapterStyles = GptSlot.darkStyles;
