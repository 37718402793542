import { StyledEndLineAlignmentContainer as BottomContainer } from '../EndLineAlignmentContainer.jsx';
import { noop } from '../../../utils/commons';
import { darkHubHeadlineStyles, HubHeadline } from './HubHeadline';

export const Hub = ({
  className,
  children,
  headlineFn = noop,
  bottomElementFn = noop,
  hubContent = {},
  headlineIsA = 'h2',
}) => {
  const headlineElement = headlineFn(hubContent);
  const bottomElement = bottomElementFn(hubContent);

  return (
    <>
      {headlineElement && (
        <HubHeadline isA={headlineIsA} className={className}>
          {headlineElement}
        </HubHeadline>
      )}
      {children}
      {bottomElement && (
        <BottomContainer data-tracking-name="hub-bottom-container" className={className}>
          {bottomElement}
        </BottomContainer>
      )}
    </>
  );
};

export const darkHubStyles = darkHubHeadlineStyles;

export const StyledHub = Hub;
