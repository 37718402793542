import { styled } from '@linaria/react';
import gql from 'graphql-tag';
import { StyledButtonBar as ButtonBar } from '../../buttons/Button/ButtonBar.jsx';
import { ButtonSecondary } from '../../buttons/Button';
import { LinkBase } from '../../Links/LinkBase';
import { makeMemoFragment } from '../../../utils/graphql';
import { PAGE_SIZE } from './AutoTopicContentsPaginated.jsx';
import { useTranslation } from '../../hooks/useTranslation.jsx';

export const allItemsLinkFragment = makeMemoFragment({
  name: 'AllItemsLink',
  fragment() {
    return gql`fragment ${this.name} on AutoTopic {
        namedUrl
        relatedContentsPaginated(offset: $offset, amount: ${PAGE_SIZE}) {
         totalHits
        }
      }
    `;
  },
});

export const AllItemsLink = ({ className, content }) => {
  const { namedUrl, relatedContentsPaginated } = content;
  const paginationPageUrl = `${namedUrl}/page-1`;
  const buttonTranslation = useTranslation({
    key: 'content_block.all_items_link_atp.label',
    parameters: { totalNumberOfContentItems: relatedContentsPaginated.totalHits },
  });

  return (
    <ButtonBar className={className}>
      <ButtonSecondary
        isA={LinkBase}
        role="link"
        to={paginationPageUrl}
      >
        {buttonTranslation}
      </ButtonSecondary>
    </ButtonBar>
  );
};

export const darkAllItemsLinkStyles = ButtonSecondary.darkStyles;

export const StyledAllItemsLink = styled(AllItemsLink)`
  padding-inline: 15px;
  padding-block-end: 30px;
`;
