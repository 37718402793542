import { styled } from '@linaria/react';
import { mediaMin } from '../utils/css';

export const CmpPreviewCompatiblePlaceholder = (({ forwardRef, ...props }) => (
  <div {...props} ref={forwardRef} />
));

export const StyledCmpPreviewCompatiblePlaceholder = styled(CmpPreviewCompatiblePlaceholder)`
  min-height: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  > * {
    width: 100%
  }
  
  ${mediaMin.sm`
    > *:not(.cmppreviewroot) {
      width: auto;
      min-width: 548px;
    }
`}
`;
