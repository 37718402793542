import { extendWithClassNames } from '../../../utils/extendWithClassNames.jsx';
import {
  StyledTeaserComposition as TeaserComposition,
  extendWithDefaultTeaserComposition,
  extendDefaultTeaserCompositionWithStandardTeaserBasicLooks,
  extendTeaserCompositionWithBottomAlignmentLooks,
  extendTeaserCompositionWithSmallImageTextTeaserLooks,
} from '../TeaserComposition';

export const StyledDefaultSmallImageTextLayout = extendWithClassNames(TeaserComposition)(
  extendTeaserCompositionWithBottomAlignmentLooks,
  extendTeaserCompositionWithSmallImageTextTeaserLooks,
  extendWithDefaultTeaserComposition,
  extendDefaultTeaserCompositionWithStandardTeaserBasicLooks,
);
