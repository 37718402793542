import { accessPath } from './jsonPath';

export const replaceInPattern = (pattern, options) => Object.keys(options).reduce(
  (acc, nextVar) => {
    const varNameRegex = new RegExp(/\${/.source + nextVar + /}/.source, 'g'); // ${nextVar}
    return acc.replace(varNameRegex, options[nextVar]);
  },
  pattern,
);

export const getQuantityTranslation = ({ key, count, parameters = {} }) => (
  count === 1
    ? { key: `${key}.singular`, parameters }
    : { key: `${key}.plural`, parameters }
);

const retrieveLabel = ({ key, translations }) => {
  const translation = accessPath(key, translations);
  if (!translation) {
    return key;
  }
  return translation;
};

export class I18n {
  translations = null;

  constructor(inTranslations) {
    this.translations = inTranslations || null;
  }

  t(key, options = {}) {
    const label = retrieveLabel({ key, translations: this.translations });
    return replaceInPattern(label, options);
  }
}
