import { Helmet } from 'react-helmet-async';
import { GlobalFonts } from '../../../GlobalFonts';
import { LanguageContext, langContextDefaults } from './LanguageContext.jsx';

const cd23Alignments = content => {
  const { isAsianOrAfricanLang } = content;
  // this change needs to be moved to webApi when we launch CD23 // REMOVED PERSIAN coz RWD-3866 -> refactoring will be done with RWD-4014
  const arabicLanguages = ['ARABIC', 'DARI', 'PASHTO', 'URDU'];
  return {
    ...content,
    isAsianOrAfricanLang:
      isAsianOrAfricanLang && !arabicLanguages.includes(content.language),
  };
};

export const LanguageContextProvider = ({
  children,
  content = langContextDefaults,
}) => {
  const {
    isRtl, isEuropeanLang, isAsianOrAfricanLang, isChineseLang,
  } = cd23Alignments(content);
  return (
    <LanguageContext.Provider
      value={{
        isRtl,
        isEuropeanLang,
        isAsianOrAfricanLang,
        isChineseLang,
      }}
    >
      <GlobalFonts />
      <Helmet htmlAttributes={{
        dir: isRtl ? 'rtl' : 'ltr',
      }}/>
      {children}
    </LanguageContext.Provider>
  );
};
