import gql from 'graphql-tag';
import {
  expandableFocusTopThreeUnifiedBigImageTextFragment,
  StyledExpandableFocusTopThreeUnifiedBigImageText as ExpandableFocusTopThreeUnifiedBigImageTextTemplate,
} from '../../ContentTeaserSnippets/Templates/ExpandableFocusTopThreeUnifiedBigImageText.jsx';
import { makeMemoFragment } from '../../../utils/graphql';

export const relatedVideosThematicFocusRegion = makeMemoFragment({
  name: 'RelatedVideosThematicFocusRegion',
  fragment() {
    return gql`fragment ${this.name} on Video {
      moreAvContentsByThematicFocusAndRegion {
        ...${expandableFocusTopThreeUnifiedBigImageTextFragment.name}
      }
      ... on AssociationsAspect {
        thematicFocusCategory {
          name
        }
        level1GeoRegion {
          alignedName
        }
      }
    }
    ${expandableFocusTopThreeUnifiedBigImageTextFragment.fragment()}
  `;
  },
});

export const RelatedVideosThematicFocusRegion = ({ content, className }) => {
  const { moreAvContentsByThematicFocusAndRegion } = content;

  const thematicFocusName = content.thematicFocusCategory?.name;

  if (!thematicFocusName) {
    return null;
  }

  const level1GeoRegionAlignedName = content.level1GeoRegion?.alignedName;
  const headlineTranslation = {
    key: 'content_block_detail.related_videos_thematic_focus_region.headline',
    parameters: {
      firstThematicFocus: thematicFocusName,
      regionOfFirstRegionalReference: level1GeoRegionAlignedName,
    },
  };

  return (
    <ExpandableFocusTopThreeUnifiedBigImageTextTemplate
      className={className}
      contents={moreAvContentsByThematicFocusAndRegion}
      headlineTranslation={headlineTranslation}
      showMoreBtnTranslation="content_block_detail.related_videos_thematic_focus_region.show_more"
      pageSectionId="related-content-by-thematic-focus-region"
    />
  );
};

export const StyledRelatedVideosThematicFocusRegion = RelatedVideosThematicFocusRegion;
