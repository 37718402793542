import { css } from '@linaria/core';
import { StyledSpinnerIcon as SpinnerIcon } from './icons';
import { colors } from '../utils/css';
import { extendWithClassNames } from '../utils/extendWithClassNames.jsx';

const spinnerAnimation = css`
@keyframes spinning {
  0% {
    stroke-dashoffset: 340;
  }
  50% {
    stroke-dashoffset: 170;
  }
  100% {
    stroke-dashoffset: 0;
  } 
}
.first-circle {
  stroke: ${colors.BLUE_GREY_03};
}
.second-circle {
  stroke: ${colors.DW_LIGHT_BLUE};
  animation: spinning 2000ms cubic-bezier(0.43,0,0.09,1) infinite normal forwards;
}`;

export const StyledSpinner = extendWithClassNames(SpinnerIcon)(spinnerAnimation);

export const StyledSpinnerPlaceholder = extendWithClassNames(SpinnerIcon)(
  spinnerAnimation,
  css`
    width: 100%;
    padding-block: 200px;
    box-sizing: content-box;
  `,
);
