import {
  darkHeadlineOpinionContentTeaserStyles,
  headlineOpinionContentTeaserFragment,
  StyledHeadlineOpinionContentTeaser as HeadlineOpinionContentTeaser,
} from '../../../ContentTeaserSnippets/ContentTeasers/HeadlineOpinionContentTeaser.jsx';

export const adapterForMoreFromColumnFragment = headlineOpinionContentTeaserFragment;

export const expandAdapterForMoreFromColumn = renderedContents => (
  renderedContents.map(renderedContent => (
    <HeadlineOpinionContentTeaser
      key={renderedContent.id}
      content={renderedContent}
      titleIsA="h3"
    />
  ))
);

export const darkExpandAdapterForMoreFromColumnStyles = darkHeadlineOpinionContentTeaserStyles;
