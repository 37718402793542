import gql from 'graphql-tag';

import { StyledVideo as Video, videoFragment } from '../../../components/Video';
import { makeMemoFragment } from '../../graphql';

export const replacerVideoFragment = makeMemoFragment({
  name: 'RichTextVideoPlaceholders',
  fragment() {
    return gql`fragment ${this.name} on AssociationsAspect {
        videos {
          id
          ...${videoFragment.name}
        }
    }
    ${videoFragment.fragment()}
    `;
  },
});

export const videoPlaceholderToVideoComponentFactory = content => matchedElem => {
  const videoId = matchedElem.dataset.id;
  const video = content.videos.find(v => v.id === +videoId);

  return video && (
    <Video content={video}/>
  );
};
