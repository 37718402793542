import gql from 'graphql-tag';
import { cx } from '@linaria/core';
import { makeMemoFragment } from '../../../utils/graphql';
import { StyledContentBlockList as ContentBlockList, darkContentBlockListStyles } from '../../ContentBlocks/Types/ContentBlockList.jsx';
import { StyledBigAvTextContentTeaser as BigAvTextContentTeaser, bigAvTextContentTeaserFragment, darkBigAvTextContentTeaserStyles } from '../ContentTeasers/BigAvTextContentTeaser.jsx';
import { StyledDefaultDarkBigTextLayout as DefaultDarkBigTextLayout, darkDefaultDarkBigTextLayoutStyles } from '../Layouts/DefaultDarkBigTextLayout';

export const listDefaultUnifiedBigAvTextFragment = makeMemoFragment({
  name: 'ListDefaultUnifiedBigAvText',
  fragment() {
    return gql`fragment ${this.name} on Content {
        ... on ModelAspect {
          id
        }
        ...${bigAvTextContentTeaserFragment.name}
      }
      ${bigAvTextContentTeaserFragment.fragment()}
    `;
  },
});

export const ListDefaultUnifiedBigAvText = ({ contents = [], hideKicker, ...restProps }) => (
  <ContentBlockList headlineIsA='h2' {...restProps}>
    <DefaultDarkBigTextLayout>
      {contents.map(content => (
        <BigAvTextContentTeaser
          key={content.id}
          content={content}
          titleIsA='h3'
          hideKicker={hideKicker}
        />
      ))}
    </DefaultDarkBigTextLayout>
  </ContentBlockList>
);

export const darkListDefaultUnifiedBigAvTextStyles = cx(
  darkContentBlockListStyles,
  darkBigAvTextContentTeaserStyles,
  darkDefaultDarkBigTextLayoutStyles,
);

export const StyledListDefaultUnifiedBigAvText = ListDefaultUnifiedBigAvText;
