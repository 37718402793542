import { cx } from '@linaria/core';
import { commonBlockSpacing } from './ContentBlocks/ContentBlock';

export const ContentSpacer = ({ className, children }) => (
  <div className={cx(className, commonBlockSpacing)}>
    {children}
  </div>
);

export const StyledContentSpacer = ContentSpacer;
