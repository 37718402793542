import { useReducer } from 'react';

const initialState = {
  isNavigationVisible: false,
  isLanguageToggled: false,
};

const actionTypes = {
  openNav: 'OPEN_NAV',
  closeNav: 'CLOSE_NAV',
  toggleLang: 'TOGGLE_LANG',
};

const reducer = (state, action) => {
  switch (action.type) {
    case actionTypes.openNav: {
      return {
        ...state,
        isNavigationVisible: true,
      };
    }
    case actionTypes.closeNav: {
      return {
        ...state,
        isNavigationVisible: false,
        isLanguageToggled: false,
      };
    }
    case actionTypes.toggleLang: {
      return {
        ...state,
        isLanguageToggled: !state.isLanguageToggled,
      };
    }
    default: {
      // eslint-disable-next-line fp/no-throw
      throw new Error('Unexpected action!');
    }
  }
};

export const useNavigationToggle = (init = initialState) => {
  const [state, dispatch] = useReducer(reducer, init);
  return [
    state,
    {
      openNav: () => dispatch({ type: actionTypes.openNav }),
      closeNav: () => dispatch({ type: actionTypes.closeNav }),
      toggleLang: () => dispatch({ type: actionTypes.toggleLang }),
    },
  ];
};
