import { styled } from '@linaria/react';
import gql from 'graphql-tag';

import { StyledFooterLink as FooterLink, footerLinkFragment } from './FooterLink.jsx';
import { StyledFooterSectionHeadline as FooterSectionHeadline } from './FooterSectionHeadline.jsx';
import { toKebabCase } from '../../utils/commons';
import { makeMemoFragment } from '../../utils/graphql';

export const footerSectionFragment = makeMemoFragment({
  name: 'FooterSection',
  fragment() {
    return gql`fragment ${this.name} on FooterSection {
      headline
      links {
        ...${footerLinkFragment.name}
      }
    }
    ${footerLinkFragment.fragment()}
  `;
  },
});

export const FooterSectionMainGroup = ({ className, section }) => {
  const sectionId = `footer-section-${toKebabCase(section.headline)}`;
  return (
    <div className={className} role="group" aria-labelledby={sectionId}>
      <FooterSectionHeadline id={sectionId}>
        {section.headline}
      </FooterSectionHeadline>
      <ul className="section-list">
        {section.links.map(link => (
          <FooterLink link={link} key={link.text} />
        ))}
      </ul>
    </div>
  );
};
// TODO linaria-next: css``
export const sharedFooterSectionStyles = `
  margin-bottom: 30px;

  .section-list {
    list-style: none;
    padding: 0;
  }
`;

export const StyledFooterSectionMainGroup = styled(FooterSectionMainGroup)`
  ${sharedFooterSectionStyles}
`;
