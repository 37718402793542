import gql from 'graphql-tag';
import {
  expandableListDefaultHighlightSmallImageTextFragment,
  StyledExpandableListDefaultHighlightSmallImageText as ExpandableListDefaultHighlightSmallImageText,
} from '../../../ContentTeaserSnippets/Templates/ExpandableListDefaultHighlightSmallImageText.jsx';
import { makeMemoFragment } from '../../../../utils/graphql';

export const storiesListFragment = expandableListDefaultHighlightSmallImageTextFragment;

export const storiesListConfigFragment = makeMemoFragment({
  name: 'StoriesListConfig',
  fragment() {
    return gql`fragment ${this.name} on CompositionComponent {
        configuration {
          numContentItems
        }
      }
    `;
  },
});

export const StoriesList = ({ configuration, ...props }) => (
  <ExpandableListDefaultHighlightSmallImageText {...props} initialListCount={configuration.numContentItems} />
);

export const StyledStoriesList = StoriesList;
