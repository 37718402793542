import { Helmet } from 'react-helmet-async';
import { useFrontendConfig } from '../FrontendConfigContext.jsx';

export const FacebookMetadata = () => {
  const {
    facebookMetaPageIds = [],
    facebookMetaAdminIds = [],
  } = useFrontendConfig();
  return (
    <Helmet>
      {facebookMetaAdminIds.map(id => <meta property="fb:admins" content={id} key={id} />)}
      {facebookMetaPageIds.map(id => <meta property="fb:pages" content={id} key={id} />)}
    </Helmet>
  );
};
