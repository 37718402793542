import { createContext, useContext } from 'react';

export const LeadingPageContentContext = createContext({
  leadingContent: null,
});

export const useLeadingPageContent = () => useContext(LeadingPageContentContext);

export const LeadingPageContentContextProvider = ({ children, content }) => (
  <LeadingPageContentContext.Provider value={{ leadingContent: content }}>
    {children}
  </LeadingPageContentContext.Provider>
);
