import { StyledExpandableDetailRichText as ExpandableDetailRichText, darkExpandableDetailRichTextStyles, expandableDetailRichTextFragment } from '../ExpandableDetailRichText.jsx';

export const personDetailRichTextFragment = expandableDetailRichTextFragment;

export const PersonDetailRichText = ({ person, isOpen: initialIsOpen = false, className }) => {
  if (!person.text) {
    return null;
  }
  return (
    <ExpandableDetailRichText
      className={className}
      content={person}
      translationKeyPrefix="component_detail.person_detail.fullVita"
      isOpen={initialIsOpen}
    />
  );
};

export const darkPersonDetailRichTextStyles = darkExpandableDetailRichTextStyles;

export const StyledPersonDetailRichText = PersonDetailRichText;
