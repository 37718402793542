import {
  createContext, useContext, useState,
} from 'react';
import { useInView } from 'react-intersection-observer';

import { INTERSECTION_OBSERVER_SETTINGS } from '../../LazyLoadDwPicture.jsx';
import { useWindowInnerWidthContext } from '../../WindowInnerWidthContext';

const AdContext = createContext([{ inView: false, showAdBox: false }, {}]);

export const useAdContext = () => useContext(AdContext);

// --- Context providers ---

export const AdContextProvider = ({ children, slotConfig }) => {
  const lowestResolutionConfigEntry = slotConfig[0];
  const fixedAd = lowestResolutionConfigEntry.adUnitCode.endsWith('_Top');

  const [showAdBox, setShowAdBox] = useState(fixedAd);
  const [currentSlotUnit, setCurrentSlotUnit] = useState();
  const [inViewRef, inView] = useInView(INTERSECTION_OBSERVER_SETTINGS);
  const innerWidth = useWindowInnerWidthContext();

  const contributeShowAdBox = isAtLeastOneSlotVisible => {
    setShowAdBox(prev => prev || isAtLeastOneSlotVisible);
  };

  if (lowestResolutionConfigEntry.minWidth > innerWidth && showAdBox) {
    setShowAdBox(() => false);
  }

  return (
    <AdContext.Provider value={[
      {
        inView, showAdBox, currentSlotUnit,
      },
      { contributeShowAdBox, setCurrentSlotUnit },
    ]}>
      <span ref={inViewRef}></span>
      {children}
    </AdContext.Provider>
  );
};
