import gql from 'graphql-tag';
import { cx } from '@linaria/core';
import { darkContentBlockStyles, StyledContentBlock as ContentBlock } from '../../ContentBlocks/ContentBlock';
import { StyledDefaultLayout as DefaultLayout } from '../Layouts/DefaultLayout';
import { darkHubStyles, StyledHub as Hub } from '../../ContentBlocks/Types/Hub.jsx';
import {
  StyledBigImageTextContentTeaser as BigImageTextContentTeaser,
  bigImageTextContentTeaserFragment,
  darkBigImageTextContentTeaserStyles,
} from '../ContentTeasers/BigImageTextContentTeaser.jsx';
import { makeMemoFragment } from '../../../utils/graphql';

export const hubDefaultUnifiedBigImageTextFragment = makeMemoFragment({
  name: 'HubDefaultUnifiedBigImageText',
  fragment() {
    return gql`fragment ${this.name} on Content {
        ... on ModelAspect {
          id
        }
        ...${bigImageTextContentTeaserFragment.name}
      }
      ${bigImageTextContentTeaserFragment.fragment()}
    `;
  },
});

export const HubDefaultUnifiedBigImageText = ({
  hubContents = [],
  hubHeadlineFn,
  hubBottomElementFn,
  ...restProps
}) => (
  <ContentBlock headlineIsA = 'h2' {...restProps}>
    <DefaultLayout>
      {hubContents.map(hubContent => (
        <Hub key={hubContent.content.id}
          headlineIsA = 'h3'
          headlineFn={hubHeadlineFn}
          hubContent={hubContent}
          bottomElementFn={hubBottomElementFn}
        >
          <BigImageTextContentTeaser content={hubContent.content} titleIsA='h4'/>
        </Hub>
      ))}
    </DefaultLayout>
  </ContentBlock>
);

export const darkHubDefaultUnifiedBigImageTextStyles = cx(
  darkContentBlockStyles,
  darkBigImageTextContentTeaserStyles,
  darkHubStyles,
);

export const StyledHubDefaultUnifiedBigImageText = HubDefaultUnifiedBigImageText;
