import React from 'react';
import { suspendedComponentFactory } from '../../SuspendedComponent/suspendedComponentFactory.jsx';
import { StyledSpinnerPlaceholder as Spinner } from '../../Spinner';

export const { TopStoryZone } = suspendedComponentFactory(
  () => import('./TopStoryZone.jsx'),
  {
    fallback: <Spinner/>,
  },
);
