import { styled } from '@linaria/react';
import { cx } from '@linaria/core';
import classnames from 'classnames';
import { contentBlockPadding, contentBlockWidth } from '../../ContentBlocks/ContentBlock';
import { StyledPlayIcon as PlayIcon } from '../../icons/slideShow/components/Play.jsx';
import { StyledPauseIcon as PauseIcon } from '../../icons/slideShow/components/Pause';
import { InformationDefault } from '../../Text/Information.jsx';
import { colors, mediaMin } from '../../../utils/css';
import { getQuantityTranslation } from '../../../utils/i18n';
import { usePageContext } from '../../PageEmbeddingContext.jsx';
import { useTranslation } from '../../hooks/useTranslation.jsx';
import { StyledA11yAriaLive as A11yAriaLive } from '../../a11y/A11yAriaLive.jsx';
import { ButtonBlueWithIconOnly } from '../../buttons/Button';
import { useLanguageContext } from '../../commons/WithGraphQLContentHandling/LanguageContext';

export const imageCountTranslationKey = 'component.slideshow.number_of_images';

export const STATUS = Object.freeze({
  play: 'play',
  pause: 'pause',
  init: 'init',
});

export const ControlBar = ({
  className, amountOfSlides, currentSlideIndex, onPlayPause, playingState,
}) => {
  const pageContext = usePageContext();
  const { isRtl } = useLanguageContext();
  const alignedSlideNumber = currentSlideIndex === 0 ? 1 : currentSlideIndex;
  const PlayPauseIcon = playingState === STATUS.play ? PauseIcon : PlayIcon;
  const isInInitState = playingState === STATUS.init;
  const buttonTitle = useTranslation(`component.slideshow.play_button.${playingState === STATUS.play ? 'pause' : 'play'}.label`);
  const ariaLiveTranslation = {
    key: 'component.slideshow.paging_live_region.label',
    parameters: {
      currentImage: currentSlideIndex,
      totalImages: amountOfSlides,
    },
  };
  const ariaLiveTranslationCover = getQuantityTranslation({
    key: 'component.slideshow.number_of_images',
    count: amountOfSlides,
    parameters: { count: amountOfSlides },
  });

  const slideInfoQuantityTranslation = useTranslation(getQuantityTranslation({
    key: imageCountTranslationKey,
    count: amountOfSlides,
    parameters: { count: amountOfSlides },
  }));

  return (
    <div className={className}>
      <div dir="ltr" className={classnames('control-bar-wrapper', { 'is-hero': !pageContext.isInRichText })}>
        <ButtonBlueWithIconOnly
          className={classnames(
            'play-pause-button',
            ButtonBlueWithIconOnly.darkStyles,
            {
              playing: playingState === STATUS.play,
              clicked: playingState !== STATUS.init,
            },
          )}
          onClick={onPlayPause}
          title={buttonTitle}
        >
          <PlayPauseIcon/>
        </ButtonBlueWithIconOnly>
        <A11yAriaLive translation={currentSlideIndex === 0 ? ariaLiveTranslationCover : ariaLiveTranslation} />
        <div
          dir={isRtl ? 'rtl' : 'ltr'}
          aria-hidden="true"
          className={cx('slide-info', InformationDefault.darkStyles)}
        >
          <InformationDefault className={classnames('slide-position', { init: isInInitState })}>
            {`${alignedSlideNumber} | ${amountOfSlides}`}
          </InformationDefault>
          <InformationDefault className="image-count">
            {slideInfoQuantityTranslation}
          </InformationDefault>
        </div>
      </div>
    </div>
  );
};

export const StyledControlBar = styled(ControlBar)`
  background-color: ${colors.BLACK};

  .control-bar-wrapper {
    display: flex;
    align-items: center;
    margin: auto;
    justify-content: flex-start;
  }
  *:where(.play-pause-button) {
    width: 50px;
    background: linear-gradient(to right,
            ${colors.DW_LIGHT_BLUE} 0%,
            ${colors.DW_LIGHT_BLUE} 50%,
            ${colors.BLACK} 50%,
            ${colors.BLACK} 100%);
    background-size: 200%;
    transition: background-position 800ms cubic-bezier(0.43, 0, 0.09,1);
    svg {
      width: 20px;
      stroke-width: .8px;
      fill: ${colors.WHITE};
    }

    &.playing,
    &.clicked {
      background-position: right;
      transition: background-position 800ms cubic-bezier(0.91, 0, 0.57, 1);

      svg {
        fill: none;
      }
    }
  }

  .slide-info {
    width: calc(100% - 100px);
    position: relative;
    font-size: 1.6rem;
    height: 50px;
    padding: 15px 0;
  }

  .slide-position,
  .image-count {
    position: absolute;
    transition: all 800ms cubic-bezier(0.43,0,0.09,1);
    left: 50%;
    transform: translateX(-50%);

    &.init {
      left: 15px;
      transform: translateX(0%);
    }
  }

    .slide-position {
      opacity: 1;

      &.init {
        opacity: 0;
    }
  }

  .image-count {
    opacity: 0;

    &.init {
      opacity: 1;
    }
  }

  ${mediaMin.md`
    .slide-info {
      font-size: 1.4rem;
    }
  `}

  ${mediaMin.lg`
    *:where(.play-pause-button) {
      width: 60px;
      svg {
        width: 24px;
      }
    }
    .slide-info {
      width: calc(100% - 120px);
      height: 60px;
      padding: 18px 0;
    }
    .control-bar-wrapper {
      max-width: ${contentBlockWidth.lg}px;
      margin: auto;
      &.is-hero {
        padding-inline: ${contentBlockPadding.lg}px;
      }
    }
  `}
  ${mediaMin.xl`
    .control-bar-wrapper {
      max-width: ${contentBlockWidth.xl}px;
    }
  `}
`;
