import { styled } from '@linaria/react';
import classNames from 'classnames';
import { colors, hexToRgba, mediaMin } from '../../css';
import { InformationDefault } from '../../../components/Text/Information.jsx';

export const InfoBar = ({ className, videoDuration }) => (
  <div className={classNames(className, 'info-bar')}>
    <div className="vjs-duration-text">
      <InformationDefault className={InformationDefault.darkStyles}>{videoDuration}</InformationDefault>
    </div>
  </div>
);

export const StyledInfoBar = styled(InfoBar)`
  position: absolute;
  height: 100px;
  bottom: 0;
  width: 100%;
  z-index: 1;
  display: flex;
  align-items: flex-end;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0), ${hexToRgba(colors.DARK_BLUE_GREY_01, 0.9)});

  .vjs-duration-text {
    position: relative;
    inset-inline-start: 65px;
    font-size: 1.6rem;
    height: 50px;
    display: flex;
    align-items: center;

    & > span {
      letter-spacing: 0.05rem;
    }
  }

  ${mediaMin.lg`
    .vjs-duration-text {
      height: 60px;
      inset-inline-start: 75px;
    }
  `}
`;
