import { css } from '@linaria/core';
import { mediaMin } from '../../../../utils/css';

// TODO linaria-next:
// - bottom alignment can nowadays be done with "margin-block-start: auto" in flex containers
const resolutionDependentStyles = `
  .teaser-wrap{
    display: flex;
    flex-direction: column;

    .teaser{
      flex-grow: 1;
      flex-direction: column;

      .teaser-data-wrap{
        flex-grow: 1;
        display: flex;
        flex-direction: column;

        .teaser-data {
          flex-grow: 1;
        }
      }
    }
  }
`;

export const extendTeaserCompositionWithBottomAlignmentLooksForXS = css`
  .col-12, .col-md-6, .col-lg-4{
    flex: initial;
  }
  ${mediaMin.xs`
    ${resolutionDependentStyles}
  `}
`;

export const extendTeaserCompositionWithBottomAlignmentLooks = css`
  .col-12, .col-md-6, .col-lg-4{
    flex: initial;
  }
  ${mediaMin.md`
    ${resolutionDependentStyles}
  `}
`;
