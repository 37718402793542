import { StyledScreenReaderOnly as ScreenReaderOnly } from '../../ContentDetailHeader/ScreenReaderOnly.jsx';
import { useTranslation } from '../../hooks/useTranslation.jsx';

const Heading = ({ name }) => (
  <span role="columnheader">{useTranslation(`content_block_detail.live_video_program_schedule.schedule_table_header.${name}`)}</span>
);

export const LivestreamScheduleHeader = ({ className }) => (
  <ScreenReaderOnly className={className}>
    <div role="rowgroup">
      <div role="row">
        <Heading name="time" />
        <Heading name="program" />
        <Heading name="description" />
      </div>
    </div>
  </ScreenReaderOnly>
);

export const StyledLivestreamScheduleHeader = LivestreamScheduleHeader;
