import { styled } from '@linaria/react';
import { css } from '@linaria/core';
import { colors } from '../../utils/css';

export const hideOnPrint = css`
@media print {
  display: none !important;
}`;

export const transparentOnPrint = css`
@media print {
  background-color: transparent !important;
}`;

export const darkChildTextColorOnPrint = css`
@media print {
  & * {
    color: ${colors.DW_DARK_BLUE} !important;
  }
}`;

export const GlobalPrintStyles = styled.div`
  :global() {
    @media print {
      /* because of a dependency cycle we could not import the Header */
      .page-header {
        position: relative !important;

        &::after {
          background-color: transparent !important;
        }

        svg {
          fill: ${colors.DW_DARK_BLUE} !important;
        }

        & > *:not(.logo) {
          display: none !important;
        }

        & + .spx {
          margin-top: 0 !important;
        }
      }

      .vjs-wrapper {
        display: none !important;
      }
    }
  }
`;
