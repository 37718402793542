import { styled } from '@linaria/react';
import { colors } from '../utils/css';

export const Dot = ({ className }) => (
  <span className={className}></span>
);

export const StyledDot = styled(Dot)`
  height: 10px;
  width: 10px;
  background-color: ${colors.WHITE};
  border-radius: 50%;
  display: inline-block;
`;
