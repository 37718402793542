import { styled } from '@linaria/react';
import { cx } from '@linaria/core';
import gql from 'graphql-tag';

import { makeMemoFragment } from '../../../utils/graphql';
import {
  darkExpandableFocusTopThreeUnifiedBigImageTextStyles,
  expandableFocusTopThreeUnifiedBigImageTextFragment,
  StyledExpandableFocusTopThreeUnifiedBigImageText as ExpandableFocusTopThreeUnifiedBigImageTextTemplate,
} from '../../ContentTeaserSnippets/Templates/ExpandableFocusTopThreeUnifiedBigImageText.jsx';
import { cssVariables as contentPageLayoutCssVariables } from '../../layouts/ContentPageLayout.jsx';
import { colors } from '../../../utils/css';

export const moreFromProgramFragment = makeMemoFragment({
  name: 'MoreFromProgram',
  fragment() {
    return gql`fragment ${this.name} on Content {
      ... on PlaybackResourceAspect {
        containsMultipleContributions
      }
      ... on AssociationsAspect {
        unifiedPrograms(amount: 1) {
          moreContentsFromUnifiedProgram(pageId: $id, amount: 15, types: [VIDEO, AUDIO], fullEpisodes: false) {
            ...${expandableFocusTopThreeUnifiedBigImageTextFragment.name}
          }
        }
      }
    }
    ${expandableFocusTopThreeUnifiedBigImageTextFragment.fragment()}
    `;
  },
});

export const MoreFromProgram = ({ content, className }) => {
  if (!content.containsMultipleContributions) {
    return null;
  }
  const { moreContentsFromUnifiedProgram } = content.unifiedPrograms[0] || {};

  return (
    <ExpandableFocusTopThreeUnifiedBigImageTextTemplate
      className={cx(className, darkExpandableFocusTopThreeUnifiedBigImageTextStyles)}
      contents={moreContentsFromUnifiedProgram}
      headlineTranslation="content_block_detail.more_from_this_program.headline"
      showMoreBtnTranslation="content_block_detail.more_from_this_program.show_more"
      pageSectionId='more-from-program'
    />
  );
};

export const StyledMoreFromProgram = styled(MoreFromProgram)`
  ${contentPageLayoutCssVariables.alternativeBgColor}: ${colors.DARK_BLUE_GREY_02};
  ${contentPageLayoutCssVariables.basicBgColor}: ${colors.DARK_BLUE_GREY_02};
`;
