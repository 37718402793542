import { styled } from '@linaria/react';
import { cx } from '@linaria/core';
import gql from 'graphql-tag';
import { TextDefault } from '../Text';
import { useTranslation, useTranslationWithComponent } from '../hooks/useTranslation.jsx';
import { StyledLearnGermanTranslatedLink as LearnGermanTranslatedLink } from './LearnGermanTranslatedLink.jsx';
import { colors, mediaMin } from '../../utils/css';
import { makeMemoFragment } from '../../utils/graphql';
import { StyledNavigationSpacer as NavigationSpacer } from './NavigationSpacer.jsx';
import { focusTargetClassName } from '../FlyingFocus/constants';

export const learnGermanFragment = makeMemoFragment({
  name: 'LearnGerman',
  fragment() {
    return gql`fragment ${this.name} on FooterAspect {
      footer {
        learngermanUrl
      }
    }`;
  },
});

export const LearnGerman = ({ className, learngermanUrl, ...restProps }) => {
  const learnGermanLink = useTranslationWithComponent({
    key: 'component.navigation.more_products.learngerman.item',
    parameter: 'link',
    componentFn: () => <LearnGermanTranslatedLink learngermanUrl={learngermanUrl} />,
  });

  return (
    <section
      className={cx(className, 'learn-german')}
      aria-label={useTranslation('component.navigation.more_products.section.label')}
      {...restProps}
    >
      <NavigationSpacer>
        {/* TODO linaria-next check if className 'text' is needed?! */}
        <TextDefault className={cx('text', TextDefault.darkStyles)}>{learnGermanLink}</TextDefault>
      </NavigationSpacer>
    </section>
  );
};

export const StyledLearnGerman = styled(LearnGerman)`
  padding-block: 20px;
  font-size: 1.6rem;
  background-color: ${colors.DW_DARK_BLUE};

  a {
    &:hover, &.${focusTargetClassName}{
      text-decoration: underline;
    }
  }


  ${mediaMin.md`
    padding-block: 25px;
  `}

  ${mediaMin.lg`
    padding-block: 30px;
  `}
`;
