import { cx } from '@linaria/core';
import gql from 'graphql-tag';
import {
  darkPartialStyles, partialFragments, Partials, StyledContentTeaser as ContentTeaser,
} from '../../../../ContentTeaserSnippets/ContentTeaserPartials';
import { makeMemoFragment } from '../../../../../utils/graphql';

export const internalContentFragment = makeMemoFragment({
  name: 'InternalContentLinks',
  fragment() {
    return gql`fragment ${this.name} on AssociationsAspect {
      contentLinks {
        targetId
        name
        description
        target {
          ... on ModelAspect {
            id
          }
          ... on UrlAspect {
            namedUrl
          }
          ... on NamedAspect {
            name
          }
          ... on TextualAspect {
            teaser
          }
          ...${partialFragments.teaserDataFragment.name}
          ...${partialFragments.contentStatusTeaserBadgeFragment.name}
          ...${partialFragments.imageWrapFragment.name}
          ...${partialFragments.linkedTitleFragment.name}
        }
      }
    }
    ${partialFragments.teaserDataFragment.fragment()}
    ${partialFragments.contentStatusTeaserBadgeFragment.fragment()}
    ${partialFragments.imageWrapFragment.fragment()}
    ${partialFragments.linkedTitleFragment.fragment()}
    `;
  },
});

export const InternalContentTeaser = ({
  link = {}, className, imageFormatConfig,
}) => (
  <ContentTeaser
    className={cx(className, 'row')}
    formatConfig={imageFormatConfig}
    content={link.target}
    link={link}
  >
    <Partials.LinkedImage>
      <div className="teaser-data-xs-sm">
        <Partials.ContentStatusTeaserBadge className='badge-xs-sm'/>
        <Partials.LinkedTitle isA='h3' className='title-xs-sm'/>
      </div>
    </Partials.LinkedImage>
    <Partials.MainContentArea>
      <Partials.Data>
        <Partials.ContentStatusTeaserBadge className='badge-above-md'/>
        <Partials.LinkedTitle isA='h3' className='title-above-md'/>
        <Partials.LinkedDescription className='d-none d-md-block' />
      </Partials.Data>
    </Partials.MainContentArea>
  </ContentTeaser>
);

export const darkInternalContentTeaserStyles = cx(
  darkPartialStyles.darkLinkedTitleStyles,
  darkPartialStyles.darkLinkedDescriptionStyles,
  darkPartialStyles.darkTeaserDataStyles,
);

export const StyledInternalContentTeaser = InternalContentTeaser;
