import { resolutions } from '../../utils/css';

export const alwaysLandscapePictureConfig = [
  {
    minWidth: resolutions.min.xs,
    maxWidth: resolutions.max.wide_xl,
    formatGroupName: '60X',
  },
];

export const alwaysCinemascopePictureConfig = [
  {
    minWidth: resolutions.min.xs,
    maxWidth: resolutions.max.wide_xl,
    formatGroupName: '100X',
  },
];

export const heroPictureConfig = [
  {
    minWidth: resolutions.min.xs,
    maxWidth: resolutions.max.xs,
    formatGroupName: '80X',
  },
  {
    minWidth: resolutions.min.sm,
    maxWidth: resolutions.max.lg,
    formatGroupName: '60X',
  },
  {
    minWidth: resolutions.min.xl,
    maxWidth: resolutions.max.wide_xl,
    formatGroupName: '100X',
  },
];

export const heroPictureInTextConfig = [
  {
    minWidth: resolutions.min.xs,
    maxWidth: resolutions.max.xs,
    formatGroupName: '80X',
  },
  {
    minWidth: resolutions.min.sm,
    maxWidth: resolutions.max.md,
    formatGroupName: '60X',
  },
  {
    minWidth: resolutions.min.lg,
    maxWidth: resolutions.max.wide_xl,
    formatGroupName: '100X',
  },
];

export const alwaysSquarePictureConfig = [
  {
    minWidth: resolutions.min.xs,
    maxWidth: resolutions.max.wide_xl,
    formatGroupName: '80X',
  },
];

export const squareConfigFactory = ({ squareTill = 'xs', landscapeFrom = 'sm' } = {}) => [
  {
    minWidth: resolutions.min.xs,
    maxWidth: resolutions.max[squareTill],
    formatGroupName: '80X',
  },
  {
    minWidth: resolutions.min[landscapeFrom],
    maxWidth: resolutions.max.wide_xl,
    formatGroupName: '60X',
  },
];
