/* eslint-disable jsx-a11y/media-has-caption */
import { styled } from '@linaria/react';
import gql from 'graphql-tag';

import { cx } from '@linaria/core';
import { onePixelTransparentImage } from '../../utils/imgUtils';
import { StyledPlaybackLazyPosterContainer as PlaybackLazyPosterContainer } from '../PlaybackLazyPosterContainer.jsx';
import { liveVideoOptions, videoOptions } from '../../utils/video/api';
import { makeMemoFragment } from '../../utils/graphql';
import { useTranslation } from '../hooks/useTranslation.jsx';
import { isVideo, isChannel, isLivestream } from '../../utils/contentUtils';
import { VideoJsSkeleton } from '../VideoJsSkeleton';
import { useVideoJsPlayer } from '../../utils/video/useVideoJsPlayer';
import { screenReaderOnly } from '../ContentDetailHeader/ScreenReaderOnly.jsx';
import { hideOnPrint } from '../GlobalPrintStyles';
import { mediaTrackingDataFragment } from '../Tracking/trackingFragments';

export const videoFragment = makeMemoFragment({
  name: 'Video',
  fragment() {
    return gql`fragment ${this.name} on Video {
      id
      posterImageUrl
      hlsVideoSrc
      formattedDuration
      subtitles {
        language
        srcLanguage
        subtitleUrl
      }
      ...${mediaTrackingDataFragment.name}
      }
      ${mediaTrackingDataFragment.fragment()}
    `;
  },
});

const getPlayBtnTranslation = content => {
  if (isVideo(content)) {
    return 'component.video_player.play_button.label';
  }
  return 'component.video_player.play_button.label_live_stream';
};

const initCustomPlugins = ({ player, content }) => {
  player.vhsQualitySelector();
  player.flyingFocus();
  player.setSourceForChromeAndroidPlugin(content);
};

export const Video = ({ content, className, isHero = false }) => {
  const {
    playerElemRef, onSkeletonPlayClick, showSpinner, showSkeleton,
  } = useVideoJsPlayer({
    content,
    initCustomPlugins,
    playerOptions: isChannel(content) || isLivestream(content) ? liveVideoOptions : videoOptions,
  });

  const playButtonTitle = useTranslation(getPlayBtnTranslation(content));

  return (
    <PlaybackLazyPosterContainer className={cx(hideOnPrint, className)}
      content={content}
      isHero={isHero}
      key={content.id}
      onClick={onSkeletonPlayClick}
    >
      <>
        <video id={`video-${content.id}`}
          ref={playerElemRef}
          dir="ltr"
          className="dw-player video-js vjs-fluid"
          controls
          playsInline
          preload="none"
          poster={onePixelTransparentImage}
          data-duration={content.formattedDuration}
          data-play-btn-title={playButtonTitle}
        >
          <source src={content.hlsVideoSrc} type="application/x-mpegURL"/>
          {content.subtitles?.map((sub, index) => (
            <track key={index} src={sub.subtitleUrl}
              srcLang={sub.srcLanguage}
              label={sub.language}
            />
          ))}
          <p className="vjs-no-js">To view this video please enable JavaScript, and consider upgrading to a web browser that <a href="https://videojs.com/html5-video-support/" rel="noopener noreferrer" target="_blank">supports HTML5 video</a></p>
        </video>
        <VideoJsSkeleton
          content={content}
          showSpinner={showSpinner}
          showSkeleton={showSkeleton}
          playButtonTitle={playButtonTitle}
          onPlayClick={onSkeletonPlayClick}
        />
      </>
    </PlaybackLazyPosterContainer>
  );
};

export const StyledVideo = styled(Video)`
  video:not(.vjs-tech) {
    ${screenReaderOnly}
  }
`;
