import { useEffect } from 'react';

import { loadScriptPromise } from '../staticResourceHelpers';
import globals from '../globals';
import { useMobileAppContext } from '../../components/hooks/mobileAppContext';
import { useLocationQueryParam } from '../../components/hooks/useLocationQueryParam';
import { useAdsAllowedByCmp } from './useAdsAllowedByCmp';

const enableAdsService = async () => {
  const { googletag } = globals.window;
  // eslint-disable-next-line no-underscore-dangle
  if (!googletag._loaded) {
    await loadScriptPromise({
      url: 'https://securepubads.g.doubleclick.net/tag/js/gpt.js',
      scriptId: 'gpt-script',
    });
  }
};

export const useGptEffect = () => {
  const { isMobileMode } = useMobileAppContext();
  const adsAllowed = useAdsAllowedByCmp();

  const adTargetNameParam = useLocationQueryParam('adTargetName');
  const adTargetValueParam = useLocationQueryParam('adTargetValue');

  if (!globals.window.googletag) {
    globals.window.googletag = { cmd: [] }; // eslint-disable-line fp/no-mutation
  }

  // to test it locally, add this to the url: ?adTargetName=cpg_debug&adTargetValue=on
  useEffect(() => {
    if (adTargetNameParam && adTargetValueParam) {
      const { googletag } = globals.window;
      googletag.cmd.unshift(() => { // eslint-disable-line fp/no-mutating-methods
        googletag.pubads().setTargeting(adTargetNameParam, [adTargetValueParam]);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const { googletag, document } = globals.window;
    googletag.cmd.unshift(() => { // eslint-disable-line fp/no-mutating-methods
      googletag.pubads().addEventListener('slotOnload', event => {
        const iframeElem = document.getElementById(event.slot.getSlotElementId()).querySelector('iframe');
        iframeElem.setAttribute('tabindex', -1);
      });
    });
  }, []);

  useEffect(() => {
    const { googletag } = globals.window;
    // IMPORTANT: In order to work correctly, commands need to be pushed as soon as possible (not when API promise resolves)
    // initialization commands need to be FIRST, this is why those are added in last useEffect but with unshift method.
    googletag.cmd.unshift(() => { // eslint-disable-line fp/no-mutating-methods
      googletag.pubads().disableInitialLoad();

      // HINT: Use options below for ads debugging
      // googletag.openConsole();
      googletag.enableServices();
    });
  }, []);

  // IMPORTANT: Effect with gpt js file download needs to be the last one!
  // Especially initialization commands should be already placed in cmd array at the start of that array
  // (other hooks can add other commands here as well)
  useEffect(() => {
    if (adsAllowed && !isMobileMode) {
      enableAdsService();
    }
  }, [adsAllowed, isMobileMode]);
};
