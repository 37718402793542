import { extendWithClassNames } from '../../../../utils/extendWithClassNames.jsx';

import {
  StyledTeaserComposition as TeaserComposition,
  extendWithDefaultTeaserComposition,
  extendDefaultTeaserCompositionWithStandardTeaserBasicLooks,
  extendTeaserCompositionWithBottomAlignmentLooks,
} from '../../TeaserComposition';

export const StyledDefaultLayout = extendWithClassNames(TeaserComposition)(
  extendTeaserCompositionWithBottomAlignmentLooks,
  extendWithDefaultTeaserComposition,
  extendDefaultTeaserCompositionWithStandardTeaserBasicLooks,
);
