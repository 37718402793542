import { createContext, useContext } from 'react';

export const langContextDefaults = Object.freeze({
  isRtl: false,
  isEuropeanLang: true,
  isAsianOrAfricanLang: false,
  isChineseLang: false,
});

export const LanguageContext = createContext(langContextDefaults);

export const useLanguageContext = () => useContext(LanguageContext);
