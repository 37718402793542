// This optional code is used to register a service worker.
// register() is not called by default.

// This lets the app load faster on subsequent visits in production, and gives
// it offline capabilities. However, it also means that developers (and users)
// will only see deployed updates on subsequent visits to a page, after all the
// existing tabs open on the page have been closed, since previously cached
// resources are updated in the background.

// To learn more about the benefits of this model and instructions on how to
// opt-in, read https://cra.link/PWA

const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' ||
    // 127.0.0.0/8 are considered localhost for IPv4.
    window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/),
);

export async function unregister() {
  if ('serviceWorker' in navigator) {
    // According to https://developer.mozilla.org/en-US/docs/Web/API/ServiceWorkerContainer
    // "ready" promise will never reject
    const registration = await navigator.serviceWorker.ready;
    return registration.unregister();
  }
  return null;
}

export function register() {
  if (import.meta.env.MODE === 'production' && 'serviceWorker' in navigator) {
    window.addEventListener('load', async () => {
      const swUrl = '/service-worker.js';

      if (isLocalhost) {
        // This is running on localhost. Let's check if a service worker still exists or not.
        checkValidServiceWorker(swUrl);

        // Add some additional logging to localhost, pointing developers to the
        // service worker/PWA documentation.
        await navigator.serviceWorker.ready;
        console.log('This web app is being served cache-first by a service worker.'); // To learn more, visit https://cra.link/PWA
      } else {
        // Is not localhost. Just register service worker
        registerValidSW(swUrl);
      }

      let isControlled = Boolean(navigator.serviceWorker.controller);// eslint-disable-line fp/no-let
      navigator.serviceWorker.addEventListener('controllerchange', () => {
        // whenever a worker takes control of the page we want to reload
        // if the page was already controlled by a previous worker
        if (isControlled) {
          console.log('Service worker has changed, to avoid issues we will reload the page for you');
          window.location.reload();
        } else {
          isControlled = true; // eslint-disable-line fp/no-mutation
        }
      });
    });
  }
}

function invokeServiceWorkerUpdateFlow(registration) {
  if (registration.waiting) {
    // let waiting Service Worker know it should become active
    registration.waiting.postMessage({ type: 'SKIP_WAITING' });
  }
}

async function registerValidSW(swUrl) {
  try {
    const registration = await navigator.serviceWorker.register(swUrl);

    // detect Service Worker update available and wait for it to become installed
    // eslint-disable-next-line fp/no-mutation, no-param-reassign
    registration.onupdatefound = () => {
      const installingWorker = registration.installing;
      if (installingWorker == null) {
        return;
      }
      // eslint-disable-next-line fp/no-mutation
      installingWorker.onstatechange = () => {
        if (installingWorker.state === 'installed') {
          if (navigator.serviceWorker.controller) {
            // At this point, the updated precached content has been fetched,
            // but the previous service worker will still serve the older
            // content until all client tabs are closed.
            invokeServiceWorkerUpdateFlow(registration);
            console.log('New application version is available'); // See https://cra.link/PWA
          } else {
            // At this point, everything has been precached.
            console.log('Content is cached for offline use.');
          }
        }
      };
    };
  } catch (error) {
    console.error('Error during service worker registration:', error);
  }
}

async function checkValidServiceWorker(swUrl) {
  // Check if the service worker can be found. If it can't reload the page.
  try {
    const response = await fetch(swUrl, {
      headers: { 'Service-Worker': 'script' },
    });
    // Ensure service worker exists, and that we really are getting a JS file.
    const contentType = response.headers.get('content-type');
    if (
      response.status === 404 ||
      (contentType != null && contentType.indexOf('javascript') === -1)
    ) {
      // No service worker found. Probably a different app. Reload the page.
      await unregister();
      window.location.reload();
    } else {
      // Service worker found. Proceed as normal.
      registerValidSW(swUrl);
    }
  } catch (e) {
    console.log('No internet connection found. App is running in offline mode.');
  }
}
