import globals from './globals';
import { observeMutations } from './mutationObserver';
import { isServer } from './ssr';

export const noop = () => null;

export const invert = inMap => Object.entries(inMap).reduce((acc, next) => ({ ...acc, [next[1]]: next[0] }), {});

export const not = predicate => args => !predicate(args);

export const pipe = (...fns) => fns.reduce((v, f) => (...args) => f(v(...args))); // eslint-disable-line fp/no-rest-parameters

export const toKebabCase = string => !!string && string.toLowerCase().replace(/[_\s]/g, '-');

export const camelCaseToKebabCase = str => str.replace(/[A-Z]+(?![a-z])|[A-Z]/g, ($, ofs) => (ofs ? '-' : '') + $.toLowerCase());

export const throttle = ({ callbackFn, timeout }) => {
  let wait = false; // eslint-disable-line fp/no-let
  return event => {
    if (!wait) {
      callbackFn.call(this, event);
      wait = true; // eslint-disable-line fp/no-mutation
      globals.window.setTimeout(() => { wait = false; }, timeout); // eslint-disable-line fp/no-mutation
    }
  };
};

export const scrollToTop = () => {
  if (globals.window.scrollY > 0) {
    globals.window.scrollTo(0, 0);
  }
};

export const scrollToElement = ({ element, offset = 0 }) => {
  const currentDistanceFromTop = element.getBoundingClientRect().top - offset;
  if (Math.abs(currentDistanceFromTop) > 50) {
    globals.window.scrollTo({
      top: globals.window.scrollY + currentDistanceFromTop,
    });
  }
};

export function waitForElementById({ id, rootElement = globals.window.document.getElementById('root'), timeoutDuration = 20000 } = {}) {
  return new Promise((resolve, reject) => {
    const element = globals.window.document.getElementById(id);
    if (element) {
      resolve(element);
      return;
    }

    // eslint-disable-next-line fp/no-let
    let timeoutHandle;
    const mutationCallback = (mutationEntry, observer) => {
      const elementAfterMutation = globals.window.document.getElementById(id);
      if (elementAfterMutation) {
        resolve(elementAfterMutation);
        observer.disconnect();
        clearTimeout(timeoutHandle);
      }
    };
    const observer = observeMutations({
      callback: mutationCallback,
      node: rootElement,
    });
    // eslint-disable-next-line fp/no-mutation
    timeoutHandle = globals.window.setTimeout(() => {
      observer.disconnect();
      reject();
    }, timeoutDuration);
  });
}

export const isSafari = () => !isServer() && globals.window.navigator.userAgent.toLowerCase().indexOf('safari/') > -1 &&
  globals.window.navigator.userAgent.toLowerCase().indexOf('chrome/') === -1; // chrome also has 'safari' in its agent

// TODO -linaria-next: css``
export const resetBoostrapAndBrowserDefaultLineHeight = 'line-height: 0;';
