const acceptedTypes = ['VIDEO', 'AUDIO', 'IMAGE_GALLERY', 'ARTICLE', 'EXTERNAL_LINK', 'DOWNLOAD', 'LIVEBLOG'];

export const isDownloadLink = link => link.targetType === 'DOWNLOAD';

export const isExternalLink = link => link.targetType === 'EXTERNAL_LINK';

export const hasAcceptedType = link => acceptedTypes.includes(link.targetType);

const linkNotInText = link => !link.isInText;

export const validTargetLinks = link => link.target !== undefined || link.targetType === 'EXTERNAL_LINK';

export const isNotExternalAndNotDownloadLink = link => !isExternalLink(link) && !isDownloadLink(link);

export const filterAndRearrange = (contentLinks = []) => {
  const validContentLinks = contentLinks.filter(hasAcceptedType).filter(validTargetLinks).filter(linkNotInText);
  const externalContentLinks = validContentLinks.filter(isExternalLink);
  const downloadContentLinks = validContentLinks.filter(isDownloadLink);
  const internalLinkableContentLinks = validContentLinks.filter(isNotExternalAndNotDownloadLink);

  return (
    [...internalLinkableContentLinks, ...externalContentLinks, ...downloadContentLinks]
  );
};
