import { lazy } from 'react';
import { SuspendedComponent } from '.';

export const suspendedComponentFactory = (importFn, options = {}) => {
  const {
    fallback,
  } = options;
  const namedExportHandler = {
    get(target, exportName) {
      const Component = lazy(() => importFn().then(module => ({ default: module[exportName] })));
      return props => <SuspendedComponent isA={Component} fallback={fallback} {...props} />;
    },
  };
  // eslint-disable-next-line fp/no-proxy
  return new Proxy({}, namedExportHandler);
};
