import { globalCssVariables } from '../components/Page/PageGlobalStyles';

export const getPositionOfEvent = e => (
  typeof e.changedTouches !== 'undefined'
    ? { x: e.changedTouches[0].clientX, y: e.changedTouches[0].clientY }
    : { x: e.pageX, y: e.pageY }
);

export const getXMouseMovement = (e, xStart) => (
  getPositionOfEvent(e).x - xStart
);

export const setTranslateX = ({ target, x }) => {
  // eslint-disable-next-line fp/no-mutating-assign
  Object.assign(target, {
    transform: `translate3d(${x}px, 0, 0)`,
    transition: 'none',
    willChange: 'transform',
  });
};

export const transition = 'all ease-in-out 400ms';

export const detachTranslateX = ({ target, originalX }) => {
  // eslint-disable-next-line fp/no-mutating-assign
  Object.assign(target, {
    transform: originalX,
    transition,
    willChange: null,
  });
};

export const getSliderPositionStyles = ({
  childrenLength, currentIndex, unit = '%',
}) => {
  if (childrenLength === 0) {
    return {};
  }
  return {
    width: `${childrenLength * 100}${unit}`,
    transform: `translate3d(calc(var(${globalCssVariables.flowDirection}) * -${(currentIndex * 100) / childrenLength}${unit}), 0, 0)`,
  };
};
