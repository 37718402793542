import { useEffect, useRef } from 'react';
import { styled } from '@linaria/react';
import { useInView } from 'react-intersection-observer';

import { useLoadTwitterApiEffect } from '../utils/twitter';
import globals from '../utils/globals';
import { INTERSECTION_OBSERVER_SETTINGS } from './LazyLoadDwPicture.jsx';
import { StyledCmpPreviewCompatiblePlaceholder as CmpPreviewCompatiblePlaceholder } from './CmpPreviewCompatiblePlaceholder.jsx';

const tweetOptions = {
  align: 'center',
};

const cmpPreviewClass = 'x-tweet';
// globals.window not defined during the transformation of the linaria/wyw-in-js plugin
if (globals.window) {
  // eslint-disable-next-line fp/no-mutation
  globals.window.cmp_preview_vendor_s34_class = `.${cmpPreviewClass}`;
}

export const TwitterTweet = ({ tweetId, className }) => {
  const tweetRef = useRef();
  const [inViewRef, inView] = useInView(INTERSECTION_OBSERVER_SETTINGS);
  const { twttr } = useLoadTwitterApiEffect({ inView });
  useEffect(() => {
    if (twttr && tweetRef.current && inView) {
      twttr.widgets.createTweet(
        tweetId,
        tweetRef.current,
        tweetOptions,
      );
    }
  }, [tweetId, twttr, inView]);
  return (
    <CmpPreviewCompatiblePlaceholder forwardRef={inViewRef} className={className}>
      <blockquote ref={tweetRef}
        className={cmpPreviewClass}
        data-id={tweetId}
      />
    </CmpPreviewCompatiblePlaceholder>
  );
};

export const StyledTwitterTweet = styled(TwitterTweet)`
`;
