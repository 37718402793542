import gql from 'graphql-tag';

import {
  useParams, useLocation, Redirect,
} from '../dwouter';
import { WithGraphQLHandling } from './commons/WithGraphQLHandling.jsx';
import { useSilentRedirectContext } from './hooks/silentRedirectContext.jsx';
import { useGqlFetch } from './hooks/useGqlFetch';

export const navigationQuery = () => (
  gql`query getDefaultNavigationPath($lang: Language!) {
    topStoriesNavigation(lang: $lang, displayChannel: DESKTOP) {
      namedUrl
      isLive
      subdomainUrl
    }
  }`
);

export const StartPageRedirect = ({ forcedLangCode }) => {
  const { langCode = 'en' } = useParams();
  const [{ search }] = useLocation();
  const { setIsSilent } = useSilentRedirectContext();

  const overwrittenLangCode = forcedLangCode || langCode;
  const gqlMeta = useGqlFetch({ path: `${overwrittenLangCode}/default-nav`, depts: [overwrittenLangCode] });

  if (forcedLangCode) {
    setIsSilent(true);
  }

  return (
    <WithGraphQLHandling gqlMeta={gqlMeta}>
      {({ topStoriesNavigation }) => {
        // ROAD live or beta - usual path, remember it needs to remain SSR compatible
        setIsSilent(false);
        return (
          <Redirect to={{
            pathname: topStoriesNavigation.namedUrl,
            search,
          }}/>
        );
      }}
    </WithGraphQLHandling>
  );
};
