import { styled } from '@linaria/react';

import { css, cx } from '@linaria/core';
import { darkCopyrightInfoStyles, StyledItalicCopyrightInfo as ItalicCopyrightInfo } from './CopyrightInfo.jsx';
import { TextImageCaption } from './Text';
import { colors, mediaMin } from '../utils/css';

export const ImageBasicCaption = ({
  caption,
  licenserSupplement,
  className,
}) => (
  <TextImageCaption isA="figcaption" className={className}>
    {caption}
    <ItalicCopyrightInfo className="copyright" licenserSupplement={licenserSupplement}/>
  </TextImageCaption>
);

export const cssVariables = {
  background: '--image-basic-caption-background',
};

export const darkImageBasicCaptionStyles = cx(
  TextImageCaption.darkStyles,
  darkCopyrightInfoStyles,
  css`${cssVariables.background}: ${colors.DARK_BLUE_GREY_02};`,
);

// TODO linaria-next css`` ??
export const captionBasicStyles = `
  display: flow-root;
  width: 100%;
  padding: 10px 15px;
  font-size: 1.2rem;
  background-color: var(${cssVariables.background}, ${colors.BLUE_GREY_01});
  .copyright {
    font-size: 1.1rem;
    float: inline-end;
    margin-inline-start: 15px;
  }

  ${mediaMin.md`
    font-size: 1.3rem;
  `}
  ${mediaMin.lg`
    font-size: 1.5rem;
  `}
`;

export const StyledImageBasicCaption = styled(ImageBasicCaption)`
  ${captionBasicStyles}
`;
