import gql from 'graphql-tag';
import { Helmet } from 'react-helmet-async';
import { makeMemoFragment } from '../utils/graphql';

export const audioOpenGraphFragment = makeMemoFragment({
  name: 'AudioOpenGraphMetadata',
  fragment() {
    return gql`fragment ${this.name} on OpenGraphAspect {
      openGraphMetadata {
        url
      }
    }
    `;
  },
});

export const AudioOpenGraphMetadata = ({ content }) => {
  if (!content.openGraphMetadata) {
    return null;
  }
  const {
    url,
  } = content.openGraphMetadata;

  return (
    <Helmet>
      <meta property="og:audio:type" content="audio/mpeg" />
      <meta property="og:audio" content={url} />
      <meta property="og:audio:secure_url" content={url} />
    </Helmet>

  );
};
