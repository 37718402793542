import { getPathnameFromUrlPath } from '../utils/url';

// creates a matcher function
export function makeMatcher() {
  const regexpCache = {};

  // obtains a cached regexp version of the pattern
  const getRegexp = routeRegexpStr => {
    if (!regexpCache[routeRegexpStr]) {
      const regexp = new RegExp(`^${routeRegexpStr}$`);
      // eslint-disable-next-line fp/no-mutation
      regexpCache[routeRegexpStr] = { regexp };
    }
    return regexpCache[routeRegexpStr];
  };

  // eslint-disable-next-line default-param-last
  return (routeRegexpStr = '(.*)', location) => {
    const pathname = (typeof location === 'object') ? location.pathname : getPathnameFromUrlPath(location);
    const { regexp } = getRegexp(routeRegexpStr);

    const matches = regexp.exec(pathname);
    return matches
      ? [true, matches.groups || {}]
      : [false, {}];
  };
}
