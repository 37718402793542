import gql from 'graphql-tag';
import { styled } from '@linaria/react';
import { css } from '@linaria/core';
import { useState, useEffect } from 'react';

import { useLocation } from '../../dwouter';
import { makeMemoFragment } from '../../utils/graphql';
import { liveblogPostFragment, StyledLiveblogPost as LiveblogPost } from '../LiveblogPost';
import { StyledExpandable as Expandable } from '../Expandable';
import { colors } from '../../utils/css';

export const POSTS_PER_VIEW_AMOUNT = 15;

export const expandableLiveblogPostListFragment = makeMemoFragment({
  name: 'ExpandableLiveblogPostList',
  fragment() {
    return gql`fragment ${this.name} on Liveblog {
        posts {
          id
          ...${liveblogPostFragment.name}
        }
      }
      ${liveblogPostFragment.fragment()}
    `;
  },
});

const useLiveblogPostIdFromLocationHash = () => {
  const [{ hash }] = useLocation();
  const elementId = hash.replace('#', '');
  // eslint-disable-next-line fp/no-mutating-methods
  const contentId = elementId.split('-').reverse()[0];
  return +contentId;
};

const useGetInitialListCount = posts => {
  const [initialListCount, setInitialListCount] = useState(POSTS_PER_VIEW_AMOUNT);
  const id = useLiveblogPostIdFromLocationHash();

  useEffect(() => {
    const postIndex = posts.findIndex(post => post.id === id);
    if (postIndex > POSTS_PER_VIEW_AMOUNT) {
      setInitialListCount(postIndex + 1);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return initialListCount;
};

export const ExpandableLiveblogPostList = ({ content = {}, className }) => {
  const initialListCount = useGetInitialListCount(content.posts);

  return (
    <Expandable
      dataArray={content.posts}
      className={className}
      initialListCount={initialListCount}
      stepMoreAmount={POSTS_PER_VIEW_AMOUNT}
      showMoreBtnTranslation="component_detail.liveblog_detail.show_more_posts"
      focusContainerClass="liveblog-post"
    >
      {renderedContents => (
        renderedContents.map(renderedContent => (
          <LiveblogPost className="liveblog-post" key={renderedContent.id} content={renderedContent} />
        ))
      )}
    </Expandable>
  );
};

export const cssVariables = {
  borderColor: '--liveblog-post-bottom-border-color',
};

export const darkExpandableLiveblogPostListStyles = css`
  ${cssVariables.borderColor}: ${colors.BLUE_GREY_04};
`;

export const StyledExpandableLiveblogPostList = styled(ExpandableLiveblogPostList)`
  .liveblog-post {
    &:not(:last-of-type)::after {
      content: "";
      width: 60%;
      height: 1px;
      background-color: var(${cssVariables.borderColor}, ${colors.BLUE_GREY_03});
      display: block;
      margin: 50px auto;
    }
}
`;
