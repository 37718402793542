import { css, cx } from '@linaria/core';
import { StyledButtonBar as ButtonBar } from './buttons/Button/ButtonBar.jsx';
import { ButtonSecondary } from './buttons/Button';
import { LinkBase } from './Links/LinkBase';
import { StyledLeftChevron as LeftChevron, StyledRightChevron as RightChevron } from './icons/index';
import { InformationItalic } from './Text/Information.jsx';
import { useTranslations } from './hooks/useTranslation.jsx';
import { mediaMax } from '../utils/css';
import { commonBlockSpacing } from './ContentBlocks/ContentBlock';

const defaultTranslationKey = 'component.pagination';

export const Pagination = ({
  className, currentPageNumber, totalPages,
  disablePrevButton, disableNextButton, // TODO: when search pagination works on urls, disable logic can be moved to Pagination
  toPrev, onClickPrev, toNext, onClickNext,
}) => {
  const translationKeys = [
    {
      key: `${defaultTranslationKey}.paging.label`,
      parameters: { currentPage: currentPageNumber, totalPages },
    },
    `${defaultTranslationKey}.label_aria`,
    `${defaultTranslationKey}.previous_button.label`,
    `${defaultTranslationKey}.next_button.label`,
    `${defaultTranslationKey}.previous_button.label_aria`,
    `${defaultTranslationKey}.next_button.label_aria`,
  ];
  const [
    pagingLabel,
    navAriaLabel,
    prevBtnText,
    nextBtnText,
    prevBtnAria,
    nextBtnAria,
  ] = useTranslations(translationKeys);

  return (
    <nav aria-label={navAriaLabel} className={cx(className, paginationClass, commonBlockSpacing)}>
      <ButtonBar align="space-between">
        <ButtonSecondary
          isA={disablePrevButton ? undefined : LinkBase}
          role="link"
          rel="prev"
          aria-label={prevBtnAria}
          to={toPrev}
          onClick={onClickPrev}
          className="prev"
          disabled={disablePrevButton}
        >
          <LeftChevron className="chevron-icon"/>
          {prevBtnText}
        </ButtonSecondary>
        <InformationItalic>{pagingLabel}</InformationItalic>
        <ButtonSecondary
          isA={disableNextButton ? undefined : LinkBase}
          role="link"
          rel="next"
          aria-label={nextBtnAria}
          to={toNext}
          onClick={onClickNext}
          className="next"
          disabled={disableNextButton}
        >
          {nextBtnText}
          <RightChevron className="chevron-icon" />
        </ButtonSecondary>
      </ButtonBar>
    </nav>
  );
};

const darkPaginationStyles = cx(
  ButtonSecondary.darkStyles,
  InformationItalic.darkStyles,
);

Pagination.darkStyles = darkPaginationStyles;

export const paginationClass = css`
  padding-block-end: 30px;

  span {
    align-self: center;
    font-size: 1.4rem;
  }

  .chevron-icon {
    width: 18px;
  }

  ${mediaMax.xs`
    span {
      text-align: center;
    }

    .prev {
      order: 1;
    }

    .next {
      order: 2;
    }
  `}
`;
