import { cx } from '@linaria/core';
import {
  darkExpandableFocusTopThreeUnifiedBigImageTextStyles,
  expandableFocusTopThreeUnifiedBigImageTextFragment,
  StyledExpandableFocusTopThreeUnifiedBigImageText as ExpandableFocusTopThreeUnifiedBigImageTextTemplate,
} from '../../ContentTeaserSnippets/Templates/ExpandableFocusTopThreeUnifiedBigImageText.jsx';

export const moreEpisodesFragment = expandableFocusTopThreeUnifiedBigImageTextFragment;

export const MoreEpisodes = ({ contents, className }) => (
  <ExpandableFocusTopThreeUnifiedBigImageTextTemplate
    className={cx(className, darkExpandableFocusTopThreeUnifiedBigImageTextStyles)}
    contents={contents}
    headlineTranslation="content_block_detail.more_episodes.headline"
    showMoreBtnTranslation="content_block_detail.more_episodes.show_more"
    pageSectionId='more-episodes'
  />
);

export const StyledMoreEpisodes = MoreEpisodes;
