export const MINUTE_IN_MILLIS = 60 * 1000;
export const HOUR_IN_MILLIS = 60 * MINUTE_IN_MILLIS;
export const DAY_IN_MILLIS = 24 * HOUR_IN_MILLIS;

export function getRelativeTimeVariables(contentDateString) {
  const now = Date.now();
  const contentDate = new Date(contentDateString);
  const elapsedTime = now - contentDate.getTime();

  if (elapsedTime < 0) {
    return null;
  }
  if (elapsedTime < HOUR_IN_MILLIS) {
    return {
      minutes: Math.round(elapsedTime / MINUTE_IN_MILLIS),
    };
  }
  if (elapsedTime < DAY_IN_MILLIS) {
    return {
      hours: Math.round(elapsedTime / HOUR_IN_MILLIS),
    };
  }
  return null;
}

export const isDateInRange = ({
  dateStr, startDateStr, endDateStr,
}) => (
  startDateStr <= dateStr && dateStr < endDateStr
);
