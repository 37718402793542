import gql from 'graphql-tag';
import { Helmet } from 'react-helmet-async';

import { useParams } from '../dwouter';

import { StyledHeader as Header, headerFragmentFactory } from '../components/Header';
import { StyledNotFoundMessage as NotFoundMessage, darkNotFoundMessageStyles } from '../components/NotFoundMessage.jsx';
import { StyledFooter as Footer, footerFragmentFactory } from '../components/Footer';
import { StyledQuickNavigationList as QuickNavigationList } from '../components/a11y/QuickNavigationList.jsx';
import { BrowserOnlyBreakingBanner } from '../components/BreakingBanner';
import { HttpStatus } from '../components/HttpStatus.jsx';
import { noRealContentLangBaseFn, PageWithData, pageWithDataFragment } from './PageWithData.jsx';

import { withTheme } from '../components/higherOrderComponents/withTheme.jsx';

export const notFoundQuery = () => {
  const headerFragment = headerFragmentFactory({ isContent: false });
  const footerFragment = footerFragmentFactory({ isContent: false });
  return gql`
  query getNotFoundPageData($lang: Language!) {
    topStoriesNavigation(lang: $lang){
      id
      ...${pageWithDataFragment.name}
    }
    ...${headerFragment.name}
    ...${footerFragment.name}
  }
  ${pageWithDataFragment.fragment()}
  ${headerFragment.fragment()}
  ${footerFragment.fragment()}
`;
};

export const NotFoundPageNoQuery = ({
  className, topStoriesNavigation, topStoriesNavigations, footer,
}) => (
  <>
    <QuickNavigationList content={{ topStoriesNavigation }}/>
    <div className={className}>
      <Header content={{ topStoriesNavigation, topStoriesNavigations }}/>
      <BrowserOnlyBreakingBanner />
      <NotFoundMessage topStoriesNavigation={topStoriesNavigation}/>
      <Footer content={{ footer }}/>
    </div>
  </>
);

export const NotFoundPageNoQueryWithTheme = withTheme(NotFoundPageNoQuery)(darkNotFoundMessageStyles);

export const NotFoundPage = () => {
  const { langCode = 'en' } = useParams();
  const queryDef = {
    path: `${langCode}/not-found`,
    depts: [langCode],
  };

  return (
    <HttpStatus code={404}>
      <PageWithData queryDef={queryDef} langBaseContentFn={noRealContentLangBaseFn}>
        {data => (
          <>
            <Helmet>
              <meta property="og:site_name" content="dw.com" />
            </Helmet>
            <NotFoundPageNoQueryWithTheme {...data}/>
          </>
        )}
      </PageWithData>
    </HttpStatus>
  );
};
