import { isDateInRange } from '../components/commons/DateAnalyser';
import { isServer } from './ssr';

const VISIBLE_TIME_SLOT_LIMIT = 3;

// @VisibleForTesting
export const getTimeSlotsToDisplay = ({ livestreamChannel, currentDate }) => {
  const { nextTimeSlots } = livestreamChannel;

  const currentTimeSlotIndex = nextTimeSlots.findIndex(
    timeSlot => isDateInRange({
      dateStr: currentDate.toISOString(),
      startDateStr: timeSlot.startDate,
      endDateStr: timeSlot.endDate,
    }),
  );
  const lastIndexOrArrayEnd = Math.min(currentTimeSlotIndex + VISIBLE_TIME_SLOT_LIMIT, nextTimeSlots.length); // better safe than sorry
  return nextTimeSlots.slice(currentTimeSlotIndex, lastIndexOrArrayEnd);
};

// @VisibleForTesting
export const findMatchingNowCandidate = ({ currentDate, today }) => {
  const currentDateIso = isServer()
    ? currentDate.toISOString()
    : currentDate.toLocaleString('sv').replace(' ', 'T'); // at least one country is close to ISO :D totally hacky but short
  return today.find(candidate => candidate.iso === currentDateIso.substring(0, currentDateIso.indexOf('T')));
};

export const livestreamScheduleStateCalcFactory = ({ livestreamChannel, today }) => ({ currentDate = new Date(), freshData } = {}) => {
  const freshLivestreamChannel = freshData?.livestreamChannel ?? livestreamChannel;
  const freshToday = freshData?.today ?? today;

  const displayedTimeSlots = getTimeSlotsToDisplay({ livestreamChannel: freshLivestreamChannel, currentDate });
  const currentDay = findMatchingNowCandidate({ currentDate, today: freshToday });
  return { currentDate, displayedTimeSlots, currentDay };
};
