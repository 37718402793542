import gql from 'graphql-tag';
import {
  expandableFocusTopThreeUnifiedBigImageTextFragment,
  StyledExpandableFocusTopThreeUnifiedBigImageText as ExpandableFocusTopThreeUnifiedBigImageTextTemplate,
} from '../../ContentTeaserSnippets/Templates/ExpandableFocusTopThreeUnifiedBigImageText.jsx';
import { makeMemoFragment } from '../../../utils/graphql';

export const relatedVideosThematicFocusGlobal = makeMemoFragment({
  name: 'RelatedVideosThematicFocusGlobal',
  fragment() {
    return gql`fragment ${this.name} on Video {
      moreAvContentsByThematicFocusAndGlobal {
        ...${expandableFocusTopThreeUnifiedBigImageTextFragment.name}
      }
      ... on AssociationsAspect {
        thematicFocusCategory {
          name
        }
      }
    }
    ${expandableFocusTopThreeUnifiedBigImageTextFragment.fragment()}
  `;
  },
});

export const RelatedVideosThematicFocusGlobal = ({ content, className }) => {
  const { moreAvContentsByThematicFocusAndGlobal } = content;

  const thematicFocusName = content.thematicFocusCategory?.name;

  if (!thematicFocusName) {
    return null;
  }

  const headlineTranslation = {
    key: 'content_block_detail.related_videos_thematic_focus_global.headline',
    parameters: { firstThematicFocus: thematicFocusName },
  };

  return (
    <ExpandableFocusTopThreeUnifiedBigImageTextTemplate
      className={className}
      contents={moreAvContentsByThematicFocusAndGlobal}
      headlineTranslation={headlineTranslation}
      showMoreBtnTranslation="content_block_detail.related_videos_thematic_focus_global.show_more"
      pageSectionId="related-content-by-thematic-focus-global"
    />
  );
};

export const StyledRelatedVideosThematicFocusGlobal = RelatedVideosThematicFocusGlobal;
