import { Link as RouterLink } from '../../../dwouter';
import { useLocation } from '../../../dwouter/use-location';
import { getLocationAsObj } from '../../../dwouter/locationToUrlConverter';
import { useMobileAppContext } from '../../hooks/mobileAppContext';
import { addReferrerToHistoryState } from '../../hooks/usePatchRogueReferrer';

export const InternalLink = ({
  to, ...restProps
}) => {
  const locationWithReferrer = addReferrerToHistoryState(getLocationAsObj(to));
  const { isMobileMode } = useMobileAppContext();
  const [fromLocation] = useLocation();
  const isSelfLink = fromLocation.pathname === locationWithReferrer.pathname;
  return (
    <RouterLink
      to={locationWithReferrer}
      isMobileMode={isMobileMode}
      replace={isSelfLink}
      scrollTop
      {...restProps}
    />
  );
};
