import { useEffect, useRef } from 'react';
import { useLocation } from '../../dwouter';

const runLazyGreekAccentedReplacer = async () => {
  const { runGreekAccentedReplacer } = await import('./runGreekAccentedReplacer');
  runGreekAccentedReplacer();
};

const isGreekUrl = pathname => /^\/el\//.test(pathname);

export const GreekAccentedReplacer = () => {
  const alreadyRun = useRef();
  const [{ pathname }] = useLocation();
  useEffect(() => {
    if (!alreadyRun.current && isGreekUrl(pathname)) {
      runLazyGreekAccentedReplacer();
      // eslint-disable-next-line fp/no-mutation
      alreadyRun.current = true;
    }
  }, [pathname]);
  return null;
};
