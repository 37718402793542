import { styled } from '@linaria/react';
import { StyledPlayIcon as PlayIcon } from '../../../components/icons/slideShow/components/Play.jsx';
import { mediaMin } from '../../css';
import { ButtonBlueWithIconOnly } from '../../../components/buttons/Button';

export const VideoPlayButton = ({
  className, buttonTitle, ...restProps
}) => (
  <ButtonBlueWithIconOnly
    className={className}
    title={buttonTitle}
    {...restProps}
  >
    <PlayIcon/>
  </ButtonBlueWithIconOnly>
);

export const StyledVideoPlayButton = styled(VideoPlayButton)`
  position: absolute;
  bottom: 0;
  z-index: 2;
  width: 50px;
  svg {
    width: 20px;
    height: 20px;
  }

  ${mediaMin.lg`
    width: 60px;
    svg {
      width: 24px;
      height: 24px;
    }
  `}
`;
