import { makeUrl } from './locationToUrlConverter';
import { useLocation } from './use-location';
import { makeMatcher } from './matcher';

import {
  useContext,
  createContext,
  isValidElement,
  cloneElement,
  createElement,
  Fragment,
  forwardRef,
  useIsomorphicLayoutEffect,
  useEvent,
} from './preact-deps';

export { useLocation } from './use-location';

/*
 * Router and router context. Router is a lightweight object that represents the current
 * routing options: how location is managed, base path etc.
 *
 * There is a default router present for most of the use cases, however it can be overridden
 * via the <Router /> component.
 */

const defaultRouter = {
  matcher: makeMatcher(),
  staticContext: {},
};

const RouterCtx = createContext(defaultRouter);

export const useRouter = () => useContext(RouterCtx);

/*
 * Part 1, Hooks API: useRoute, useLocation and useParams
 */

export const useRoute = route => {
  const router = useRouter();
  const [location] = useLocation();
  return router.matcher(route, location);
};

const ParamsCtx = createContext({ params: {} });
export const useParams = () => useContext(ParamsCtx).params;

// used to get correct params for immediate changing location
export const useImmediateParams = ({ currentPathRegexp }) => {
  const [routeMatch, params] = useRoute(currentPathRegexp);
  return routeMatch ? params : {};
};

/*
 * Part 2, Low Carb Router API: Router, Route, Link, Switch
 */

export const Router = ({
  matcher,
  staticContext,
  children,
}) => (
  <RouterCtx.Provider value={{
    matcher: matcher || defaultRouter.matcher,
    staticContext: staticContext || defaultRouter.staticContext,
  }}>
    {children}
  </RouterCtx.Provider>
);

// Helper to wrap children component inside the ParamsCtx provider
const ParamsWrapper = ({ params, children }) => (
  <ParamsCtx.Provider value={{ params }}>
    {children}
  </ParamsCtx.Provider>
);

export const Route = ({
  path, match, component, children, isLocationOverride,
}) => {
  const useRouteMatch = useRoute(path);
  if (!useRouteMatch[0] && !isLocationOverride) return null;

  // `props.match` is present - Route is controlled by the Switch // TODO: do we need that?
  const [matches, params] = match || useRouteMatch;

  if (!matches) return null;

  // React-Router style `component` prop
  if (component) {
    return (
      <ParamsWrapper params={params}>
        {createElement(component, { params })}
      </ParamsWrapper>
    );
  }
  // support render prop or plain children
  return (
    <ParamsWrapper params={params}>
      {typeof children === 'function' ? children(params) : children}
    </ParamsWrapper>
  );
};

export const Link = forwardRef(({
  replace, isMobileMode, scrollTop, ...props
}, ref) => {
  const [, navigate] = useLocation();

  const {
    to, onClick, ...restLinkProps
  } = props;

  const href = (typeof to === 'object') ? `${to.pathname}${to.search}` : to;

  const handleClick = useEvent(event => {
    // ignores the navigation when clicked using right mouse button or
    // by holding a special modifier key: ctrl, command, win, alt, shift
    if (
      event.ctrlKey ||
      event.metaKey ||
      event.altKey ||
      event.shiftKey ||
      event.button !== 0
    ) { return; }

    onClick && onClick(event);
    if (!event.defaultPrevented) {
      event.preventDefault();
      navigate(to, { replace, isMobileMode, scrollTop });
    }
  });

  return (
    // eslint-disable-next-line jsx-a11y/anchor-has-content
    <a href={href} onClick={handleClick} ref={ref} {...restLinkProps}/>
  );
});

const flattenChildren = children => (Array.isArray(children)
  ? [].concat(
    ...children.map(c => (c && c.type === Fragment
      ? flattenChildren(c.props.children)
      : flattenChildren(c))),
  )
  : [children]);

export const Switch = ({ children, location }) => {
  const { matcher } = useRouter();
  const [originalLocation] = useLocation();

  // eslint-disable-next-line fp/no-loops
  for (const element of flattenChildren(children)) {
    // eslint-disable-next-line fp/no-let
    let match = 0;

    if (
      isValidElement(element) &&
      // we don't require an element to be of type Route,
      // but we do require it to contain a truthy `path` prop.
      // this allows to use different components that wrap Route
      // inside of a switch, for example <AnimatedRoute />.
      // eslint-disable-next-line fp/no-mutation
      (match = element.props.path
        ? matcher(element.props.path, location || originalLocation)
        : [true, {}])[0]
    ) {
      const isLocationOverride = !!location;
      return cloneElement(element, { match, isLocationOverride });
    }
  }

  return null;
};

export const Redirect = props => {
  const { to } = props;
  const [, navigate] = useLocation();
  const redirect = useEvent(() => navigate(to, props));
  const router = useRouter();
  // eslint-disable-next-line fp/no-mutation
  router.staticContext.httpStatus = 301;
  // eslint-disable-next-line fp/no-mutation
  router.staticContext.url = makeUrl(to, props);

  // redirect is guaranteed to be stable since it is returned from useEvent
  useIsomorphicLayoutEffect(() => {
    redirect();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return null;
};
