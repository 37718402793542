import gql from 'graphql-tag';

import { InView } from 'react-intersection-observer';
import { cx } from '@linaria/core';
import { PageHeadline } from '../../PageHeadline.jsx';
import { StyledA11yLinkTargetMarker as A11yLinkTargetMarker } from '../../a11y/A11yLinkTargetMarker.jsx';
import { makeMemoFragment } from '../../../utils/graphql';
import { ids } from '../../../utils/staticElementIds/index';
import { TopicHeader, topicHeaderFragment } from '../../ContentBlocks/TopicHeader';
import { AtpItemsList, atpItemsListFragment } from '../../ContentBlocks/AtpItemsList/AtpItemsList.jsx';
import { AutoTopicPagination } from '../../AutoTopicPagination.jsx';
import { useHeaderColorToggle } from '../../hooks/useHeaderColorToggle';
import { useTopOffsetHeaderThreshold } from '../../hooks/useTopOffsetHeaderThreshold';

export const PAGE_SIZE = 50;

export const autoTopicContentsPaginatedFragment = makeMemoFragment({
  name: 'AutoTopicContentsPaginated',
  fragment() {
    return gql`fragment ${this.name} on AutoTopic {
      id
      pageHeadline
      isCurrent
      namedUrl
      relatedContentsPaginated(offset: $offset, amount: ${PAGE_SIZE}) {
        totalHits
        hits {
          ...${atpItemsListFragment.name}
        }
      }
      ...${topicHeaderFragment.name}
    }
    ${topicHeaderFragment.fragment()}
    ${atpItemsListFragment.fragment()}
    `;
  },
});

export const AutoTopicContentsPaginated = ({ autoTopic, className }) => {
  const {
    pageHeadline,
    isCurrent,
    namedUrl,
    relatedContentsPaginated,
  } = autoTopic;
  const { onChangeInViewListenerForHeaderSwitch, forcedOffsetParentForHeaderToggleClassName } = useHeaderColorToggle();
  const topOffsetHeaderThreshold = useTopOffsetHeaderThreshold();

  return (
    <A11yLinkTargetMarker className={cx(className, forcedOffsetParentForHeaderToggleClassName)} id={ids.quickNav.mainContent}>
      <InView as="div" onChange={onChangeInViewListenerForHeaderSwitch} rootMargin={topOffsetHeaderThreshold}>
        {isCurrent
          ? <PageHeadline>{pageHeadline}</PageHeadline>
          : <TopicHeader content={autoTopic} />
        }
      </InView>
      <AtpItemsList contents={relatedContentsPaginated.hits}/>
      <AutoTopicPagination totalHits={relatedContentsPaginated.totalHits} pageSize={PAGE_SIZE} namedUrl={namedUrl} />
    </A11yLinkTargetMarker>
  );
};

export const darkAutoTopicContentsPaginatedStyles = cx(
  AutoTopicPagination.darkStyles,
  AtpItemsList.darkStyles,
  TopicHeader.darkStyles,
);

AutoTopicContentsPaginated.darkStyles = darkAutoTopicContentsPaginatedStyles;
