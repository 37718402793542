import { useCallback, useState } from 'react';

export const useDomRef = () => {
  const [htmlNode, setHtmlNode] = useState(null);

  const ref = useCallback(node => {
    setHtmlNode(node);
  }, []);
  return [ref, htmlNode];
};
