import { useEffect } from 'react';
import { suspendedComponentFactory } from '../SuspendedComponent/suspendedComponentFactory.jsx';
import globals from '../../utils/globals';

// @VisibleForTesting
export const { Tracking: LazyTracking } = suspendedComponentFactory(() => import('./Lazy/Tracking.jsx'), { fallback: null });

export const EnableAsyncTracking = ({ content }) => {
  useEffect(() => {
    // According to the docs those need to be set before the piano lib gets loaded
    // eslint-disable-next-line fp/no-mutation, no-underscore-dangle
    globals.window._pac = globals.window._pac || {};
    // eslint-disable-next-line fp/no-mutation, no-underscore-dangle
    globals.window._pac.cookieDomain = '.dw.com';
  }, []);

  return (
    <LazyTracking content={content}/>
  );
};
