/* eslint-disable fp/no-mutation */
/* eslint-disable no-param-reassign */
import { useEffect, useRef, useState } from 'react';
import { useEventListener } from './useEventListener';
import { throttle } from '../../utils/commons';
import { useWindowInnerWidthContext } from '../WindowInnerWidthContext';
import { getXMouseMovement } from '../../utils/draggableUtils';
import { useLanguageContext } from '../commons/WithGraphQLContentHandling/LanguageContext';

export const useSwipe = ({ parentNode, childNode }) => {
  const pixelToleranceFromTheRightSide = 5;
  const swipeThresholdOnX = 20;
  const [showNextArrow, setShowNextArrow] = useState(false);
  const [showPrevArrow, setShowPrevArrow] = useState(false);
  const innerWidth = useWindowInnerWidthContext();
  const { isRtl } = useLanguageContext();

  const swipeInProgress = useRef(false);
  const startX = useRef(0);
  const scrollLeft = useRef(0);
  const directionUno = isRtl ? -1 : 1;

  const handleSwipeStart = event => {
    swipeInProgress.current = true;
    parentNode.style.cursor = 'grab';
    startX.current = getOffsetLeftPosition(event);
    scrollLeft.current = parentNode.scrollLeft;
  };

  const preventDrag = event => {
    event.preventDefault();
  };

  const handleSwipeMove = event => {
    if (!swipeInProgress.current) {
      return;
    }

    if (event.cancelable && event.type === 'touchmove') {
      event.preventDefault();
    }

    const distance = getOffsetLeftPosition(event) - startX.current;
    parentNode.scrollLeft = scrollLeft.current - distance;
  };

  const handleSwipeStop = () => {
    swipeInProgress.current = false;
    parentNode.style.cursor = 'unset';
    calcBoundaries();
  };

  const getChildBoundingRect = () => childNode.getBoundingClientRect();

  const getParentBoundingRect = () => parentNode.getBoundingClientRect();

  const getRemainingSpace = () => {
    const { right: childRightSpace, left: childLeftSpace } = getChildBoundingRect();
    const { right: parentRightSpace, left: parentLeftSpace } = getParentBoundingRect();
    if (isRtl) {
      return Math.round(childLeftSpace) - parentLeftSpace;
    }
    return Math.round(childRightSpace) - parentRightSpace;
  };

  const isCloseToSide = () => Math.abs(getRemainingSpace()) < pixelToleranceFromTheRightSide;

  const moveNext = amount => {
    if (parentNode) {
      parentNode.scrollLeft += amount * directionUno;
      handleSwipeStop();
    }
  };

  const movePrev = amount => {
    if (parentNode) {
      parentNode.scrollLeft -= amount * directionUno;
      handleSwipeStop();
    }
  };

  const getOffsetLeftPosition = event => getXMouseMovement(event, parentNode.offsetLeft);

  const calcBoundaries = () => {
    if (parentNode) {
      const positionOnX = parentNode.scrollLeft * directionUno;

      const { width: parentWidth } = getParentBoundingRect();
      const { width: childWidth } = getChildBoundingRect();

      const swipeable = Math.round(childWidth) > parentWidth;
      const swipedMoreThanThreshold = positionOnX > swipeThresholdOnX;

      setShowPrevArrow(swipeable && swipedMoreThanThreshold);
      setShowNextArrow(swipeable && !isCloseToSide());
    }
  };

  useEventListener(parentNode, 'mousedown touchstart', handleSwipeStart, { passive: true });
  useEventListener(parentNode, 'dragstart', preventDrag);
  useEventListener(parentNode, 'mouseup mouseleave touchend', handleSwipeStop, { passive: true });
  useEventListener(parentNode, 'mousemove touchmove', throttle({ callbackFn: handleSwipeMove, timeout: 50 }), { passive: false });

  useEffect(() => {
    calcBoundaries();
  }, [innerWidth, parentNode]); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    showNextArrow, showPrevArrow, movePrev, moveNext,
  };
};
