import { css, cx } from '@linaria/core';
import { ButtonSemiTransparentWithIconOnly } from '../../buttons/Button';
import { StyledRightChevron as RightChevron, StyledLeftChevron as LeftChevron } from '../../icons';
import { mediaMin, selector } from '../../../utils/css';
import { useTranslation } from '../../hooks/useTranslation.jsx';

export const cssVariables = {
  iconWidth: '--icon-width',
};

export const SlideArrows = ({
  className, onPrev, onNext, disablePrevArrow, disableNextArrow,
}) => {
  const prevArrowTitle = useTranslation('component.slideshow.previous_button.label');
  const nextArrowTitle = useTranslation('component.slideshow.next_button.label');

  return (
    <div className={cx(className, slideArrowsStyles)}>
      <ButtonSemiTransparentWithIconOnly
        className={cx('prev', disablePrevArrow && slideOutLeft, slideInLeft, chevronSharedStyles, chevronStyles)}
        onClick={onPrev}
        disabled={disablePrevArrow}
        title={prevArrowTitle}
      >
        <LeftChevron/>
      </ButtonSemiTransparentWithIconOnly>
      <ButtonSemiTransparentWithIconOnly
        className={cx('next', disableNextArrow && slideOutRight, slideInRight, chevronSharedStyles, chevronStyles)}
        onClick={onNext}
        disabled={disableNextArrow}
        title={nextArrowTitle}
      >
        <RightChevron/>
      </ButtonSemiTransparentWithIconOnly>
    </div>
  );
};

const inTimingFunction = 'cubic-bezier(0, 0, 0.09, 1)';
const outTimingFunction = 'cubic-bezier(0.91, 0, 1, 1)';

export const slideInLeft = css`
  inset-inline-start: 0;
  transition-timing-function: ${inTimingFunction};
`;

export const slideOutLeft = css`
  inset-inline-start: calc(-1 * var(${cssVariables.iconWidth}));
  transition-timing-function: ${outTimingFunction};
`;

export const slideInRight = css`
  inset-inline-end: 0;
  transition-timing-function: ${inTimingFunction};
`;

export const slideOutRight = css`
  inset-inline-end: calc(-1 * var(${cssVariables.iconWidth}));
  transition-timing-function: ${outTimingFunction};
`;

// TODO: linaria-next - We could improve css definition here by removing .prev and .next
// selectors and placing css class name on correct node.
// FIXME: maybe do this and also slide arrows and carousel arrows now seem samey (watch out for focus menu)
export const chevronSharedStyles = css`
  position: absolute;
  top: calc(50% - 55px);
  z-index: 3;
  filter: drop-shadow(0 0 8px rgba(0,0,0,0.3));
  opacity: 1;

  transition-property: inset-inline-start, inset-inline-end, opacity, visibility;
  transition-duration: 800ms;

  &${selector.disabled} {
    visibility: hidden;
    opacity: 0;
  }
`;

export const chevronStyles = css`
    margin: 0 1rem;
    width: 55px;
    svg {
      width: 25px;
    }
  
  ${mediaMin.sm`
    top: calc(50% - 65px);
    width: 65px;
    svg {
      width: 35px;
    }
  `}

  ${mediaMin.lg`
    top: calc(50% - 70px);;
    width: 70px;
    svg {
      width: 40px;
    }
  `}

  ${mediaMin.xl`
    top: calc(50% - 80px);
    width: 80px;
    svg {
      width: 50px;
    }
  `}
`;

export const slideArrowsStyles = css`
  ${cssVariables.iconWidth}: 60px;

  ${mediaMin.sm`
    ${cssVariables.iconWidth}: 70px;
  `}

  ${mediaMin.lg`
    ${cssVariables.iconWidth}: 80px;
  `}
`;
