import gql from 'graphql-tag';
import { makeMemoFragment } from '../../../utils/graphql';
import { moreEpisodesFragment, StyledMoreEpisodes as MoreEpisodes } from './MoreEpisodes.jsx';
import { latestEpisodeFragment, StyledLatestEpisode as LatestEpisode } from './LatestEpisode.jsx';

export const episodesFragment = makeMemoFragment({
  name: 'Episodes',
  fragment() {
    return gql`fragment ${this.name} on UnifiedProgram {
      moreContentsFromUnifiedProgram(amount: 16, types: [VIDEO, AUDIO], fullEpisodes: true) {
        ...${latestEpisodeFragment.name}
        ...${moreEpisodesFragment.name}
      }
    }
    ${latestEpisodeFragment.fragment()}
    ${moreEpisodesFragment.fragment()}
    `;
  },
});

export const Episodes = ({ content }) => {
  const { moreContentsFromUnifiedProgram } = content;
  const [latestContent, ...moreEpisodes] = moreContentsFromUnifiedProgram;

  return (
    <>
      <LatestEpisode content={latestContent} />
      <MoreEpisodes contents={moreEpisodes}/>
    </>
  );
};
