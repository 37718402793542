import gql from 'graphql-tag';
import { cx } from '@linaria/core';
import {
  partialFragments, Partials, StyledContentTeaser as ContentTeaser, darkPartialStyles,
} from '../ContentTeaserPartials';
import { StyledAuthorLinkedImage as AuthorLinkedImage } from '../../ContentDetailHeader/AuthorsLine/AuthorLinkedImage.jsx';
import { StyledAuthorData as AuthorData, authorDataFragment, darkAuthorDataStyles } from '../ContentTeaserPartials/Partials/AuthorData.jsx';
import { makeMemoFragment } from '../../../utils/graphql';

export const smallImageOpinionContentTeaserFragment = makeMemoFragment({
  name: 'SmallImageOpinionContentTeaser',
  fragment() {
    return gql`fragment ${this.name} on Content {
        ... on UrlAspect {
          namedUrl
        }
        ... on NamedAspect {
          title
        }
        ...on TextualAspect {
          shortTeaser
          teaser
        }
        ...${authorDataFragment.name}
        ...${partialFragments.contentStatusTeaserBadgeFragment.name}
        ...${partialFragments.linkedTitleFragment.name}
        ...${partialFragments.teaserFooterFragment.name}
      }
      ${authorDataFragment.fragment()}
      ${partialFragments.contentStatusTeaserBadgeFragment.fragment()}
      ${partialFragments.linkedTitleFragment.fragment()}
      ${partialFragments.teaserFooterFragment.fragment()}
    `;
  },
});

export const SmallImageOpinionContentTeaser = ({
  content = {},
  className,
  titleIsA,
  hideDescription,
  isHidden,
}) => {
  const [person] = content.firstPersonArray;

  return (
    <ContentTeaser
      className={className}
      isHidden={isHidden}
      content={content}
      hideDescription={hideDescription}
      hideOpinionMetadata
    >
      <AuthorLinkedImage className="author-image" person={person}/>
      <Partials.MainContentArea>
        <AuthorData forceHideAuthorLinkedImage>
          <Partials.ContentStatusTeaserBadge />
          <Partials.LinkedTitle isA={titleIsA}/>
        </AuthorData>
        <Partials.LinkedDescription/>
        <Partials.TeaserFooter className="teaser-footer-wrapper"/>
      </Partials.MainContentArea>
    </ContentTeaser>
  );
};

export const darkSmallImageOpinionContentTeaserStyles = cx(
  darkPartialStyles.darkLinkedTitleStyles,
  darkPartialStyles.darkTeaserFooterStyles,
  darkAuthorDataStyles,
);

export const StyledSmallImageOpinionContentTeaser = SmallImageOpinionContentTeaser;
