import { useState } from 'react';
import globals from '../globals';
import { useEventListener } from '../../components/hooks/useEventListener';

const CMP_DATA_EVENT_NAME = 'CONSENT_DATA_EVENT';

const GOOGLE_ADS_VENDOR_ID = 1;
const GOOGLE_ADS_PRODUCTS_VENDOR_ID = 755;

export const useAdsAllowedByCmp = () => {
  const [adsAllowed, setAdsAllowed] = useState();
  useEventListener(globals.window, CMP_DATA_EVENT_NAME, event => {
    const { VendorsAllowed } = event.detail || {};
    setAdsAllowed(
      VendorsAllowed?.includes(GOOGLE_ADS_VENDOR_ID) &&
      VendorsAllowed?.includes(GOOGLE_ADS_PRODUCTS_VENDOR_ID),
    );
  });
  return adsAllowed;
};
