import {
  StyledBigImageTextContentTeaser as BigImageTextContentTeaser, bigImageTextContentTeaserFragment, darkBigImageTextContentTeaserStyles,
} from '../../../ContentTeaserSnippets/ContentTeasers/BigImageTextContentTeaser.jsx';

export const adapterForDossierItemsFragment = bigImageTextContentTeaserFragment;

export const expandAdapterForDossierItems = renderedContents => (
  renderedContents.map(renderedContent => (
    <BigImageTextContentTeaser
      key={renderedContent.id}
      content={renderedContent}
      titleIsA="h3"
    />
  ))
);

export const darkExpandAdapterForDossierItemsStyles = darkBigImageTextContentTeaserStyles;
