import { cx } from '@linaria/core';

import {
  listDefaultUnifiedBigImageTextFragment,
  ListDefaultUnifiedBigImageTextTemplate,
} from '../../ContentTeaserSnippets/Templates/ListDefaultUnifiedBigImageTextTemplate.jsx';
import { isValidList } from '../../../utils/contentUtils';

export const programListFragment = listDefaultUnifiedBigImageTextFragment;

export const ProgramList = ({
  contents, headlineTranslation, pageSectionId, className,
}) => {
  if (!isValidList(contents)) {
    return null;
  }
  return (
    <ListDefaultUnifiedBigImageTextTemplate
      headlineTranslation={headlineTranslation}
      className={cx(className, ListDefaultUnifiedBigImageTextTemplate.darkStyles)}
      contents={contents}
      pageSectionId={pageSectionId}
      hideKicker
      hideTimeIndicator
    />
  );
};

ProgramList.darkStyles = ListDefaultUnifiedBigImageTextTemplate.darkStyles;
