import { styled } from '@linaria/react';
import { css } from '@linaria/core';
import { colors } from '../../utils/css';
import { focusTargetClassName } from '../FlyingFocus/constants';
import {
  italicStyles,
  resetOpticalSizing,
  regularStyles,
  semiBoldStyles,
} from '../Text/TextBase/TextBase.jsx';
import {
  LinkBase, StyledLinkBase, activeStyles, cssVariables,
} from './LinkBase';
import { extendWithClassNames } from '../../utils/extendWithClassNames.jsx';

// TODO linaria-next: use extendWithClassNames & semiBoldClass
// SemiBold
const LinkSemiBold = styled(StyledLinkBase)`
  ${semiBoldStyles}
`;
LinkSemiBold.darkStyles = StyledLinkBase.darkStyles;

// Regular
export const LinkRegular = styled(StyledLinkBase)`
  ${regularStyles}
`;
LinkRegular.darkStyles = StyledLinkBase.darkStyles;

// Default
export const LinkDefault = LinkSemiBold;

export const LinkDefaultNarrow = styled(LinkDefault)`
  ${resetOpticalSizing}
`;
LinkDefaultNarrow.darkStyles = LinkDefault.darkStyles;

export const LinkDefaultNarrowItalic = styled(LinkDefaultNarrow)`
  ${italicStyles}
`;
LinkDefaultNarrowItalic.darkStyles = LinkDefault.darkStyles;

// LinkMonoColor
const monoColorStyles = `
  && {
    color: var(${cssVariables.color}, ${colors.DW_DARK_BLUE});
  }
`;
export const LinkMonoColor = styled(LinkDefault)`${monoColorStyles}`;
LinkMonoColor.darkStyles = LinkDefault.darkStyles;

// LanguageLink
export const languageLinkStyles = `
  &:hover, &.${focusTargetClassName} {
    text-decoration: none;
    span:first-of-type {
        text-decoration: underline;
    }
  }
  &:focus-visible {
    text-decoration: none;
  }
`;
export const LanguageLink = styled(LinkMonoColor)`${languageLinkStyles}`;
LanguageLink.darkStyles = LinkMonoColor.darkStyles;

// LinkInText
const linkInTextStyles = css`
text-decoration: underline;
color: inherit;
&:hover {
  color: inherit;
}
&:active {
  ${activeStyles}
}
`;
export const LinkInText = extendWithClassNames(LinkDefault)(linkInTextStyles);
LinkInText.darkStyles = LinkDefault.darkStyles;

// - LinkNoLink
export const LinkNoLink = styled(LinkBase)`
  color: inherit;
  &&:is(:link, :visited, :hover, :active)  {
    text-decoration: none;
    color: inherit;
    line-height: inherit;
    font-weight: inherit;
  }
`;
