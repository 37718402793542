import {
  createContext, useContext, useState,
} from 'react';
import { noop } from '../utils/commons';
import globals from '../utils/globals';
import { livestreamScheduleStateCalcFactory } from '../utils/livestreamUtils';
import { isDateInRange, MINUTE_IN_MILLIS } from './commons/DateAnalyser';
import { useEventListener } from './hooks/useEventListener';
import { useFeatureFlag } from './hooks/useFeatureFlag';
import { useTimeout } from './hooks/useTimeout';
import { makeApiPath } from './hooks/useGqlFetch';
import { useParams } from '../dwouter';
import { useFetchContext } from './FetchContext';

const RunningLivestreamContext = createContext({
  currentDate: null,
  currentDay: null,
  displayedTimeSlots: [],
});

const useRunningLivestreamContext = () => useContext(RunningLivestreamContext);

export const useCurrentDay = () => useRunningLivestreamContext().currentDay;

export const useTimeSlots = () => useRunningLivestreamContext().displayedTimeSlots;

export const useIsLiveSlot = timeSlot => {
  const { currentDate } = useRunningLivestreamContext();
  return isDateInRange({
    dateStr: currentDate.toISOString(),
    startDateStr: timeSlot.startDate,
    endDateStr: timeSlot.endDate,
  });
};

// make sure that browser data are fresh, and make sure time is a bit higher than max CDN caching time
// (otherwise we are stuck with those data for next 5 min)
const REFRESH_DATA_TIME_MILLIS = 5.1 * MINUTE_IN_MILLIS;

// START: this was added to make DEV/QA life easier, hidden behind a query param flag if you need it.
// Due to extraction of current data and whole related context initialization to a single place
// we are able to move time forward on demand by clicking a button.
// This can help to find bugs easier and e.g. make sure that poster image is changed properly
// with next show without waiting for current show to end.
const useFwdTimeEvent = ({ refreshState }) => {
  const hasFeatureFlag = useFeatureFlag();
  const [minutes, setMinutes] = useState(0);
  const handleTimeFwd = event => {
    const futureDate = new Date();
    const totalMinutes = minutes + (+event.detail.minutes);
    futureDate.setMinutes(futureDate.getMinutes() + totalMinutes);
    refreshState({ currentDate: futureDate });
    setMinutes(totalMinutes);
  };
  // document.dispatchEvent(new CustomEvent('forward-time-for-schedule', { detail: { minutes: 30 } }))
  useEventListener(globals.document, 'forward-time-for-schedule', hasFeatureFlag ? handleTimeFwd : noop);
};
// END

const useCurrentShowRefreshEffect = ({ state, refreshState }) => {
  // refresh the currently running show after it is over
  const runningShowSlot = state.displayedTimeSlots[0];
  const timeToSwitchCurrent = runningShowSlot
    ? new Date(runningShowSlot.endDate) - state.currentDate
    : null;
  useTimeout({
    callback: refreshState,
    delay: timeToSwitchCurrent,
    conditional: timeToSwitchCurrent,
  }, [state.currentDate]); // make sure to make a new timeout after UI update
};

const useRefreshUrgentChangesInTimeSlots = ({ state, refreshState }) => {
  const { langCode, channel } = useParams();
  const { fetch } = useFetchContext();
  useTimeout({
    callback: async () => {
      const res = await fetch(makeApiPath(`${langCode}/livestream/${channel}`));
      const freshData = await res.json();
      refreshState({ freshData });
    },
    delay: REFRESH_DATA_TIME_MILLIS,
  }, [state.currentDate]);
};

export const RunningLivestreamContextProvider = ({
  children, livestreamChannel, today,
}) => {
  const calcCurrentState = livestreamScheduleStateCalcFactory({ livestreamChannel, today });
  const [state, setState] = useState(calcCurrentState());
  const refreshState = stateArgs => setState(calcCurrentState(stateArgs));

  useCurrentShowRefreshEffect({ state, refreshState });
  useRefreshUrgentChangesInTimeSlots({ state, refreshState });

  useFwdTimeEvent({ refreshState }); // DEV/QA purpose only!
  return (
    <RunningLivestreamContext.Provider value={state}>
      {children}
    </RunningLivestreamContext.Provider>
  );
};
