import { styled } from '@linaria/react';
import { cx } from '@linaria/core';
import gql from 'graphql-tag';

import { selector } from '../../utils/css';
import { makeMemoFragment } from '../../utils/graphql';
import { darkKickerTextStyles, StyledKickerText as KickerText } from './KickerText.jsx';
import { LinkDefault } from '../Links';

export const contentDetailKickerFragment = makeMemoFragment({
  name: 'ContentDetailKicker',
  fragment() {
    return gql`fragment ${this.name} on AssociationsAspect {
          thematicFocusCategory {
            name
            correspondingContent {
              ... on UrlAspect {
                namedUrl
              }
            }
          }
          firstRegionArray: regions(amount: 1) {
            alignedName
            correspondingContent {
              ... on UrlAspect {
                namedUrl
              }
            }
          }
        }
    `;
  },
});

const ConditionalLink = ({
  url, text, tabIndex = 0,
}) => {
  if (!text) {
    return null;
  }
  return url
    ? <LinkDefault to={url} title={text} skipTitleForInternal tabIndex={tabIndex}>
      {text}
    </LinkDefault>
    : text;
};

export const ContentDetailKicker = ({
  content,
  className,
  tabIndex,
  'aria-hidden': ariaHidden,
}) => {
  const thematicFocusName = content.thematicFocusCategory?.name;
  const [firstRegion] = content.firstRegionArray;
  const regionAlignedName = firstRegion?.alignedName;

  if (!thematicFocusName && !regionAlignedName) {
    return null;
  }
  return (
    <KickerText
      className={`kicker ${className}`}
      aria-hidden={ariaHidden}
      dataTrackingName="content-detail-kicker"
    >
      {thematicFocusName && (
        <span>
          <ConditionalLink
            tabIndex={tabIndex}
            url={content.thematicFocusCategory?.correspondingContent?.namedUrl}
            text={thematicFocusName}
          />
        </span>
      )}
      {regionAlignedName && (
        <span>
          <ConditionalLink
            tabIndex={tabIndex}
            url={firstRegion?.correspondingContent?.namedUrl}
            text={regionAlignedName}
          />
        </span>
      )}
    </KickerText>
  );
};

export const darkContentDetailKickerStyles = cx(
  LinkDefault.darkStyles,
  darkKickerTextStyles,
);

export const StyledContentDetailKicker = styled(ContentDetailKicker)`
  margin-bottom: 8px;

  & > *${selector.notLast} {
    &:after {
      content: '\u00A0|\u00A0';
    }
  }
`;
