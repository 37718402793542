import { useLayoutEffect, useState } from 'react';
import { replaceAllPlaceholders } from './replacers';
import { replacerImageGalleryFragment } from './replacers/imageGalleryReplacer.jsx';
import { replacerVideoFragment } from './replacers/videoReplacer.jsx';
import { replacerAudioFragment } from './replacers/audioReplacer.jsx';
import { replacerWidgetFragment } from './replacers/widgetReplacer.jsx';

export const replacersFragments = [
  replacerImageGalleryFragment,
  replacerVideoFragment,
  replacerAudioFragment,
  replacerWidgetFragment,
];

export const usePlaceholderReplacementEffect = ({ content, richTextRef }) => {
  const [portals, setPortals] = useState(null);
  useLayoutEffect(() => {
    if (richTextRef.current) {
      const renderedPortals = replaceAllPlaceholders({ content, richTextElement: richTextRef.current });
      setPortals(renderedPortals);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [content.id]);

  return portals;
};
