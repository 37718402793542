import { styled } from '@linaria/react';
import { css } from '@linaria/core';
import { mediaMin } from '../../../../utils/css';
import { contentBlockPadding } from '../../../ContentBlocks/ContentBlock';

export const contentAreaPadding = {
  ...contentBlockPadding,
  lg: 95,
  xl: 110,
};
export const contentAreaWidth = {
  lg: 960,
  xl: 1110,
};

export const listPadding = {
  xs: contentAreaPadding.xs + 31,
  sm: contentAreaPadding.sm + 31,
  md: contentAreaPadding.md + 33,
  lg: contentAreaPadding.lg + 38,
  xl: contentAreaPadding.xl + 38,
};

export const liveblogPostPadding = {
  sm: contentAreaPadding.sm - 15,
  md: contentAreaPadding.md - 15,
  lg: contentAreaPadding.lg - 15,
  xl: contentAreaPadding.xl - 15,
};

export const ContentArea = ({ children, className }) => (
  <div className={className}>
    {children}
  </div>
);

export const cssVariables = {
  contentAreaInlineSpacing: '--content-area-inline-spacing',
  listInlineStartSpacing: '--list-inline-start-spacing',
};

// TODO linaria-next convert all there to class`` classes ??
export const contentAreaPaddingVariables = `
  ${cssVariables.contentAreaInlineSpacing}: ${contentAreaPadding.xs}px;
  ${mediaMin.sm`
    ${cssVariables.contentAreaInlineSpacing}: ${contentAreaPadding.sm}px;
  `}
  ${mediaMin.md`
    ${cssVariables.contentAreaInlineSpacing}: ${contentAreaPadding.md}px;
  `}
  ${mediaMin.lg`
    ${cssVariables.contentAreaInlineSpacing}: ${contentAreaPadding.lg}px;
  `}
  ${mediaMin.xl`
    ${cssVariables.contentAreaInlineSpacing}: ${contentAreaPadding.xl}px;
  `}
`;

export const listInlineStartVariables = `
  ${cssVariables.listInlineStartSpacing}: ${listPadding.xs}px;
  ${mediaMin.sm`
    ${cssVariables.listInlineStartSpacing}: ${listPadding.sm}px;
  `}
  ${mediaMin.md`
    ${cssVariables.listInlineStartSpacing}: ${listPadding.md}px;
  `}
  ${mediaMin.lg`
    ${cssVariables.listInlineStartSpacing}: ${listPadding.lg}px;
  `}
  ${mediaMin.xl`
    ${cssVariables.listInlineStartSpacing}: ${listPadding.xl}px;
  `}
`;

export const contentAreaPaddingStyles = `
  ${contentAreaPaddingVariables}
  padding-inline: var(${cssVariables.contentAreaInlineSpacing});
`;

export const richTextAlignmentStyles = `
    ${contentAreaPaddingVariables}
    margin-inline: calc(-1 * var(${cssVariables.contentAreaInlineSpacing}));
`;

export const richTextAlignmentClassName = css`
    ${contentAreaPaddingVariables}
    margin-inline: calc(-1 * var(${cssVariables.contentAreaInlineSpacing}));
`;

export const StyledBasicContentArea = styled(ContentArea)`
  padding-top: 20px;
  padding-bottom: 20px;
  ${contentAreaPaddingStyles}
`;

export const StyledContentArea = styled(StyledBasicContentArea)`
  ${mediaMin.lg`
    padding-top: 30px;
  `}
`;
