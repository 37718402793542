import { styled } from '@linaria/react';
import { screenReaderOnly } from './ContentDetailHeader/ScreenReaderOnly.jsx';
import { I18nText } from './I18nText.jsx';

export const HiddenHeadline = props => (
  <I18nText {...props}/>
);

export const StyledHiddenHeadline = styled(HiddenHeadline)`
    ${screenReaderOnly}
`;
