import { noop } from '../../../utils/commons';
import { darkContentBlockStyles, StyledContentBlock as ContentBlock } from '../ContentBlock';
import { StyledEndLineAlignmentContainer as BottomContainer } from '../EndLineAlignmentContainer.jsx';

export const ContentBlockList = ({ bottomElementFn = noop, children, ...restProps }) => {
  const bottomElement = bottomElementFn();
  return (
    <ContentBlock {...restProps}>
      {children}
      {bottomElement && (
        <BottomContainer data-tracking-name="list-bottom-container" >
          {bottomElement}
        </BottomContainer>
      )}
    </ContentBlock>
  );
};

export const darkContentBlockListStyles = darkContentBlockStyles;

export const StyledContentBlockList = ContentBlockList;
