import { Children } from 'react';
import { styled } from '@linaria/react';
import { cx } from '@linaria/core';

export const TeaserComposition = ({ children, className }) => (
  <div className={cx(className, 'row')}>
    {Children.map(children, (child, index) => (child &&
        <div key={index} className="teaser-wrap col-12 col-md-6 col-lg-4">
          {child}
        </div>
    ))}
  </div>
);

export const DEFAULT_TEASER_SPACING = '30px';

export const StyledTeaserComposition = styled(TeaserComposition)`
  row-gap: ${DEFAULT_TEASER_SPACING};
`;
