import { useEffect, useState } from 'react';
import globals from '../../utils/globals';

export const useHtmlLoad = url => {
  const [embedData, setEmbedData] = useState();
  useEffect(() => {
    if (url) {
      (async () => {
        const oEmbedResponse = await globals.window.fetch(url);
        const oEmbedData = await oEmbedResponse.json();
        setEmbedData(oEmbedData.html);
      })();
    }
  }, [url]);
  return embedData;
};
