import { styled } from '@linaria/react';
import { StyledAVCarousel as AVCarousel, avCarouselFragment } from '../AVCarousel/AVCarousel.jsx';
import { colors } from '../../../utils/css';
import { screenReaderOnly } from '../../ContentDetailHeader/ScreenReaderOnly.jsx';

export const featuredAVFragment = avCarouselFragment;

export const FeaturedAV = ({
  className, contents, headlineTranslation, pageSectionId,
}) => (
  <AVCarousel
    className={className}
    contents={contents}
    headlineTranslation={headlineTranslation}
    pageSectionId={pageSectionId}
    isFeatured
  />
);

export const StyledFeaturedAV = styled(FeaturedAV)`
  background-color: ${colors.DARK_BLUE_GREY_02};

  h2 {
    ${screenReaderOnly}
  }

  &:not(:last-child) {
    margin-bottom: 25px;
  }
`;
