import gql from 'graphql-tag';
import { styled } from '@linaria/react';
import { cx } from '@linaria/core';

import { colors, mediaMin } from '../../utils/css';
import { StyledProgramDetailHeader as ProgramDetailHeader, programDetailHeaderFragment } from './ProgramDetailHeader.jsx';
import { StyledContentMoveUpLayout as ContentMoveUpLayout, contentMoveUpLayoutFragment, darkContentMoveUpLayoutStyles } from '../layouts/ContentMoveUpLayout';
import { StyledProgramDetailRichText as ProgramDetailRichText, programDetailRichTextFragment } from './ProgramDetailRichText.jsx';
import { makeMemoFragment } from '../../utils/graphql';

export const programDetailsFragment = makeMemoFragment({
  name: 'ProgramDetails',
  fragment() {
    return gql`fragment ${this.name} on UnifiedProgram {
      ...${contentMoveUpLayoutFragment.name}
      ...${programDetailHeaderFragment.name}
      ...${programDetailRichTextFragment.name}
    }
    ${contentMoveUpLayoutFragment.fragment()}
    ${programDetailHeaderFragment.fragment()}
    ${programDetailRichTextFragment.fragment()}
    `;
  },
});

export const ProgramDetails = ({ program, className }) => (
  <ContentMoveUpLayout
    content={program}
    className={cx(className, darkContentMoveUpLayoutStyles)}
  >
    <ProgramDetailHeader program={program}/>
    <ProgramDetailRichText program={program}/>
  </ContentMoveUpLayout>
);

export const StyledProgramDetails = styled(ProgramDetails)`
  background: ${colors.DARK_BLUE_GREY_02};

  .content-area {
    background-color: ${colors.DARK_BLUE_GREY_01};
    padding-bottom: 30px;
  }
  ${mediaMin.lg`
    .content-area {
      min-height: 20rem;
    }
  `}
`;
