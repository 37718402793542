import { css } from '@linaria/core';
import { mediaMin } from '../../../../../utils/css';

const noImageSelector = '.teaser-data-wrap:first-child';

export const extendTeaserCompositionWithSmallImageTeaserLooks = css`
  && .teaser-wrap .teaser {
    flex-direction: row;
  }
  .teaser-wrap{
    .teaser-image-wrap {
      max-width: 25%;
      padding-inline-end: 0;
    }
    .teaser-data-wrap {
      max-width: 75%;
    }
    .title {
      font-style: normal;
      font-size: 1.8rem;
    }
    ${noImageSelector}{
      flex: 0 0 100%;
      max-width: 100%;
      .title {
        font-size: 2.2rem;
      }
    }
  }

  ${mediaMin.sm`
    .teaser-wrap{
      .teaser-image-wrap {
        max-width: 30.6%;
      }
      .teaser-data-wrap {
        max-width: 69.4%;
      }
      .title {
        font-size: 2rem;
      }
    }
  `}

  ${mediaMin.md`
    .teaser-wrap{
      .teaser {
        flex-direction: column;
      }
      .teaser-image-wrap {
        padding-inline-end: 15px;
      }
      .teaser-image-wrap,
      .teaser-data-wrap {
        max-width: 100%;
      }
      .title,
      ${noImageSelector} .title {
        font-size: 2.4rem;
      }
    }
  `}

`;

export const extendTeaserCompositionWithSmallImageTeaserMixLooks = css`
  .teaser-data {
    flex-grow: 1;
  }

  ${mediaMin.lg`
    .author-image {
      margin-inline-start: 50px;
    }
  `};

  ${mediaMin.xl`
    .author-image {
      margin-inline-start: 62px;
    }
  `};
`;
