import { createContext, useContext } from 'react';
import { alwaysLandscapePictureConfig } from '../../ResponsiveDwPicture/pictureConfigs';

export const contentTeaserSetup = Object.freeze({
  config: Object.freeze({
    isHidden: false,
    formatConfig: alwaysLandscapePictureConfig,
    hide: Object.freeze({
      kicker: false,
      description: false,
      opinion: false,
      opinionMetadata: false,
      timeIndicator: false,
    }),
    isCurrent: false,
    isPreloadImage: false,
  }),
  content: {},
  link: {},
});

export const ContentTeaserContext = createContext({
  config: {
    isHidden: contentTeaserSetup.config.isHidden,
    formatConfig: contentTeaserSetup.config.formatConfig,
    hide: {
      kicker: contentTeaserSetup.config.hide.kicker,
      description: contentTeaserSetup.config.hide.description,
      opinion: contentTeaserSetup.config.hide.opinion,
      opinionMetadata: contentTeaserSetup.config.hide.opinionMetadata,
      timeIndicator: contentTeaserSetup.config.hide.timeIndicator,
    },
    isCurrent: contentTeaserSetup.config.isCurrent,
    isPreloadImage: contentTeaserSetup.config.isPreloadImage,
  },
  content: contentTeaserSetup.content,
  link: contentTeaserSetup.link,
});

export const useContentTeaserContext = () => useContext(ContentTeaserContext);
