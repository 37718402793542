import { styled } from '@linaria/react';
import { StyledWithClipboardEvent as WithClipboardEvent } from '../WithClipboardEvent.jsx';
import { useToggle } from '../hooks/useToggle';
import { useTranslation } from '../hooks/useTranslation.jsx';
import { StyledChainLink as ChainLinkIcon } from '../icons';
import { ButtonTertiary } from './Button';

export const CopyTextButton = ({
  textToCopy,
  className,
  ...restProps
}) => {
  const [isOpen, { toggleOnClick: toggleIsOpen }] = useToggle();
  const copyLabel = useTranslation(`component_detail.detail_actions.${isOpen ? 'link_copied' : 'copy_link'}`);

  return (
    <WithClipboardEvent textToCopy={textToCopy}>
      {({ triggerCopy, isSupportedByBrowser }) => (
        !isSupportedByBrowser()
          ? null
          : (
            <ButtonTertiary
              key={isOpen}
              className={className}
              onClick={() => {
                triggerCopy();
                toggleIsOpen();
              }}
              {...restProps}
            >
              <ChainLinkIcon className="link-icon" />
              {copyLabel}
            </ButtonTertiary>
          )
      )}
    </WithClipboardEvent>
  );
};

export const darkCopyButtonStyles = ButtonTertiary.darkStyles;

export const StyledCopyTextButton = styled(CopyTextButton)`
  .link-icon {
    path {
      stroke: none;
    }
  }
`;
