import gql from 'graphql-tag';
import { makeMemoFragment } from '../../utils/graphql';
import { LinkDefault } from '../Links';

export const footerVendorSettingsLinkFragment = makeMemoFragment({
  name: 'FooterVendorSettingsLink',
  fragment() {
    return gql`fragment ${this.name} on Footer {
        privacySettings {
          title
          namedUrl
        }
    }
  `;
  },
});

export const FooterVendorSettingsLink = ({ className, footer }) => (
  <span>
    <LinkDefault className={className} to={footer.privacySettings.namedUrl} title={footer.privacySettings.title} skipTitleForInternal>
      {footer.privacySettings.title}
    </LinkDefault>
  </span>
);
