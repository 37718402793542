import gql from 'graphql-tag';
import { Helmet } from 'react-helmet-async';
import { makeMemoFragment } from '../utils/graphql';

export const imageSharingMetadataFragment = makeMemoFragment({
  name: 'ImageSharingMetadata',
  fragment() {
    return gql`fragment ${this.name} on SocialMediaAspect {
      imageSharingMetadata {
        url
        width
        height
        alt
      }
    }
    `;
  },
});

export const ImageSharingMetadata = ({ content }) => {
  const {
    url, width, height, alt,
  } = content.imageSharingMetadata || {};

  if (!url) {
    return null;
  }

  return (
    <Helmet>
      <meta property="og:image:type" content="image/jpeg" />
      <meta property="og:image" content={url} />
      <meta name="twitter:image" content={url}/>
      {width && <meta property="og:image:width" content={width} />}
      {height && <meta property="og:image:height" content={height} />}
      {alt && <meta property="og:image:alt" content={alt} /> }
      {alt && <meta name="twitter:image:alt" content={alt} /> }
    </Helmet>
  );
};
