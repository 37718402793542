import { cx } from '@linaria/core';
import gql from 'graphql-tag';
import { filterAndRearrange } from './linkOrderer';
import { darkContentBlockStyles, StyledContentBlock as ContentBlock } from '../ContentBlock';
import { isValidList } from '../../../utils/contentUtils';
import { StyledExpandableAll as ExpandableAll, darkExpandableAllStyles } from '../../ExpandableAll';
import { selectContentTeaser } from './ContentTeasers';
import { StyledFocusTopThreeHighlightLayout as MoreOnTopicTeaserLayout } from '../../ContentTeaserSnippets/Layouts/FocusTopThreeHighlightLayout';
import { darkExternalLinksTeaserStyles, externalLinksFragment } from './ContentTeasers/ExternalLinksContentTeaser.jsx';
import { darkDownloadContentTeaserStyles, downloadTeaserFragment } from './ContentTeasers/DownloadContentTeaser.jsx';
import { darkInternalContentTeaserStyles, internalContentFragment } from './ContentTeasers/InternalContentTeaser';
import { squareConfigFactory } from '../../ResponsiveDwPicture/pictureConfigs';
import { makeMemoFragment } from '../../../utils/graphql';

export const moreOnTopicFragment = makeMemoFragment({
  name: 'MoreOnTopic',
  fragment() {
    return gql`fragment ${this.name} on AssociationsAspect {
      contentLinks {
        targetId
        targetType
        isInText
      }
      externalLinks {
        targetId: id
        modelType
        isInText
      }
      ...${internalContentFragment.name}
      ...${downloadTeaserFragment.name}
      ...${externalLinksFragment.name}
    }
    ${internalContentFragment.fragment()}
    ${downloadTeaserFragment.fragment()}
    ${externalLinksFragment.fragment()}
    `;
  },
});

export const MoreOnTopic = ({ className, content = {} }) => {
  const { contentLinks = [], externalLinks = [] } = content;
  const normalizedExternalLinks = externalLinks.map(link => ({
    ...link,
    targetType: link.modelType,
  }));
  const normalizedContentLinks = contentLinks.map(link => ({
    ...link,
    target: link.target && {
      ...link.target,
      title: link.target.name,
    },
  }));
  const filteredLinks = filterAndRearrange([...normalizedContentLinks, ...normalizedExternalLinks]);

  if (!isValidList(filteredLinks)) {
    return null;
  }
  return (
    <ContentBlock
      className={className}
      headlineTranslation="content_block_detail.more_on_topic.headline"
      headlineIsA="h2"
      pageSectionId="more-on-topic"
    >
      <ExpandableAll dataArray={filteredLinks}>
        {renderedLinks => (
          <MoreOnTopicTeaserLayout>
            {renderedLinks.map((link, index) => selectContentTeaser({
              link,
              imageFormatConfig: index > 0 ? squareConfigFactory() : undefined,
              key: link.targetId,
            }))}
          </MoreOnTopicTeaserLayout>
        )}
      </ExpandableAll>
    </ContentBlock>
  );
};

export const darkMoreOnTopicStyles = cx(
  darkContentBlockStyles,
  darkDownloadContentTeaserStyles,
  darkExternalLinksTeaserStyles,
  darkInternalContentTeaserStyles,
  darkExpandableAllStyles,
);

export const StyledMoreOnTopic = MoreOnTopic;
