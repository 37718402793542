import {
  useState, useEffect, useRef, useCallback,
} from 'react';
import { styled } from '@linaria/react';
import globals from '../../utils/globals';
import { keyCodes, isForwardTab } from '../../utils/keyCodes';
import { setFocusInList } from '../../utils/listFocusUtils';
import { usePrev } from '../hooks/usePrev';

import { isValidList } from '../../utils/contentUtils';
import { mediaMin } from '../../utils/css';
import { StyledShowMoreButton as ShowMoreButton } from '../buttons/ShowMoreButton/ShowMoreButton.jsx';
import { StyledButtonBar as ButtonBar } from '../buttons/Button/ButtonBar.jsx';

export const TEASER_AMOUNT = 6;
export const SHOW_MORE_BTN_DEFAULT_TRANSLATION = 'content_block.general.show_more';
export const DEFAULT_FOCUS_CONTAINER_CLASS = 'teaser';

const useArraySlicer = (array, step, initialSliceAmount) => {
  const [slicedArray, updateSlicedArray] = useState(array.slice(0, initialSliceAmount));

  useEffect(() => {
    updateSlicedArray(array.slice(0, initialSliceAmount));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [array.length, initialSliceAmount]);
  const giveMore = (nextStep = step) => updateSlicedArray(array.slice(0, slicedArray.length + nextStep));
  return [slicedArray, giveMore];
};

export const Expandable = ({
  dataArray = [],
  className,
  children,
  stepMoreAmount = TEASER_AMOUNT,
  initialListCount = TEASER_AMOUNT,
  showMoreBtnTranslation = SHOW_MORE_BTN_DEFAULT_TRANSLATION,
  focusContainerClass = DEFAULT_FOCUS_CONTAINER_CLASS,
}) => {
  const [renderedContents, giveMore] = useArraySlicer(dataArray, stepMoreAmount, initialListCount);
  const [focus, setFocus] = useState(false);
  const teaserListRef = useRef(null);
  const prevRenderedContentLength = usePrev(renderedContents.length);

  const onKeyDown = useCallback(e => {
    if (isForwardTab(e) && focus) {
      e.preventDefault();
      setFocusInList({
        listElement: teaserListRef?.current,
        indexToFocus: prevRenderedContentLength,
        listEntryClass: focusContainerClass,
      });
    }
    if (e.key === keyCodes.TAB) {
      setFocus(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [focus, prevRenderedContentLength]);

  useEffect(() => {
    focus && globals.document.addEventListener('keydown', onKeyDown);
    return () => globals.document.removeEventListener('keydown', onKeyDown);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [focus]);

  return (
    <div ref={teaserListRef} className={className}>
      { isValidList(renderedContents) && children(renderedContents) }
      { dataArray.length > renderedContents.length && (
        <ButtonBar>
          <ShowMoreButton
            className="show-more-button"
            translation={showMoreBtnTranslation}
            onClick={() => {
              giveMore();
              setFocus(true);
            }}
          />
        </ButtonBar>
      )}
    </div>
  );
};
export const darkExpandableStyles = ShowMoreButton.darkStyles;

// TODO linaria-next: css``
export const expandableCss = `
  .show-more-button {
    margin-top: 30px;
  }

  ${mediaMin.sm`
    .show-more-button {
      margin-inline: auto;
    }
  `}
`;

export const StyledExpandable = styled(Expandable)`
  ${expandableCss}
`;
