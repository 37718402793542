import { styled } from '@linaria/react';
import { useTranslation } from '../hooks/useTranslation.jsx';
import { StyledQuickNavigationItem as QuickNavigationItem } from './QuickNavigationItem.jsx';
import { StyledA11yInternalLinkTarget as A11yInternalLinkTarget } from './A11yInternalLinkTarget.jsx';

const getTrackingNameFromPageSectionId = (pageSectionId = '') => {
  const endingWithIdRegExp = /-(\d)*$/;
  const sectionName = pageSectionId.replace(endingWithIdRegExp, '');
  return `section-${sectionName}`;
};

export const A11ySection = ({
  className,
  pageSectionId,
  sectionTitle,
  children,
}) => (
  <section className={className} id={pageSectionId} data-tracking-name={getTrackingNameFromPageSectionId(pageSectionId)}>
    <QuickNavigationItem
      className='quick-item'
      pageSectionId={`post-${pageSectionId}`}
      translation={
        {
          key: 'component.a11y.skip_section.link',
          parameters: { sectionTitle: useTranslation(sectionTitle) },
        }
      }
    />
    {children}
    <A11yInternalLinkTarget className='quick-item-target' id={`post-${pageSectionId}`}/>
  </section>
);

export const StyledA11ySection = styled(A11ySection)`
  position: relative;

  & > .quick-item  {
    top: -42px;
  }

  & > .quick-item-target {
    bottom: 150px;
    top: auto;
  }
`;
