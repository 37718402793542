import gql from 'graphql-tag';

import { StyledAudio as Audio, audioFragment } from '../../../components/Audio';
import { makeMemoFragment } from '../../graphql';

export const replacerAudioFragment = makeMemoFragment({
  name: 'RichTextAudioPlaceholders',
  fragment() {
    return gql`fragment ${this.name} on AssociationsAspect {
        audios {
          id
          ...${audioFragment.name}
        }
    }
    ${audioFragment.fragment()}
    `;
  },
});

export const audioPlaceholderToAudioComponentFactory = content => matchedElem => {
  const audioId = matchedElem.dataset.id;
  const audio = content.audios.find(a => a.id === +audioId);

  return audio && (
    <Audio content={audio}/>
  );
};
