import { css, cx } from '@linaria/core';
import { colors } from '../utils/css';
import { extendWithClassNames } from '../utils/extendWithClassNames.jsx';

export const cssVariables = {
  background: '--infobox-rich-text-background',
};

export const Infobox = ({ children, ...props }) => <div {...props}>{children}</div>;

export const infoboxStylesString = `
  background-color: var(${cssVariables.background}, ${colors.BLUE_GREY_01});
  border-radius: 8px;
  padding-inline: 15px;
  padding-block: 25px 30px;
`;

export const infoboxLastChildNoMarginBottomStylesString = `
  & > *:last-child > *:last-child {
    margin-bottom: 0;
  }
`;

export const infoboxClass = css`${infoboxStylesString}`;
export const infoboxLastChildNoMarginBottomClass = css`${infoboxLastChildNoMarginBottomStylesString}`;

export const darkInfoboxStyles = cx(
  css`${cssVariables.background}: ${colors.DARK_BLUE_GREY_02};`,
);

export const StyledInfobox = extendWithClassNames(Infobox)(
  infoboxClass,
  infoboxLastChildNoMarginBottomClass,
);

StyledInfobox.darkStyles = darkInfoboxStyles;
