import { styled } from '@linaria/react';
import classNames from 'classnames';
import {
  mediaMax,
} from '../../../utils/css';

export const ButtonBar = ({
  className, isA = 'div', children, align = 'center',
}) => {
  const Tag = isA;
  return (
    // TODO linaria-next: remove classNames from dependencies?
    <Tag className={classNames(className, align)}>
      { children }
    </Tag>
  );
};

export const StyledButtonBar = styled(ButtonBar)`
  width: 100%;
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  && > * {
    ${mediaMax.xs`
      width: 100%;
    `}
  }
  &:where(.center) {
    justify-content: center;
  }
  &:where(.start) {
    justify-content: start;
  }
  &:where(.end) {
    justify-content: end;
  }
  &:where(.space-between) {
    justify-content: space-between;
  }
`;
