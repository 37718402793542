import { styled } from '@linaria/react';
import { cx } from '@linaria/core';
import { StyledTeaserText as TeaserText, darkTeaserTextStyles } from '../../TeaserText.jsx';
import { mediaMin } from '../../../utils/css';
import { usePageContext } from '../../PageEmbeddingContext.jsx';
import { contentAreaPaddingStyles } from '../../layouts/ContentMoveUpLayout/ContentArea';
import { centeredContent } from '../../layouts/BasicLayout';
import { metaBackground, pseudoAsBackground, metaGradient } from '../../../utils/gradientCss';
import { useContentSpecificAriaLabel } from '../../hooks/useContentSpecificAriaLabel';
import { StyledMediaTitle as MediaTitle } from '../../MediaTitle.jsx';
import { StyledContentStatusHeaderBadge as ContentStatusHeaderBadge } from '../../ContentStatusBadge.jsx';

const imageGalleryType = 'ImageGallery';
export const TextOverlay = ({
  className, title, teaser, priority,
}) => {
  const pageContext = usePageContext();
  const ariaLabel = useContentSpecificAriaLabel(imageGalleryType, title);
  return (
    <section className={className}>
      <div className='headline-container'>
        <div className='headline-wrapper'>
          <ContentStatusHeaderBadge content={{ priority }}/>
          <MediaTitle
            className="headline"
            isA={ pageContext.isInRichText ? 'h2' : 'h1'}
            aria-label={ariaLabel}
            contentType={imageGalleryType}
            title={title}
          />
        </div>
      </div>
      <div className='description-container'>
        <TeaserText className={cx('description', darkTeaserTextStyles)} isA={ pageContext.isInRichText ? 'h3' : 'h2'}>
          {teaser}
        </TeaserText>
      </div>
    </section>
  );
};

// TODO linaria-next css`` ??
export const bgHeadline = `
  height: 100%;
  ${metaGradient}
`;

export const StyledTextOverlay = styled(TextOverlay)`
  .headline-container, .description-container {
    & > * {
      ${contentAreaPaddingStyles}
    }
  }

  .headline-container, .description-container {
    ${pseudoAsBackground}
  }

  .headline-container:before {
    ${bgHeadline}
  }

  .description-container:before {
    height: calc(100% + 20px);
    ${metaBackground}
  }

  .headline-wrapper {
    margin-top: 10px;
    padding-top: 47px;
  }

  .description {
    margin-block: 10px;
  }

  ${mediaMin.sm`
    .headline-wrapper {
      padding-top: 60px;
    }
  `}

  ${mediaMin.md`
    .headline-wrapper {
      padding-top: 70px;
    }
  `}

  ${mediaMin.lg`
    .headline-wrapper {
      padding-top: 85px;
    }
  `}

  ${mediaMin.xl`
    .headline-container, .description-container {
      & > * {
        ${centeredContent}
      }
    }
  `}
`;
