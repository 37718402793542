import { styled } from '@linaria/react';
import { css, cx } from '@linaria/core';

import { socialMediaToIcon } from '../../utils/socialBookmarks';
import { colors, mediaDevice } from '../../utils/css';
import { focusTargetClassName } from '../FlyingFocus/constants';
import { ButtonWithIconOnly } from '../buttons/Button';
import { LinkBase } from '../Links/LinkBase';

export const cssVariables = {
  fill: '--social-media-icon-link-fill', // added for AMP compatibility
  hoverFill: '--social-media-service-hover-color',
  activeFill: '--social-media-icon-link-active-fill',
};

export const SocialMediaIconLink = ({
  socialMediaId,
  className,
  isHidden,
  title,
  url,
  ...restProps
}) => {
  const socialMediaIconDef = socialMediaToIcon[socialMediaId];
  if (!socialMediaIconDef) {
    return null;
  }
  const socialMediaIconColor = socialMediaIconDef.color;
  const SocialMediaIconTag = socialMediaIconDef.Tag;
  const propsBeingValidHtmlForNotLazy = isHidden ? { isHidden } : {};
  return (
    <ButtonWithIconOnly className={cx(className, socialMediaId)}
      style={{ [cssVariables.hoverFill]: socialMediaIconColor }}
      isA={LinkBase}
      to={url}
      title={title}
      role="link"
      {...restProps}
    >
      <SocialMediaIconTag {...propsBeingValidHtmlForNotLazy} />
    </ButtonWithIconOnly>
  );
};

export const darkSocialMediaIconLinkStyles = cx(
  ButtonWithIconOnly.darkStyles,
  css`${cssVariables.activeFill}: ${colors.DW_DARK_BLUE};`,
);

export const StyledSocialMediaIconLink = styled(SocialMediaIconLink)`
  ${mediaDevice.mouse`
    &:hover svg {
      fill: var(${cssVariables.hoverFill}, currentColor);
    }
  `}
  &&.${focusTargetClassName} svg {
    fill: var(${cssVariables.hoverFill}, currentColor);
  }

  &&& svg {
    width: 100%;
    path {
      stroke: none;
    }
  }

  &&&:active {
    svg {
      fill: var(${cssVariables.activeFill}, ${colors.WHITE});
    }
  }
`;
