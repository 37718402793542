import { colors, hexToRgba } from './css';

// TODO linaria-next: css`` classes
export const metaGradient = `background-image: linear-gradient(to bottom,rgba(0,0,0,0), ${hexToRgba(colors.DARK_BLUE_GREY_01, 0.9)} 90%);`;
export const metaBackground = `background-color: ${hexToRgba(colors.DARK_BLUE_GREY_01, 0.9)};`;
export const pseudoAsBackground = `
  position: relative;
  &:before {
    content: "";
    width: 100%;
    left: 0;
    top: 0;
    z-index: -1;
    position: absolute;
  }
`;
