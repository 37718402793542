import gql from 'graphql-tag';
import { cx } from '@linaria/core';

import {
  Partials, StyledContentTeaser as ContentTeaser, partialFragments, darkPartialStyles,
} from '../ContentTeaserPartials';
import { makeMemoFragment } from '../../../utils/graphql';

export const bigImageTextContentTeaserFragment = makeMemoFragment({
  name: 'BigImageTextContentTeaser',
  fragment() {
    return gql`fragment ${this.name} on Content {
      ... on UrlAspect {
        namedUrl
      }
      ... on NamedAspect {
        title
      }
      ...on TextualAspect {
        shortTeaser
        teaser
      }
      ...${partialFragments.teaserDataFragment.name}
      ...${partialFragments.contentStatusTeaserBadgeFragment.name}
      ...${partialFragments.imageWrapFragment.name}
      ...${partialFragments.linkedTitleFragment.name}
    }
    ${partialFragments.teaserDataFragment.fragment()}
    ${partialFragments.contentStatusTeaserBadgeFragment.fragment()}
    ${partialFragments.imageWrapFragment.fragment()}
    ${partialFragments.linkedTitleFragment.fragment()}
    `;
  },
});

export const BigImageTextContentTeaser = ({
  content = {}, className, titleIsA, hideDescription, hideKicker, hideTimeIndicator, isHidden, isPreloadImage,
}) => (
  <ContentTeaser
    className={cx(className, 'row')}
    content={content}
    isHidden={isHidden}
    hideDescription={hideDescription}
    hideKicker={hideKicker}
    hideTimeIndicator={hideTimeIndicator}
    isPreloadImage={isPreloadImage}
  >
    <Partials.LinkedImage />
    <Partials.MainContentArea>
      <Partials.Data>
        <Partials.ContentStatusTeaserBadge />
        <Partials.LinkedTitle isA={titleIsA} />
        <Partials.LinkedDescription/>
      </Partials.Data>
    </Partials.MainContentArea>
  </ContentTeaser>
);

export const darkBigImageTextContentTeaserStyles = cx(
  darkPartialStyles.darkLinkedTitleStyles,
  darkPartialStyles.darkLinkedDescriptionStyles,
  darkPartialStyles.darkTeaserDataStyles,
);

export const StyledBigImageTextContentTeaser = BigImageTextContentTeaser;
