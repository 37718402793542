import {
  isAudio, isValidList, isVideo, isChannel, isLivestream,
} from '../contentUtils';

const isVideoLinkPredicate = link => link.targetType === 'VIDEO';
const isAudioLinkPredicate = link => link.targetType === 'AUDIO';

const getLinksInText = content => (content.contentLinks || []).filter(link => link.isInText);

// embeddings tracking should ask for all data needed to detect players via THE FLAG
// so it should be safe to use it for other use cases as well, instead of Regex
// on rich text which will cost more time for sure.
export const getPlayerLinksInText = content => getLinksInText(content)
  .filter(link => isVideoLinkPredicate(link) || isAudioLinkPredicate(link));

const hasVideoPlaceholdersInRichText = content => isValidList(getLinksInText(content).filter(isVideoLinkPredicate));
const hasAudioPlaceholdersInRichText = content => isValidList(getLinksInText(content).filter(isAudioLinkPredicate));

export const hasVideoPlayer = content => (
  isVideo(content) || isChannel(content) || isLivestream(content) || hasVideoPlaceholdersInRichText(content)
);
export const hasAudioPlayer = content => isAudio(content) || hasAudioPlaceholdersInRichText(content);
