import gql from 'graphql-tag';
import { styled } from '@linaria/react';

import { makeMemoFragment } from '../../utils/graphql';
import { darkCopyButtonStyles, StyledCopyTextButton as CopyTextButton } from '../buttons/CopyTextButton.jsx';
import { StyledButtonBar as ButtonBar } from '../buttons/Button/ButtonBar.jsx';

export const liveblogPostActionsFragment = makeMemoFragment({
  name: 'LiveblogPostActions',
  fragment() {
    return gql`fragment ${this.name} on LiveblogElement {
        permaLinkUrl  
      }
    `;
  },
});

export const LiveblogPostActions = ({ className, content }) => {
  const { permaLinkUrl } = content;

  return (
    <ButtonBar align="start">
      <CopyTextButton
        className={className}
        textToCopy={permaLinkUrl}
      />
    </ButtonBar>
  );
};

export const darkLiveblogPostActionsStyles = darkCopyButtonStyles;

export const StyledLiveblogPostActions = styled(LiveblogPostActions)` 
  && {
    margin: 30px 0 20px 0;
  }
`;
