import { cx } from '@linaria/core';
import { darkContentBlockStyles } from '../ContentBlocks/ContentBlock';
import { darkPartialStyles } from '../ContentTeaserSnippets/ContentTeaserPartials';
import { GptSlot } from '../GptSlot';

import { withTheme } from '../higherOrderComponents/withTheme.jsx';
import { darkCoCoComponentBackgroundSelectorStyles, StyledCoCoComponentBackgroundSelector } from './CoCoComponentBackgroundSelector.jsx';
import { darkHubStyles } from '../ContentBlocks/Types/Hub.jsx';
import { darkExpandableStyles } from '../Expandable';

export const darkCoCoComponentBackgroundSelectorWithThemeStyles = cx(
  darkCoCoComponentBackgroundSelectorStyles,
  Object.values(darkPartialStyles).join(' '),
  darkContentBlockStyles,
  darkHubStyles,
  GptSlot.darkStyles,
  darkExpandableStyles,
);

export const CoCoComponentBackgroundSelectorWithTheme = withTheme(StyledCoCoComponentBackgroundSelector)(
  darkCoCoComponentBackgroundSelectorWithThemeStyles,
);
