import { useEffect, useState } from 'react';

import { loadTwitterApi } from './api';

export const useLoadTwitterApiEffect = ({ inView }) => {
  const [twitterApi, setTwitterApi] = useState({ twttr: null });

  useEffect(() => {
    const loadAndSaveTwitterApi = async () => {
      const { twttr } = await loadTwitterApi();
      setTwitterApi({ twttr });
    };
    if (inView && !twitterApi.twttr) {
      loadAndSaveTwitterApi();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView]);

  return twitterApi;
};
