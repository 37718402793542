import { useState, useEffect } from 'react';
import { useLocationQueryParam } from '../useLocationQueryParam';
import { useSessionStorage } from '../useSessionStorage';

export const useFeatureFlag = ({ name = 'next-features', initialValue = false } = {}) => {
  const featureQueryParam = useLocationQueryParam(name);
  const [value, setValue] = useSessionStorage(name, initialValue);
  const [isFeatureActive, setIsActive] = useState(initialValue);

  useEffect(() => {
    if (featureQueryParam === '1') {
      setValue(true);
    } else if (featureQueryParam === '0') {
      setValue(false);
    }
    setIsActive(featureQueryParam === '1' || value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [featureQueryParam, value]);

  return isFeatureActive;
};
