import gql from 'graphql-tag';
import { cx } from '@linaria/core';

import { getAutoDeliveredColumnOpinionSeries } from '../../../utils/metaDataHelpers';
import { makeMemoFragment } from '../../../utils/graphql';
import { darkMainTopicStyles, mainTopicFragment, StyledMainTopic as MainTopic } from '../TopicCluster/MainTopic.jsx';
import { darkContentBlockStyles, StyledContentBlock as ContentBlock } from '../ContentBlock';
import { useTranslation } from '../../hooks/useTranslation.jsx';

export const columnInfoFragment = makeMemoFragment({
  name: 'ColumnInfo',
  fragment() {
    return gql`fragment ${this.name} on Article {
        ... on MetadataAspect {
          genre
        }
        ... on OpinionAspect {
          isOpinion
        }
        ... on AssociationsAspect {
          opinionSeries {
            genre
            name
            teaser
            autoDelivery
            ...${mainTopicFragment.name}
          }
        }
      }
      ${mainTopicFragment.fragment()}
    `;
  },
});

export const ColumnInfo = ({ className, content }) => {
  const columnSeries = getAutoDeliveredColumnOpinionSeries(content);

  const aboutColumnTitleTranslation = useTranslation({
    key: 'content_block_detail.column_info.headline.label',
    parameters: { seriesTitle: columnSeries?.name },
  });

  if (!content.isOpinion || !columnSeries) {
    return null;
  }
  return (
    <ContentBlock
      className={className}
      headlineIsA='h2'
      headlineTranslation='content_block_detail.column_info.headline.content_block'
    >
      <MainTopic
        content={columnSeries}
        topicTitleSelector={c => c.name}
        topicTeaserSelector={c => c.teaser}
        aboutTopicTitleTranslation={aboutColumnTitleTranslation}
      />
    </ContentBlock>
  );
};

export const darkColumnInfoStyles = cx(
  darkContentBlockStyles,
  darkMainTopicStyles,
);

export const StyledColumnInfo = ColumnInfo;
