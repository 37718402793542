import { useRef } from 'react';
import { useInView } from 'react-intersection-observer';

import { getFormatForWidth, isInternalDwImageUrl } from '../../utils/imgUtils';
import { INTERSECTION_OBSERVER_SETTINGS } from '../LazyLoadDwPicture.jsx';
import { alwaysLandscapePictureConfig } from '../ResponsiveDwPicture/pictureConfigs';
import { useFrontendConfig } from '../FrontendConfigContext.jsx';
import { useWindowInnerWidthContext } from '../WindowInnerWidthContext';

export const DW_IMAGE_SUFFIX_REGEX = /_605.(webp)$/;

export const replaceImageFormatInPosterImageUrl = (imageUrl, imgFormat) => imageUrl.replace(DW_IMAGE_SUFFIX_REGEX, `_${imgFormat.id}.$1`);

const getPosterImageUrl = ({
  content, inView, playerWidth, innerWidth, staticBaseHost,
}) => {
  if (!inView || !content.posterImageUrl) {
    return '';
  }
  const { posterImageUrl } = content;
  if (!isInternalDwImageUrl({ imageUrl: posterImageUrl, staticBaseHost })) {
    return posterImageUrl;
  }

  const imgFormat = getFormatForWidth({
    innerWidth,
    imgWidth: playerWidth,
    formatConfig: alwaysLandscapePictureConfig,
  });

  return replaceImageFormatInPosterImageUrl(content.posterImageUrl, imgFormat);
};

export const useLazyPosterImage = ({ content }) => {
  const sizeRef = useRef();
  const playerWidth = sizeRef?.current?.clientWidth;
  const innerWidth = useWindowInnerWidthContext();
  const [inViewRef, inView] = useInView(INTERSECTION_OBSERVER_SETTINGS);
  const { staticBaseHost } = useFrontendConfig();

  return {
    sizeRef,
    inViewRef,
    backgroundImageUrl: getPosterImageUrl({
      content, inView, playerWidth, innerWidth, staticBaseHost,
    }),
  };
};
