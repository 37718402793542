import { useTranslation } from './useTranslation.jsx';
import { usePageContext } from '../PageEmbeddingContext.jsx';
import { ContentType } from '../../utils/contentUtils';

const typeToi18nAttribute = {
  [ContentType.Audio]: 'audio_player',
  [ContentType.Video]: 'video_player',
  [ContentType.ImageGallery]: 'slideshow',
  [ContentType.Livestream]: 'video_player',
};

export const useContentSpecificAriaLabel = (contentType, title) => {
  const pageContext = usePageContext();
  const translatedContentTypePrefix = useTranslation(`component.${typeToi18nAttribute[contentType]}.title_prefix.${pageContext.isInRichText ? 'embedded' : 'regular'}`);
  return Object.keys(typeToi18nAttribute).includes(contentType) ? `${translatedContentTypePrefix} — ${title}` : undefined;
};
