import gql from 'graphql-tag';

import { ButtonTertiary } from './buttons/Button';
import { LinkBase } from './Links/LinkBase';
import { makeMemoFragment } from '../utils/graphql';

export const autoTopicLinkFragment = makeMemoFragment({
  name: 'AutoTopicLink',
  fragment() {
    return gql`fragment ${this.name} on AutoTopic {
      namedUrl
      name
    }
    `;
  },
});

export const AutoTopicLink = ({ autoTopic, className }) => (
  <ButtonTertiary
    isA={LinkBase}
    to={autoTopic.namedUrl}
    title={autoTopic.name}
    skipTitleForInternal
    className={className}
  >
    {autoTopic.name}
  </ButtonTertiary>
);

export const StyledAutoTopicLink = AutoTopicLink;

StyledAutoTopicLink.darkStyles = ButtonTertiary.darkStyles;
