import gql from 'graphql-tag';
import { makeMemoFragment } from '../../../utils/graphql';

import {
  listDefaultHighlightSmallImageTextFragment,
  StyledListDefaultHighlightSmallImageText as ListDefaultHighlightSmallImageTextTemplate,
} from '../../ContentTeaserSnippets/Templates/ListDefaultHighlightSmallImageText.jsx';

export const manualContentListFragment = listDefaultHighlightSmallImageTextFragment;

export const manualContentListConfigFragment = makeMemoFragment({
  name: 'ManualContentListConfig',
  fragment() {
    return gql`fragment ${this.name} on CompositionComponent {
        configuration {
          contentBlockHeadline
        }
      }
    `;
  },
});

export const ManualContentList = ({
  configuration = {},
  headlineTranslation,
  ...restProps
}) => {
  const { contentBlockHeadline } = configuration;
  return (
    <ListDefaultHighlightSmallImageTextTemplate {...restProps}
      headlineTranslation={contentBlockHeadline || headlineTranslation}
    />
  );
};

export const StyledManualContentList = ManualContentList;
