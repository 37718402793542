import { css } from '@linaria/core';
import { mediaMin } from '../../../utils/css';
import { makeLinkColorOnActive, makeLinkColorOnHover } from '../../ContentTeaserSnippets/ContentTeaserPartials/Partials/commons';

export const extendContentTeaserWithCommonMoreOnTopStoryLooks = css`
  display: flex;

  .teaser-data-wrap {
    flex: initial;
    display: flex;
    flex-direction: column;
    padding: 0;
  }

  .title {
    font-size: 1.8rem;
  }

  .teaser-footer-wrapper {
    margin-top: 10px;
  }

  ${mediaMin.md`
    .title {
      font-size: 2rem;
    }
  `};

  ${mediaMin.xl`
    .title {
      font-size: 2.4rem;
    }
  `};
`;

export const extendSmallImageContentTeaserWithMoreOnTopStoryLooks = css`
  .teaser-image-wrap {
    max-width: 50px;
    padding: 0;
    margin-inline-end: 15px;
  }

  ${mediaMin.sm`
    .teaser-image-wrap {
      max-width: 75px;
    }
  `};

  ${mediaMin.md`
    .teaser-image-wrap {
      max-width: 90px;
    }
  `};

  ${mediaMin.lg`
    .teaser-image-wrap {
      max-width: 130px;
    }
  `};

  ${mediaMin.xl`
    .teaser-image-wrap {
      max-width: 160px;
    }
  `};
`;

export const extendSmallImageOpinionContentTeaserWithMoreOnTopStoryLooks = css`
  .author-data {
    .author-link, .no-link {
      font-size: 1.3rem;
      margin-top: 10px;
      display: inline-block;
    }
  }

  ${makeLinkColorOnHover({ linkCssSelector: '.author-image:hover + * .author-data .author-link' })}
  ${makeLinkColorOnActive({ linkCssSelector: '.author-image:active + * .author-data .author-link' })}

  .author-image {
    display: block;
    max-width: 50px;
    max-height: 50px;
    padding: 0;
    width: 100%;
    height: auto;
    margin-inline-end: 15px;
  }

  ${mediaMin.sm`
    .author-image {
      max-width: 75px;
      max-height: 75px
    }
  `};

  ${mediaMin.md`
    .author-image {
      max-width: 90px;
      max-height: 90px;
    }
  `};

  ${mediaMin.lg`
    .author-image {
      max-width: 80px;
      max-height: 80px;
    }
  `};

  ${mediaMin.xl`
    .author-image {
      max-width: 98px;
      max-height: 98px;
    }
  `};
`;
