import gql from 'graphql-tag';
import { cx } from '@linaria/core';
import { isValidList } from '../../../utils/contentUtils';
import { StyledContentBlock as ContentBlock, darkContentBlockStyles } from '../../ContentBlocks/ContentBlock';
import { StyledExpandable as Expandable, darkExpandableStyles } from '../../Expandable';
import { StyledFocusTopThreeLayout as FocusTopThreeLayout } from '../Layouts/FocusTopThreeLayout';

import { makeMemoFragment } from '../../../utils/graphql';
import { expandAdapterFragment, darkExpandAdapterStyles, expandAdapter } from './expandAdapter';

export const expandableFocusTopThreeUnifiedBigImageTextFragment = makeMemoFragment({
  name: 'ExpandableFocusTopThreeUnifiedBigImageText',
  fragment() {
    return gql`fragment ${this.name} on Content {
        ... on ModelAspect {
          id
        }
        ...${expandAdapterFragment.name}
      }
      ${expandAdapterFragment.fragment()}
    `;
  },
});

const INITIAL_TEASER_AMOUNT = 3;

export const ExpandableFocusTopThreeUnifiedBigImageText = ({
  className,
  contents,
  pageSectionId,
  headlineTranslation,
  showMoreBtnTranslation,
  theme,
  initialTeaserCount = INITIAL_TEASER_AMOUNT,
}) => {
  if (!isValidList(contents)) {
    return null;
  }
  return (
    <ContentBlock
      className={className}
      theme={theme}
      headlineIsA='h2'
      headlineTranslation={headlineTranslation}
      pageSectionId={pageSectionId}
    >
      <Expandable
        dataArray={contents}
        initialListCount={initialTeaserCount}
        showMoreBtnTranslation={showMoreBtnTranslation}
      >
        {(...expandableData) => (
          <FocusTopThreeLayout>
            {expandAdapter(...expandableData)}
          </FocusTopThreeLayout>
        )}
      </Expandable>
    </ContentBlock>
  );
};

export const darkExpandableFocusTopThreeUnifiedBigImageTextStyles = cx(
  darkContentBlockStyles,
  darkExpandAdapterStyles,
  darkExpandableStyles,
);

export const StyledExpandableFocusTopThreeUnifiedBigImageText = ExpandableFocusTopThreeUnifiedBigImageText;
