import gql from 'graphql-tag';
import { styled } from '@linaria/react';
import classnames from 'classnames';

import { useParams } from '../../../dwouter';
import { useTranslation } from '../../hooks/useTranslation.jsx';
import { mediaMin } from '../../../utils/css';
import { HeadlineWideRegularDarkBlueGrey02AndBlueGrey01 } from '../../Text/Headline.jsx';

import { toIso639LangCode, toLang, toLangCode } from '../../../utils/langMapper';
import { StyledLanguageToggleButton as LanguageToggleButton } from './LanguageToggleButton.jsx';
import { StyledVerticalColumnsList as VerticalColumnsList } from '../../VerticalColumnsList';
import { StyledNavigationSpacer as NavigationSpacer } from '../NavigationSpacer.jsx';

import languageNameTranslations from './languages.json';
import { makeMemoFragment } from '../../../utils/graphql';
import { headerHeight } from '../../Header/headerUtils';
import { useHeaderStateContext } from '../../Header/HeaderStateContext';
import { StyledLanguageSelectorItem as LanguageSelectorItem, languageSelectorItemFragment } from './LanguageSelectorItem.jsx';
import { ids } from '../../../utils/staticElementIds';
import { StyledButtonBar as ButtonBar } from '../../buttons/Button/ButtonBar.jsx';

export const languageSelectorFragmentFactory = ({ isContent = true } = {}) => makeMemoFragment(({
  name: `LanguageSelector${isContent ? '' : 'OnQuery'}`,
  fragment() {
    return gql`fragment ${this.name} on ${isContent ? 'AssociationsAspect' : 'Query'} {
        topStoriesNavigations(displayChannel: DESKTOP) {
          ...${languageSelectorItemFragment.name}
        }
      }
      ${languageSelectorItemFragment.fragment()}
      `;
  },
}));

export const LanguageSelector = ({
  className,
  content,
  hiddenClassName,
  onClick,
}) => {
  const { isLanguageToggled, isFocusMenuVisible } = useHeaderStateContext();
  const { langCode } = useParams();
  const iso639LangCode = toIso639LangCode(langCode);
  const currentLanguage = toLang(langCode);

  const activeNav = content?.topStoriesNavigations?.find(({ language }) => language === currentLanguage);
  const { language } = activeNav || {};
  const activeLangCode = toLangCode(language);
  const selectorHeadline = useTranslation('component.navigation.language_selector.headline');
  const buttonTitle = useTranslation(`component.navigation.language_selector.toggle_button.label.${isLanguageToggled ? 'close' : 'open'}`);

  return (
    <section className={classnames(className, { 'is-open': isLanguageToggled, 'focus-menu-shown': isFocusMenuVisible })}>
      <NavigationSpacer className="language-wrapper">
        <HeadlineWideRegularDarkBlueGrey02AndBlueGrey01 isA="h2">{selectorHeadline}</HeadlineWideRegularDarkBlueGrey02AndBlueGrey01>
        <ButtonBar align="start">
          <LanguageToggleButton
            title={buttonTitle}
            onClick={onClick}
            className="lang-toggle-button"
            id={ids.languageSelectorToggle}
          >
            <span>{iso639LangCode}</span>
            {languageNameTranslations.native[activeLangCode]}
          </LanguageToggleButton>
        </ButtonBar>
      </NavigationSpacer>
      <NavigationSpacer dir="ltr" className="language-list">
        <VerticalColumnsList className={classnames({ [hiddenClassName]: !isLanguageToggled })}>
          {content?.topStoriesNavigations?.map(topStoryNavigation => (
            <LanguageSelectorItem
              key={topStoryNavigation.language}
              pageLanguage={language}
              topStoryNavigation={topStoryNavigation}
            />
          ))}
        </VerticalColumnsList>
      </NavigationSpacer>
    </section>
  );
};
// TODO linaria-next: css``
export const languageSelectorBaseCss = `
  &.is-open {
    .language-wrapper {
      position: fixed;
      top: calc(${headerHeight.xs.thin}px + 1px);
      inset-inline: 0;
    }

    &.focus-menu-shown {
      .language-wrapper {
        position: fixed;
        top: calc(${headerHeight.xs.wide}px + 1px);
      }
    }
  }

  .language-wrapper {
    padding-top: 20px;
    padding-bottom: 20px;

    h2 {
      font-size: 1.2rem;
      margin-bottom: 5px;
    }
  }
  .language-list {
    ul {
      padding-top: 20px;
      padding-bottom: 5px;
    }
  }

  ${mediaMin.md`
    .language-wrapper {
      padding-top: 25px;
      padding-bottom: 25px;
    }

    .language-list ul {
      padding-top: 25px;
      padding-bottom: 10px;
    }
  `}
`;

export const StyledLanguageSelector = styled(LanguageSelector)`
  ${languageSelectorBaseCss}

  ${mediaMin.md`
    &.is-open,
     &.is-open.focus-menu-shown {
      .language-wrapper {
        top: calc(${headerHeight.md}px + 1px);
      }
    }
  `}

  ${mediaMin.lg`
    .language-list ul {
      padding-top: 30px;
      padding-bottom: 15px;
    }

    &.is-open,
     &.is-open.focus-menu-shown {
      .language-wrapper {
        top: calc(${headerHeight.lg}px + 1px);
      }
    }

    .language-wrapper {
      padding-top: 30px;
      padding-bottom: 30px;
    }
  `}
`;
