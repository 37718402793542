import gql from 'graphql-tag';
import { styled } from '@linaria/react';
import { cx } from '@linaria/core';

import { selector } from '../../../utils/css';
import { InformationDefault } from '../../Text/Information.jsx';
import { StyledScreenReaderOnly as ScreenReaderOnly } from '../../ContentDetailHeader/ScreenReaderOnly.jsx';
import { useCurrentDay } from '../../RunningLivestreamContext.jsx';
import { makeMemoFragment } from '../../../utils/graphql';

export const livestreamScheduleCaptionTodayFragment = makeMemoFragment({
  name: 'LivestreamScheduleCaptionToday',
  fragment() {
    return gql`fragment ${this.name} on DayDate {
      iso
      localized
      localizedSr
      dayName
    }`;
  },
});

export const LivestreamScheduleCaption = ({ className, id }) => {
  const currentDateDay = useCurrentDay();
  return (
    <div id={id} className={className}>
      <span className={cx('current-date', InformationDefault.darkStyles)} aria-hidden="true">
        <InformationDefault className="weekday">{currentDateDay.dayName}</InformationDefault>
        <InformationDefault isA="time">{currentDateDay.localized}</InformationDefault>
      </span>
      <ScreenReaderOnly>{currentDateDay.localizedSr}</ScreenReaderOnly>
    </div>
  );
};

export const StyledLivestreamScheduleCaption = styled(LivestreamScheduleCaption)`
  font-size: 1.1rem;

  .current-date {
    time {
      letter-spacing: 0.085rem;
    }

    & > *${selector.notLast} {
      &:after {
        content: '\u00A0|\u00A0';
        letter-spacing: 0.08rem;
        margin: 0 2px;
      }
    }
  }
`;
