import { styled } from '@linaria/react';
import { useContentTeaserContext } from '../ContentTeaserContext';
import { StyledContentStatusBadge as ContentStatusBadge, contentStatusBadgeFragment } from '../../../ContentStatusBadge.jsx';
import { mediaMin } from '../../../../utils/css';

export const contentStatusTeaserBadgeFragment = contentStatusBadgeFragment;

export const ContentStatusTeaserBadge = ({ className }) => {
  const { content } = useContentTeaserContext();
  return (
    <ContentStatusBadge className={className} content={content}/>
  );
};

export const StyledContentStatusTeaserBadge = styled(ContentStatusTeaserBadge)`
  margin-bottom: 5px;

  ${mediaMin.md`
    margin-bottom: 10px;
  `};
`;
