/* eslint-disable no-restricted-globals */
import { isServer } from './ssr';

const globals = {
  window: isServer() ? global.window || {} : window,
  document: isServer() ? {} : document,
  navigator: isServer() ? {} : navigator,
  history: isServer() ? {} : history,
  Date,
  localStorage: isServer() ? {} : window.localStorage,
  sessionStorage: isServer() ? {} : window.sessionStorage,
  console,
  Image: isServer() ? function Image() {} : Image,
  MutationObserver: isServer() ? {} : MutationObserver,
};

export default globals;
