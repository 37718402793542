import { resolutions } from '../../utils/css';

const belowResolution = slotConfigEntry => ({
  minWidth: resolutions.min.xs,
  maxWidth: slotConfigEntry.minWidth - 1,
});

const aboveResolution = slotConfigEntry => ({
  minWidth: slotConfigEntry.maxWidth + 1,
  maxWidth: resolutions.max.wide_xl,
});

export const getRanges = slotConfigEntry => {
  if (slotConfigEntry.minWidth === resolutions.min.xs) {
    return [
      slotConfigEntry,
      aboveResolution(slotConfigEntry),
    ];
  }
  if (slotConfigEntry.maxWidth === resolutions.max.wide_xl) {
    return [
      belowResolution(slotConfigEntry),
      slotConfigEntry,
    ];
  }
  return [
    belowResolution(slotConfigEntry),
    slotConfigEntry,
    aboveResolution(slotConfigEntry),
  ];
};

const toSlotDimsArray = size => [size.width, size.height];

export const toMultiSizeArray = slotSize => (
  Array.isArray(slotSize) ? slotSize.map(toSlotDimsArray) : toSlotDimsArray(slotSize)
);

export const getElementId = slotConfigEntry => slotConfigEntry.adUnitCode.toLowerCase().replace(/\//g, '-');
