import gql from 'graphql-tag';
import { cx, css } from '@linaria/core';
import { makeMemoFragment } from '../../utils/graphql';
import { StyledTimeAreaEmphasized as TimeAreaEmphasized, timeAreaFragment } from '../ContentDetailHeader/TimeArea.jsx';
import { authorDetailsFragment, StyledAuthorDetails as AuthorDetails, darkAuthorDetailsStyles } from '../ContentDetailHeader/AuthorDetails.jsx';

import { HeadlineSemiBoldDarkBlueGrey02AndBlueGrey01 } from '../Text/Headline.jsx';
import { richTextHeadlineCss } from '../ContentDetailRichText/ContentDetailRichText.jsx';
import { extendWithClassNames } from '../../utils/extendWithClassNames.jsx';

export const liveblogPostHeaderFragment = makeMemoFragment({
  name: 'LiveblogPostHeader',
  fragment() {
    return gql`fragment ${this.name} on LiveblogElement {
          title
          ...${timeAreaFragment.name}
          ...${authorDetailsFragment.name}
        }
        ${timeAreaFragment.fragment()}
        ${authorDetailsFragment.fragment()}
    `;
  },
});

export const LiveblogPostHeader = ({ content, className }) => (
  <div className={className}>
    <TimeAreaEmphasized className="time-area" content={content}/>
    <HeadlineSemiBoldDarkBlueGrey02AndBlueGrey01 isA="h2">{content.title}</HeadlineSemiBoldDarkBlueGrey02AndBlueGrey01>
    <AuthorDetails content={content} className="author-details" />
  </div>
);

export const darkLiveblogPostHeaderStyles = cx(
  HeadlineSemiBoldDarkBlueGrey02AndBlueGrey01.darkStyles,
  TimeAreaEmphasized.darkStyles,
  darkAuthorDetailsStyles,
);

export const liveblogPostHeaderStyles = css`
  h2 {
    ${richTextHeadlineCss}
    margin-bottom: 0;
    margin-top: 10px;
  }

  .time-area {
    & > * {
      font-size: 1.6rem;
    }
  }

  .author-details {
    margin-top: 15px;
  }
`;

export const StyledLiveblogPostHeader = extendWithClassNames(LiveblogPostHeader)(
  liveblogPostHeaderStyles,
);

StyledLiveblogPostHeader.darkStyles = darkLiveblogPostHeaderStyles;
