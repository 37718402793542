import { css, cx } from '@linaria/core';
import gql from 'graphql-tag';

import { urlHostNameFromString } from '../../../../utils/url';
import { StyledExternalLink as ExternalLinkIcon } from '../../../icons';
import { darkPartialStyles, Partials, StyledContentTeaser as ContentTeaser } from '../../../ContentTeaserSnippets/ContentTeaserPartials';
import { LinkDefault } from '../../../Links';
import { makeMemoFragment } from '../../../../utils/graphql';

export const externalLinksFragment = makeMemoFragment({
  name: 'ExternalLinks',
  fragment() {
    return gql`fragment ${this.name} on AssociationsAspect {
      externalLinks {
        name
        description
        url
      }
    }`;
  },
});

export const ExternalLinksTeaser = ({ link = {}, className }) => {
  const { url } = link;

  return (
    <ContentTeaser
      className={cx(className, 'row')}
      link={link}
    >
      <Partials.MainContentArea>
        <div className="teaser-data-area">
          <Partials.LinkedTitle isA='h3'/>
          <Partials.LinkedDescription className='d-none d-md-block'/>
          <LinkDefault title={link.name} className={customLinkStyles} to={url} tabIndex={-1}>
            {urlHostNameFromString({ url })}
            <ExternalLinkIcon/>
          </LinkDefault>
        </div>
      </Partials.MainContentArea>
    </ContentTeaser>
  );
};

export const darkExternalLinksTeaserStyles = cx(
  darkPartialStyles.darkLinkedTitleStyles,
  darkPartialStyles.darkLinkedDescriptionStyles,
  LinkDefault.darkStyles,
);

const customLinkStyles = css`
  font-size: 1.2rem;
    display: flex;
    align-items: center;
    padding-top: 20px;
    column-gap: 5px;

    svg {
      width: 13px;
    }
`;

export const StyledExternalLinkContentTeaser = ExternalLinksTeaser;
