import { cx } from '@linaria/core';
import { useTranslation } from '../hooks/useTranslation.jsx';
import { LinkMonoColor } from '../Links';

export const LearnGermanTranslatedLink = ({ learngermanUrl, className }) => {
  const label = useTranslation('component.navigation.more_products.learngerman.label');
  const itemLinkTranslation = useTranslation('component.navigation.more_products.learngerman.itemLink');

  return (
    <LinkMonoColor title={label} to={learngermanUrl} rel='noopener noreferrer' className={cx(className, LinkMonoColor.darkStyles)}>
      {itemLinkTranslation}
    </LinkMonoColor>
  );
};

export const StyledLearnGermanTranslatedLink = LearnGermanTranslatedLink;
