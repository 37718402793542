import {
  createContext, useContext,
} from 'react';
import { useWindowInnerWidth, getWidth } from './useWindowInnerWidth';

export const WindowInnerWidthContext = createContext(getWidth() || 0);

export const useWindowInnerWidthContext = () => useContext(WindowInnerWidthContext);

export const WindowInnerWidthContextProvider = ({
  children,
}) => {
  const innerWidth = useWindowInnerWidth();

  return (
    <WindowInnerWidthContext.Provider value={innerWidth}>
      {children}
    </WindowInnerWidthContext.Provider>
  );
};
