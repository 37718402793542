export function isServer() {
  return !(typeof window !== 'undefined' && window.document);
}

export const commaListToArray = str => {
  if (!str) {
    return [];
  }
  return str.split(',').map(s => s.trim());
};
