import { useState } from 'react';

export const useToggle = ({ initialState = false, key = 'Enter' } = {}) => {
  const [isToggled, setToggled] = useState(initialState);

  const toggleOnClick = () => {
    const newValue = !isToggled;
    setToggled(newValue);
    return newValue;
  };

  const toggleOnKey = event => {
    if (event.key === key) {
      setToggled(!isToggled);
    }
  };

  return [isToggled, { toggleOnClick, toggleOnKey }];
};
