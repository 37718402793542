import { css, cx } from '@linaria/core';
import { useParams } from '../../dwouter';
import { useTranslation } from '../hooks/useTranslation.jsx';
import { LinkBase } from '../Links/LinkBase';
import { ChoiceChip } from '../ChoiceChip.jsx';

export const AzIndexTopicGroupLink = ({ group, className }) => {
  const { selectedTopicGroupId } = useParams();
  const isSelected = group.id === selectedTopicGroupId;
  const ariaLabel = useTranslation({
    key: isSelected ? 'content_block.topic_group_selector.current_page.label' : 'content_block.topic_group_selector.page.label',
    parameters: {
      topicGroupLabel: group.label.toUpperCase(),
    },
  });
  return (
    <ChoiceChip isA={LinkBase} className={cx(azIndexTopicGroupLinkClass, className)} isSelected={isSelected} aria-label={ariaLabel} to={group.url} aria-current={isSelected ? 'page' : undefined}>
      {group.label}
    </ChoiceChip>
  );
};

AzIndexTopicGroupLink.darkStyles = ChoiceChip.darkStyles;

export const azIndexTopicGroupLinkClass = css`
  text-transform: uppercase;
`;
