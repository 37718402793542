import gql from 'graphql-tag';

import { Redirect, useParams, useLocation } from '../../../dwouter';
import { isPathMatching } from '../PathAnalyser';
import { NotFoundPage } from '../../../pages/NotFoundPage.jsx';
import { makeMemoFragment } from '../../../utils/graphql';
import { LeadingPageContentContextProvider } from '../../LeadingPageContentContext.jsx';
import { contentLangBaseFn, PageWithData, pageWithDataFragment } from '../../../pages/PageWithData.jsx';
import { ExternalRedirect } from '../../ExternalRedirect.jsx';
import { useIsMountedFn } from '../../hooks/useIsMountedFn';

export const withGraphQLContentHandlingFragment = makeMemoFragment({
  name: 'WithGraphQLContentHandling',
  fragment() {
    return gql`fragment ${this.name} on Content {
      ... on ModelAspect {
        id
      }
      ... on UrlAspect {
        namedUrl
        canonicalUrl
      }
      ... on DeliveryAspect {
        subdomain
        subdomainUrl
      }
      ...${pageWithDataFragment.name}
    }
    ${pageWithDataFragment.fragment()}
    `;
  },
});

// @VisibleForStorybook
export const contentQueryFactory = ({
  name,
  fragmentDef,
}) => gql`
  query ${name}($id: Int!, $lang: Language) {
    content(id: $id, lang: $lang) {
      ...${withGraphQLContentHandlingFragment.name}
      ...${fragmentDef.name}
    }
  }
  ${withGraphQLContentHandlingFragment.fragment()}
  ${fragmentDef.fragment()}
`;

const externalDomains = ['CORPORATE', 'SERVICE', 'ACADEMY', 'LEARN_GERMAN'];

const shouldDoExternalRedirect = subdomain => (externalDomains.includes(subdomain));

export const WithGraphQLContentHandling = ({
  children,
  queryDef,
}) => {
  const [{ pathname, search }] = useLocation();
  const { pageNumber } = useParams();
  const isMountedFn = useIsMountedFn();

  return (
    <PageWithData queryDef={queryDef} langBaseContentFn={contentLangBaseFn}>
      {({ content, ...restProps }) => {
        if (!content || !content.namedUrl) {
          return <NotFoundPage />;
        }
        const {
          namedUrl, subdomain, canonicalUrl,
        } = content;

        if (shouldDoExternalRedirect(subdomain)) {
          return <ExternalRedirect to={{ pathname: canonicalUrl, search }}/>;
        }
        if (!isMountedFn() && !isPathMatching({ namedUrl, pathname })) {
          const redirectPathname = namedUrl.concat(pageNumber ? `/page-${pageNumber}` : '');
          return <Redirect to={{ pathname: redirectPathname, search }} />;
        }

        return (
          <LeadingPageContentContextProvider content={content}>
            {children({ content, ...restProps })}
          </LeadingPageContentContextProvider>
        );
      }}
    </PageWithData>
  );
};
