import gql from 'graphql-tag';
import { memo } from 'react';
import { cx } from '@linaria/core';

import { useParams } from '../dwouter';
import { StyledHeader as Header, headerFragmentFactory } from '../components/Header';
import { darkContentPageLayoutStyles, ContentPageLayout } from '../components/layouts/ContentPageLayout.jsx';
import { StyledFooter as Footer, footerFragmentFactory } from '../components/Footer';
import { StyledQuickNavigationList as QuickNavigationList } from '../components/a11y/QuickNavigationList.jsx';
import { TopStoryZone } from '../components/zones/TopStoryZone/index.jsx';
import {
  livestreamActionsFragment, livestreamDetailsFragment, livestreamDetailsTodayFragment, StyledLivestreamDetails as LivestreamDetails,
} from '../components/LivestreamDetails.jsx';
import { OnDetailPageContext } from '../components/PageEmbeddingContext.jsx';
import { BrowserOnlyBreakingBanner } from '../components/BreakingBanner';
import { StyledContentDetailActions as ContentDetailActions } from '../components/ContentDetailActions';
import { PageWithData, pageWithDataFragment } from './PageWithData.jsx';
import { PageHeaderMetadata, pageHeaderMetadataFragment } from '../components/PageHeaderMetadata.jsx';
import { ImageSharingMetadata, imageSharingMetadataFragment } from '../components/ImageSharingMetadata.jsx';
import { StyledAllProgramsTeaser as AllProgramsTeaser, allProgramsTeaserFragment } from '../components/ContentBlocks/AllProgramsTeaser';
import { withTheme } from '../components/higherOrderComponents/withTheme.jsx';
import { darkCoCoComponentBackgroundSelectorStyles } from '../components/CoCo/CoCoComponentBackgroundSelector.jsx';

export const livestreamQuery = () => {
  const headerFragment = headerFragmentFactory();
  const footerFragment = footerFragmentFactory();
  return gql`
  query livestreamData($lang: Language!, $livestreamChannel: ChannelNames!) {
    livestreamChannels (channelNames: [$livestreamChannel]) {
      id
      name
      hlsVideoSrc: livestreamUrl
     ...${livestreamDetailsFragment.name}
     ...${livestreamActionsFragment.name}
     ...${pageHeaderMetadataFragment.name}
     ...${imageSharingMetadataFragment.name}
     ...${headerFragment.name}
     ...${footerFragment.name}
     ...${pageWithDataFragment.name}
    }
    today(lang: $lang) {
      ...${livestreamDetailsTodayFragment.name}
    }
    programsOverview(lang: $lang) {
        ...${allProgramsTeaserFragment.name}
      }
  }
  ${pageWithDataFragment.fragment()}
  ${headerFragment.fragment()}
  ${footerFragment.fragment()}
  ${livestreamActionsFragment.fragment()}
  ${livestreamDetailsFragment.fragment()}
  ${livestreamDetailsTodayFragment.fragment()}
  ${pageHeaderMetadataFragment.fragment()}
  ${imageSharingMetadataFragment.fragment()}
  ${allProgramsTeaserFragment.fragment()}
`;
};

const TopStoryZoneMemo = memo(TopStoryZone);

export const LivestreamPageNoQuery = ({
  livestreamChannels, today, className, programsOverview,
}) => {
  const [channel] = livestreamChannels;

  return (
    <OnDetailPageContext>
      <QuickNavigationList content={channel} />
      <PageHeaderMetadata content={channel} />
      <ImageSharingMetadata content={channel} />
      <div className={className}>
        <Header content={channel}>
          {({ isHeaderInWhiteState }) => (isHeaderInWhiteState ? (
            <ContentDetailActions
              content={channel}
              isInHeader
            />
          ) : null
          )}
        </Header>
        <BrowserOnlyBreakingBanner />
        <ContentPageLayout
          DetailZoneFn={
            () => <LivestreamDetails
              channel={channel}
              today={today}
            />
          }
          AlternatingColorZoneConfig={{
            compFn: () => <>
              <AllProgramsTeaser
                className={cx(darkContentPageLayoutStyles, darkCoCoComponentBackgroundSelectorStyles)}
                programsOverview={programsOverview}
              />
              <TopStoryZoneMemo content={channel} />
            </>,
          }}
        />
        <Footer content={channel} />
      </div>
    </OnDetailPageContext>
  );
};

export const LivestreamPageNoQueryWithTheme = withTheme(LivestreamPageNoQuery)(darkContentPageLayoutStyles);

export const LivestreamPage = () => {
  const { langCode, channel } = useParams();

  const queryDef = {
    path: `${langCode}/livestream/${channel}`,
    depts: [langCode, channel],
  };

  return (
    <PageWithData queryDef={queryDef} langBaseContentFn={data => data.livestreamChannels[0]}>
      {data => (
        <LivestreamPageNoQueryWithTheme livestreamChannels={data.livestreamChannels}
          today={data.today}
          programsOverview={data.programsOverview}
        />
      )}
    </PageWithData>
  );
};
