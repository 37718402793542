import { styled } from '@linaria/react';
import gql from 'graphql-tag';

import { cx } from '@linaria/core';
import { StyledAuthorLink as AuthorLink, authorLinkFragment, darkAuthorLinkStyles } from './AuthorLink.jsx';
import { TextGrey } from '../../Text';
import { darkTwitterLinkStyles, StyledTwitterLink as TwitterLink } from './TwitterLink.jsx';
import { makeMemoFragment } from '../../../utils/graphql';

export const authorNameFragment = makeMemoFragment({
  name: 'AuthorName',
  fragment() {
    return gql`fragment ${this.name} on Person {
          twitterHandle
          shortProfile
          ...${authorLinkFragment.name}
        }
        ${authorLinkFragment.fragment()}
    `;
  },
});

export const ShortProfile = styled(TextGrey)`
  letter-spacing: 0.04rem;
`;

export const AuthorName = ({
  withExtraInfo = true,
  withShortProfile = false,
  withTwitterLink = false,
  person,
  additionalInformation,
}) => (
  <>
    <AuthorLink className="author"
      person={person}
    />
    {withExtraInfo && additionalInformation &&
    <TextGrey className="extra-info">
      {` ${additionalInformation}`}
    </TextGrey>
    }
    {withShortProfile &&
    <ShortProfile>
      {` ${person?.shortProfile}`}
    </ShortProfile>
    }
    {withTwitterLink && person?.twitterHandle &&
    <TwitterLink twitterHandle={person?.twitterHandle}/>
    }
  </>
);

export const darkAuthorNameStyles = cx(
  darkAuthorLinkStyles,
  TextGrey.darkStyles,
  darkTwitterLinkStyles,
);
