import gql from 'graphql-tag';
import { styled } from '@linaria/react';
import { cx } from '@linaria/core';
import { isValidList } from '../../utils/contentUtils';
import { makeMemoFragment } from '../../utils/graphql';

import { authorsLineFragment, darkAuthorsLineStyles, StyledAuthorsLine as AuthorsLine } from './AuthorsLine';
import { StyledLegacyAuthor as LegacyAuthor, legacyAuthorFragment, darkLegacyAuthorStyles } from './AuthorsLine/LegacyAuthor.jsx';

export const authorDetailsFragment = makeMemoFragment({
  name: 'AuthorDetails',
  fragment() {
    return gql`fragment ${this.name} on Content {
            ...${authorsLineFragment.name}
            ...${legacyAuthorFragment.name}
        }
        ${authorsLineFragment.fragment()}
        ${legacyAuthorFragment.fragment()}
    `;
  },
});

export const AuthorDetails = ({
  content,
  className,
}) => {
  const hasPersons = isValidList(content.persons);
  if (!content.legacyAuthor && !hasPersons) {
    return null;
  }
  return (
    hasPersons
      ? <AuthorsLine content={content} className={className} />
      : <LegacyAuthor fullName={content.legacyAuthor} className={className} />
  );
};

export const darkAuthorDetailsStyles = cx(
  darkAuthorsLineStyles,
  darkLegacyAuthorStyles,
);

export const StyledAuthorDetails = styled(AuthorDetails)`
  font-size: 1.3rem;
  letter-spacing: 0.04rem;
`;
