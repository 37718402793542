export const InfoSpaceName = Object.freeze({
  TOP_STORIES: 'T',
  PROGRAM: 'Pr',
  RECOMMENDED: 'E',
  OPINION: 'M',
  SOCIAL_MEDIA: 'S',
  PANORAMA: 'Pa',
});

const isOfInfoSpace = expectedInfoSpaceName => infoSpace => infoSpace.name === expectedInfoSpaceName;

export const isTopStoriesInfoSpace = isOfInfoSpace(InfoSpaceName.TOP_STORIES);
export const isProgramInfoSpace = isOfInfoSpace(InfoSpaceName.PROGRAM);
