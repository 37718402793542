import { useParams } from '../dwouter';
import { Pagination } from './Pagination.jsx';

export const getTotalPages = (totalHits, pageSize) => Math.ceil(totalHits / pageSize);
const generatePath = ({ pageNum, namedUrl }) => `${namedUrl}/page-${pageNum}`;

export const AutoTopicPagination = ({
  className, totalHits, pageSize, namedUrl,
}) => {
  const { pageNumber } = useParams();
  const currentPageNumber = +pageNumber;
  const totalPages = getTotalPages(totalHits, pageSize);

  const disablePrevButton = currentPageNumber === 1;
  const disableNextButton = currentPageNumber === totalPages;

  return (
    <Pagination
      className={className}
      currentPageNumber={currentPageNumber}
      totalPages={totalPages}
      disablePrevButton={disablePrevButton}
      disableNextButton={disableNextButton}
      toPrev={!disablePrevButton && generatePath({ pageNum: currentPageNumber - 1, namedUrl })}
      toNext={!disableNextButton && generatePath({ pageNum: currentPageNumber + 1, namedUrl })}
    />
  );
};

AutoTopicPagination.darkStyles = Pagination.darkStyles;
