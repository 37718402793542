import globals from '../../utils/globals';

const getLearnGermanNodes = () => globals.document.querySelectorAll('nav .learn-german');

const isVisibleNode = node => {
  const style = globals.window.getComputedStyle(node);
  return style?.display !== 'none';
};
export const getOnlyVisibleLearnGermanLink = () => {
  const nodes = getLearnGermanNodes();
  const visibleNode = [...nodes].filter(isVisibleNode)[0];
  return visibleNode?.querySelector('a');
};

export const isSameNode = ({ event, node }) => (event.target === node);
