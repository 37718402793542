import gql from 'graphql-tag';
import { Helmet } from 'react-helmet-async';

import { getTitle } from '../utils/metaDataHelpers';
import { useGlobalsContext } from './GlobalsContext';
import { useFrontendConfig } from './FrontendConfigContext.jsx';
import { toLangCode } from '../utils/langMapper';
import { makeMemoFragment } from '../utils/graphql';
import { SocialMediaMetadata, socialMediaMetadataFragment } from './SocialMediaMetadata';
import { PodcastMetadata, podcastMetadataFragment } from './PodcastMetadata.jsx';
import { getLangCodeFromUrlPath } from '../utils/url';

export const pageHeaderMetadataFragment = makeMemoFragment({
  name: 'PageHeaderMetadata',
  fragment() {
    return gql`fragment ${this.name} on Content {
            ... on ModelAspect {
              isLive
              language
            }
            ... on UrlAspect {
              canonicalUrl
              ampUrl
            }
            ... on NamedAspect {
              title
            }
            ...${podcastMetadataFragment.name}
            ...${socialMediaMetadataFragment.name}
        }
        ${podcastMetadataFragment.fragment()}
        ${socialMediaMetadataFragment.fragment()}
    `;
  },
});

export const detailPageHeaderMetadataFragment = makeMemoFragment({
  name: 'DetailPageHeaderMetadata',
  fragment() {
    return gql`fragment ${this.name} on Content {
          ... on DeliveryAspect {
            localizedContentDate
          }
          ... on UrlAspect {
            alternateUrls
          }
        }
    `;
  },
});

export const PageHeaderMetadata = ({ content }) => {
  const { staticBaseHost } = useFrontendConfig();
  const contentUrl = useGlobalsContext().window.location.href;

  const metadataTitle = getTitle({
    title: content.title,
    date: content.localizedContentDate,
  });
  const metadataDescription = content.sharingDescription;

  const metadataCanonical = encodeURI((content.canonicalUrl) ? content.canonicalUrl : contentUrl);

  return (
    <>
      <Helmet>
        <title>{metadataTitle}</title>
        <meta name="description" content={metadataDescription}/>
        <link rel="canonical" href={metadataCanonical}/>
        <link rel="preconnect" href={staticBaseHost}/>
        <link rel="dns-prefetch" href={staticBaseHost}/>

        <link rel="manifest" href={`/${toLangCode(content.language)}/manifest.json`}/>

        {content.alternateUrls?.map(alternateUrl => (
          <link
            key={alternateUrl}
            rel="alternate"
            hrefLang={getLangCodeFromUrlPath(alternateUrl)}
            href={alternateUrl}
          />
        ))}

        <meta name="robots" content={content.isLive ? 'max-snippet:-1, max-video-preview:-1, max-image-preview:large' : 'noindex, nofollow'} />

        <meta property="og:site_name" content="dw.com" />
        <meta property="og:title" content={metadataTitle}/>
        <meta property="og:url" content={metadataCanonical}/>
        <meta property="og:description" content={metadataDescription} />

        {content.ampUrl && <link rel="amphtml" href={encodeURI(content.ampUrl)}></link>}
      </Helmet>
      <SocialMediaMetadata content={content} />
      <PodcastMetadata content={content} />
    </>
  );
};
