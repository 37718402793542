import { useEffect, useRef } from 'react';
import globals from '../../utils/globals';

export const useTimeout = ({
  callback,
  delay = 100,
  conditional = true,
}, triggers = []) => {
  const savedCallback = useRef();

  useEffect(() => {
    // eslint-disable-next-line fp/no-mutation
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    const tick = () => savedCallback.current();
    if (!conditional) {
      return undefined;
    }
    const handleId = globals.window.setTimeout(tick, delay);
    return () => globals.window.clearTimeout(handleId);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [delay, conditional, ...triggers]);
};
