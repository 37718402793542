// TODO: Facebook icon is available in project twice, unfortunately icons used for social media are padded.
// We agreed that some day we will switch to not-padded ones, but that did not happen yet.
// When the switch is done, this component can be removed.
export const FacebookIcon = ({
  className,
}) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 20 20">
    <defs>
      <path id="facebook-a" d="M10.9955,20.0443125 L10.9955,11.233875 L13.9529375,11.233875 L14.395625,7.8001875 L10.9955,7.8001875 L10.9955,5.6079375 C10.9955,4.613625 11.2715,3.9361875 12.69725,3.9361875 L14.5154375,3.9354375 L14.5154375,0.864375 C14.201,0.8225625 13.1215625,0.729 11.8660625,0.729 C9.2444375,0.729 7.4496875,2.329125 7.4496875,5.2678125 L7.4496875,7.8 L4.4845625,7.8 L4.4845625,11.2336875 L7.4495,11.2336875 L7.4495,20.044125 L10.9955,20.044125 L10.9955,20.0443125 Z" />
    </defs>
    <use xlinkHref="#facebook-a"/>
  </svg>
);

export const StyledFacebookIcon = FacebookIcon;
