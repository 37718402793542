import { useLocation } from '../../../dwouter';
import { useLocationQueryParam } from '../useLocationQueryParam';
import globals from '../../../utils/globals';
import { MaybeProtocolFlip } from './MaybeProtocolFlip.jsx';
import { initialMobileAppConfig, MobileAppContext } from './context';
import { useEventListener } from '../useEventListener';

export * from './context';

const CUSTOM_EVENT_HISTORY_PUSH = 'history-push';

export const MobileAppContextProvider = ({ children }) => {
  const [, setLocation] = useLocation();
  const mobileAppParam = useLocationQueryParam('mobileApp');
  const isMobileModeByQueryParam = mobileAppParam === 'true';
  const isMobileMode = isMobileModeByQueryParam || initialMobileAppConfig.isMobileMode;

  useEventListener(globals.window, CUSTOM_EVENT_HISTORY_PUSH, e => setLocation(e.detail.url));

  return (
    <MobileAppContext.Provider value={{ isMobileMode }}>
      <MaybeProtocolFlip>
        {children}
      </MaybeProtocolFlip>
    </MobileAppContext.Provider>
  );
};
