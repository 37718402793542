import { styled } from '@linaria/react';
import classnames from 'classnames';
import { useInView } from 'react-intersection-observer';

import { useTranslation } from './hooks/useTranslation.jsx';
import { INTERSECTION_OBSERVER_SETTINGS } from './LazyLoadDwPicture.jsx';
import { useMobileAppContext } from './hooks/mobileAppContext';

export const YoutubeIframe = ({ videoUrl, className }) => {
  const [inViewRef, inView] = useInView(INTERSECTION_OBSERVER_SETTINGS);
  const iframeTitle = useTranslation('content_block.widget.headline');
  const { isMobileMode } = useMobileAppContext();
  const lazyVideoUrl = inView ? videoUrl : '';
  return (
    <iframe
      title={iframeTitle}
      className={classnames(className, { cmplazyload: inView })}
      ref={inViewRef}
      data-cmp-vendor="s30"
      src={isMobileMode ? lazyVideoUrl : 'about:blank'}
      data-cmp-src={lazyVideoUrl}
      allowFullScreen
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
    >
    </iframe>
  );
};

export const StyledYoutubeIframe = styled(YoutubeIframe)`
  border: none;
  width: 100%;
  height: 100%;
`;
