import gql from 'graphql-tag';

import { navigationGroupFragment, StyledNavigationGroup as NavigationGroup } from './NavigationGroup';
import { isValidList } from '../../utils/contentUtils';
import { makeMemoFragment } from '../../utils/graphql';

export const groupKeys = [
  'regionsChildren',
  'topicsChildren',
  'categoriesChildren',
  'miscellaneousChildren',
];

export const navigationGroupsFragment = makeMemoFragment({
  name: 'NavigationGroups',
  fragment() {
    return gql`fragment ${this.name} on Navigation {
        ${groupKeys.map(groupKey => `
          ${groupKey} {
            ...${navigationGroupFragment.name}
          }
        `)}
        }
        ${navigationGroupFragment.fragment()}
    `;
  },
});

const toGroupName = groupKey => groupKey.split('Children')[0].toLowerCase();

const toGroupTranslationKey = groupKey => `component.navigation.groups.${toGroupName(groupKey)}`;

export const groupTranslationKeys = groupKeys
  .filter(g => g !== 'miscellaneousChildren')
  .reduce((acc, next) => ({ ...acc, [next]: toGroupTranslationKey(next) }), {});

export const NavigationGroups = ({ className, navsByGroup = {} }) => (
  <>
    {groupKeys.map((groupKey, index) => isValidList(navsByGroup[groupKey]) && (
      <NavigationGroup
        key={index}
        className={`${className} nav-group-${groupKey}`}
        navigations={navsByGroup[groupKey]}
        groupTranslationKey={groupTranslationKeys[groupKey]}
      />
    ))}
  </>
);

export const StyledNavigationGroups = NavigationGroups;
