import gql from 'graphql-tag';
import { cx } from '@linaria/core';
import { Partials, StyledContentTeaser as ContentTeaser, partialFragments } from '../ContentTeaserPartials';
import { makeMemoFragment } from '../../../utils/graphql';

export const headlineContentTeaserFragment = makeMemoFragment({
  name: 'HeadlineContentTeaser',
  fragment() {
    return gql`fragment ${this.name} on Content {
        ... on UrlAspect {
          namedUrl
        }
        ... on NamedAspect {
          title
        }
        ...${partialFragments.teaserDataFragment.name}
        ...${partialFragments.contentStatusTeaserBadgeFragment.name}
        ...${partialFragments.linkedTitleFragment.name}
      }
      ${partialFragments.teaserDataFragment.fragment()}
      ${partialFragments.contentStatusTeaserBadgeFragment.fragment()}
      ${partialFragments.linkedTitleFragment.fragment()}
    `;
  },
});

export const HeadlineContentTeaser = ({
  content = {}, className, titleIsA, isCurrent,
}) => (
  <ContentTeaser
    className={cx(className, 'row')}
    content={content}
    isCurrent={isCurrent}
  >
    <Partials.MainContentArea>
      <Partials.Data>
        <Partials.ContentStatusTeaserBadge />
        <Partials.LinkedTitle isA={titleIsA}/>
      </Partials.Data>
    </Partials.MainContentArea>
  </ContentTeaser>
);

export const StyledHeadlineContentTeaser = HeadlineContentTeaser;
