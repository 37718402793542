import { useTranslation } from './hooks/useTranslation.jsx';
import { StyledText as Text } from './Text/TextBase';

export const I18nText = ({
  translation,
  isA,
  className,
  ...restProps
}) => {
  const label = useTranslation(translation);
  return (
    <Text className={className} isA={isA} { ...restProps }>
      {label}
    </Text>
  );
};
