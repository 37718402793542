import { styled } from '@linaria/react';

import { StyledFacebookIcon as FacebookIcon } from './FacebookIcon.jsx';
import { LinkDefault } from '../../Links';

export const FacebookLink = ({ facebookName, className }) => (
  <LinkDefault to={`https://facebook.com/${facebookName}`} title={`facebook — ${facebookName}`} className={className}>
    <FacebookIcon className="facebook-icon"/>
    {facebookName}
  </LinkDefault>
);

export const darkFacebookLinkStyles = LinkDefault.darkStyles;

export const StyledFacebookLink = styled(FacebookLink)`
  display: flex;
  align-items: center;
  letter-spacing: 0.04rem;

  .facebook-icon {
    height: 20px;
    width: 20px;
  }
`;
