import { useI18nContext } from '../I18nContext.jsx';

const isPlainKey = translation => typeof translation === 'string' || !translation;

const normalizeTranslation = translation => (
  isPlainKey(translation)
    ? {
      key: translation,
      parameters: {},
    }
    : translation
);

export const useTranslation = translation => {
  const i18n = useI18nContext();
  const { key, parameters } = normalizeTranslation(translation);
  if (!key) {
    return null;
  }
  return i18n.t(key, parameters);
};

export const useTranslations = translations => {
  const i18n = useI18nContext();
  return translations.map(translation => normalizeTranslation(translation))
    .map(({ key, parameters }) => (key === null ? null : i18n.t(key, parameters)));
};

export const useTranslationWithComponent = ({ key, parameter, componentFn }) => {
  const rawTranslation = useTranslation(key);

  // we split on unreplaced ${parameter}
  const [before, after] = rawTranslation.split(`\${${parameter}}`);
  return (
    <>
      {before}
      {componentFn()}
      {after}
    </>
  );
};
