import gql from 'graphql-tag';
import { cx } from '@linaria/core';

import {
  StyledSmallImageContentTeaser as SmallImageContentTeaser,
  smallImageContentTeaserFragment,
  darkSmallImageContentTeaserStyles,
} from './SmallImageContentTeaser.jsx';
import {
  StyledSmallImageOpinionContentTeaser as SmallImageOpinionContentTeaser,
  darkSmallImageOpinionContentTeaserStyles,
  smallImageOpinionContentTeaserFragment,
} from './SmallImageOpinionContentTeaser.jsx';
import {
  extendContentTeaserWithCommonMoreOnTopStoryLooks,
  extendSmallImageContentTeaserWithMoreOnTopStoryLooks,
  extendSmallImageOpinionContentTeaserWithMoreOnTopStoryLooks,
} from '../../ContentBlocks/TopStory/MoreOnTopStoryLayout.jsx';
import { squareConfigFactory } from '../../ResponsiveDwPicture/pictureConfigs';
import { makeMemoFragment } from '../../../utils/graphql';
import { extendWithClassNames } from '../../../utils/extendWithClassNames.jsx';

export const smallImageMixContentTeaserFragment = makeMemoFragment({
  name: 'SmallImageMixContentTeaser',
  fragment() {
    return gql`fragment ${this.name} on Content {
        ... on OpinionAspect {
          isOpinion
        }
        ...${smallImageOpinionContentTeaserFragment.name}
        ...${smallImageContentTeaserFragment.name}
      }
      ${smallImageOpinionContentTeaserFragment.fragment()}
      ${smallImageContentTeaserFragment.fragment()}
    `;
  },
});

const imageFormatConfig = squareConfigFactory({ squareTill: 'md', landscapeFrom: 'lg' });

export const SmallImageMixContentTeaser = ({
  content, className, titleIsA, hideDescription, isHidden = false,
}) => {
  const ContentTeaser = content.isOpinion
    ? {
      Tag: SmallImageOpinionContentTeaser,
      props: {},
    }
    : {
      Tag: SmallImageContentTeaser,
      props: {
        imageFormatConfig,
      },
    };

  return (
    <ContentTeaser.Tag
      className={className}
      content={content}
      titleIsA={titleIsA}
      isHidden={isHidden}
      hideDescription={hideDescription}
      {...ContentTeaser.props}
    />
  );
};

export const darkSmallImageMixContentTeaserStyles = cx(
  darkSmallImageOpinionContentTeaserStyles,
  darkSmallImageContentTeaserStyles,

);

export const StyledSmallImageMixContentTeaser = extendWithClassNames(SmallImageMixContentTeaser)(
  extendContentTeaserWithCommonMoreOnTopStoryLooks,
  extendSmallImageContentTeaserWithMoreOnTopStoryLooks,
  extendSmallImageOpinionContentTeaserWithMoreOnTopStoryLooks,
);
