import { createContext, useContext } from 'react';

export const HeaderContext = createContext({
  isNavigationVisible: false,
  isLanguageToggled: false,
  isFocusMenuShown: false,
});

export const useHeaderStateContext = () => useContext(HeaderContext);

export const HeaderContextStateProvider = ({ children, value }) => (
  <HeaderContext.Provider value={value}>
    {children}
  </HeaderContext.Provider>
);
