import { useRef, useEffect } from 'react';

export const usePrev = value => {
  const ref = useRef();
  useEffect(() => {
    // eslint-disable-next-line fp/no-mutation
    ref.current = value;
  }, [value]);
  return ref.current;
};
