import gql from 'graphql-tag';
import {
  darkExpandableFocusTopThreeUnifiedBigImageTextStyles,
  expandableFocusTopThreeUnifiedBigImageTextFragment,
  StyledExpandableFocusTopThreeUnifiedBigImageText as ExpandableFocusTopThreeUnifiedBigImageTextTemplate,
} from '../../ContentTeaserSnippets/Templates/ExpandableFocusTopThreeUnifiedBigImageText.jsx';
import { makeMemoFragment } from '../../../utils/graphql';

export const relatedVideosKeywordRegion = makeMemoFragment({
  name: 'RelatedVideosKeywordRegion',
  fragment() {
    return gql`fragment ${this.name} on Video {
      moreAvContentsByKeywordAndRegion {
        ...${expandableFocusTopThreeUnifiedBigImageTextFragment.name}
      }
      ... on AssociationsAspect {
        firstRegionArray: regions(amount: 1) {
          alignedName
        }
      }
    }
    ${expandableFocusTopThreeUnifiedBigImageTextFragment.fragment()}
  `;
  },
});

export const RelatedVideosKeywordRegion = ({ content, className }) => {
  const { moreAvContentsByKeywordAndRegion } = content;

  const [firstRegion] = content.firstRegionArray;
  const headlineTranslation = {
    key: 'content_block_detail.related_videos_keyword_country.headline',
    parameters: { firstRegionalReference: firstRegion?.alignedName },
  };

  return (
    <ExpandableFocusTopThreeUnifiedBigImageTextTemplate
      className={className}
      contents={moreAvContentsByKeywordAndRegion}
      headlineTranslation={headlineTranslation}
      showMoreBtnTranslation="content_block_detail.related_videos_keyword_country.show_more"
      pageSectionId="related-content-by-keyword-region"
    />
  );
};

export const darkRelatedVideosKeywordRegionStyles = darkExpandableFocusTopThreeUnifiedBigImageTextStyles;

export const StyledRelatedVideosKeywordRegion = RelatedVideosKeywordRegion;
