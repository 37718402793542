import { styled } from '@linaria/react';
import { cx } from '@linaria/core';
import { darkContentBlockStyles, StyledContentBlock as ContentBlock } from '../ContentBlock';
import { useTranslation } from '../../hooks/useTranslation.jsx';
import { StyledContentTeaser as ContentTeaser, Partials, darkPartialStyles } from '../../ContentTeaserSnippets/ContentTeaserPartials';
import { useFrontendConfig } from '../../FrontendConfigContext.jsx';
import { darkLinkWithArrowStyles, linkWithArrowFragment, StyledLinkWithArrow as LinkWithArrow } from '../LinkWithArrow.jsx';
import { StyledEndLineAlignmentContainer as BottomContainer } from '../EndLineAlignmentContainer.jsx';
import { colors } from '../../../utils/css';
import { StyledDefaultLayout as DefaultLayout } from '../../ContentTeaserSnippets/Layouts/DefaultLayout';

export const allProgramsTeaserFragment = linkWithArrowFragment;

export const AllProgramsTeaser = ({ className, programsOverview }) => {
  const { showProgramsOverview } = useFrontendConfig();
  const titleTranslation = useTranslation('content_block.all_programs.title');
  const teaserTranslation = useTranslation('content_block.all_programs.teaser_text');
  const linkTranslation = useTranslation('content_block.all_programs.link');

  if (!showProgramsOverview) {
    return null;
  }

  const normalizedTeaserContent = {
    ...programsOverview,
    title: titleTranslation,
    teaser: teaserTranslation,
  };

  return (
    <ContentBlock
      className={cx(className, darkContentBlockStyles)}
      headlineIsA="h2"
      pageSectionId="all-programs"
      headlineTranslation="content_block.all_programs.headline"
    >
      <DefaultLayout>
        <ContentTeaser
          content={normalizedTeaserContent}
          hideKicker
          hideTimeIndicator
        >
          <Partials.Data>
            <Partials.LinkedTitle className={darkPartialStyles.darkLinkedTitleStyles} isA="h3"/>
            <Partials.LinkedDescription className={darkPartialStyles.darkLinkedDescriptionStyles} />
            <BottomContainer>
              <LinkWithArrow
                className={darkLinkWithArrowStyles}
                content={programsOverview}
                linkTranslation={linkTranslation}
                tabIndex={-1}
              />
            </BottomContainer>
          </Partials.Data>
        </ContentTeaser>
      </DefaultLayout>
    </ContentBlock>);
};

export const StyledAllProgramsTeaser = styled(AllProgramsTeaser)`
  .teaser-data {
    padding: 15px;
    background-color: ${colors.DARK_BLUE_GREY_01};
  }
`;
