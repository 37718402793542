import { css, cx } from '@linaria/core';
import { commonOpinionStyles } from './opinionTeaserCommons';

export const extendTeaserCompositionWithBigImageOpinionTeaserLooks = cx(
  commonOpinionStyles,
  css`
  && .teaser-wrap .teaser .teaser-data-wrap {
    display: flex;
    flex-direction: row;
  }
  .teaser-wrap{
    .teaser {
      .title + .opinion-metadata {
        margin-top: 15px;
      }

      .author-data {
        order: 1;
        flex-grow: 1;

        .author-link, .no-link {
          margin-top: 15px;
          display: inline-block;
        }
      }

      .author-image {
        order: 2;
        display: block;
        max-width: 120px;
        max-height: 120px;
        margin-inline-start: 15px;
        width: 100%;
        height: auto;
      }

    }
  }
`,
);
