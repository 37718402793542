import { StyledScreenReaderOnly as ScreenReaderOnly } from './ScreenReaderOnly.jsx';

import { useRelativeTimeLabelFromDates } from '../hooks/useRelativeTimeLabelFromDates';
import { InformationDefault } from '../Text/Information.jsx';
import { useI18nContext } from '../I18nContext.jsx';

const useTimeInfo = (label, date, localizedDate) => {
  const i18n = useI18nContext();
  const infoLabel = label ? i18n.t(label) : '';

  return `${infoLabel} ${useRelativeTimeLabelFromDates(date, localizedDate)}`.trim();
};

export const TimeIndicator = ({
  date,
  localizedDate,
  localizedDateSr,
  label,
  className,
  isA = InformationDefault,
}) => {
  const timeInfo = useTimeInfo(label, date, localizedDate);
  const timeInfoSr = useTimeInfo(label, date, localizedDateSr);

  const TextComponent = isA;
  return (
    <TextComponent className={className}>
      <time aria-hidden="true">{timeInfo}</time>
      <ScreenReaderOnly>{timeInfoSr}</ScreenReaderOnly>
    </TextComponent>
  );
};

export const darkTimeIndicatorStyles = InformationDefault.darkStyles;

export const StyledTimeIndicator = TimeIndicator;
