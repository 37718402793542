import { createContext, useContext } from 'react';

const pageContextTypes = Object.freeze({
  NONE: 'NONE',
  ON_DETAILS_PAGE: 'ON_DETAILS_PAGE',
  IN_RICH_TEXT: 'IN_RICH_TEXT',
});

const contextFactory = (type = pageContextTypes.NONE) => ({
  get isOnDetailPage() {
    return type === pageContextTypes.ON_DETAILS_PAGE || type === pageContextTypes.IN_RICH_TEXT;
  },
  get isInRichText() {
    return type === pageContextTypes.IN_RICH_TEXT;
  },
});

// --- Context setup ---

export const PageEmbeddingContext = createContext(contextFactory());

export const usePageContext = () => useContext(PageEmbeddingContext);

// --- Context providers ---

export const OnDetailPageContext = ({ children }) => (
  <PageEmbeddingContext.Provider value={contextFactory(pageContextTypes.ON_DETAILS_PAGE)}>
    {children}
  </PageEmbeddingContext.Provider>
);

export const InRichTextContext = ({ children }) => (
  <PageEmbeddingContext.Provider value={contextFactory(pageContextTypes.IN_RICH_TEXT)}>
    {children}
  </PageEmbeddingContext.Provider>
);
