import { extendWithClassNames } from '../../../utils/extendWithClassNames.jsx';
import {
  extendDefaultTeaserCompositionWithStandardTeaserBasicLooks,
  extendTeaserCompositionWithBottomAlignmentLooks,
  extendTeaserCompositionWithSmallImageTextTeaserLooksNotFirst,
  extendWithDefaultTeaserComposition,
  StyledTeaserComposition as TeaserComposition,
} from '../TeaserComposition';

export const StyledDefaultHighlightSmallImageTextLayout = extendWithClassNames(TeaserComposition)(
  extendTeaserCompositionWithBottomAlignmentLooks,
  extendTeaserCompositionWithSmallImageTextTeaserLooksNotFirst,
  extendWithDefaultTeaserComposition,
  extendDefaultTeaserCompositionWithStandardTeaserBasicLooks,
);
