import { extendWithClassNames } from '../../../utils/extendWithClassNames.jsx';
// import { selector } from '../../../utils/css';
import {
  StyledTeaserComposition as TeaserComposition,
  extendTeaserCompositionWithSmallImageTextTeaserLooksNotFirst,
  extendTeaserCompositionWithBottomAlignmentLooks,
} from '../TeaserComposition';

export const StyledHighlightSmallImageTextLayout = extendWithClassNames(TeaserComposition)(
  extendTeaserCompositionWithBottomAlignmentLooks,
  extendTeaserCompositionWithSmallImageTextTeaserLooksNotFirst,
);
