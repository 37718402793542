import gql from 'graphql-tag';

import { replaceInPattern } from '../utils/i18n';
import { useTranslation } from './hooks/useTranslation.jsx';
import { makeMemoFragment } from '../utils/graphql';
import { StyledSocialMediaIconLink as SocialMediaIconLink, darkSocialMediaIconLinkStyles } from './SocialMediaIconLink';

export const contentDetailActionsSocialMediaIconLinkFragment = makeMemoFragment({
  name: 'ContentDetailActionsSocialMediaIconLink',
  fragment() {
    return gql`fragment ${this.name} on SocialMediaEntry {
          id
          name
          url
        }
    `;
  },
});

export const ContentDetailActionsSocialMediaIconLink = ({
  socialMediaEntry,
  contentUrl,
  className,
  ...restProps
}) => {
  const socialMediaSharingLinkTitle = useTranslation(
    {
      key: 'component_detail.detail_actions.service.label',
      parameters: { service: socialMediaEntry.name },
    },
  );
  const urlWithReplacedVars = replaceInPattern(socialMediaEntry.url, { contentUrl });
  return (
    <SocialMediaIconLink className={className}
      socialMediaId={socialMediaEntry.id}
      url={urlWithReplacedVars}
      title={socialMediaSharingLinkTitle}
      {...restProps}
    />
  );
};

export const darkContentDetailActionsSocialMediaIconLinkStyles = darkSocialMediaIconLinkStyles;

export const StyledContentDetailActionsSocialMediaIconLink = ContentDetailActionsSocialMediaIconLink;
