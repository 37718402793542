export const keyCodes = Object.freeze({
  TAB: 'Tab',
  ENTER: 'Enter',
  SPACEBAR: ' ',
  ARROW_LEFT: 'ArrowLeft',
  ARROW_RIGHT: 'ArrowRight',
  ARROW_UP: 'ArrowUp',
  ARROW_DOWN: 'ArrowDown',
  ESCAPE: 'Escape',
});

export const isForwardTab = event => (!event.shiftKey && event.key === keyCodes.TAB);
export const isBackwardTab = event => (event.shiftKey && event.key === keyCodes.TAB);
export const isEnter = event => event.key === keyCodes.ENTER;
export const isEnterOrSpacebar = event => (isEnter(event) || event.key === keyCodes.SPACEBAR);
