import { useEffect, useState } from 'react';
import { useLocalStorage } from '../hooks/useLocalStorage';
import { BrowserOnly } from '../BrowserOnly.jsx';
import { StartPageRedirect } from '../StartPageRedirect.jsx';
import { toDwLangCode } from './dwLangCodeConverter';
import globals from '../../utils/globals';

export const HomePage = () => {
  const [userLang, setUserLang] = useLocalStorage('com.dw.userLanguage', { isPrimitive: true });
  const [, setLangDetected] = useLocalStorage('com.dw.userLanguageDetected', { isPrimitive: true });
  const [detectedLangCode, setDetectedLangCode] = useState();

  useEffect(() => {
    if (userLang) { // user on home page root - dw.com, but they visited the page before
      setDetectedLangCode(userLang);
    } else {
      // looks like first time of the page, lets guess the lang!
      const dwLangCode = toDwLangCode(globals.navigator.language);
      setLangDetected('true');
      setUserLang(dwLangCode);
      setDetectedLangCode(dwLangCode);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <BrowserOnly>
      <div className='blank-root-page'>
        {detectedLangCode && <StartPageRedirect forcedLangCode={detectedLangCode} /> }
      </div>
    </BrowserOnly>
  );
};
