import { css } from '@linaria/core';
import { mediaMax, mediaMin, selector } from '../../../../../utils/css';

// TODO linaria-next: maybe we find a solution to get this css out of our JS?!
const extendTeaserCompositionWithSmallImageTextTeaserStyles = (cssSelector = '') => `
  .badge-above-md,
  .title-above-md {
    display: none;
  }
  .title-xs-sm {
    display: block;
    width: 100%;
  }

  .teaser-wrap${cssSelector} {
    .teaser-image-wrap{
      display: flex;
    }
    .teaser-image {
      width: 100%;
      max-width: 23%;
      margin-inline-end: 15px;
    }
    .teaser-data-xs-sm {
      margin-top: 0;
    }
    .title-xs-sm {
      font-size: 1.8rem;
    }
  }
  .teaser-data-wrap:first-child .title-above-md {
    display: block;
  }
  .teaser-data-wrap:first-child .badge-above-md {
    display: inline-flex;
  }

  ${mediaMax.sm`
    .teaser-wrap {
      .teaser {
        row-gap: 0;
      }
    }

    .teaser-data-xs-sm {
      margin-top: 15px;
    }
    
  `}
  ${mediaMin.sm`
    .teaser-wrap${cssSelector} {
      .teaser-image {
        max-width: 33.333333%;
      }

      .title-xs-sm {
        font-size: 2rem;
      }
    }
  `}

  ${mediaMin.md`
    .badge-above-md {
      display: inline-flex;
    }
    .title-above-md {
      display: block;
    }
    .badge-xs-sm,
    .title-xs-sm {
        display: none;
    }

    .teaser-wrap${cssSelector} {
      .teaser-image {
        max-width: 100%;
        margin-inline-end: 0;
      }
    }
  `}
`;

export const extendTeaserCompositionWithSmallImageTextTeaserLooks = css`${extendTeaserCompositionWithSmallImageTextTeaserStyles()}`;
export const extendTeaserCompositionWithSmallImageTextTeaserLooksNotFirst = css`${extendTeaserCompositionWithSmallImageTextTeaserStyles(selector.notFirst)}`;
