import { PersonDetailsWithFallbackWithTheme } from '../components/PersonDetails';
import { WithGraphQLContentHandling } from '../components/commons/WithGraphQLContentHandling';
import { useParams } from '../dwouter';

export const PersonDetailsPage = () => {
  const { contentId, langCode } = useParams();
  const queryDef = {
    path: `${langCode}/content/person/${contentId}`,
    depts: [langCode, contentId],
  };
  return (
    <WithGraphQLContentHandling queryDef={queryDef}>
      {({ content }) => (
        <PersonDetailsWithFallbackWithTheme person={content} />
      )}
    </WithGraphQLContentHandling>
  );
};
