import { useTranslation } from './useTranslation.jsx';
import { isValidList } from '../../utils/contentUtils';

const opinionTypeToLabel = Object.freeze({
  EXTERNAL_AUTHOR: 'external',
  COMMENT: 'comment',
  COLUMN: 'column',
});

const externalPersonTypes = ['EXTERNAL_AUTHOR'];

export const useOpinionSpecificType = content => {
  const person = isValidList(content.firstPersonArray) && content.firstPersonArray[0];
  const opinionType =
    person && externalPersonTypes.includes(person.personType)
      ? person.personType
      : content.genre;

  const translatedType = useTranslation(`component.opinion.type.${opinionTypeToLabel[opinionType]}`);
  return Object.keys(opinionTypeToLabel).includes(opinionType)
    ? translatedType
    : undefined;
};
