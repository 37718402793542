import gql from 'graphql-tag';
import { styled } from '@linaria/react';
import { cx } from '@linaria/core';

import { StyledTopicTeaserText as TopicTeaserText, darkTopicTeaserTextStyles } from '../TopicCluster/TopicTeaserText.jsx';
import { StyledTopicClusterTitle as TopicClusterTitle, darkTopicClusterTitleStyles } from '../TopicCluster/TopicClusterTitle.jsx';
import { makeMemoFragment } from '../../../utils/graphql';
import { DEFAULT_TEASER_SPACING } from '../../ContentTeaserSnippets/TeaserComposition';

export const autoTopicHeaderFragment = makeMemoFragment({
  name: 'AutoTopicHeader',
  fragment() {
    return gql`fragment ${this.name} on AutoTopic {
        teaser
        name
      }
    `;
  },
});

export const AutoTopicHeader = ({ className, autoTopic }) => (
  <div className={className}>
    <TopicClusterTitle>{autoTopic.name}</TopicClusterTitle>
    <TopicTeaserText>{autoTopic.teaser}</TopicTeaserText>
  </div>
);

export const darkAutoTopicHeaderStyles = cx(
  darkTopicClusterTitleStyles,
  darkTopicTeaserTextStyles,
);

export const StyledAutoTopicHeader = styled(AutoTopicHeader)`
  margin-bottom: ${DEFAULT_TEASER_SPACING};

  display: grid;
  justify-items: center;
`;
