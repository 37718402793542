import { styled } from '@linaria/react';
import { mediaMin } from '../../utils/css';

export const NavigationSpacer = ({
  className,
  isA = 'div',
  ...restProps
}) => {
  const Tag = isA;
  return (
    <Tag
      className={className}
      {...restProps}
    >
    </Tag>
  );
};

export const StyledNavigationSpacer = styled(NavigationSpacer)`
  margin-inline: 45px 12px;

  ${mediaMin.md`
    width: 600px;
    margin-inline: 145px auto;
  `}
  ${mediaMin.lg`
    width: 700px;
    margin-inline-start: auto;
  `}
  ${mediaMin.xl`
    width: 810px;
  `}
`;
