import { useEffect } from 'react';
import { HttpStatus } from './HttpStatus.jsx';
import globals from '../utils/globals';

export const ExternalRedirect = ({ to }) => {
  const { pathname, search } = to;
  const href = `${pathname}${search || ''}`;

  useEffect(() => {
    globals.window.location.replace(href);
  }, [href]);

  return (
    <HttpStatus code={301} contextExtension={{ url: href }}></HttpStatus>
  );
};
