import { styled } from '@linaria/react';
import { InformationDefault } from '../Text/Information.jsx';
import { mediaMin } from '../../utils/css';

export const KickerText = ({
  className,
  'aria-hidden': ariaHidden,
  dataTrackingName,
  children,
}) => (
  <InformationDefault
    isA="div"
    className={className}
    aria-hidden={ariaHidden}
    data-tracking-name={dataTrackingName}
  >
    {children}
  </InformationDefault>
);

export const darkKickerTextStyles = InformationDefault.darkStyles;

export const StyledKickerText = styled(KickerText)`
  font-size: 1.5rem;
  letter-spacing: 0.12rem;
  text-transform: uppercase;

  ${mediaMin.lg`
    font-size: 1.6rem;
    letter-spacing: 0.17rem;
  `}
`;
