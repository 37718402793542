import { styled } from '@linaria/react';
import gql from 'graphql-tag';
import { LinkDefault } from '../Links';
import { TextDefault } from '../Text';
import { colors, selector } from '../../utils/css';
import { getUrlWithFallbackToHomePage } from '../../utils/url';
import { makeMemoFragment } from '../../utils/graphql';
import { useTranslation } from '../hooks/useTranslation.jsx';
import { FooterVendorSettingsLink, footerVendorSettingsLinkFragment } from './FooterVendorSettingsLink.jsx';

const footerLinkFields = ['policy', 'legal', 'accessibility'];

export const footerSectionStandardFragment = makeMemoFragment({
  name: 'FooterSectionStandard',
  fragment() {
    return gql`fragment ${this.name} on Footer {
      ...${footerVendorSettingsLinkFragment.name}
      ${footerLinkFields.map(fieldName => `
        ${fieldName} {
          namedUrl
        }
      `).join('\n')}
    }
    ${footerVendorSettingsLinkFragment.fragment()}
  `;
  },
});

// @VisibleForTesting
export const StandardLink = ({ fieldName, footer }) => {
  const translation = useTranslation(`component.footer.${fieldName}`);
  return (
    <span key={fieldName}>
      <LinkDefault
        className={LinkDefault.darkStyles}
        to={getUrlWithFallbackToHomePage(footer[fieldName])}
        title={translation}
        skipTitleForInternal
      >
        {translation}
      </LinkDefault>
    </span>
  );
};

export const FooterSectionStandard = ({ className, footer }) => {
  const currentYear = new Date().getFullYear();

  return (
    <section className={className} data-tracking-name="footer-section-standard-links">
      <TextDefault className={TextDefault.darkStyles}>
          © {currentYear} Deutsche Welle
      </TextDefault>
      {footerLinkFields.map(fieldName => (
        <StandardLink key={fieldName} fieldName={fieldName} footer={footer}/>
      ))}
      <FooterVendorSettingsLink className={LinkDefault.darkStyles} footer={footer}/>
    </section>
  );
};

export const StyledFooterSectionStandard = styled(FooterSectionStandard)`
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;

  > *, > *:after {
    white-space: nowrap;
    font-size: 1.1rem;
  }
  > ${selector.notLast}:after {
    content: '\u00A0|\u00A0';
    color: ${colors.BLUE_GREY_01};
    line-height: 1.4;
    margin: 0 5px;
  }
`;
