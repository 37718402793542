import { extendWithClassNames } from '../../utils/extendWithClassNames.jsx';
import { useForcedTheme } from '../hooks/useForcedTheme';

export const withTheme = Component => darkStyleClass => {
  const ComponentDark = extendWithClassNames(Component)(darkStyleClass);

  return ({ hasTheme, ...props }) => {
    const [isForcedDarkMode] = useForcedTheme();

    if (isForcedDarkMode || hasTheme) {
      return <ComponentDark {...props} />;
    }
    return <Component {...props} />;
  };
};
