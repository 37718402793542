import gql from 'graphql-tag';

import { useParams } from '../../../dwouter';
import { useMobileAppContext } from '../mobileAppContext';
import { frontendConfigFragment } from './fragment';
import { FrontendConfig } from '../../../utils/frontendConfig';
import { I18n } from '../../../utils/i18n';
import { useGqlFetch } from '../useGqlFetch';

export const i18nByLangWithFrontendConfigQuery = () => gql`
  query i18nByLangWithFrontendConfig($lang: Language!, $appName: String) {
    i18n: i18nByLang(lang: $lang){
      all
    }
    config {
      ...${frontendConfigFragment.name}
    }
  }
  ${frontendConfigFragment.fragment()}
`;

export const useQueryTranslationAndFrontendConfig = () => {
  const { langCode = 'en' } = useParams();
  const { isMobileMode } = useMobileAppContext();
  const appName = isMobileMode ? 'mobileApp' : 'road';

  const gqlMeta = useGqlFetch({
    path: `${langCode}/${appName}/translations`,
    depts: [langCode, appName],
  });

  return {
    gqlMeta,
    frontendConfig: !gqlMeta.loading ? new FrontendConfig(gqlMeta.data.config) : new FrontendConfig(),
    i18n: !gqlMeta.loading && gqlMeta.data.i18n ? new I18n(JSON.parse(gqlMeta.data.i18n.all)) : new I18n(),
  };
};
