import globals from '../../utils/globals';
import { useFeatureFlag } from '../hooks/useFeatureFlag';

export const getXCloseFeedbackBtn = () => globals.document.querySelector('div.feedback-sidebar .header .close-feedback-btn');

const getSubmitBtn = () => globals.document.querySelector('div.feedback-elements button');
const getSuccessFeedbackCloseBtn = () => globals.document.querySelector('div.feedback-elements .close-feedback-btn');
export const getLastBtnInFeedbackSidebar = () => getSubmitBtn() || getSuccessFeedbackCloseBtn();

export const feedbackType = Object.freeze({
  BUTTON: 'BUTTON',
  RICH_TEXT: 'RICH_TEXT',
});

export const useFeedbackDetector = ({ feedbackTypes, isLive }) => {
  const showFeatureFlag = useFeatureFlag();
  const showFeedback = isLive || showFeatureFlag;

  const showFeedbackButton = showFeedback && feedbackTypes.includes(feedbackType.BUTTON);
  const hasFeedbackInRichText = showFeedback && feedbackTypes.includes(feedbackType.RICH_TEXT);
  return {
    showFeedbackButton,
    hasFeedbackInRichText,
    hasFeedback: showFeedbackButton || hasFeedbackInRichText,
  };
};
