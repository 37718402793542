import { styled } from '@linaria/react';
import classnames from 'classnames';

import { useAdContext } from './AdContext';
import { hideOnPrint } from '../GlobalPrintStyles';

export const AdvertisementBox = ({ children, className }) => {
  const [{ showAdBox }] = useAdContext();

  return (
    <div className={classnames(className, hideOnPrint, 'advertisement', { 'no-box': !showAdBox })}>
      {children}
    </div>
  );
};

export const StyledAdvertisementBox = styled(AdvertisementBox)`
  &.no-box {
    display: none;
  }
`;
