import { styled } from '@linaria/react';
import gql from 'graphql-tag';
import { LinkMonoColor } from '../Links';
import { mediaMin } from '../../utils/css';
import { makeMemoFragment } from '../../utils/graphql';

export const focusMenuItemFragment = makeMemoFragment({
  name: 'FocusMenuItemFragment',
  fragment() {
    return gql`fragment ${this.name} on ContentLink {
        name
        target {
          ...on UrlAspect {
            namedUrl
          }
          ... on Navigation {
            visibleInWebApp
          }
        }
    }`;
  },
});

export const FocusMenuItem = ({ className, link }) => {
  if (!link.target) {
    return null;
  }
  const { target: { namedUrl, visibleInWebApp }, name } = link;
  if (!namedUrl || visibleInWebApp === false) {
    return null;
  }

  return (
    <LinkMonoColor title={name} skipTitleForInternal to={namedUrl} className={className}>
      {name}
    </LinkMonoColor>
  );
};

export const StyledFocusMenuItem = styled(FocusMenuItem)`
  font-size: 1.3rem;
  white-space: nowrap;

  ${mediaMin.lg`
    font-size: 1.4rem;
  `}
`;
