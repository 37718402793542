import { useEffect } from 'react';

import globals from '../../utils/globals';
import { useWindowInnerWidthContext } from '../WindowInnerWidthContext';
import { useAdContext } from './AdContext';

const SLOT_RENDERED_EVENT = 'slotRenderEnded';

export const useSlotLazyLoadEffect = ({ slot, slotConfigEntry }) => {
  const innerWidth = useWindowInnerWidthContext();
  const [{ inView, currentSlotUnit }, { contributeShowAdBox, setCurrentSlotUnit }] = useAdContext();

  // safer to register event listeners first, before doing anything else
  useEffect(() => {
    const { googletag } = globals.window;
    const slotRenderEndedCallback = event => {
      if (event.slot === slot) {
        contributeShowAdBox(!event.isEmpty);
      }
    };
    googletag.cmd.push(() => { // eslint-disable-line fp/no-mutating-methods
      googletag.pubads().addEventListener(SLOT_RENDERED_EVENT, slotRenderEndedCallback);
    });
    return () => {
      googletag.cmd.push(() => { // eslint-disable-line fp/no-mutating-methods
        googletag.pubads().removeEventListener(SLOT_RENDERED_EVENT, slotRenderEndedCallback);
      });
    };
  }, [contributeShowAdBox, slot]);

  useEffect(() => {
    const { googletag } = globals.window;
    const isSlotResolutionFit = innerWidth >= slotConfigEntry.minWidth && innerWidth <= slotConfigEntry.maxWidth;
    const isSlotVisibleReady = !!slot && isSlotResolutionFit && inView;
    if (isSlotVisibleReady && currentSlotUnit !== slotConfigEntry.adUnitCode) {
      setCurrentSlotUnit(slotConfigEntry.adUnitCode);
      googletag.cmd.push(() => { // eslint-disable-line fp/no-mutating-methods
        googletag.pubads().refresh([slot]);
      });
    }
  }, [slot, innerWidth, inView, slotConfigEntry, currentSlotUnit, setCurrentSlotUnit]);
};
