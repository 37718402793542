import { styled } from '@linaria/react';
import { StyledDot as Dot } from '../../Dot.jsx';

export const PulseDot = ({ className }) => (
  <Dot className={className}/>
);

export const StyledPulseDot = styled(PulseDot)`
  @keyframes pulsate {
    0%, 100% {
      transform: scale(0.8, 0.8);
    }
    50% {
      transform: scale(1.0, 1.0);
    }
  }
  animation: pulsate 3s cubic-bezier(0.43, 0, 0.09, 1) infinite;
`;
