import { createPortal } from 'react-dom';
import globals from '../../globals';

export const replaceWithComponent = ({ selector, componentFn, richTextElement }) => {
  const portals = [...richTextElement.querySelectorAll(selector)]
    .map(matchedElem => {
      const stargateComponent = componentFn(matchedElem);
      if (!stargateComponent) {
        return null;
      }
      const renderContainerDiv = globals.document.createElement('div');
      const placeholderClassNames = matchedElem.className;
      renderContainerDiv.setAttribute('class', `render-container ${placeholderClassNames}`.trim());

      matchedElem.parentNode.replaceChild(renderContainerDiv, matchedElem);
      return createPortal(
        stargateComponent,
        renderContainerDiv,
      );
    }).filter(Boolean);
  return { richTextElement, portals };
};
