import { css, cx } from '@linaria/core';
import { colors } from '../utils/css';
import { ButtonSecondary } from './buttons/Button';

export const ChoiceChip = ({
  className,
  isSelected,
  ...restProps
}) => (
  <ButtonSecondary className={cx(className, choiceChipClass, isSelected && 'selected')} {...restProps} />
);

export const cssVariables = {
  textColorSelected: '--choice-chip-text-color-selected',
  backgroundColorSelected: '--choice-chip-background-color-selected',
};

export const choiceChipClass = css`
  &.selected {
    color: var(${cssVariables.textColorSelected}, ${colors.WHITE});
    background-color: var(${cssVariables.backgroundColorSelected}, ${colors.DW_DARK_BLUE});
  }
  && {
    border-radius: 18px;
    padding: 8px 16px;
    min-width: 50px;
    min-height: 30px;
    user-select:none;
  }
`;

export const darkChoiceChipClass = cx(
  ButtonSecondary.darkStyles,
  css`
    ${cssVariables.textColorSelected}: ${colors.DW_DARK_BLUE};
    ${cssVariables.backgroundColorSelected}: ${colors.WHITE};
  `,
);

ChoiceChip.darkStyles = darkChoiceChipClass;

export const hiddenStylesClass = css`
  opacity: 0;
  position: absolute;
`;

export const defaultLabelClass = css`
  margin-bottom: 0;
`;

export const ChoiceChipAsCheckbox = ({
  className,
  isSelected,
  id,
  name,
  value,
  onChange,
  ...restBtnProps
}) => (
  <label htmlFor={id} className={cx(defaultLabelClass, className)}>
    <input
      id={id}
      type="checkbox"
      className={hiddenStylesClass}
      name={name}
      value={value}
      onChange={onChange}
      checked={isSelected} />
    <ChoiceChip isSelected={isSelected} {...restBtnProps}>{value}</ChoiceChip>
  </label>

);

ChoiceChipAsCheckbox.darkStyles = darkChoiceChipClass;

export const ChoiceChipAsRadio = ({
  className,
  isSelected,
  id,
  name,
  value,
  onChange,
  ...restBtnProps
}) => (
  <label htmlFor={id} className={cx(defaultLabelClass, className)}>
    <input
      id={id}
      type="radio"
      className={hiddenStylesClass}
      name={name}
      value={value}
      onChange={onChange}
      checked={isSelected} />
    <ChoiceChip isSelected={isSelected} {...restBtnProps}>{value}</ChoiceChip>
  </label>

);

ChoiceChipAsRadio.darkStyles = darkChoiceChipClass;
