import gql from 'graphql-tag';
import { StyledContentBlockList as ContentBlockList } from '../../ContentBlocks/Types/ContentBlockList.jsx';
import { StyledDefaultBigImageOpinionLayout as DefaultBigImageOpinionLayout } from '../Layouts/DefaultBigImageOpinionLayout';
import { StyledBigImageOpinionContentTeaser as BigImageOpinionContentTeaser, bigImageOpinionContentTeaserFragment } from '../ContentTeasers/BigImageOpinionContentTeaser.jsx';
import { makeMemoFragment } from '../../../utils/graphql';

export const listDefaultUnifiedBigImageOpinionFragment = makeMemoFragment({
  name: 'ListDefaultUnifiedBigImageOpinion',
  fragment() {
    return gql`fragment ${this.name} on Content {
        ... on ModelAspect {
          id
        }
        ...${bigImageOpinionContentTeaserFragment.name}
      }
      ${bigImageOpinionContentTeaserFragment.fragment()}
    `;
  },
});

export const ListDefaultUnifiedBigImageOpinion = ({ contents = [], ...restProps }) => (
  <ContentBlockList headlineIsA='h2' {...restProps}>
    <DefaultBigImageOpinionLayout>
      {contents.map(content => (
        <BigImageOpinionContentTeaser
          key={content.id}
          content={content}
          titleIsA='h3'
        />
      ))}
    </DefaultBigImageOpinionLayout>
  </ContentBlockList>
);

export const StyledListDefaultUnifiedBigImageOpinion = ListDefaultUnifiedBigImageOpinion;
