import { styled } from '@linaria/react';
import { mediaMin } from '../../utils/css';
import { useTranslation } from '../hooks/useTranslation.jsx';
import { HeadlineMediumBlueGrey04AndDwLightBlueNew } from '../Text/Headline.jsx';

export const ContentBlockHeadline = ({
  isA, className, translation, ...restProps
}) => {
  const translatedText = useTranslation(translation);
  return (
    <HeadlineMediumBlueGrey04AndDwLightBlueNew className={className} isA={isA} {...restProps}>
      {translatedText}
    </HeadlineMediumBlueGrey04AndDwLightBlueNew>
  );
};

export const darkContentBlockHeadlineStyles = HeadlineMediumBlueGrey04AndDwLightBlueNew.darkStyles;

export const StyledContentBlockHeadline = styled(ContentBlockHeadline)`
  margin-bottom: 25px;
  font-size: 1.6rem;

  ${mediaMin.md`
    font-size: 1.8rem;
  `}

  ${mediaMin.lg`
    font-size: 2rem;
  `}
`;
