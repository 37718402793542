import { useEffect } from 'react';
import { useLocation } from '../../dwouter/use-location';
import globals from '../../utils/globals';

export const useFixCmpPreviews = () => {
  const [{ pathname }] = useLocation();
  useEffect(() => () => {
    [...globals.document.querySelectorAll('script[data-cmp-haspreview]')].forEach(elem => {
      ['data-cmp-preview-connect', 'data-cmp-preview-id', 'data-cmp-haspreview'].forEach(attr => elem.removeAttribute(attr));
    });
  }, [pathname]);
};
