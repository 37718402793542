import { css, cx } from '@linaria/core';
import { mediaMin, selector } from '../../../../utils/css';

// this covers all teaser cases with no image (opinion teasers and regular teaser and regular teasers with opinion)
export const teaserNoImageSelector = '.teaser *:first-child:is(.teaser-data-wrap:not(:has(> .author-data)), .author-data)';

// TODO linaria-next: rename these "extendXYZ" variables to "XYZClassName"
export const extendWithDefaultTeaserComposition = css`
  ${mediaMin.md`
    .teaser-wrap${selector.composition.odd}{
      flex: 0 0 100%;
      max-width: 100%;
    }
  `}

  ${mediaMin.lg`
    .teaser-wrap${selector.composition.odd}{
      flex: 0 0 33.333333%;
      max-width: 33.333333%;
    }

    .teaser-wrap${selector.composition._4710},
    .teaser-wrap${selector.composition._4710} ~ .teaser-wrap${selector.topElements(4)},
    .teaser-wrap${selector.composition._258},
    .teaser-wrap${selector.composition._258} ~ .teaser-wrap${selector.topElements(2)}{
      flex: 0 0 50%;
      max-width: 50%;
    }

    .teaser-wrap${selector.justNElements(1)}{
      flex: 0 0 100%;
      max-width: 100%;
    }
  `}
`;

export const extendDefaultTeaserCompositionWithMandatoryBasicLooks = css`
  ${mediaMin.md`
    .teaser-wrap${selector.composition.odd}{
      .title {
        font-size: 2.8rem;
      }
    }
  `}

  ${mediaMin.lg`
    .teaser-wrap${selector.composition.odd}{
      .title {
        font-size: 2.4rem;
      }
    }

    .teaser-wrap${selector.composition._4710},
    .teaser-wrap${selector.composition._4710} ~ .teaser-wrap${selector.topElements(4)},
    .teaser-wrap${selector.composition._258},
    .teaser-wrap${selector.composition._258} ~ .teaser-wrap${selector.topElements(2)}{
      .title {
        font-size: 2.8rem;
      }
    }

    .teaser-wrap${selector.justNElements(1)}{
      .title {
        font-size: 3.2rem;
      }
      ${teaserNoImageSelector}{
        max-width: 50%;

        .title {
          font-size: 2.8rem;
        }
      }
    }
  `}

  ${mediaMin.xl`
    .teaser-wrap${selector.composition._4710},
    .teaser-wrap${selector.composition._4710} ~ .teaser-wrap${selector.topElements(4)},
    .teaser-wrap${selector.composition._258},
    .teaser-wrap${selector.composition._258} ~ .teaser-wrap${selector.topElements(2)}{
      .title {
        font-size: 3.2rem;
      }
    }

    .teaser-wrap${selector.justNElements(1)}{
      .title {
        font-size: 3.6rem;
      }
      ${teaserNoImageSelector} .title {
          font-size: 3.2rem;
      }
    }
 `}
`;

export const extendDefaultTeaserCompositionWithStandardTeaserBasicLooks = cx(
  extendDefaultTeaserCompositionWithMandatoryBasicLooks,
  css`
    ${mediaMin.lg`
      .teaser-wrap${selector.justNElements(1)}{
        .teaser {
          flex-direction: row;
        }
        .teaser-image-wrap,
        .teaser-av-wrap{
          max-width: 66.666666%;
        }
        .teaser-data-wrap {
          max-width: 33.333333%;
        }
      }
    `}
`,
);
