// @VisibleForStorybook
import gql from 'graphql-tag';
import { makeMemoFragment } from '../../../../utils/graphql';

export const frontendConfigFragment = makeMemoFragment({
  name: 'FrontendConfig',
  fragment() {
    return gql`fragment ${this.name} on FrontendConfig {
        domainName
        staticBaseHost
        gtmId
        gtmLevel1Id(appName: $appName)
        gtmEnvironment(appName: $appName, lang: $lang)
        adsEnabled(appName: $appName, lang: $lang)
        taboolaPathFragment(appName: $appName, lang: $lang)
        consentManagerId(appName: $appName)
        showProgramsOverview
        showSearch(lang: $lang)
        facebookMetaPageIds
        facebookMetaAdminIds
        reCaptchaSiteKey
        feedbackFormMaxFileSizeMb
        feedbackFormAcceptedFileTypes
        einbliqCustomerId
        peachSiteKey
        atiConfig {
          siteId
          log
          domain
        }
      }
    `;
  },
});
