import { styled } from '@linaria/react';
import { TeaserLinkedImage } from '../../ContentTeaserSnippets/ContentTeaserPartials/TeaserLinkedImage.jsx';
import { aspectRatioAware } from '../../../utils/css';
import { ultraWideScreenCenterHeroImages, ultraWideScreenHeroStyles } from '../../../utils/ultraWideCustomCss';

export const TopStoryHeroImage = styled(TeaserLinkedImage)`
  ${aspectRatioAware.xl.ultraWide`
    ${ultraWideScreenHeroStyles}
    ${ultraWideScreenCenterHeroImages}
  `}
`;
