import { invert } from './commons';

const langToCode = {
  NEUTRAL: '',
  GERMAN: 'de',
  ENGLISH: 'en',
  RUSSIAN: 'ru',
  CHINESE: 'zh',
  CHINESE_TRADITIONAL: 'zh-hant',
  PORTUGUESE_BRAZIL: 'pt-br',
  ALBANIAN: 'sq',
  AMHARIC: 'am',
  ARABIC: 'ar',
  BENGALI: 'bn',
  BOSNIAN: 'bs',
  BULGARIAN: 'bg',
  DARI: 'fa-af',
  FRENCH: 'fr',
  GREEK: 'el',
  HAUSA: 'ha',
  HINDI: 'hi',
  INDONESIAN: 'id',
  KISWAHILI: 'sw',
  CROATIAN: 'hr',
  MACEDONIAN: 'mk',
  PASHTO: 'ps',
  PERSIAN: 'fa-ir',
  POLISH: 'pl',
  PORTUGUESE_AFRICA: 'pt-002',
  ROMANIAN: 'ro',
  SERBIAN: 'sr',
  SPANISH: 'es',
  TURKISH: 'tr',
  UKRANIAN: 'uk',
  URDU: 'ur',
};

const codeToLang = invert(langToCode);

export const toLangCode = lang => lang && langToCode[lang.toUpperCase()];
export const toLang = code => code && codeToLang[code.toLowerCase()];
export const toEngineName = lang => lang.toLowerCase().replace('_', '-');
export const toIso639LangCode = code => code?.split('-')[0];
export const isDWLangCode = langCode => langCode in codeToLang;
export const getAllLangCodes = () => Object.values(langToCode).filter(Boolean);
