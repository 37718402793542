import { extendWithClassNames } from '../../../../utils/extendWithClassNames.jsx';
// import { selector } from '../../../../utils/css';
import {
  extendFocusTopThreeTeaserCompositionWithStandardTeaserBasicLooks,
  extendTeaserCompositionWithBottomAlignmentLooks,
  extendTeaserCompositionWithSmallImageTextTeaserLooksNotFirst,
  extendWithFocusTopThreeTeaserComposition,
  StyledTeaserComposition as TeaserComposition,
} from '../../TeaserComposition';

export const StyledFocusTopThreeHighlightLayout = extendWithClassNames(TeaserComposition)(
  extendTeaserCompositionWithBottomAlignmentLooks,
  extendTeaserCompositionWithSmallImageTextTeaserLooksNotFirst,
  extendWithFocusTopThreeTeaserComposition,
  extendFocusTopThreeTeaserCompositionWithStandardTeaserBasicLooks,
);
