import gql from 'graphql-tag';
import { InformationDefault } from '../../../../Text/Information.jsx';
import { useContentTeaserContext } from '../../ContentTeaserContext';
import { useTranslation } from '../../../../hooks/useTranslation.jsx';
import { getQuantityTranslation } from '../../../../../utils/i18n';
import { makeMemoFragment } from '../../../../../utils/graphql';

export const teaserFooterImageCountFragment = makeMemoFragment({
  name: 'TeaserFooterImageCount',
  fragment() {
    return gql`fragment ${this.name} on ImageGallery {
      extendedGalleryImages {
        name
      }
    }`;
  },
});

export const TeaserFooterImageCount = ({ className }) => {
  const {
    content,
  } = useContentTeaserContext();

  const slideCount = content.extendedGalleryImages.length - 1;
  const numberOfImages = useTranslation(getQuantityTranslation({
    key: 'component.slideshow.number_of_images',
    count: slideCount,
    parameters: { count: slideCount },
  }));

  return (
    <InformationDefault className={className}>{numberOfImages}</InformationDefault>
  );
};

export const StyledTeaserFooterImageCount = TeaserFooterImageCount;
