import { styled } from '@linaria/react';

import { StyledSpinner as Spinner } from '../Spinner';

export const LoadingMessage = ({ className }) => (
  <div className={className}>
    <Spinner className='spinner' title="Loading..."/>
  </div>
);

export const StyledLoadingMessage = styled(LoadingMessage)`
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  height: 100vh;
  margin-top: 0;

  .spinner {
    position: fixed;
    inset: 0;
    margin: auto;
    opacity: 0;
    animation: fadeIn 500ms cubic-bezier(0.43, 0, 0.09, 1) 1 forwards;
  }
`;
