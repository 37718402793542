import { resolutions } from '../../css';
import { StyledResponsiveDwPicture as ResponsiveDwPicture } from '../../../components/ResponsiveDwPicture';

const getFormatGroupName = ({ rawFormat }) => {
  if (rawFormat.includes('LEGACY')) {
    return 'LEGACY';
  }
  if (rawFormat === 'FREE_IMAGE') {
    return 'FREE';
  }
  return '90X';
};

export const mapImgPlaceholderToPicComponent = matchedElem => {
  const aspectRatioFromCss = matchedElem.style['padding-bottom'];
  const { id, format: rawImageFormat, url } = matchedElem.dataset;

  const alt = matchedElem.getAttribute('alt');
  const { className } = matchedElem;

  const props = {
    image: { id: +id, staticUrl: url },
    alt,
    className,
    formatConfig: [{
      minWidth: resolutions.min.xs,
      maxWidth: resolutions.max.wide_xl,
      formatGroupName: getFormatGroupName({ rawFormat: rawImageFormat }),
    }],
    aspectRatio: +aspectRatioFromCss.slice(0, -1),
    isA: 'div',
  };

  return (
    <ResponsiveDwPicture {...props} />
  );
};
