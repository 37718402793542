import { extendWithClassNames } from '../../../../utils/extendWithClassNames.jsx';
import {
  StyledTeaserComposition as TeaserComposition,
  extendWithFocusTopThreeTeaserComposition,
  extendFocusTopThreeTeaserCompositionWithStandardTeaserBasicLooks,
  extendTeaserCompositionWithBottomAlignmentLooks,
} from '../../TeaserComposition';

export const StyledFocusTopThreeLayout = extendWithClassNames(TeaserComposition)(
  extendTeaserCompositionWithBottomAlignmentLooks,
  extendWithFocusTopThreeTeaserComposition,
  extendFocusTopThreeTeaserCompositionWithStandardTeaserBasicLooks,
);
