import { styled } from '@linaria/react';
import gql from 'graphql-tag';
import classnames from 'classnames';
import { cx } from '@linaria/core';
import { StyledDownload as DownloadIcon } from '../../../icons';
import { Partials, StyledContentTeaser as ContentTeaser, darkPartialStyles } from '../../../ContentTeaserSnippets/ContentTeaserPartials';
import { LinkDefault } from '../../../Links';
import { makeMemoFragment } from '../../../../utils/graphql';
import { useTranslation } from '../../../hooks/useTranslation.jsx';

export const downloadTeaserFragment = makeMemoFragment({
  name: 'DownloadTeaser',
  fragment() {
    return gql`fragment ${this.name} on AssociationsAspect {
      contentLinks {
        targetId
        name
        description
        target {
          ... on Download {
            downloadType
            staticUrl
          }
        }
      }
    }
    `;
  },
});

export const DownloadContentTeaser = ({
  link = {}, translationKey, className,
}) => (
  <ContentTeaser
    className={classnames(className, 'row')}
    link={{ ...link, url: link.target.staticUrl }}
  >
    <Partials.MainContentArea>
      <div className="teaser-data-area">
        <Partials.LinkedTitle isA='h3'/>
        <Partials.LinkedDescription className='d-none d-md-block'/>
        <LinkDefault title={link.name} to={link.target.staticUrl} className="download-link" tabIndex={-1}>
          {useTranslation(
            {
              key: translationKey,
              parameters: { type: link.target.downloadType },
            },
          )}
          <DownloadIcon/>
        </LinkDefault>
      </div>
    </Partials.MainContentArea>
  </ContentTeaser>
);

export const darkDownloadContentTeaserStyles = cx(
  darkPartialStyles.darkLinkedTitleStyles,
  darkPartialStyles.darkLinkedDescriptionStyles,
  LinkDefault.darkStyles,
);

export const StyledDownloadContentTeaser = styled(DownloadContentTeaser)`
  .download-link {
    font-size: 1.2rem;
    display: flex;
    align-items: center;
    padding-top: 20px;
    column-gap: 5px;

    svg {
      width: 13px;
    }
  }
`;
