import gql from 'graphql-tag';
import { css } from '@linaria/core';
import { useContentTeaserContext } from '../ContentTeaserContext';
import { StyledAuthorData as AuthorData, authorDataFragment, darkAuthorDataStyles } from './AuthorData.jsx';
import { commonOpinionStyles } from '../../TeaserComposition/TeaserLayoutRelated/BigTeasers/opinionTeaserCommons';
import { makeMemoFragment } from '../../../../utils/graphql';
import { extendWithClassNames } from '../../../../utils/extendWithClassNames.jsx';

export const opinionFragment = makeMemoFragment({
  name: 'Opinion',
  fragment() {
    return gql`fragment ${this.name} on Content {
        ... on OpinionAspect {
          isOpinion
        }
        ...${authorDataFragment.name}
      }
      ${authorDataFragment.fragment()}
    `;
  },
});

export const Opinion = ({ className }) => {
  const {
    content,
    config: {
      hide: {
        opinion: hideOpinion,
      },
    },
  } = useContentTeaserContext();

  if (hideOpinion || !content.isOpinion) {
    return null;
  }
  return (
    <div className={className}>
      <AuthorData/>
    </div>
  );
};

export const darkOpinionStyles = darkAuthorDataStyles;

export const StyledOpinion = extendWithClassNames(Opinion)(
  commonOpinionStyles,
  css`
  display: flex;
  align-items: center;

  .author-image{
    margin-inline-end: 10px;
  }

  .author-data{
    display: flex;
    flex-direction: column;
  }
`,
);
