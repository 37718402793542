import gql from 'graphql-tag';
import { cx } from '@linaria/core';
import { isValidList } from '../../../utils/contentUtils';
import { darkContentBlockStyles, StyledContentBlock as ContentBlock } from '../ContentBlock';
import { darkExpandableStyles, StyledExpandable as Expandable } from '../../Expandable';
import { getColumnOpinionSeries } from '../../../utils/metaDataHelpers';
import { makeMemoFragment } from '../../../utils/graphql';
import { StyledFocusTopThreeHeadlineOpinionLayout as FocusTopThreeHeadlineOpinionLayout } from '../../ContentTeaserSnippets/Layouts/FocusTopThreeHeadlineOpinionLayout';
import {
  adapterForMoreFromColumnFragment,
  darkExpandAdapterForMoreFromColumnStyles,
  expandAdapterForMoreFromColumn,
} from './expandAdapter';

export const COLUMN_TEASER_AMOUNT = 3;

export const moreFromColumnFragment = makeMemoFragment({
  name: 'MoreFromColumn',
  fragment() {
    return gql`fragment ${this.name} on AssociationsAspect {
      opinionSeries {
        genre
        moreArticlesFromSeries: moreContentsFromSeries(pageId: $id, amount: 6, types:[ARTICLE]) {
          ... on ModelAspect {
            id
          }
          ...${adapterForMoreFromColumnFragment.name}
        }
      }
    }
    ${adapterForMoreFromColumnFragment.fragment()}
    `;
  },
});

export const MoreFromColumn = ({ content, className }) => {
  const { moreArticlesFromSeries } = getColumnOpinionSeries(content) || [];
  if (!isValidList(moreArticlesFromSeries)) {
    return null;
  }
  return (
    <ContentBlock
      className={className}
      headlineIsA="h2"
      headlineTranslation="content_block_detail.more_from_this_column.headline"
      pageSectionId="more-from-column"
    >
      <Expandable
        dataArray={moreArticlesFromSeries}
        initialListCount={COLUMN_TEASER_AMOUNT}
        stepMoreAmount={COLUMN_TEASER_AMOUNT}
        showMoreBtnTranslation="content_block_detail.more_from_this_column.show_more"
      >
        {(...expandableData) => (
          <FocusTopThreeHeadlineOpinionLayout>
            {expandAdapterForMoreFromColumn(...expandableData)}
          </FocusTopThreeHeadlineOpinionLayout>
        )}
      </Expandable>
    </ContentBlock>
  );
};

export const darkMoreFromColumnStyles = cx(
  darkContentBlockStyles,
  darkExpandableStyles,
  darkExpandAdapterForMoreFromColumnStyles,
);

export const StyledMoreFromColumn = MoreFromColumn;
