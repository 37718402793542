import gql from 'graphql-tag';
import { isValidList } from '../../../utils/contentUtils';
import { linkWithArrowFragment, StyledLinkWithArrow as LinkWithArrow } from '../LinkWithArrow.jsx';
import {
  listDefaultHighlightSmallImageTextFragment,
  StyledListDefaultHighlightSmallImageText as ListDefaultHighlightSmallImageTextTemplate,
} from '../../ContentTeaserSnippets/Templates/ListDefaultHighlightSmallImageText.jsx';
import { noop } from '../../../utils/commons';
import { autoTopicHeaderFragment, StyledAutoTopicHeader as AutoTopicHeader } from './AutoTopicHeader.jsx';
import { makeMemoFragment } from '../../../utils/graphql';

export const topicClusterAutomaticConfigFragment = makeMemoFragment({
  name: 'TopicClusterAutomaticConfig',
  fragment() {
    return gql`fragment ${this.name} on CompositionComponent {
        configuration {
          sourceAssociations {
            targetId
            target {
              ... on DeliveryAspect {
                autoDelivery
              }
              ...${linkWithArrowFragment.name}
              ...${autoTopicHeaderFragment.name}
            }
          }
        }
      }
      ${linkWithArrowFragment.fragment()}
      ${autoTopicHeaderFragment.fragment()}
    `;
  },
});

export const topicClusterAutomaticFragment = listDefaultHighlightSmallImageTextFragment;

const makeBottomLinkFn = autoTopicContent => () => ((
  <LinkWithArrow
    content={autoTopicContent}
    linkTranslation="content_block.topic_cluster_automatic.all" />
));

export const TopicClusterAutomatic = ({
  contents = [],
  configuration = {},
  className,
  pageSectionId = 'topic-cluster-automatic',
  headlineTranslation = 'content_block.topic_cluster.headline',
}) => {
  if (!isValidList(contents)) {
    return null;
  }

  const autoTopicContent = configuration.sourceAssociations[0].target;

  return (
    <ListDefaultHighlightSmallImageTextTemplate
      className={className}
      bottomElementFn={autoTopicContent.autoDelivery ? makeBottomLinkFn(autoTopicContent) : noop }
      headlineTranslation={headlineTranslation}
      pageSectionId={pageSectionId}
      contents={contents}
      preListSlotFn={
        () => <AutoTopicHeader autoTopic={autoTopicContent} />
      }
    />

  );
};

export const StyledTopicClusterAutomatic = TopicClusterAutomatic;
