import {
  useState, useEffect,
} from 'react';
import globals from '../../utils/globals';
import { useEventListener } from '../hooks/useEventListener';
import { isServer } from '../../utils/ssr';

export const getWidth = () => (isServer()
  ? 0
  : globals.window?.visualViewport?.width || globals.window?.innerWidth
);

const RESIZE_EVENT = 'resize';

export function useWindowInnerWidth({ isAlwaysReset = false } = {}) {
  const initialWidthValue = isAlwaysReset ? 0 : getWidth() || 0;

  const [innerWidth, setInnerWidth] = useState(initialWidthValue);
  const onResize = () => {
    setInnerWidth(getWidth());
  };
  useEventListener(globals.window, RESIZE_EVENT, onResize);

  useEffect(() => {
    // This is needed because when "isAlwaysReset" is true. For example the <Carousel> renders different HTML depending on
    // the width returned here, so during rehydration the value needs to be 0 (same value as on the server).
    // But then the width needs to be set to the proper width to align the UI accordingly
    onResize();
  }, []);

  return innerWidth;
}
