import { styled } from '@linaria/react';
import { cx } from '@linaria/core';

import { HeadlineSemiBoldBlueGrey04AndBlueGrey03, HeadlineSemiBoldDwDarkBlueAndWhite } from '../../Text/Headline.jsx';
import { mediaMin } from '../../../utils/css';
import { topStoryTitleMargin } from '../TopStory/TopStoryContentTeaserTitle.jsx';
import { contentBlockWidth } from '../ContentBlock';

export const TopicHeaderTitle = ({ className, children, aboveImage }) => {
  const TextComponent = aboveImage ? HeadlineSemiBoldDwDarkBlueAndWhite : HeadlineSemiBoldBlueGrey04AndBlueGrey03;
  return (
    <TextComponent isA="h1" className={cx(HeadlineSemiBoldDwDarkBlueAndWhite.darkStyles, className)}>
      {children}
    </TextComponent>
  );
};

export const darkTopicHeaderTitleStyles = HeadlineSemiBoldBlueGrey04AndBlueGrey03.darkStyles;

export const StyledTopicHeaderTitle = styled(TopicHeaderTitle)`
  font-size: 2.4rem;
  word-wrap: break-word;
  margin-bottom: 10px;
  text-align: center;
  ${topStoryTitleMargin}

  ${mediaMin.sm`
    font-size: 3.2rem;
  `}

  ${mediaMin.md`
    font-size: 4.1rem;
    margin-bottom: 20px;
  `}

  ${mediaMin.lg`
    font-size: 5.0rem;
    margin-bottom: 30px;
    max-width: ${contentBlockWidth.lg}px;
    margin-inline: auto;
  `}

  ${mediaMin.xl`
    font-size: 6.0rem;
    max-width: ${contentBlockWidth.xl}px;
  `}
`;
