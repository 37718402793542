import gql from 'graphql-tag';
import { makeMemoFragment } from '../../../utils/graphql';

export const geographicRegionFragment = makeMemoFragment({
  name: 'GeographicRegionHeadline',
  fragment() {
    return gql`fragment ${this.name} on GeographicRegion {
        alignedName
      }
    `;
  },
});

export const GeographicRegionHeadline = ({ geographicRegion }) => (
  <>
    {geographicRegion.alignedName}
  </>
);
