import classnames from 'classnames';
import { styled } from '@linaria/react';
import { cx } from '@linaria/core';
import gql from 'graphql-tag';
import { toLangCode } from '../../../utils/langMapper';
import languageNameTranslations from './languages.json';
import { StyledCheck as CheckIcon } from '../../icons';
import { colors } from '../../../utils/css';
import { useHeaderStateContext } from '../../Header/HeaderStateContext';
import { makeMemoFragment } from '../../../utils/graphql';
import { LanguageLink } from '../../Links';
import { InfoSystemFont } from '../../Text/Information.jsx';
import { resetBoostrapAndBrowserDefaultLineHeight } from '../../../utils/commons';

export const languageSelectorItemFragment = makeMemoFragment(({
  name: 'LanguageSelectorItem',
  fragment() {
    return gql`fragment ${this.name} on Navigation {
        language
        isLive
        canonicalUrl
        namedUrl
        localeLang
    }`;
  },
}));

export const LanguageSelectorItem = ({
  className,
  pageLanguage,
  topStoryNavigation,
}) => {
  const {
    language,
    namedUrl,
    localeLang,
  } = topStoryNavigation;

  const langCodeTopStory = toLangCode(language);
  const isActiveLang = pageLanguage === language;
  const nativeLang = languageNameTranslations.native[langCodeTopStory];
  const { isLanguageToggled } = useHeaderStateContext();

  return (
    <li className={classnames(className, { active: isActiveLang })}>
      { isActiveLang && <CheckIcon isHidden={!isLanguageToggled} className="check-icon" />}
      <LanguageLink
        to={namedUrl}
        className={LanguageLink.darkStyles}
        hrefLang={localeLang.replace('_', '-')}
      >
        <span>{languageNameTranslations.language[langCodeTopStory]}</span>
        <InfoSystemFont className={cx('native-text', langCodeTopStory, InfoSystemFont.darkStyles)}>{nativeLang}</InfoSystemFont>
      </LanguageLink>
    </li>
  );
};

export const StyledLanguageSelectorItem = styled(LanguageSelectorItem)`
  font-size: 1.3rem;
  text-align: start;
  position: relative;
  ${resetBoostrapAndBrowserDefaultLineHeight}

  .native-text {
    margin-inline-start: 6px;
  }

  .ar, .fa-af, .fa-ir, .ps, .ur {
    font-size: 1.5rem;
  }

  &.active {
    .check-icon {
      position: absolute;
      inset-inline-start: -25px;
      fill: ${colors.WHITE};
    }
  }
`;
