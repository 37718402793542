import { styled } from '@linaria/react';
import { cx } from '@linaria/core';

import { StyledSocialMediaIconLink as SocialMediaIconLink, darkSocialMediaIconLinkStyles } from '../../SocialMediaIconLink';

export const SocialMediaAccountLink = ({
  socialMediaAccount,
  className,
  ...restProps
}) => {
  const { service, url } = socialMediaAccount;
  return (
    <SocialMediaIconLink className={cx(className, darkSocialMediaIconLinkStyles)}
      socialMediaId={service}
      url={url}
      title={service}
      {...restProps}
    />
  );
};

export const StyledSocialMediaAccountLink = styled(SocialMediaAccountLink)`
  width: 40px;
  margin: 0;
  margin-inline-end: 8px;
`;
