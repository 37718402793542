import gql from 'graphql-tag';
import { cx } from '@linaria/core';
import { makeMemoFragment } from '../../utils/graphql';
import { episodesFragment, Episodes } from '../ContentBlocks/Episodes';
import { StyledMoreOnTopic as MoreOnTopic, moreOnTopicFragment, darkMoreOnTopicStyles } from '../ContentBlocks/MoreOnTopic';
import { StyledAllProgramsTeaser as AllProgramsTeaser, allProgramsTeaserFragment } from '../ContentBlocks/AllProgramsTeaser';

export const programRecommendedZoneFragment = makeMemoFragment({
  name: 'ProgramRecommendedZone',
  fragment() {
    return gql`fragment ${this.name} on UnifiedProgram {
      ...${episodesFragment.name}
      ...${moreOnTopicFragment.name}
      programsOverview {
        ...${allProgramsTeaserFragment.name}
      }

    }
    ${episodesFragment.fragment()}
    ${moreOnTopicFragment.fragment()}
    ${allProgramsTeaserFragment.fragment()}
    `;
  },
});

export const ProgramRecommendedZone = ({ className, content }) => (
  <>
    <Episodes className={className} content={content} />
    <MoreOnTopic className={cx(className, darkMoreOnTopicStyles)} content={content} />
    <AllProgramsTeaser className={className} programsOverview={content.programsOverview}/>
  </>
);

export const StyledProgramRecommendedZone = ProgramRecommendedZone;
