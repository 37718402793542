import gql from 'graphql-tag';
import { styled } from '@linaria/react';
import { css, cx } from '@linaria/core';

import { mediaMin, colors } from '../../utils/css';
import { darkTimeAreaStyles, StyledTimeArea as TimeArea, timeAreaFragment } from './TimeArea.jsx';
import { darkTeaserTextStyles, StyledTeaserText as TeaserText } from '../TeaserText.jsx';
import { Partials, partialFragments } from '../ContentTeaserSnippets/ContentTeaserPartials';
import { ContentTeaserContext, contentTeaserSetup } from '../ContentTeaserSnippets/ContentTeaserPartials/ContentTeaserContext';
import { HeadlineSemiBoldItalicDwDarkBlueAndWhite } from '../Text/Headline.jsx';
import {
  StyledContentDetailKicker as ContentDetailKicker,
  contentDetailKickerFragment,
  darkContentDetailKickerStyles,
} from './ContentDetailKicker.jsx';
import { makeMemoFragment } from '../../utils/graphql';
import { StyledContentStatusHeaderBadge as ContentStatusHeaderBadge, contentStatusBadgeFragment } from '../ContentStatusBadge.jsx';
import { darkOpinionStyles } from '../ContentTeaserSnippets/ContentTeaserPartials/Partials/Opinion.jsx';

const hideOpinion = contentTeaserSetup.config.hide.opinion;

export const opinionDetailHeaderFragment = makeMemoFragment({
  name: 'OpinionDetailHeader',
  fragment() {
    return gql`fragment ${this.name} on Article {
          kicker
          title
          ...${contentStatusBadgeFragment.name}
          ...${timeAreaFragment.name}
          ...${contentDetailKickerFragment.name}
          ...${partialFragments.opinionFragment.name}
        }
        ${contentStatusBadgeFragment.fragment()}
        ${timeAreaFragment.fragment()}
        ${contentDetailKickerFragment.fragment()}
        ${partialFragments.opinionFragment.fragment()}
    `;
  },
});

export const OpinionDetailHeader = ({
  content,
  className,
}) => (
  <ContentTeaserContext.Provider value={{
    config: {
      hide: {
        opinion: hideOpinion,
      },
    },
    content,
  }}>
    <header className={className}>
      <div className="background-wrapper">
        <ContentStatusHeaderBadge content={content}/>
        <ContentDetailKicker content={content} />
        <HeadlineSemiBoldItalicDwDarkBlueAndWhite isA="h1" className="title">{content.title}</HeadlineSemiBoldItalicDwDarkBlueAndWhite>
        <Partials.Opinion />
      </div>
      <TimeArea className="time-area" content={content} />
      {content.teaser &&
        <TeaserText className="teaser-text">{content.teaser}</TeaserText>
      }
    </header>
  </ContentTeaserContext.Provider>
);

export const cssVariables = {
  background: '--opinion-detail-header-background',
};

export const darkOpinionDetailHeaderStyles = cx(
  darkContentDetailKickerStyles,
  HeadlineSemiBoldItalicDwDarkBlueAndWhite.darkStyles,
  darkTimeAreaStyles,
  darkOpinionStyles,
  darkTeaserTextStyles,
  css`${cssVariables.background}: ${colors.DARK_BLUE_GREY_02};`,
);

export const StyledOpinionDetailHeader = styled(OpinionDetailHeader)`
  .background-wrapper {
    position: relative;
    overflow: visible;
    padding-bottom: 30px;
    margin-bottom: 27px;
    z-index: 1;
    &:before {
      content: "";
      z-index: -1;
      position: absolute;
      bottom: 0;
      inset-inline-start: -15px;
      width: 100vw;
      height: calc(100% + 30px);
      background-color: var(${cssVariables.background}, ${colors.BLUE_GREY_01});
    }
  }

  .title {
    font-size: 3rem;
    margin-bottom: 20px;
    word-wrap: break-word;
  }

  .time-area {
    margin-top: 5px;
  }

  .teaser-text {
    margin-bottom: 10px;
    margin-top: 10px;
  }

  .author-image {
    height: 80px;
    width: 80px;
  }

  .author-data{
    .author-link, .no-link {
      font-size: 1.6rem;
    }
  }

  ${mediaMin.sm`
    .author-data {
      .opinion-metadata {
        font-size: 1.6rem;
      }
    }
    .background-wrapper {
      margin-bottom: 30px;
      &:before {
        inset-inline-start: -45px;
      }
    }
  `}

  ${mediaMin.md`
    .kicker {
      margin-bottom: 10px;
    }
    .title {
      font-size:3.8rem;
      margin-bottom: 25px;
    }
    .author-image {
      height: 120px;
      width: 120px;
    }
    .author-data {
      .author-link, .no-link {
        font-size: 1.8rem;
      }
      .opinion-metadata {
        font-size: 1.8rem;
      }
    }
    .background-wrapper {
      padding-bottom: 40px;
      &:before {
        inset-inline-start: -40px;
      }
    }
  `}

  ${mediaMin.lg`
    .title {
      font-size: 4.6rem;
    }
    .background-wrapper {
      margin-bottom: 27px;
      &:before {
        inset-inline-start: -95px;
      }
    }
  `}
  ${mediaMin.xl`
    .background-wrapper {
      &:before {
        inset-inline-start: calc(-50vw + (1110px / 2) - 110px);
      }
    }
  `}
`;
