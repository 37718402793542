import { styled } from '@linaria/react';
import { cx } from '@linaria/core';

import { HeadlineSemiBoldDwDarkBlueAndWhite } from './Text/Headline.jsx';
import { StyledMediaTag as MediaTag } from './MediaTag.jsx';
import { mediaMin } from '../utils/css';

export const MediaTitle = ({
  className,
  isA = 'h2',
  'aria-label': ariaLabel,
  contentType,
  title,
}) => (
  <HeadlineSemiBoldDwDarkBlueAndWhite
    isA={isA}
    aria-label={ariaLabel}
    className={cx(className, HeadlineSemiBoldDwDarkBlueAndWhite.darkStyles)}
  >
    <MediaTag contentType={contentType}/>
    <span>{title}</span>
  </HeadlineSemiBoldDwDarkBlueAndWhite>
);

export const StyledMediaTitle = styled(MediaTitle)`
  font-size: 3rem;
  margin: 0;

  ${mediaMin.md`
    font-size: 3.8rem;
  `}
  ${mediaMin.lg`
    font-size: 4.6rem;
  `}

`;
