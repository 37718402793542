import { cx, css } from '@linaria/core';

import { StyledChevronOpenClose as ChevronOpenClose } from '../../icons';
import { useTranslation } from '../../hooks/useTranslation.jsx';
import { ButtonSecondary } from '../Button';

export const ShowMoreButton = ({
  className,
  translation,
  isOpen = false,
  ...forwardedProps
}) => {
  const showMoreText = useTranslation(translation);
  return (
    <ButtonSecondary
      className={cx(className, showMoreButtonStyles)}
      data-tracking-name="show-more"
      {...forwardedProps}
    >
      {showMoreText}
      <ChevronOpenClose pointsDown={!isOpen} className="more-icon"/>
    </ButtonSecondary>
  );
};

export const showMoreButtonStyles = css`
  .more-icon {
    width: 22px;
    height: 22px;
  }
`;

export const StyledShowMoreButton = ShowMoreButton;

StyledShowMoreButton.darkStyles = ButtonSecondary.darkStyles;
