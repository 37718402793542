import { styled } from '@linaria/react';

// This component was created for demo purposes
// and can be used everywhere where final component
// is not known yet.
export const Placeholder = ({ isA = 'div', className, children }) => {
  const Tag = isA;
  return (
    <Tag className={className}>
      {children}
    </Tag>
  );
};

export const StyledPlaceholder = styled(Placeholder)`
    color: white;
    background-color: #CED4D6;
    height: 100%;
    text-align: center;
    overflow: hidden;
`;

// To be used where placeholders are stacked together in e.g. temporary component
// implementation and not configurable from the outside. For other cases simple
// styled placeholder should be used and extended with needed styles
export const StyledPlaceholderWithGaps = styled(StyledPlaceholder)`
    padding-inline: 5px;
    margin-inline: 15px;
`;
