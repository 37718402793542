import gql from 'graphql-tag';
import { useEffect } from 'react';

import { useLocation } from '../../dwouter';
import { makeMemoFragment } from '../../utils/graphql';
import { darkLiveblogPostStyles, liveblogPostFragment, StyledLiveblogPost as LiveblogPost } from '../LiveblogPost';

export const liveblogPostSharingDetailsFragment = makeMemoFragment({
  name: 'LiveblogPostSharingDetails',
  fragment() {
    return gql`fragment ${this.name} on LiveblogElement {
        liveblog {
          namedUrl
        }
        ...${liveblogPostFragment.name}
      }
      ${liveblogPostFragment.fragment()}
    `;
  },
});

export const LiveblogPostSharingDetails = ({ content = {}, className }) => {
  const [, setLocation] = useLocation();
  const { liveblog, id } = content;
  const liveblogPostUrl = `${liveblog.namedUrl}#liveblog-post-${id}`;

  useEffect(() => {
    setLocation(liveblogPostUrl, { replace: true });
  }, [liveblogPostUrl, setLocation]);

  return (
    <LiveblogPost className={className} content={content} sectionId="no-id-here" />
  );
};

export const darkLiveblogPostSharingDetailsStyles = darkLiveblogPostStyles;

export const StyledLiveblogPostSharingDetails = LiveblogPostSharingDetails;
