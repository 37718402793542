const config = {
  graphApi: {
    baseUrl: import.meta.env.VITE_GRAPH_API_BASE_URL,
  },
  searchApi: {
    baseUrl: import.meta.env.VITE_SEARCH_API_BASE_URL,
  },
};

export default config;
