import { useEffect, useRef } from 'react';

export const useIsMountedFn = () => {
  const ref = useRef(false);
  useEffect(() => {
    // eslint-disable-next-line fp/no-mutation
    ref.current = true;
    return () => {
      // eslint-disable-next-line fp/no-mutation
      ref.current = false;
    };
  }, []);
  return () => ref.current;
};
