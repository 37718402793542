import { styled } from '@linaria/react';
import { css, cx } from '@linaria/core';
import { noop } from '../../../utils/commons';
import {
  colors, mediaDevice, selector,
} from '../../../utils/css';
import { isEnterOrSpacebar, keyCodes } from '../../../utils/keyCodes';
import { focusTargetClassName } from '../../FlyingFocus/constants';
import { StyledTextWideSemiBold as TextWideSemiBold } from '../../Text/TextBase';

export const ButtonBase = ({
  className,
  isA = 'div',
  onClick = noop,
  onKeyDown = noop,
  children,
  disabled,
  'aria-hidden': ariaHidden,
  tabIndex = (disabled || ariaHidden) ? -1 : 0,
  ...restProps
}) => (
  <TextWideSemiBold
    isA={isA}
    className={cx(className, disabled && 'disabled', interactionCss)}
    role="button"
    tabIndex={tabIndex}
    aria-hidden={ariaHidden}
    onClick={onClick}
    onKeyDown={e => {
      onKeyDown(e);
      if (isEnterOrSpacebar(e)) {
        e.key === keyCodes.SPACEBAR && e.preventDefault();
        onClick(e);
      }
    }}
    { ...restProps } >
    {children}
  </TextWideSemiBold>
);

export const cssVariables = {
  color: '--button-color',
  background: '--button-background',
  hoverColor: '--button-hover-color',
  activeColor: '--button-active-color',
  activeBg: '--button-active-background-color',
  disabledBg: '--button-disabled-background-color',
};

// TODO linaria-next css`` ???
export const commonHoverAndFocusCss = `
    box-shadow: 0 0 0 2px var(${cssVariables.hoverColor}, ${colors.DW_DARK_BLUE}) inset;
    text-decoration: none;
`;

export const interactionCss = css`
  &.${focusTargetClassName} {
    ${commonHoverAndFocusCss}
  }
  ${mediaDevice.mouse`
    &:hover {
      ${commonHoverAndFocusCss}
      color: var(${cssVariables.color}, ${colors.DW_DARK_BLUE});
    }
  `}
  &&:active  {
    background: var(${cssVariables.activeBg}, ${colors.DW_DARK_BLUE});
    color: var(${cssVariables.activeColor}, ${colors.WHITE});
    box-shadow: none;

    svg {
      fill: var(${cssVariables.activeColor}, ${colors.WHITE});
      path {
        stroke: var(${cssVariables.activeColor}, ${colors.WHITE});
      }
    }
  }
`;

export const StyledButtonBase = styled(ButtonBase)`
  text-decoration: none;
  display: block;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  outline: none;

  background: var(${cssVariables.background}, transparent);
  color: var(${cssVariables.color}, ${colors.DW_DARK_BLUE});
  svg {
    fill: currentColor;
    path {
      stroke: currentColor;
    }
  }

  &${selector.disabled} {
    cursor: default;
    pointer-events: none;
  }
`;

StyledButtonBase.darkStyles = css`
  ${cssVariables.hoverColor}: ${colors.WHITE};
  ${cssVariables.color}: ${colors.WHITE};
  ${cssVariables.activeColor}: ${colors.DW_DARK_BLUE};
  ${cssVariables.activeBg}: ${colors.WHITE};
  ${cssVariables.disabledBg}: ${colors.BLUE_GREY_04};
`;
