import { useLayoutEffect, useState } from 'react';
import globals from '../../utils/globals';
import { getElementId, toMultiSizeArray } from './slotCommons';
import { useLeadingPageContent } from '../LeadingPageContentContext.jsx';
import { networkCode } from './slotConfigs';

export const useSlotSetupEffect = ({ slotConfigEntry }) => {
  const { leadingContent } = useLeadingPageContent();

  const [slot, setSlot] = useState(null);
  useLayoutEffect(() => {
    const { googletag } = globals.window;
    const elementId = getElementId(slotConfigEntry);
    // eslint-disable-next-line fp/no-let
    let newSlot;
    googletag.cmd.push(() => { // eslint-disable-line fp/no-mutating-methods
      const allSizes = toMultiSizeArray(slotConfigEntry.slotSize);
      // eslint-disable-next-line fp/no-mutation
      newSlot = googletag.defineSlot(`/${networkCode}/${slotConfigEntry.adUnitCode}`, allSizes, elementId);
      newSlot.addService(googletag.pubads());
      if (leadingContent) {
        newSlot.setTargeting('language', leadingContent.language);
        newSlot.setTargeting('type', leadingContent.__typename);
        newSlot.setTargeting('id', leadingContent.id);
      }

      setSlot(newSlot);
      googletag.display(newSlot);
    });
    return () => {
      if (newSlot) {
        googletag.destroySlots([newSlot]);
        setSlot(null);
        // eslint-disable-next-line fp/no-mutation
        newSlot = null;
      }
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [leadingContent?.id, slotConfigEntry]);
  return slot;
};
