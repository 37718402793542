import gql from 'graphql-tag';

import {
  StyledHubDefaultUnifiedSmallImageText as HubDefaultUnifiedSmallImageTextTemplate,
  hubDefaultUnifiedSmallImageTextFragment, darkHubDefaultUnifiedSmallImageTextStyles,
} from '../../../ContentTeaserSnippets/Templates/HubDefaultUnifiedSmallImageText.jsx';
import { geographicRegionFragment, GeographicRegionHeadline } from '../GeographicRegionHeadline.jsx';
import { geographicRegionLinkFragment, GeographicRegionLink } from '../GeographicRegionLink.jsx';
import { isValidList } from '../../../../utils/contentUtils';
import { makeMemoFragment } from '../../../../utils/graphql';

export const geoRegionStoriesConfigurationFragment = makeMemoFragment({
  name: 'GeoRegionStoriesConfiguration',
  fragment() {
    return gql`fragment ${this.name} on CompositionComponentPartConfiguration {
        geographicRegion {
          ...${geographicRegionFragment.name}
          ...${geographicRegionLinkFragment.name}
        }
      }
      ${geographicRegionFragment.fragment()}
      ${geographicRegionLinkFragment.fragment()}
    `;
  },
});

export const highPriorityStoriesFragment = makeMemoFragment({
  name: 'HighPriorityStories',
  fragment() {
    return gql`fragment ${this.name} on CocoContent {
        configuration {
          ...${geoRegionStoriesConfigurationFragment.name}
        }
        content {
          ...${hubDefaultUnifiedSmallImageTextFragment.name}
        }
      }
      ${geoRegionStoriesConfigurationFragment.fragment()}
      ${hubDefaultUnifiedSmallImageTextFragment.fragment()}
    `;
  },
});

export const geoRegionHeadlineFn = cocoContent => (
  <GeographicRegionHeadline geographicRegion={cocoContent?.configuration?.geographicRegion}/>
);
export const geoRegionBottomLinkFn = cocoContent => (
  <GeographicRegionLink geographicRegion={cocoContent?.configuration?.geographicRegion}/>
);

export const HighPriorityStories = ({
  contents,
  hubHeadlineFn = geoRegionHeadlineFn,
  hubBottomElementFn = geoRegionBottomLinkFn,
  ...restProps
}) => (
  isValidList(contents) && (
    <HubDefaultUnifiedSmallImageTextTemplate
      hubContents={contents}
      hubHeadlineFn={hubHeadlineFn}
      hubBottomElementFn={hubBottomElementFn}
      hideDescription
      {...restProps}
    />
  )
);

export const darkHighPriorityStoriesStyles = darkHubDefaultUnifiedSmallImageTextStyles;

export const StyledHighPriorityStories = HighPriorityStories;
