import gql from 'graphql-tag';
import { styled } from '@linaria/react';
import { StyledRightChevron as RightArrowIcon } from '../icons';
import { LinkDefault } from '../Links';
import { makeMemoFragment } from '../../utils/graphql';
import { useTranslation } from '../hooks/useTranslation.jsx';

export const linkWithArrowFragment = makeMemoFragment({
  name: 'LinkWithArrow',
  fragment() {
    return gql`fragment ${this.name} on Content {
        ... on UrlAspect {
          namedUrl
        }
        ... on NamedAspect {
          title
        }
      }
    `;
  },
});

export const LinkWithArrow = ({
  content,
  className,
  linkTranslation,
  ...restProps
}) => {
  const linkText = useTranslation(linkTranslation);
  return (
    <LinkDefault
      className={className}
      to={content.namedUrl}
      skipTitleForInternal
      title={content.title}
      {...restProps}
    >
      {linkText}
      <RightArrowIcon className="arrow-icon"/>
    </LinkDefault>
  );
};

export const darkLinkWithArrowStyles = LinkDefault.darkStyles;

export const StyledLinkWithArrow = styled(LinkWithArrow)`
    display: flex;
    font-size: 1.4rem;
    align-items: end;
    column-gap: 5px;

    .arrow-icon {
      margin-inline-end: -3px;
      height: 12px;
      width: 12px;
      path {
        stroke-width: 3;
        stroke: currentColor;
     }
    }
`;
