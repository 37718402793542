import { styled } from '@linaria/react';
import { css } from '@linaria/core';
import { colors } from '../../utils/css';

export const cssVariables = {
  basicBgColor: '--coco-page-layout-basic-color',
  alternativeBgColor: '--coco-page-layout-alternative-color',
};

export const darkCoCoComponentBackgroundSelectorStyles = css`
  ${cssVariables.basicBgColor}: ${colors.DARK_BLUE_GREY_01};
  ${cssVariables.alternativeBgColor}: ${colors.DARK_BLUE_GREY_02};
`;

export const CoCoComponentBackgroundSelector = ({ className, ...restProps }) => (
  <div className={`content-blocks ${className}`} {...restProps} />
);

export const StyledCoCoComponentBackgroundSelector = styled(CoCoComponentBackgroundSelector)`
  &.content-blocks .basic-color {
    display: flow-root;
    background: var(${cssVariables.basicBgColor}, transparent);
  }
  &.content-blocks > .auto-bg-color:nth-of-type(2n+1),
  &.content-blocks > .auto-bg-color:nth-of-type(2n+1) .carousel {
    background: var(${cssVariables.basicBgColor}, transparent);
  }
  &.content-blocks > .auto-bg-color:nth-of-type(2n),
  &.content-blocks > .auto-bg-color:nth-of-type(2n) .carousel {
    background: var(${cssVariables.alternativeBgColor}, ${colors.BLUE_GREY_01});
  }

  &.content-blocks > *:first-child .quick-item {
    top: 2px;
  }
`;
