import { useState } from 'react';
import { styled } from '@linaria/react';
import { cx } from '@linaria/core';

import { StyledContentDetailRichText as ContentDetailRichText, contentDetailRichTextFragment, darkDetailRichTextStyles } from './ContentDetailRichText';
import { StyledShowMoreButton as ShowMoreButton } from './buttons/ShowMoreButton';
import { StyledButtonBar as ButtonBar } from './buttons/Button/ButtonBar.jsx';

export const expandableDetailRichTextFragment = contentDetailRichTextFragment;

export const ExpandableDetailRichText = ({
  content,
  isOpen: initialIsOpen = false,
  className,
  translationKeyPrefix,
}) => {
  const [isOpen, toggleIsOpen] = useState(initialIsOpen);
  const toggleableClassName = isOpen ? 'opened' : 'closed';
  const readMoreButtonTranslationKey = [translationKeyPrefix, isOpen ? 'close' : 'open'].join('.');

  return (
    <div className={`${className} ${toggleableClassName}`}>
      <ContentDetailRichText isA="section" content={content} className="details-text" replacePlaceholders={isOpen} />
      <ButtonBar>
        <ShowMoreButton
          onClick={() => toggleIsOpen(!isOpen)}
          translation={readMoreButtonTranslationKey}
          aria-expanded={isOpen}
          isOpen={isOpen}
        />
      </ButtonBar>
    </div>
  );
};

export const darkExpandableDetailRichTextStyles = cx(
  darkDetailRichTextStyles,
  ShowMoreButton.darkStyles,
);

export const StyledExpandableDetailRichText = styled(ExpandableDetailRichText)`
  padding-top: 10px;
  padding-bottom: 20px;

  .details-text {
    padding-bottom: 30px;
    > *:last-child {
      margin-bottom: 0;
    }
  }

  &.closed {
    padding-top: 30px;
    .details-text {
      display: none;
    }
  }
`;
