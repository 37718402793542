import { render, hydrate } from 'preact';
import { HelmetProvider } from 'react-helmet-async';
import { defaultFallbackInView } from 'react-intersection-observer';

import { Router } from './dwouter';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

import { App } from './App.jsx';

import globals from './utils/globals';

// Sets a default returned by InView in browsers that do not support intersection observer. We do that only for the browser code.
// Value "true" should allow all lazy loaded images to be rendered in browsers based on old solutions (looking at you Psiphone)
defaultFallbackInView(true);

const container = document.getElementById('root');
const app = (
  <Router>
    <HelmetProvider>
      <App></App>
    </HelmetProvider>
  </Router>
);

// hydration should only happen if the HTML was prerendered on server side. To test if this is true,
// we can check for an element that should always be present and always different for ssr and non-ssr.
export const runApp = ({ isProd }) => {
  if (JSON.parse(isProd) && globals.document.querySelector('title[data-rh=true]')) {
    hydrate(app, container);
  } else {
    render(app, container);
  }

  serviceWorkerRegistration.register();
};

// done this way to allow testing (vitest does not allow to override PROD/DEV mode variable)
runApp({ isProd: import.meta.env.PROD || 'false' });
