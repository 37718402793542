import { useLayoutEffect, useCallback } from 'react';
import { useLocation } from '../../dwouter';
import { scrollToElement, waitForElementById } from '../../utils/commons';
import { useEventListener } from './useEventListener';
import globals from '../../utils/globals';

const SCROLL_OFFSET = 200;

export function useScrollToLocationHashElement() {
  const [{ hash, pathname }] = useLocation();
  const scrollToHashElement = useCallback(async () => {
    if (!hash) {
      return;
    }
    const elementToScroll = await waitForElementById({ id: hash.replace('#', '') });
    scrollToElement({ element: elementToScroll, offset: SCROLL_OFFSET });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  useEventListener(globals.window, 'CONSENT_DATA_EVENT', () => {
    scrollToHashElement();
  });

  useLayoutEffect(() => {
    scrollToHashElement();
  }, [scrollToHashElement]);
}
