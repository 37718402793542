const isNotDisplayNone = elem => getComputedStyle(elem.parentNode)?.display !== 'none';
export const setFocusInList = ({ listElement, indexToFocus, listEntryClass }) => {
  const elements = listElement?.getElementsByClassName(listEntryClass);
  if (elements?.length > indexToFocus) {
    const lastVisibleElement = elements?.[indexToFocus];
    const focusableElements = lastVisibleElement && [...lastVisibleElement.querySelectorAll('a:not([tabindex="-1"])')];
    const elementToFocus = focusableElements?.find(isNotDisplayNone);
    elementToFocus && elementToFocus.focus();
  }
};
