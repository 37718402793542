import { useEffect } from 'react';
import { useFrontendConfig } from '../../FrontendConfigContext.jsx';
import { loadScriptPromise } from '../../../utils/staticResourceHelpers';
import globals from '../../../utils/globals';
import { useLocation } from '../../../dwouter/use-location';
import { usePrev } from '../../hooks/usePrev';
import { useFeatureFlag } from '../../hooks/useFeatureFlag';
import { useMobileAppContext } from '../../hooks/mobileAppContext';

export const TaboolaLoadScript = () => {
  const { taboolaPathFragment } = useFrontendConfig();
  const hasFeatureFlag = useFeatureFlag();
  const [{ pathname }] = useLocation();
  const { isMobileMode } = useMobileAppContext();
  const prevPathName = usePrev(pathname);

  const isTaboolaEnabled = taboolaPathFragment && hasFeatureFlag && !isMobileMode;

  useEffect(() => {
    if (isTaboolaEnabled) {
      // eslint-disable-next-line no-underscore-dangle, fp/no-mutation
      globals.window._taboola = globals.window._taboola || [];
      // eslint-disable-next-line no-underscore-dangle, fp/no-mutating-methods
      globals.window._taboola.push({ article: 'auto' }); // TODO: should we pass the URL parameter as mentioned here: https://developers.taboola.com/web-integrations/docs/spa ?
      // TODO: handle the case when lang switched - script tag needs to be re-rendered or we just add another one?
      loadScriptPromise({
        url: `//cdn.taboola.com/libtrc/${taboolaPathFragment}/loader.js`,
        scriptId: 'tb_loader_script',
        vendorId: '42',
      });
    }
  }, [isTaboolaEnabled, taboolaPathFragment]);

  useEffect(() => {
    if (isTaboolaEnabled && (pathname !== prevPathName) && prevPathName) {
      // eslint-disable-next-line no-underscore-dangle, fp/no-mutating-methods
      globals.window._taboola.push({ notify: 'newPageLoad' });
    }
  }, [pathname, prevPathName, isTaboolaEnabled]);

  return null;
};
