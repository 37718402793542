import gql from 'graphql-tag';
import { InformationDefault } from '../../../../Text/Information.jsx';
import { useContentTeaserContext } from '../../ContentTeaserContext';
import { useTranslation } from '../../../../hooks/useTranslation.jsx';
import { makeMemoFragment } from '../../../../../utils/graphql';

export const teaserFooterDurationFragment = makeMemoFragment({
  name: 'TeaserFooterDuration',
  fragment() {
    return gql`fragment ${this.name} on PlaybackResourceAspect {
        formattedDurationInMinutes
      }`;
  },
});

export const TeaserFooterDuration = ({ className }) => {
  const {
    content,
  } = useContentTeaserContext();

  const {
    formattedDurationInMinutes,
  } = content;

  const videoDuration = useTranslation({
    key: 'component.av_player.duration.minutes.abbreviation',
    parameters: { duration: formattedDurationInMinutes },
  });

  if (!formattedDurationInMinutes) {
    return null;
  }

  return (
    <InformationDefault className={className}>{videoDuration}</InformationDefault>
  );
};

export const StyledTeaserFooterDuration = TeaserFooterDuration;
