import { StyledFacebookPost as FacebookPost } from '../../../components/FacebookPost.jsx';

export const mapFacebookPlaceholderToFacebookComponent = matchedElem => {
  const facebookUrl = matchedElem.dataset.href;
  const embeddedClassName = matchedElem.className.split(' ').filter(c => c.startsWith('fb-')).join(' ');

  return (
    <FacebookPost url={facebookUrl} embeddedClassName={embeddedClassName}/>
  );
};
