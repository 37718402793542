import gql from 'graphql-tag';
import { cx } from '@linaria/core';
import { makeMemoFragment } from '../../../utils/graphql';
import {
  Partials, StyledContentTeaser as ContentTeaser, partialFragments, darkPartialStyles,
} from '../ContentTeaserPartials';

export const smallImageContentTeaserFragment = makeMemoFragment({
  name: 'SmallImageContentTeaser',
  fragment() {
    return gql`fragment ${this.name} on Content {
        ... on UrlAspect {
          namedUrl
        }
        ... on NamedAspect {
          title
        }
        ...on TextualAspect {
          shortTeaser
          teaser
        }
        ...${partialFragments.imageWrapFragment.name}
        ...${partialFragments.contentStatusTeaserBadgeFragment.name}
        ...${partialFragments.teaserDataFragment.name}
        ...${partialFragments.linkedTitleFragment.name}
      }
      ${partialFragments.imageWrapFragment.fragment()}
      ${partialFragments.contentStatusTeaserBadgeFragment.fragment()}
      ${partialFragments.teaserDataFragment.fragment()}
      ${partialFragments.linkedTitleFragment.fragment()}
    `;
  },
});

export const SmallImageContentTeaser = ({
  content = {},
  className,
  imageFormatConfig,
  titleIsA,
  isHidden,
  hideDescription,
}) => (
  <ContentTeaser
    className={className}
    isHidden={isHidden}
    formatConfig={imageFormatConfig}
    content={content}
    hideDescription={hideDescription}
    hideOpinion
  >
    <Partials.LinkedImage/>
    <Partials.MainContentArea>
      <Partials.Data>
        <Partials.ContentStatusTeaserBadge/>
        <Partials.LinkedTitle isA={titleIsA}/>
        <Partials.LinkedDescription/>
      </Partials.Data>
    </Partials.MainContentArea>
  </ContentTeaser>
);

export const darkSmallImageContentTeaserStyles = cx(
  darkPartialStyles.darkLinkedTitleStyles,
  darkPartialStyles.darkTeaserDataStyles,
);

export const StyledSmallImageContentTeaser = SmallImageContentTeaser;
