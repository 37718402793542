import classnames from 'classnames';
import gql from 'graphql-tag';
import { liveTvLinkFragment, StyledLiveTvLink as LiveTvLink } from '../buttons/LiveTvLink';
import { mediaGroupFragment, StyledMediaGroup as MediaGroup } from '../navigation/MediaNavigation/MediaGroup';
import { focusMenuFragment, StyledFocusMenu as FocusMenu } from './FocusMenu';
import { useHeaderStateContext } from './HeaderStateContext';
import { makeMemoFragment } from '../../utils/graphql';

export const headerQuickNavigationItemsFragment = makeMemoFragment(({
  name: 'HeaderQuickNavigationItems',
  fragment() {
    return gql`fragment ${this.name} on Navigation {
    ...${mediaGroupFragment.name}
    ...${focusMenuFragment.name}
    defaultChannel {
      ...${liveTvLinkFragment.name}
    }
    }
    ${mediaGroupFragment.fragment()}
    ${focusMenuFragment.fragment()}
    ${liveTvLinkFragment.fragment()}
    `;
  },
}));

export const HeaderQuickNavigationItems = ({ topStoriesNavigation }) => {
  const { isNavigationVisible } = useHeaderStateContext();

  return (
    <>
      <LiveTvLink
        channel={topStoriesNavigation?.defaultChannel}
        className="live-tv-btn"
      />
      <MediaGroup
        className={classnames('media-group', { invisible: isNavigationVisible })}
        navigation={topStoriesNavigation}
      />
      <FocusMenu
        parentClassName="focus-menu"
        content={topStoriesNavigation}
      />
    </>
  );
};
