import { styled } from '@linaria/react';
import { mediaMin } from '../../utils/css';
import { getSliderPositionStyles, transition } from '../../utils/draggableUtils';

export const Slider = ({
  className,
  children,
  currentIndex,
  forwardRef,
}) => {
  if (!children) {
    return null;
  }
  const sliderPositionStyle = getSliderPositionStyles({ childrenLength: children.length, currentIndex });
  return (
    <div className={className} style={sliderPositionStyle} ref={forwardRef}>
      {children}
    </div>
  );
};

export const StyledSlider = styled(Slider)`
  display: flex;
  flex-wrap: nowrap;
  margin-top: 15px;
  position: relative;
  transition: ${transition};

  ${mediaMin.md`
    margin-top: 20px;
  `}
`;
