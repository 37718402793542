import { css } from '@linaria/core';
import { makeLinkColorOnActive, makeLinkColorOnHover } from '../../../ContentTeaserPartials/Partials/commons';

export const commonOpinionStyles = css`
  .author-image{
    flex-shrink: 0;
  }

  .author-data{
    .author-link, .no-link {
      font-size: 1.3rem;
    }
  }

  ${makeLinkColorOnHover({ linkCssSelector: '.author-image:hover + .author-data .author-link' })}
  ${makeLinkColorOnActive({ linkCssSelector: '.author-image:active + .author-data .author-link' })}
`;
