import { styled } from '@linaria/react';
import { mediaMin } from '../../utils/css';

export const NavigationGroupSpacer = ({ className, children }) => (
  <div className={className}>
    {children}
  </div>
);

export const StyledNavigationGroupSpacer = styled(NavigationGroupSpacer)`
  padding-top: 20px;

  ${mediaMin.md`
    padding-top: 25px;
  `}

  ${mediaMin.lg`
    padding-top: 30px;
  `}
`;
