import { suspendedComponentFactory } from '../../SuspendedComponent/suspendedComponentFactory.jsx';
import { useFeatureFlag } from '../useFeatureFlag';
import { BrowserOnly } from '../../BrowserOnly.jsx';

// @VisibleForTesting
export const { ProxyProtocolFlip: LazyProxyProtocolFlip } = suspendedComponentFactory(
  () => import('./ProxyProtocolFlip.jsx'),
  { fallback: null },
);

export const PROTOCOL_FLIP_QUERY_PARAM_NAME = 'protocol-flip';

export const MaybeProtocolFlip = ({ children }) => {
  const isProxyFlip = useFeatureFlag({ name: PROTOCOL_FLIP_QUERY_PARAM_NAME });
  if (isProxyFlip) {
    return (
      <BrowserOnly>
        <LazyProxyProtocolFlip>
          {children}
        </LazyProxyProtocolFlip>
      </BrowserOnly>
    );
  }
  return children;
};
