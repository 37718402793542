import {
  ListDefaultUnifiedBigImageTextTemplate,
  listDefaultUnifiedBigImageTextFragment,
} from '../../ContentTeaserSnippets/Templates/ListDefaultUnifiedBigImageTextTemplate.jsx';

export const dwRecommendsTopicsFragment = listDefaultUnifiedBigImageTextFragment;

export const DwRecommendsTopics = props => (
  <ListDefaultUnifiedBigImageTextTemplate {...props}/>
);

export const StyledDwRecommendsTopics = DwRecommendsTopics;
