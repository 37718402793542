export const ClockIcon = ({
  className,
}) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 144 144">
    <g fill="none" fillRule="evenodd">
      <mask id="b" fill="#fff">
        <path id="a" d="M80 82c-.008 4.389-3.614 8-8 8-4.39 0-8.008-3.611-8-8 .005-2.915 2-5.403 4-6.786V50h8v25.22c2.332 1.384 4.005 3.87 4 6.78zm40 0c0 26.467-21.533 48-48 48s-48-21.533-48-48c0-25.119 19.399-45.959 44-48v-8h-4s-12-4-16-8v-4h48v4c-4 4-16 8-16 8h-4v8c24.601 2.041 44 22.881 44 48zm-8 0c0-22.056-17.944-40-40-40S32 59.944 32 82s17.944 40 40 40 40-17.944 40-40z"/>
      </mask>
      <path id="a" fill="#262624" fillRule="nonzero" d="M80 82c-.008 4.389-3.614 8-8 8-4.39 0-8.008-3.611-8-8 .005-2.915 2-5.403 4-6.786V50h8v25.22c2.332 1.384 4.005 3.87 4 6.78zm40 0c0 26.467-21.533 48-48 48s-48-21.533-48-48c0-25.119 19.399-45.959 44-48v-8h-4s-12-4-16-8v-4h48v4c-4 4-16 8-16 8h-4v8c24.601 2.041 44 22.881 44 48zm-8 0c0-22.056-17.944-40-40-40S32 59.944 32 82s17.944 40 40 40 40-17.944 40-40z"/>
      <g fill="#7f8891" mask="url(#b)">
        <path d="M0 0h144v144.664H0z"/>
      </g>
    </g>
  </svg>
);

export const StyledClockIcon = ClockIcon;
