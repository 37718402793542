import { styled } from '@linaria/react';
import gql from 'graphql-tag';
import { StyledBreakingRedBadge as BreakingRedBadge } from './Badge.jsx';
import { makeMemoFragment } from '../utils/graphql';
import { StyledPulseDot as PulseDot } from './buttons/LiveTvLink/PulseDot.jsx';
import { isLiveblog } from '../utils/contentUtils';
import { noop } from '../utils/commons';
import { mediaMin } from '../utils/css';

export const contentStatusBadgeFragment = makeMemoFragment({
  name: 'ContentStatusBadge',
  fragment() {
    return gql`fragment ${this.name} on Content {
        ... on MetadataAspect {
          priority
        }
        ... on Liveblog {
          isLiveblogActive
          isLiveVideo
        }
        ... on Article {
          isLiveVideo
        }
      }
    `;
  },
});

const isActiveLiveblog = content => isLiveblog(content) && content.isLiveblogActive;
const isLiveVideo = content => content.isLiveVideo;
const isOfBreakingNewsPriority = content => content.priority === 'BREAKING_NEWS';

const getTranslationForLabel = content => {
  if (isOfBreakingNewsPriority(content)) {
    return 'component.content_labels.breaking.label';
  }
  if (isLiveVideo(content)) {
    return 'component.content_labels.live_video.label';
  }
  if (isActiveLiveblog(content)) {
    return 'component.content_labels.live_blog.label';
  }
  return null;
};

const isLiveActive = content => isActiveLiveblog(content) && isOfBreakingNewsPriority(content);

export const ContentStatusBadge = ({ className, content }) => {
  const translation = getTranslationForLabel(content);
  if (!translation) {
    return null;
  }
  const hasPulseDot = isLiveVideo(content) || isLiveActive(content);

  return (
    <BreakingRedBadge
      className={className}
      translationKey={translation}
      iconSlotFn={hasPulseDot ? () => <PulseDot className="pulse-dot"/> : noop}>
    </BreakingRedBadge>
  );
};

export const StyledContentStatusBadge = ContentStatusBadge;

export const StyledContentStatusHeaderBadge = styled(StyledContentStatusBadge)`
  margin-bottom: 10px;

  ${mediaMin.md`
    margin-bottom: 15px;
  `}
`;
