import globals from '../../utils/globals';

export const addReferrerToHistoryState = locationObj => ({
  ...locationObj,
  state: {
    ...(locationObj.state || {}),
    referrer: globals.window.location?.href,
  },
});

export const usePatchRogueReferrer = () => {
  const initialReferrer = globals.window.document?.referrer;

  // eslint-disable-next-line no-underscore-dangle
  globals.window.document?.__defineGetter__('referrer', () => globals.window.history?.state?.referrer || initialReferrer);
};
