import { resolutions } from '../../../utils/css';

export const networkCode = 228556409;

export const slotSizes = Object.freeze({
  billboardMobile: { width: 320, height: 100 },
  billboard: { width: 970, height: 250 },
  premiumBillboard: { width: 1540, height: 250 },
  leaderBoardSuper: { width: 970, height: 90 },
  leaderBoard: { width: 728, height: 90 },
  rectangleMedium: { width: 300, height: 250 },
  halfPageAd: { width: 300, height: 600 },
  mobileInterstitial: { width: 320, height: 480 },
});

const fixedSizeTopSlot = (adUnitCodeSuffix, { isCompact = false } = {}) => [
  {
    minWidth: resolutions.min.xs,
    maxWidth: resolutions.max.sm,
    slotSize: isCompact ? slotSizes.billboardMobile : slotSizes.rectangleMedium,
    adUnitCode: `DW_M_${adUnitCodeSuffix}`,
  },
  {
    minWidth: resolutions.min.md,
    maxWidth: resolutions.max.md,
    slotSize: slotSizes.leaderBoard,
    adUnitCode: `DW_T_${adUnitCodeSuffix}`,
  },
  {
    minWidth: resolutions.min.lg,
    maxWidth: resolutions.max.wide_xl,
    slotSize: slotSizes.leaderBoardSuper,
    adUnitCode: `DW_D_${adUnitCodeSuffix}`,
  },
];

export const multiSizeSlot = (adUnitCodeSuffix, { isArticleInContentSlot = false } = {}) => [
  {
    minWidth: resolutions.min.xs,
    maxWidth: resolutions.max.sm,
    slotSize: isArticleInContentSlot
      ? [slotSizes.rectangleMedium, slotSizes.billboardMobile, slotSizes.halfPageAd, slotSizes.mobileInterstitial]
      : [slotSizes.rectangleMedium, slotSizes.billboardMobile],
    adUnitCode: `DW_M_${adUnitCodeSuffix}`,
  },
  {
    minWidth: resolutions.min.md,
    maxWidth: resolutions.max.md,
    slotSize: [slotSizes.leaderBoard, slotSizes.rectangleMedium, slotSizes.billboardMobile],
    adUnitCode: `DW_T_${adUnitCodeSuffix}`,
  },
  {
    minWidth: resolutions.min.lg,
    maxWidth: resolutions.max.wide_xl,
    slotSize: [slotSizes.leaderBoard, slotSizes.billboard],
    adUnitCode: `DW_D_${adUnitCodeSuffix}`,
  },
];

const premiumSlot = adUnitCodeSuffix => [
  {
    minWidth: resolutions.min.lg,
    maxWidth: resolutions.max.xl,
    slotSize: slotSizes.billboard,
    adUnitCode: `DW_D_${adUnitCodeSuffix}`,
  },
  {
    minWidth: resolutions.min.wide_xl,
    maxWidth: resolutions.max.wide_xl,
    slotSize: [slotSizes.premiumBillboard, slotSizes.billboard],
    adUnitCode: `DW_W_${adUnitCodeSuffix}`,
  },
];

// Article
export const articlePremiumSlotConfig = premiumSlot('Article_Premium');

export const articleTopSlotConfig = fixedSizeTopSlot('Article_Top');

export const articleContentBottomSlotConfig = multiSizeSlot('Article_Content_Bottom');

export const articleBottomSlotConfig = multiSizeSlot('Article_Bottom');

// CoCo
export const overviewPremiumSlotConfig = premiumSlot('Overview_Premium');

export const topStoriesInternalSlotConfig = fixedSizeTopSlot('Overview_Top', { isCompact: true });

export const topStoriesZoneSlotConfig = multiSizeSlot('Overview_Top_Stories');

export const opinionZoneSlotConfig = multiSizeSlot('Overview_Opinion');

export const panoramaZoneSlotConfig = multiSizeSlot('Overview_Panorama');

// media pages (audio, video, image gallery)
export const mediaTopSlotConfig = fixedSizeTopSlot('Media_Top');

export const mediaBottomSlotConfig = multiSizeSlot('Media_Bottom');
