import { styled } from '@linaria/react';
import { replaceImageFormatInPosterImageUrl, useLazyPosterImage } from './hooks/useLazyPosterImage';
import { HeroImagePreloader } from './HeroImagePreloader';
import { alwaysLandscapePictureConfig } from './ResponsiveDwPicture/pictureConfigs';

export const PlaybackLazyPosterContainer = ({
  children, className, content, isHero, ...restProps
}) => {
  const { backgroundImageUrl, sizeRef, inViewRef } = useLazyPosterImage({ content });
  return (
    <>
      {isHero && (
        <HeroImagePreloader imageUrl={content.posterImageUrl}
          formatConfig={alwaysLandscapePictureConfig}
          urlReplacementFunction={replaceImageFormatInPosterImageUrl}
        />
      )}
      <div className={className} ref={sizeRef} {...restProps}>
        <div className='bg-poster' ref={inViewRef} style={{ backgroundImage: backgroundImageUrl && `url(${backgroundImageUrl})` }}>
          {children}
        </div>
      </div>
    </>
  );
};

export const StyledPlaybackLazyPosterContainer = styled(PlaybackLazyPosterContainer)`
  position: relative;
  cursor: pointer;
  padding-bottom: 56.25%;
  background-color: black;
  .bg-poster {
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
  }
  .video-js, .bg-poster {
    position: absolute;
    top: 0;
    inset-inline-start: 0;
  }
  .video-js, .vjs-poster {
    background-color: transparent;
  }
`;
