import gql from 'graphql-tag';
import { makeMemoFragment } from '../../../utils/graphql';

import { StyledLinkWithArrow as LinkWithArrow, linkWithArrowFragment } from '../LinkWithArrow.jsx';

export const geographicRegionLinkFragment = makeMemoFragment({
  name: 'GeographicRegionLink',
  fragment() {
    return gql`fragment ${this.name} on GeographicRegion {
        alignedName
        correspondingContent {
          ...${linkWithArrowFragment.name}
        }
      }
      ${linkWithArrowFragment.fragment()}
    `;
  },
});

export const GeographicRegionLink = ({ geographicRegion }) => {
  if (!(geographicRegion?.correspondingContent)) {
    return null;
  }
  const allStoriesLabel = {
    key: 'content_block.high_priority_stories.all',
    parameters: { region: geographicRegion.alignedName },
  };
  return (
    <LinkWithArrow
      content={geographicRegion.correspondingContent}
      linkTranslation={allStoriesLabel}
    />
  );
};

export const StyledGeographicRegionLink = GeographicRegionLink;
