import { useState } from 'react';
import globals from '../../utils/globals';
import { useEventListener } from '../hooks/useEventListener';
import { suspendedComponentFactory } from '../SuspendedComponent/suspendedComponentFactory.jsx';
import { CUSTOM_EVENT_DISABLE_LAZY } from '../LazyLoadDwPicture.jsx';

export const { GlobalPrintStyles } = suspendedComponentFactory(
  () => import('./GlobalPrintStyles.jsx'),
  { fallback: null },
);
// TODO linaria-next no need to lazy load these anymore. #not worth
export const SuspendedGlobalPrintStyles = () => {
  const [loadPrintStyles, setLoadPrintStyles] = useState(false);
  useEventListener(globals.window, 'beforeprint', () => {
    setLoadPrintStyles(true);
    globals.window.document.querySelectorAll('.rich-text .lazy-load-container').forEach(el => el.dispatchEvent(new Event(CUSTOM_EVENT_DISABLE_LAZY)));
  }, { once: true });

  if (loadPrintStyles) {
    return (
      <GlobalPrintStyles />
    );
  }
  return null;
};
