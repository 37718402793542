import gql from 'graphql-tag';
import { StyledAVCarousel as AVCarousel, avCarouselFragment } from './AVCarousel.jsx';
import { makeMemoFragment } from '../../../utils/graphql';

export const avCarouselRegionVideoAdapterFragment = avCarouselFragment;

export const avCarouselRegionVideoAdapterConfigurationFragment = makeMemoFragment({
  name: 'AVCarouselRegionVideoAdapterConfiguration',
  fragment() {
    return gql`fragment ${this.name} on CompositionComponent {
        configuration {
          sourceAssociations {
            target {
              ... on NamedAspect {
                title
               }
            }
          }
        }
      }
    `;
  },
});

export const AVCarouselRegionVideoAdapter = ({ configuration, contents, ...otherProps }) => {
  const geographicRegion = configuration.sourceAssociations[0]?.target;

  return (
    <AVCarousel
      headlineTranslation={geographicRegion?.title}
      contents={contents}
      {...otherProps}
    />
  );
};
