import gql from 'graphql-tag';
import { styled } from '@linaria/react';
import { makeMemoFragment } from '../../../utils/graphql';
import { LinkBase } from '../../Links/LinkBase';
import { useTranslation } from '../../hooks/useTranslation.jsx';
import { StyledPulseDot as PulseDot } from './PulseDot.jsx';
import { ButtonPrimarySmall } from '../Button';

export const liveTvLinkFragment = makeMemoFragment({
  name: 'LiveTvLink',
  fragment() {
    return gql`fragment ${this.name} on Channel {
        name
        namedUrl
      }
    `;
  },
});

export const LiveTvLink = ({ className, channel }) => {
  const livestreamChannelTitle = useTranslation({
    key: 'component.site_header.tv_livestream.label',
    parameters: {
      channel: channel?.name,
    },
  });
  const livestreamLabel = useTranslation('component.site_header.tv_livestream.button');

  if (!channel) {
    return null;
  }

  return (
    <ButtonPrimarySmall
      className={className}
      isA={LinkBase}
      to={channel.namedUrl}
      role="link"
      data-tracking-name="go-to-live-tv"
      title={livestreamChannelTitle}
    >
      <PulseDot className="pulse-dot"/>
      <span className="label">{livestreamLabel}</span>
    </ButtonPrimarySmall>
  );
};

export const StyledLiveTvLink = styled(LiveTvLink)`
  .pulse-dot {
    background-color: currentColor;
  }
`;
