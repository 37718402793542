import { StyledInternalContentTeaser as InternalContentTeaser } from './InternalContentTeaser';
import { StyledExternalLinkContentTeaser as ExternalLinksTeaser } from './ExternalLinksContentTeaser.jsx';
import { StyledDownloadContentTeaser as DownloadTeaser } from './DownloadContentTeaser.jsx';

export const selectContentTeaser = ({ link, ...props }) => {
  if (!link) {
    return null;
  }
  switch (link.targetType) {
    case 'DOWNLOAD':
      return (
        <DownloadTeaser link={link} translationKey="component_detail.content_detail.links.download" {...props} />
      );
    case 'EXTERNAL_LINK': {
      return (
        <ExternalLinksTeaser link={link} {...props} />
      );
    }
    default:
      return (
        <InternalContentTeaser link={link} {...props} />
      );
  }
};
