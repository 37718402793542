import { styled } from '@linaria/react';
import { mediaMin } from '../../../../utils/css';
import { LinkNoLink } from '../../../Links';
import { useContentTeaserContext } from '../ContentTeaserContext';
import { TextDefault } from '../../../Text';

export const darkLinkedDescriptionStyles = TextDefault.darkStyles;

const LinkedDescription =
({
  className,
}) => {
  const {
    content,
    link,
    config: {
      hide: {
        description: hideDescription,
      },
    },
  } = useContentTeaserContext();
  const title = link.name || content.title;
  const description = link.description || content.shortTeaser || content.teaser;
  const url = link.url || content.namedUrl;

  if (hideDescription || !description) {
    return null;
  }

  return (
    <TextDefault isA='div' className={`${className} teaser-description`}>
      <LinkNoLink title={title} skipTitleForInternal to={url} tabIndex={-1}>
        {description}
      </LinkNoLink>
    </TextDefault>
  );
};

export const StyledLinkedDescription = styled(LinkedDescription)`
  font-size: 1.6rem;
  word-break: break-word;
  & > * {
    display: block;
    padding-top: 10px;
  }

  ${mediaMin.md`
    font-size: 1.4rem;
  `};
`;
