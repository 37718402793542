import { useState, useEffect } from 'react';
import classnames from 'classnames';
import gql from 'graphql-tag';
import { styled } from '@linaria/react';
import { cx } from '@linaria/core';

import { colors, mediaMin, selector } from '../../../utils/css';
import { useToggle } from '../../hooks/useToggle';
import { useTranslation } from '../../hooks/useTranslation.jsx';
import { StyledChevronOpenClose as ChevronOpenClose } from '../../icons';
import { TextDefault } from '../../Text';
import { InformationDefault } from '../../Text/Information.jsx';

import { HeadlineSemiBoldDarkBlueGrey02AndBlueGrey01 } from '../../Text/Headline.jsx';
import { useIsLiveSlot } from '../../RunningLivestreamContext.jsx';
import { makeMemoFragment } from '../../../utils/graphql';
import { ButtonWithIconOnly } from '../../buttons/Button';

export const timeSlotRowFragment = makeMemoFragment({
  name: 'TimeSlotRow',
  fragment() {
    return gql`fragment ${this.name} on TimeSlot {
      startDate
      endDate
      program {
        name
        posterImageUrl
      }
      programElement {
        name
        teaser
      }
    }`;
  },
});

const getLocalTime = ({ dateStr, isServer }) => new Date(dateStr).toLocaleTimeString(['en-GB'], { // single format for all langs
  hour: '2-digit',
  minute: '2-digit',
  timeZone: isServer ? 'UTC' : undefined,
  timeZoneName: isServer ? 'short' : undefined,
});

const useLocalTime = dateStr => {
  const initialLocalTime = getLocalTime({ dateStr, isServer: true });
  const [localTime, setLocalTime] = useState(initialLocalTime);
  useEffect(() => {
    setLocalTime(getLocalTime({ dateStr }));
  }, [dateStr]);

  return localTime;
};

export const TimeSlotRow = ({
  className, timeSlot,
}) => {
  const [isTeaserVisible, { toggleOnClick: toggleTeaserVisibility }] = useToggle();
  const buttonTitle = useTranslation(`content_block_detail.live_video_program_schedule.program_info_button.label.${isTeaserVisible ? 'close' : 'open'}`);
  const teaserExpandedClass = isTeaserVisible ? 'opened' : 'closed';

  const localStartDate = useLocalTime(timeSlot.startDate);
  const isLiveNow = useIsLiveSlot(timeSlot);
  return (
    <div role="row" className={classnames(className, teaserExpandedClass, { live: isLiveNow })}>
      <InformationDefault className={cx('time', InformationDefault.darkStyles)} role="cell">
        {localStartDate}
      </InformationDefault>
      <div role="cell" className="program-names">
        <HeadlineSemiBoldDarkBlueGrey02AndBlueGrey01 className={HeadlineSemiBoldDarkBlueGrey02AndBlueGrey01.darkStyles}>
          {timeSlot.program?.name}
        </HeadlineSemiBoldDarkBlueGrey02AndBlueGrey01>
        <TextDefault className={TextDefault.darkStyles}>{timeSlot.programElement?.name}</TextDefault>
      </div>
      {
        timeSlot.programElement?.teaser
          ? (
            <>
              <div className="teaser-toggle">
                <ButtonWithIconOnly
                  className={cx('arrow', ButtonWithIconOnly.darkStyles)}
                  onClick={toggleTeaserVisibility}
                  title={buttonTitle}
                  aria-expanded={isTeaserVisible}
                >
                  <ChevronOpenClose pointsDown={!isTeaserVisible}/>
                </ButtonWithIconOnly>
              </div>
              <TextDefault role="cell" className={cx('teaser', TextDefault.darkStyles)}>{timeSlot.programElement?.teaser}</TextDefault>
            </>
          ) : null
      }
    </div>
  );
};

export const StyledTimeSlotRow = styled(TimeSlotRow)`
  will-change: transform, max-height;

  font-size: 1.4rem;
  background-color: ${colors.DARK_BLUE_GREY_02};

  padding-block: 10px 0px;
  padding-inline: 15px 10px;
  margin-bottom: 2px;
  > *${selector.topElements(3)} {
    padding-bottom: 10px;
  }

  &.live {
    border-inline-start: 5px solid ${colors.ACCENT_GREEN};
    padding-inline-start: 10px;
  }

  display: grid;
  grid-template-columns: 50px 1fr auto;
  grid-template-areas:
    "time    programNames  teaserToggle"
    "time    programNames  teaserToggle"
    "teaser  teaser        teaserToggle";

  .time {
    grid-area: time;
  }
  .program-names {
    grid-area: programNames;
    padding-inline-start: 5px;

    & > * {
      display: block;
    }
  }
  .teaser-toggle {
    grid-area: teaserToggle;

    padding-inline-start: 5px;
  }
  .teaser {
    grid-area: teaser;

    max-height: 250px;
    padding-bottom: 10px;
    transition: all 450ms cubic-bezier(0.43, 0, 0.09, 1);
    transition-property: max-height, padding-bottom;

    overflow-y: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;
    &::-webkit-scrollbar {
      width: 0;
    }
  }

  &.closed .teaser {
    max-height: 0;
    padding-bottom: 0;
  }

  .arrow {
    margin-top: -5px;
    width: 29px;

    svg {
      width: 19px;
    }
  }


   ${mediaMin.sm`
     .teaser-toggle {
        padding-inline-start: 10px;
      }
  `}

  ${mediaMin.md`
    grid-template-columns: 66px 1fr auto;
    font-size: 1.6rem;
  `}
`;
