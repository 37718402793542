import { cx } from '@linaria/core';
import { forwardRef } from 'react';

export const extendWithClassNames = Component => (...classNames) => {
  const extendedComponent = forwardRef(({ className, ...otherProps }, ref) => (
    <Component
      ref={ref}
      className={cx(className, ...classNames)}
      {...otherProps}/>
  ));
  // eslint-disable-next-line fp/no-mutation
  extendedComponent.extendedByClassNames = classNames;
  // eslint-disable-next-line fp/no-mutation
  extendedComponent.extendingComponent = Component;
  return extendedComponent;
};
