import globals from './globals';

export const getAllNodesAdded = mutationEntries => mutationEntries
  .filter(({ type }) => type === 'childList')
  .flatMap(({ addedNodes }) => [...addedNodes]);

const defaultObserverOptions = {
  subtree: true,
  childList: true,
  attributes: false,
};

export const observeMutations = ({
  callback,
  node = globals.document.getElementById('root'),
  options = defaultObserverOptions,
}) => {
  const observer = new globals.MutationObserver(callback);
  observer.observe(node, options);
  return observer;
};
