import {
  createContext, useContext,
} from 'react';

export const initialMobileAppConfig = {
  isMobileMode: false,
};

export const MobileAppContext = createContext(initialMobileAppConfig);

export const useMobileAppContext = () => useContext(MobileAppContext);
