import gql from 'graphql-tag';
import { styled } from '@linaria/react';

import { LinkBase } from '../Links/LinkBase';
import { makeMemoFragment } from '../../utils/graphql';
import { useTranslation } from '../hooks/useTranslation.jsx';
import { ButtonPrimary } from './Button';
import { StyledButtonBar as ButtonBar } from './Button/ButtonBar.jsx';

export const homepageLinkFragment = makeMemoFragment({
  name: 'HomepageLink',
  fragment() {
    return gql`fragment ${this.name} on AssociationsAspect {
        topStoriesNavigation {
          namedUrl
        }
      }
    `;
  },
});

const getHomePagePathname = content => content.topStoriesNavigation?.namedUrl || '/';
const defaultTranslationKey = 'content_block_detail.homepage_link.to_homepage';

export const HomepageLink = ({ className, content, translation = defaultTranslationKey }) => {
  const translatedText = useTranslation(translation);
  return (
    <ButtonBar className={className}>
      <ButtonPrimary
        isA={LinkBase}
        to={getHomePagePathname(content)}
        role="link"
        data-tracking-name="go-to-homepage"
      >
        {translatedText}
      </ButtonPrimary>
    </ButtonBar>
  );
};

export const darkHomepageLinkStyles = ButtonPrimary.darkStyles;

export const StyledHomepageLink = styled(HomepageLink)`
  padding: 15px;
`;
