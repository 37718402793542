import { format, getStaticUrlWithExtension, replaceFormatInUrl } from '../../utils/imgUtils';
import { contentAreaWidth } from '../layouts/ContentMoveUpLayout/ContentArea';

export const buildSrcSet = ({
  staticUrlTemplate,
  formatGroupName,
  maxWidth = contentAreaWidth.xl,
  ext = 'jpg',
}) => {
  const maxAllowedWidth = Math.min(maxWidth, contentAreaWidth.xl);
  const imageUrlReplacementFunction = getImageUrlReplacementFunction(ext);
  const allowedImgWidthFormats = [
    ...format[formatGroupName].formats.filter(({ width }) => width < maxAllowedWidth),
    format[formatGroupName].formats.find(({ width }) => width >= maxAllowedWidth),
  ].filter(Boolean);

  return allowedImgWidthFormats
    .map(imgFormat => `${imageUrlReplacementFunction(staticUrlTemplate, imgFormat)} ${imgFormat.width}w`)
    .join(', ');
};

export const getSourcesMeta = ({
  staticUrlTemplate,
  formatConfig,
  ext,
}) => {
  const picSrcs = formatConfig.map(({ minWidth, maxWidth, formatGroupName }, index) => {
    const { aspectRatio } = format[formatGroupName];
    const notLast = index !== formatConfig.length - 1;

    return {
      srcSet: buildSrcSet({
        staticUrlTemplate, formatGroupName, maxWidth, ext,
      }),
      media: `(min-width: ${minWidth}px) ${notLast ? `and (max-width: ${maxWidth}px)` : ''}`,
      height: aspectRatio,
    };
  });

  return picSrcs;
};

export const getImageUrlReplacementFunction = ext => (staticUrlTemplate, imgFormat) => {
  const staticUrlExtTemplate = getStaticUrlWithExtension({ staticUrlTemplate, ext });
  return replaceFormatInUrl(staticUrlExtTemplate, imgFormat);
};
