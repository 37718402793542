import { styled } from '@linaria/react';

export const A11yInternalLinkTarget = ({ className, id }) => (
  <div className={className} id={id}/>
);

export const StyledA11yInternalLinkTarget = styled(A11yInternalLinkTarget)`
    position: absolute;
    top: 0;
    inset-inline-start: 0;
`;
