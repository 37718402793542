import {
  ListDefaultUnifiedBigImageTextTemplate,
  listDefaultUnifiedBigImageTextFragment,
} from '../../ContentTeaserSnippets/Templates/ListDefaultUnifiedBigImageTextTemplate.jsx';

export const panoramaItemsFragment = listDefaultUnifiedBigImageTextFragment;

export const PanoramaItems = props => (
  <ListDefaultUnifiedBigImageTextTemplate {...props}/>
);

export const StyledPanoramaItems = PanoramaItems;
