import { css, cx } from '@linaria/core';
import { colors, mediaMin, selector } from '../../../../../utils/css';

export const cssVariables = {
  bgColor: '--big-text-teaser-bg-color',
  inlinePadding: '--big-text-teaser-inline-padding',
  bottomPadding: '--big-text-teaser-bottom-padding',
  oneItemTeaserDataTopPadding: '--big-text-teaser-one-item-teaser-data-top-padding',
};

export const darkBigTextTeaserStyles = css`
  ${cssVariables.bgColor}: ${colors.BLACK};
  ${cssVariables.inlinePadding}: 30px;
  ${cssVariables.bottomPadding}: 15px;
  ${cssVariables.oneItemTeaserDataTopPadding}: 15px;
`;

export const extendTeaserCompositionWithMandatoryBigTextTeaserLooks = css`
  .teaser-wrap {
    background-color: var(${cssVariables.bgColor});
    background-clip: content-box;

    .teaser-data-wrap {
      padding-inline: var(${cssVariables.inlinePadding}, 15px);
      padding-bottom: var(${cssVariables.bottomPadding}, 0);
    }
  }
`;

export const extendTeaserCompositionWithStandardBigTextTeaserLooks = cx(
  extendTeaserCompositionWithMandatoryBigTextTeaserLooks,
  css`
  ${mediaMin.lg`
    && .teaser-wrap${selector.justNElements(1)}{
      .teaser-data-wrap {
        padding-inline-start: 15px;
        padding-top: var(${cssVariables.oneItemTeaserDataTopPadding}, 0);
      }
    }
  `}
`,
);

export const extendCarouselWithBigTextTeaserLooks = css`
  ${mediaMin.md`
    .title {
      font-size: 2rem;
    }
  `};

  ${mediaMin.lg`
    .title {
      font-size: 2.4rem;
    }
  `};
`;
