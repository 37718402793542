import { css } from '@linaria/core';
import { forwardRef } from 'react';

import { colors } from '../../../utils/css';
import { cssVariables as fontCssVariables } from '../../GlobalFonts';
import { extendWithClassNames } from '../../../utils/extendWithClassNames.jsx';

export const Text = forwardRef(({
  isA = 'span',
  children,
  className,
  ...restProps
}, ref) => {
  const Tag = isA;

  return (
    <Tag
      ref={ref}
      className={className}
      {...restProps}
    >
      {children}
    </Tag>
  );
});

export const cssVariables = {
  pangeaOpticalSize: '--pangea-opsz',
  pangeaItalic: '--pangea-italic',
};

export const baseTextClass = css`
  line-height: 1.4;
  letter-spacing: normal;
  font-variation-settings:
    "ital" var(${cssVariables.pangeaItalic}, 0),
    "opsz" var(${cssVariables.pangeaOpticalSize}, 20);
`;

export const StyledText = extendWithClassNames(Text)(baseTextClass);

export const italicStyles = `
  ${cssVariables.pangeaItalic}: 1;
`;
// TODO linaria-next: css``
export const italicStylesForSystemFonts = `
  font-style: var(${fontCssVariables.italicForSystemFont}, none);
`;

export const boldStyles = `
  font-weight: 700;
`;

export const semiBoldStyles = `
  font-weight: 600;
`;

export const regularStyles = `
  font-weight: 400;
`;

export const resetOpticalSizing = `
  ${cssVariables.pangeaOpticalSize}: unset;
`;

export const italicClass = css`
  ${cssVariables.pangeaItalic}: 1;
`;

export const italicClassForSystemFonts = css`
  font-style: var(${fontCssVariables.italicForSystemFont}, none);
`;

export const boldClass = css`
  font-weight: 700;
`;

export const semiBoldClass = css`
  font-weight: 600;
`;

export const mediumClass = css`
  font-weight: 500;
`;

export const regularClass = css`
  font-weight: 400;
`;

export const wideClass = css`
  ${cssVariables.pangeaOpticalSize}: 10;
`;

export const resetOpticalSizingClass = css`
  ${cssVariables.pangeaOpticalSize}: unset;
`;

const extendStyledText = extendWithClassNames(StyledText);

// ----- BOLD -----
export const StyledTextBold = extendStyledText(boldClass);
export const StyledTextWideBold = extendStyledText(boldClass, wideClass);

// ----- SEMI BOLD -----
export const StyledTextSemiBold = extendStyledText(semiBoldClass);
export const StyledTextSemiBoldItalic = extendStyledText(semiBoldClass, italicClass);
export const StyledTextWideSemiBold = extendStyledText(semiBoldClass, wideClass);

// ----- MEDIUM -----
export const StyledTextMedium = extendStyledText(mediumClass);
export const StyledTextWideMedium = extendStyledText(mediumClass, wideClass);

// ----- REGULAR -----
export const StyledTextRegular = extendStyledText(regularClass);
export const StyledTextWideRegular = extendStyledText(regularClass, wideClass);
export const StyledTextWideRegularItalic = extendStyledText(regularClass, italicClass, wideClass);

// ***** Text Colors *******

export const textColorCssVariables = {
  dwDarkBlueAndWhite: '--dw-dark-blue-and-white-text-color',
  whiteAndWhite: '--white-and-white-text-color',
  blueGrey04AndDwLightBlueNew: '--blue-grey-04-and-dw-light-blue-new-text-color',
  blueGrey04AndBlueGrey03: '--blue-grey-04-and-blue-grey-03-text-color',
  blueGrey05AndBlueGrey02: '--blue-grey-05-and-blue-grey-02-text-color',
  darkBlueGrey02AndBlueGrey01: '--dark-blue-grey-02-and-blue-grey-01-text-color',
  darkBlueGrey01AndBlueGrey02: '--dark-blue-grey-01-and-blue-grey-02-text-color',
  darkBlueGrey01AndDarkBlueGrey01: '--dark-blue-grey-01-and-dark-blue-grey-01-color',
  breakingRedAndAccentRed: '--breaking-red-and-accent-red-text-color',
};

export const textColors = {
  DW_DARK_BLUE_AND_WHITE: {
    // TODO linaria-next: css`` be consistent with light and darkStyles
    lightStyles: `color: var(${textColorCssVariables.dwDarkBlueAndWhite}, ${colors.DW_DARK_BLUE});`,
    darkStyles: css`${textColorCssVariables.dwDarkBlueAndWhite}: ${colors.WHITE};`,
  },
  WHITE_AND_WHITE: {
    lightStyles: `color: var(${textColorCssVariables.whiteAndWhite}, ${colors.WHITE});`,
    darkStyles: '',
  },
  BLUE_GREY_04_AND_DW_LIGHT_BLUE: {
    lightStyles: `color: var(${textColorCssVariables.blueGrey04AndDwLightBlueNew}, ${colors.BLUE_GREY_04});`,
    darkStyles: css`${textColorCssVariables.blueGrey04AndDwLightBlueNew}: ${colors.DW_LIGHT_BLUE};`,
  },
  BLUE_GREY_04_AND_BLUE_GREY_03: {
    lightStyles: `color: var(${textColorCssVariables.blueGrey04AndBlueGrey03}, ${colors.BLUE_GREY_04});`,
    darkStyles: css`${textColorCssVariables.blueGrey04AndBlueGrey03}: ${colors.BLUE_GREY_03};`,
  },
  BLUE_GREY_05_AND_BLUE_GREY_02: {
    lightStyles: `color: var(${textColorCssVariables.blueGrey05AndBlueGrey02}, ${colors.BLUE_GREY_05});`,
    darkStyles: css`${textColorCssVariables.blueGrey05AndBlueGrey02}: ${colors.BLUE_GREY_02};`,
  },
  DARK_BLUE_GREY_02_AND_BLUE_GREY_01: {
    lightStyles: `color: var(${textColorCssVariables.darkBlueGrey02AndBlueGrey01}, ${colors.DARK_BLUE_GREY_02});`,
    darkStyles: css`${textColorCssVariables.darkBlueGrey02AndBlueGrey01}: ${colors.BLUE_GREY_01};`,
  },
  DARK_BLUE_GREY_01_AND_BLUE_GREY_02: {
    lightStyles: `color: var(${textColorCssVariables.darkBlueGrey01AndBlueGrey02}, ${colors.DARK_BLUE_GREY_01});`,
    darkStyles: css`${textColorCssVariables.darkBlueGrey01AndBlueGrey02}: ${colors.BLUE_GREY_02};`,
  },
  DARK_BLUE_GREY_01_AND_DARK_BLUE_GREY_01: {
    lightStyles: `color: var(${textColorCssVariables.darkBlueGrey01AndDarkBlueGrey01}, ${colors.DARK_BLUE_GREY_01});`,
    darkStyles: css`${textColorCssVariables.darkBlueGrey01AndDarkBlueGrey01}: ${colors.DARK_BLUE_GREY_01};`,
  },
  BREAKING_RED_AND_ACCENT_RED: {
    lightStyles: `color: var(${textColorCssVariables.breakingRedAndAccentRed}, ${colors.BREAKING_RED});`,
    darkStyles: css`${textColorCssVariables.breakingRedAndAccentRed}: ${colors.ACCENT_RED};`,
  },
};
