import { useEffect } from 'react';

// eslint-disable-next-line fp/no-rest-parameters
export const useEventListener = (target, type, listener, ...options) => {
  useEffect(() => {
    const targetIsRef = target ? Object.prototype.hasOwnProperty.call(target, 'current') : null;
    const currentTarget = targetIsRef ? target.current : target;
    if (currentTarget) {
      type.split(' ').forEach(eventType => currentTarget.addEventListener(eventType, listener, ...options));
    }

    return () => {
      if (currentTarget) {
        type.split(' ').forEach(eventType => currentTarget.removeEventListener(eventType, listener, ...options));
      }
    };
  }, [target, type, listener, options]);
};
