import { Suspense } from 'react';
import { styled } from '@linaria/react';
import { BrowserOnly } from '../BrowserOnly.jsx';

const SquarePlaceholder = ({ isA: Tag = 'svg', className }) => <Tag className={className}/>;

export const StyledSquarePlaceholder = styled(SquarePlaceholder)`
  width: 100%;
  padding-bottom: 100%;
  height: 0;
`;

export const SuspendedComponent = ({
  isA, className, isHidden, fallback = <StyledSquarePlaceholder className={className}/>, ...props
}) => {
  const Tag = isA;

  return (
    <BrowserOnly fallback={fallback} isHidden={isHidden}>
      <Suspense fallback={fallback}>
        <Tag className={className} {...props}/>
      </Suspense>
    </BrowserOnly>
  );
};
