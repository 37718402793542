export const ids = Object.freeze({
  feedbackForm: Object.freeze({
    openBtn: 'feedback-button',
    subject: 'subject',
    message: 'message',
    file: 'file',
    firstName: 'firstName',
    lastName: 'lastName',
    gender: 'gender',
    userEmail: 'userEmail',
    city: 'city',
    country: 'country',
    wantOffers: 'wantOffers',
    captchaToken: 'captchaToken',
  }),
  quickNav: Object.freeze({
    mainContent: 'main-content',
    navigationToggle: 'navigation',
    footer: 'footer',
  }),
  flyingFocus: 'flying-focus',
  languageSelectorToggle: 'language',
  footerSectionSocialMedia: 'footer-section-social-media',
  livestreamScheduleAriaCaption: 'schedule-caption',
});
