import { styled } from '@linaria/react';
import classnames from 'classnames';

import { StyledChevronOpenClose as ChevronOpenClose } from '../../icons';
import { ButtonTertiary } from '../../buttons/Button';
import { useHeaderStateContext } from '../../Header/HeaderStateContext';

export const LanguageToggleButton = ({
  className,
  children,
  ...props
}) => {
  const { isLanguageToggled } = useHeaderStateContext();

  return (
    <ButtonTertiary
      className={classnames(className, { 'is-open': isLanguageToggled })}
      aria-expanded={isLanguageToggled}
      {...props}
    >
      <h3>
        {children}
      </h3>
      <ChevronOpenClose pointsDown={!isLanguageToggled} />
    </ButtonTertiary>
  );
};

export const StyledLanguageToggleButton = styled(LanguageToggleButton)`
  display: inline-flex;
  align-items: center;
  gap: 10px;
  svg {
    width: 20px;
    height: 20px;
  }

  h3 {
    font-size: 1.6rem;
    margin-bottom: 0;
    span {
      text-transform: uppercase;
      &:after {
        content: '\u00A0|\u00A0';
      }
    }
  }
`;
