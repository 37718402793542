import { useParams } from '../dwouter';
import { WithGraphQLContentHandling } from './commons/WithGraphQLContentHandling';

export const AVRedirect = () => {
  const { contentId, langCode } = useParams();
  const queryDef = {
    path: `${langCode}/content/av-redirect/${contentId}`,
    depts: [langCode, contentId],
  };
  return (
    <WithGraphQLContentHandling queryDef={queryDef} />
  );
};
