// TODO: Twitter icon is available in project twice, unfortunately icons used for social media are padded.
// We agreed that some day we will switch to not-padded ones, but that did not happen yet.
// When the switch is done, this component can be removed.
export const TwitterIcon = ({
  className,
}) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 20 20">
    <g id="sm-twitter_padded-a" stroke="none" strokeWidth="1">
      <g id="Group" transform="translate(1.000000, 1.000000)" >
        <path d="M6.00606088,0 L6.25248563,0.369823917 L17.1123603,16.6678677 L18,18 L11.9783365,18 L11.7319488,17.62975 L0.88620143,1.33170626 L0,0 L6.00606088,0 Z M5.13384269,1.70195626 L3.13622784,1.70195626 L12.8499575,16.2976495 L14.8599799,16.2976495 L5.13384269,1.70195626 Z M7.513,9.038 L8.803,10.97 L2.909,18.0001438 L0,18.0001438 L7.513,9.038 Z M18,0 L10.186,9.321 L8.908,7.375 L15.091,0 L18,0 Z" id="Combined-Shape"></path>
      </g>
    </g>
  </svg>
);

export const StyledTwitterIcon = TwitterIcon;
