import gql from 'graphql-tag';
import { cx } from '@linaria/core';

import { NotFoundPage } from '../../pages/NotFoundPage.jsx';
import { headerFragmentFactory, StyledHeader as Header } from '../Header';
import { footerFragmentFactory, StyledFooter as Footer } from '../Footer';
import { homepageLinkFragment, StyledHomepageLink as HomepageLink, darkHomepageLinkStyles } from '../buttons/HomepageLink.jsx';
import { ContentPageLayout, darkContentPageLayoutStyles } from '../layouts/ContentPageLayout.jsx';
import { StyledQuickNavigationList as QuickNavigationList } from '../a11y/QuickNavigationList.jsx';

import { OnDetailPageContext } from '../PageEmbeddingContext.jsx';
import { darkPersonRecommendStyles, PersonRecommend, personRecommendFragment } from './PersonRecommend.jsx';
import { personDetailsFragment as personDetailsInnerFragment, PersonDetails, darkPersonDetailsStyles } from './PersonDetails.jsx';
import { ImageSharingMetadata, imageSharingMetadataFragment } from '../ImageSharingMetadata.jsx';
import { PageHeaderMetadata, pageHeaderMetadataFragment } from '../PageHeaderMetadata.jsx';
import { BrowserOnlyBreakingBanner } from '../BreakingBanner';
import { makeMemoFragment } from '../../utils/graphql';
import { withTheme } from '../higherOrderComponents/withTheme.jsx';

export const personDetailsFragment = makeMemoFragment({
  name: 'PersonDetailsWithFallback',
  fragment() {
    const headerFragment = headerFragmentFactory();
    const footerFragment = footerFragmentFactory();
    return gql`fragment ${this.name} on Person {
      autoDelivery
      ...${pageHeaderMetadataFragment.name}
      ...${personDetailsInnerFragment.name}
      ...${headerFragment.name}
      ...${footerFragment.name}
      ...${personRecommendFragment.name}
      ...${imageSharingMetadataFragment.name}
      ...${homepageLinkFragment.name}

    }
    ${pageHeaderMetadataFragment.fragment()}
    ${personDetailsInnerFragment.fragment()}
    ${headerFragment.fragment()}
    ${footerFragment.fragment()}
    ${personRecommendFragment.fragment()}
    ${imageSharingMetadataFragment.fragment()}
    ${homepageLinkFragment.fragment()}
    `;
  },
});

export const PersonDetailsWithFallback = ({ person, className }) => {
  if (!person.autoDelivery) {
    return (
      <NotFoundPage/>
    );
  }
  return (
    <OnDetailPageContext>
      <PageHeaderMetadata content={person}/>
      <ImageSharingMetadata content={person}/>
      <QuickNavigationList content={person}/>
      <Header content={person}/>
      <div className={className}>
        <BrowserOnlyBreakingBanner />
        <ContentPageLayout
          DetailZoneFn={
            () => <PersonDetails person={person} />
          }
          AlternatingColorZoneConfig={{
            compFn: () => <PersonRecommend person={person} />,
            isBgColorOnEveryEven: true,
          }}
        />
        <HomepageLink content={person} className="homepage-link" />
      </div>
      <Footer content={person}/>
    </OnDetailPageContext>
  );
};

export const PersonDetailsWithFallbackWithTheme = withTheme(PersonDetailsWithFallback)(
  cx(
    darkContentPageLayoutStyles,
    darkPersonDetailsStyles,
    darkPersonRecommendStyles,
    darkHomepageLinkStyles,
  ),
);
