import { css, cx } from '@linaria/core';
import { CarouselTeaserContainer, carouselTeaserContainerClassName } from '../../ContentTeaserSnippets/ContentTeaserPartials/CarouselTeaserContainer.jsx';
import {
  StyledSmallImageMixContentTeaser as SmallImageMixContentTeaser,
  darkSmallImageMixContentTeaserStyles,
  smallImageMixContentTeaserFragment,
} from '../../ContentTeaserSnippets/ContentTeasers/SmallImageMixContentTeaser.jsx';
import { mediaMin } from '../../../utils/css';
import { contentBlockWidth } from '../ContentBlock/ContentBlock.jsx';

export const moreOnTopStoryTeaserFragment = smallImageMixContentTeaserFragment;

export const moreOnTopStoryContentTeaserClassName = css`
  ${mediaMin.lg`
    width: ${contentBlockWidth.lg / 2}px;
  `};

  ${mediaMin.xl`
    width: ${contentBlockWidth.xl / 2}px;
  `};
`;

export const MoreOnTopStoryContentTeaser = ({
  content, className, titleIsA, isHidden, ...restProps
}) => (
  <CarouselTeaserContainer
    className={cx(className, carouselTeaserContainerClassName, moreOnTopStoryContentTeaserClassName)}
    isHidden={isHidden}
    {...restProps}
  >
    <SmallImageMixContentTeaser
      content={content}
      titleIsA={titleIsA}
      isHidden={isHidden}
      hideDescription
    />
  </CarouselTeaserContainer>
);

export const darkMoreOnTopStoryContentTeaserStyles = darkSmallImageMixContentTeaserStyles;
