import { styled } from '@linaria/react';
import gql from 'graphql-tag';
import { StyledFocusLabel as FocusLabel } from './FocusLabel.jsx';
import { isValidList } from '../../../utils/contentUtils';
import { focusMenuItemFragment, StyledFocusMenuItem as FocusMenuItem } from '../FocusMenuItem.jsx';
import { makeMemoFragment } from '../../../utils/graphql';

export const focusMenuItemsFragment = makeMemoFragment({
  name: 'FocusMenuItems',
  fragment() {
    return gql`fragment ${this.name} on FocusNavigation {
      contentLinks {
        targetId
        ...${focusMenuItemFragment.name}
      }
    }
    ${focusMenuItemFragment.fragment()}

    `;
  },
});

export const FocusMenuItems = ({ className, forwardRef, focusNav }) => {
  const focusMenuLinks = focusNav?.contentLinks;

  if (!isValidList(focusMenuLinks)) {
    return null;
  }

  return (
    <div className={className} ref={forwardRef}>
      <FocusLabel isA="div" />
      {
        focusMenuLinks.map(link => (
          <FocusMenuItem link={link} key={link.targetId}/>
        ))
      }
    </div>
  );
};

export const StyledFocusMenuItems = styled(FocusMenuItems)`
  display: flex;
  align-items: center;

  & > * + * {
    margin-inline-start: 15px;
  }
`;
