import gql from 'graphql-tag';
import {
  useEffect, useMemo, useRef, useState,
} from 'react';
import { styled } from '@linaria/react';
import { makeMemoFragment } from '../../utils/graphql';

import { getFormatForWidth } from '../../utils/imgUtils';

import { useWindowInnerWidthContext } from '../WindowInnerWidthContext';
import { StyledLazyLoadDwPicture as LazyLoadDwPicture } from '../LazyLoadDwPicture.jsx';
import { alwaysLandscapePictureConfig } from './pictureConfigs';
import { mediaMin, resolutions } from '../../utils/css/index';

const useFormatForClientWidthEffect = ({ imgRef, formatConfig }) => {
  const innerWidth = useWindowInnerWidthContext();
  const lqFormat = getFormatForWidth({ innerWidth, imgWidth: 0, formatConfig });
  const [hqFormat, setHqFormat] = useState(lqFormat);

  useEffect(() => {
    const imgWidthWithSafariFallback = imgRef?.current?.clientWidth || imgRef?.current?.getBoundingClientRect().width || 0;
    const newHqFormat = getFormatForWidth({ innerWidth, imgWidth: imgWidthWithSafariFallback, formatConfig });
    setHqFormat(newHqFormat);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [innerWidth, formatConfig]);
  return { lqFormat, hqFormat };
};

export const responsiveDwPictureFragment = makeMemoFragment({
  name: 'ResponsiveDwPicture',
  fragment() {
    return gql`fragment ${this.name} on Image {
        id
        staticUrl
      }
    `;
  },
});

export const cssVariables = {
  aspectRatio: {
    xs: '--responsive-picture-aspect-ratio-xs',
    sm: '--responsive-picture-aspect-ratio-sm',
    md: '--responsive-picture-aspect-ratio-md',
    lg: '--responsive-picture-aspect-ratio-lg',
    xl: '--responsive-picture-aspect-ratio-xl',
    wide_xl: '--responsive-picture-aspect-ratio-wide_xl',
  },
};

export const getAspectRatioStyles = ({ formatConfig = alwaysLandscapePictureConfig, aspectRatio }) => {
  const cssVariableToAspectRatioEntries = Object.entries(resolutions.min).map(([breakpointName, minWidth]) => {
    if (aspectRatio) {
      return [cssVariables.aspectRatio[breakpointName], 100 / aspectRatio];
    }
    const format = getFormatForWidth({ innerWidth: minWidth, imgWidth: 0, formatConfig });
    return [cssVariables.aspectRatio[breakpointName], 100 / format.aspectRatio];
  });
  return Object.fromEntries(cssVariableToAspectRatioEntries);
};

export const ResponsiveDwPicture = ({
  image, alt, className, formatConfig = alwaysLandscapePictureConfig, aspectRatio, isA,
}) => {
  const ref = useRef();
  const { lqFormat, hqFormat } = useFormatForClientWidthEffect({
    imgRef: ref,
    formatConfig,
  });
  // without useMemo the reassigned variables seems to cause a forced reflow (see devtools performance tab)
  const aspectRatioStyles = useMemo(() => getAspectRatioStyles({ formatConfig, aspectRatio }), [formatConfig, aspectRatio]);

  return (
    <LazyLoadDwPicture
      style={aspectRatioStyles}
      ref={ref}
      className={className}
      image={image}
      alt={alt}
      lqFormat={lqFormat}
      hqFormat={hqFormat}
      isA={isA}
    />
  );
};

// TODO linaria-next: css``
export const responsivePicCss = ` 
  aspect-ratio: var(${cssVariables.aspectRatio.xs});

  ${mediaMin.sm`
    aspect-ratio: var(${cssVariables.aspectRatio.sm});
  `}
  
  ${mediaMin.md`
    aspect-ratio: var(${cssVariables.aspectRatio.md});
  `}
  
  ${mediaMin.lg`
    aspect-ratio: var(${cssVariables.aspectRatio.lg});
  `}
  
  ${mediaMin.xl`
    aspect-ratio: var(${cssVariables.aspectRatio.xl});
  `}
`;

export const StyledResponsiveDwPicture = styled(ResponsiveDwPicture)` 
  ${responsivePicCss}
`;
