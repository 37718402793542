import { styled } from '@linaria/react';
import gql from 'graphql-tag';
import { StyledNavigationSpacer as NavigationSpacer } from '../NavigationSpacer.jsx';
import { StyledVerticalColumnsList as VerticalColumnsList } from '../../VerticalColumnsList';
import { StyledNavigationItem as NavigationItem } from '../NavigationItem.jsx';
import { mediaItemFragment, StyledMediaItem as MediaItem } from './MediaItem.jsx';
import { mediaMin } from '../../../utils/css';
import { isMediaGroupShown } from '../../../utils/contentUtils';
import { makeMemoFragment } from '../../../utils/graphql';

export const mediaNavigationGroupFragment = makeMemoFragment({
  name: 'MediaNavigationGroup',
  fragment() {
    return gql`fragment ${this.name} on Navigation {
        latestMediaChildren {
          id
          ...${mediaItemFragment.name}
        }
      }
      ${mediaItemFragment.fragment()}
    `;
  },
});

export const MediaNavigationGroup = ({ className, navigation }) => {
  if (!isMediaGroupShown(navigation)) {
    return null;
  }
  return (
    <NavigationSpacer className={className}>
      <VerticalColumnsList className="media-list">
        {navigation.latestMediaChildren.map(mediaNavigation => (
          <NavigationItem key={mediaNavigation.id}>
            <MediaItem mediaNav={mediaNavigation}/>
          </NavigationItem>
        ))}
      </VerticalColumnsList>
    </NavigationSpacer>
  );
};

export const StyledMediaNavigationGroup = styled(MediaNavigationGroup)`
  margin-bottom: 5px;
  .media-list {
    > * {
      margin-inline-start: -30px;
    }
  }
  ${mediaMin.md`
    margin-bottom: 10px;
  `}

  ${mediaMin.lg`
    margin-bottom: 15px;
  `}
`;
