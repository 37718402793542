import { styled } from '@linaria/react';
import { StyledAudio as AudioIcon } from '../icons';
import { colors } from '../../utils/css';

export const AudioIconBackground = ({ className }) => (
  <div className={className}>
    <AudioIcon />
  </div>
);

export const StyledAudioIconBackground = styled(AudioIconBackground)`
  background-color: ${colors.BLUE_GREY_02};
  height: 100%;

  svg {
      display: block;
      margin: auto;
      width: 16%;
      height: 100%;
      fill: ${colors.BLUE_GREY_03};
  }
`;
