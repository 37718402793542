import { cx } from '@linaria/core';
import gql from 'graphql-tag';

import { autoTopicLinkFragment, StyledAutoTopicLink as AutoTopicLink } from '../../AutoTopicLink.jsx';
import { isValidList } from '../../../utils/contentUtils';
import { darkContentBlockStyles, StyledContentBlock as ContentBlock } from '../ContentBlock';
import { makeMemoFragment } from '../../../utils/graphql';
import { StyledButtonBar as ButtonBar } from '../../buttons/Button/ButtonBar.jsx';

export const relatedTopicsFragment = makeMemoFragment({
  name: 'RelatedTopics',
  fragment() {
    return gql`fragment ${this.name} on RelatedContentAspect {
          relatedAutoTopics {
            id
            ...${autoTopicLinkFragment.name}
          }
        }
        ${autoTopicLinkFragment.fragment()}
    `;
  },
});

export const RelatedTopics = ({ content, className }) => {
  if (!isValidList(content.relatedAutoTopics)) {
    return null;
  }
  return (
    <ContentBlock
      className={className}
      headlineTranslation="content_block_detail.related_topics.headline"
      headlineIsA="h2"
      pageSectionId='related-topics'
    >
      <ButtonBar align="start" isA="aside" data-tracking-name="related-topics">
        {
          content.relatedAutoTopics.map(autoTopic => (
            <AutoTopicLink key={autoTopic.id} autoTopic={autoTopic}/>
          ))
        }
      </ButtonBar>
    </ContentBlock>
  );
};

export const darkRelatedTopicsStyles = cx(
  darkContentBlockStyles,
  AutoTopicLink.darkStyles,
);

export const StyledRelatedTopics = RelatedTopics;
