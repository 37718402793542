import { BrowserOnly } from '../BrowserOnly.jsx';
import { suspendedComponentFactory } from '../SuspendedComponent/suspendedComponentFactory.jsx';
import globals from '../../utils/globals';
import { useGqlFetch } from '../hooks/useGqlFetch';
import { useParams } from '../../dwouter';

const { StyledBreakingBannerNoQuery: BreakingBannerNoQuery } = suspendedComponentFactory(
  () => import('./BreakingBannerNoQuery.jsx'),
  { fallback: null },
);

export const BreakingBanner = () => {
  const { langCode = 'en' } = useParams();
  const {
    data, error, loading,
  } = useGqlFetch({ path: `${langCode}/breaking-news`, depts: [langCode], disableAppStateCache: true });

  if (error) {
    globals.console.error('Could not fetch breaking news.', error);
    return null;
  }

  if (loading || !data?.breakingNews) {
    return null;
  }

  return (
    <BreakingBannerNoQuery
      content={data.breakingNews}
    />
  );
};

export const BrowserOnlyBreakingBanner = () => (
  <BrowserOnly>
    <BreakingBanner />
  </BrowserOnly>
);
