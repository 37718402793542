import { useI18nContext } from '../I18nContext.jsx';
import { getRelativeTimeVariables } from '../commons/DateAnalyser';
import { useBrowserOnly } from './useBrowserOnly';

export const MINUTE_IN_MILLIS = 60 * 1000;
export const HOUR_IN_MILLIS = 60 * MINUTE_IN_MILLIS;
export const DAY_IN_MILLIS = 24 * HOUR_IN_MILLIS;

const MINUTES_AGO = {
  PLURAL: 'general.time.minutes_ago',
  SINGULAR: 'general.time.one_minute_ago',
};

const HOURS_AGO = {
  PLURAL: 'general.time.hours_ago',
  SINGULAR: 'general.time.one_hour_ago',
};

const AGO = {
  minutes: MINUTES_AGO,
  hours: HOURS_AGO,
};

export function useRelativeTimeLabelFromDates(date, localizedDate) {
  const i18n = useI18nContext();
  const { isBrowser } = useBrowserOnly();

  if (!localizedDate) {
    return '';
  }
  if (!isBrowser) {
    return localizedDate;
  }
  const variables = getRelativeTimeVariables(date);
  if (variables) {
    const varName = Object.keys(variables)[0];
    const isPlural = variables[varName] > 1 || variables[varName] === 0;
    const translationKey = isPlural
      ? AGO[varName].PLURAL
      : AGO[varName].SINGULAR;

    return i18n.t(translationKey, variables);
  }
  return localizedDate;
}
