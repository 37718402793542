import { styled } from '@linaria/react';

import { mediaMin } from '../../../utils/css';
import { HeadlineWideRegularDarkBlueGrey02AndBlueGrey01 } from '../../Text/Headline.jsx';
import { useTranslation } from '../../hooks/useTranslation.jsx';
import { StyledNavigationList as NavigationList, navigationListFragment } from '../NavigationList.jsx';
import { StyledNavigationSpacer as NavigationSpacer } from '../NavigationSpacer.jsx';

export const navigationGroupFragment = navigationListFragment;

export const NavigationGroup = ({
  navigations = [],
  groupTranslationKey = '',
  className,
}) => {
  const groupName = useTranslation(groupTranslationKey);

  return (
    <NavigationSpacer className={className} role="group" aria-labelledby={groupName}>
      { groupTranslationKey &&
        <HeadlineWideRegularDarkBlueGrey02AndBlueGrey01 isA="h2" className="group-headline" id={groupName}>
          {groupName}
        </HeadlineWideRegularDarkBlueGrey02AndBlueGrey01>
      }
      <NavigationList navigations={navigations} className="group-list" />
    </NavigationSpacer>
  );
};
// TODO linaria-next: css`` ?!
export const commonNavigationGroupStyles = `
  margin-bottom: 5px;

  .group-headline {
    font-size: 1.1rem;
    letter-spacing: 0.16rem;
    margin-inline-start: -30px;
    text-transform: uppercase;
    margin-bottom: 0;
  }

  .group-list {
    padding-top: 15px;
    &:only-child {
      margin-top: 30px;
    }
  }

  ${mediaMin.md`
    margin-bottom: 10px;

    .group-headline {
      font-size: 1.2rem;
      letter-spacing: 0.174rem;
      margin-inline-start: -45px;
    }

    .group-list {
      &:only-child {
        margin-top: 35px;
      }
    }
  `}
`;

export const StyledNavigationGroup = styled(NavigationGroup)`
  ${commonNavigationGroupStyles}



  ${mediaMin.lg`
    margin-bottom: 15px;

    .group-list {

      &:only-child {
        margin-top: 40px;
      }
    }

    .group-headline {
      margin-inline-start: -30px;
      font-size: 1.3rem;
      letter-spacing: 0.189rem;
    }
  `}

  ${mediaMin.xl`
  .group-headline {
    margin-inline-start: -75px;
  }
  `}
`;
