import { styled } from '@linaria/react';
import {
  StyledTextWideRegular,
  StyledTextWideMedium,
  textColors,
} from './TextBase';

export const TextDefault = styled(StyledTextWideRegular)`${textColors.DARK_BLUE_GREY_02_AND_BLUE_GREY_01.lightStyles}`;
TextDefault.darkStyles = textColors.DARK_BLUE_GREY_02_AND_BLUE_GREY_01.darkStyles;

export const TextEmphasized = styled(StyledTextWideMedium)`${textColors.DARK_BLUE_GREY_02_AND_BLUE_GREY_01.lightStyles}`;
TextEmphasized.darkStyles = textColors.DARK_BLUE_GREY_02_AND_BLUE_GREY_01.darkStyles;

export const TextImageCaption = styled(StyledTextWideRegular)`${textColors.DARK_BLUE_GREY_01_AND_BLUE_GREY_02.lightStyles}`;
TextImageCaption.darkStyles = textColors.DARK_BLUE_GREY_01_AND_BLUE_GREY_02.darkStyles;

export const TextGrey = styled(StyledTextWideRegular)`${textColors.BLUE_GREY_04_AND_BLUE_GREY_03.lightStyles}`;
TextGrey.darkStyles = textColors.BLUE_GREY_04_AND_BLUE_GREY_03.darkStyles;
