import { styled } from '@linaria/react';

import { cx } from '@linaria/core';
import { HomepageLink, darkHomepageLinkStyles } from './buttons/HomepageLink.jsx';
import {
  HeadlineMediumBlueGrey04AndDwLightBlueNew,
  HeadlineSemiBoldDarkBlueGrey01AndBlueGrey02,
  HeadlineSemiBoldDwDarkBlueAndWhite,
} from './Text/Headline.jsx';
import NotFoundPageImage from './icons/svg/yogis.svg';
import { commonBlockSpacing } from './ContentBlocks/ContentBlock';
import { mediaMin } from '../utils/css';
import { useTranslation } from './hooks/useTranslation.jsx';
import { StyledA11yLinkTargetMarker as A11yLinkTargetMarker } from './a11y/A11yLinkTargetMarker.jsx';
import { ids } from '../utils/staticElementIds';

export const NotFoundMessage = ({ className, topStoriesNavigation }) => {
  const imgAlt = useTranslation('pages.not_found_404.illustration.alt');
  const imgLabel = useTranslation('pages.not_found_404.illustration.label');
  const headline = useTranslation('pages.not_found_404.headline');
  const messagePartOne = useTranslation('pages.not_found_404.message.part_one');
  const messagePartTwo = useTranslation('pages.not_found_404.message.part_two');
  return (
    <div className={cx(className, commonBlockSpacing)}>
      <img src={NotFoundPageImage} alt={imgAlt} title={imgLabel} className="hero-image"/>
      <div className="text-container">
        <HeadlineMediumBlueGrey04AndDwLightBlueNew isA="h1">{headline}</HeadlineMediumBlueGrey04AndDwLightBlueNew>
        <HeadlineSemiBoldDwDarkBlueAndWhite className="message part-one">{messagePartOne}</HeadlineSemiBoldDwDarkBlueAndWhite>
        <HeadlineSemiBoldDarkBlueGrey01AndBlueGrey02 className="message">{messagePartTwo}</HeadlineSemiBoldDarkBlueGrey01AndBlueGrey02>
      </div>
      <A11yLinkTargetMarker id={ids.quickNav.mainContent}/>
      <HomepageLink className="homepage-link" translation="pages.not_found_404.homepage_link.to_homepage" content={{ topStoriesNavigation }}/>
    </div>
  );
};

export const darkNotFoundMessageStyles = cx(
  darkHomepageLinkStyles,
  HeadlineMediumBlueGrey04AndDwLightBlueNew.darkStyles,
  HeadlineSemiBoldDwDarkBlueAndWhite.darkStyles,
  HeadlineSemiBoldDarkBlueGrey01AndBlueGrey02.darkStyles,
);

export const StyledNotFoundMessage = styled(NotFoundMessage)`
  display: grid;
  padding-top: 16px;
  padding-bottom: 30px;
  .hero-image {
    justify-self: center;
    width: 50%;
  }
  .text-container {
    padding-top: 15px;

    h1 {
      padding-bottom: 10px;
      font-size: 1.5rem;
      letter-spacing: 0.15rem;
      text-transform: uppercase;
    }
    .message {
      font-size: 2.5rem;
      &.part-one {
        &:after {
          content: " ";
        }
      }
    }
  }
  .homepage-link {
    margin-top: 20px;
  }
  ${mediaMin.sm`
    padding-top: 34px;
    .text-container {
      padding-top: 20px;
      h1 {
        font-size: 1.6rem;
        letter-spacing: 0.171rem;
      }
      .message {
        font-size: 3rem;
      }
    }
  `}
  ${mediaMin.md`
    padding-top: 29px;
    padding-bottom: 53px;
    .text-container {
      h1 {
        padding-bottom: 15px;
        font-size: 1.8rem;
        letter-spacing: 0.193rem;
      }
      .message {
        font-size: 4rem;
      }
      .homepage-link {
        margin-top: 30px;
      }
    }
  `}
  ${mediaMin.lg`
    grid-template-columns: 30% auto;
    padding-top: 40px;
    padding-bottom: 40px;
    min-height: 75vh;
    align-content: center;

    .hero-image {
      width: 100%;
    }
    .text-container {
      align-self: center;
      padding: 0;
      padding-inline-start: 30px;
      h1 {
        font-size: 2rem;
        letter-spacing: 0.214rem;
      }
      .message {
        font-size: 5rem;
      }
    }
    .homepage-link {
      margin-top: 15px;
      grid-column: 1 / 3;
    }
  `}
  ${mediaMin.xl`
    .text-container {
      h1 {
        font-size: 2.2rem;
        letter-spacing: 0.236rem;
      }
      .message {
        font-size: 6rem;
      }
    }
  `}
`;
