import gql from 'graphql-tag';
import { styled } from '@linaria/react';
import { mediaMin, selector } from '../../../../../utils/css';
import { useContentTeaserContext } from '../../ContentTeaserContext';
import { InformationDefault } from '../../../../Text/Information.jsx';
import { StyledTimeIndicator as TimeIndicator } from '../../../../ContentDetailHeader/TimeIndicator.jsx';

import { StyledTeaserFooterDuration as TeaserFooterDuration, teaserFooterDurationFragment } from './TeaserFooterDuration.jsx';
import { StyledTeaserFooterImageCount as TeaserFooterImageCount, teaserFooterImageCountFragment } from './TeaserFooterImageCount.jsx';

import { isAudio, isVideo, isImageGallery } from '../../../../../utils/contentUtils';
import { makeMemoFragment } from '../../../../../utils/graphql';

export const teaserFooterFragment = makeMemoFragment({
  name: 'TeaserFooter',
  fragment() {
    return gql`fragment ${this.name} on Content {
        ... on DeliveryAspect {
          contentDate
          localizedContentDate
          localizedContentDateSr
        }
        ... on TeaserAspect {
          roadTeaserKicker(pageId: $id, lang: $lang)
        }
        ...${teaserFooterDurationFragment.name}
        ...${teaserFooterImageCountFragment.name}
      }
      ${teaserFooterDurationFragment.fragment()}
      ${teaserFooterImageCountFragment.fragment()}
    `;
  },
});

export const TeaserFooter = ({ className }) => {
  const {
    content,
    config: { hide },
  } = useContentTeaserContext();

  return (
    <div className={className}>
      {!hide.kicker && content.roadTeaserKicker &&
        <InformationDefault className="kicker">{content.roadTeaserKicker}</InformationDefault>
      }
      {!hide.timeIndicator && content.contentDate &&
        <TimeIndicator
          className="date-time"
          date={content.contentDate}
          localizedDate={content.localizedContentDate}
          localizedDateSr={content.localizedContentDateSr}
        />
      }
      {(isAudio(content) || isVideo(content)) && <TeaserFooterDuration/>}
      {isImageGallery(content) && <TeaserFooterImageCount/>}
    </div>
  );
};

export const darkTeaserFooterStyles = InformationDefault.darkStyles;

export const StyledTeaserFooter = styled(TeaserFooter)`
  font-size: 1.1rem;

  & > *${selector.notLast} {
    &:after {
      content: '\u00A0|\u00A0';
      letter-spacing: 0.08rem;
      margin: 0 2px;
    }
  }

  .kicker {
    text-transform: uppercase;
    letter-spacing: 0.085rem;
    word-break: break-word;
  }

  .date-time {
    display: inline-block;
    time {
      letter-spacing: 0.03rem;
    }
  }

  ${mediaMin.md`
    font-size: 1.2rem;

    & > *${selector.notLast} {
      &:after {
        font-size: 1.1rem;
      }
    }

    .date-time{
      time {
        letter-spacing: 0.04rem;
      }
    }
  `};
`;
