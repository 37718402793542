import gql from 'graphql-tag';
import { styled } from '@linaria/react';
import { useContentTeaserContext } from '../ContentTeaserContext';
import { StyledAudio as Audio, audioFragment } from '../../../Audio';
import { StyledVideo as Video, videoFragment } from '../../../Video';
import { StyledLinkedTitle as LinkedTitle, darkLinkedTitleStyles } from './StyledLinkedTitle.jsx';
import { screenReaderOnly } from '../../../ContentDetailHeader/ScreenReaderOnly.jsx';
import { ContentType } from '../../../../utils/contentUtils';
import { makeMemoFragment } from '../../../../utils/graphql';

export const avWrapFragment = makeMemoFragment({
  name: 'AVWrap',
  fragment() {
    return gql`fragment ${this.name} on Content {
        ... on PlaybackResourceAspect {
          # added here for GTM, so AV-Teaser will push tracking, once there are on Navigation pages
          duration
        }
        ...${audioFragment.name}
        ...${videoFragment.name}
      }
      ${audioFragment.fragment()}
      ${videoFragment.fragment()}
    `;
  },
});

const typeToTag = {
  [ContentType.Audio]: Audio,
  [ContentType.Video]: Video,
};

export const AVWrap = ({ className, titleIsA }) => {
  const { content } = useContentTeaserContext();
  const AVTag = typeToTag[content.__typename];

  if (!AVTag) {
    return null;
  }
  return (
    <div className={`${className} teaser-av-wrap col-12`}>
      <LinkedTitle className="sr-only" isA={titleIsA} forceSkipTabbing/>
      <AVTag content={content}/>
    </div>
  );
};

export const darkAvWrapStyles = darkLinkedTitleStyles;

export const StyledAVWrap = styled(AVWrap)`
  .sr-only {
    ${screenReaderOnly}
  }
`;
