import { css } from '@linaria/core';
import { mediaMin } from '../utils/css';
import {
  StyledAudio,
  StyledVideo,
  StyledGallery,
  StyledInfographics,
  StyledInteractive,
} from './icons';
import { ContentType } from '../utils/contentUtils';
import { extendWithClassNames } from '../utils/extendWithClassNames.jsx';

const typeToTag = Object.freeze({
  [ContentType.Audio]: StyledAudio,
  [ContentType.Video]: StyledVideo,
  [ContentType.Channel]: StyledVideo,
  [ContentType.Livestream]: StyledVideo,
  [ContentType.ImageGallery]: StyledGallery,
  [ContentType.Infographics]: StyledInfographics,
  [ContentType.Interactive]: StyledInteractive,
});

export const MediaTag = ({ className, contentType }) => {
  const Tag = typeToTag[contentType];

  if (!Tag) {
    return null;
  }
  return (
    <Tag className={className} />
  );
};

export const mediaTagStyles = `
  width: 0.9em;
  height: 0.9em;
  margin-bottom: 0.18em;
  margin-inline-end: 7px;

  path {
    fill: currentColor;
  }

  ${mediaMin.lg`
    margin-inline-end: 9px;
  `}

  ${mediaMin.xl`
    margin-inline-end: 10px;
  `}
`;

export const mediaTagStylesClassName = css`${mediaTagStyles}`;

export const StyledMediaTag = extendWithClassNames(MediaTag)(mediaTagStylesClassName);
