import { styled } from '@linaria/react';

import { StyledTwitterIcon as TwitterIcon } from './TwitterIcon.jsx';
import { LinkDefault } from '../../Links';

export const TwitterLink = ({ twitterHandle, className }) => (
  <LinkDefault to={`https://twitter.com/${twitterHandle}`} title={`twitter — ${twitterHandle}`} className={className}>
    <TwitterIcon className="twitter-icon"/>
    {twitterHandle}
  </LinkDefault>
);

export const darkTwitterLinkStyles = LinkDefault.darkStyles;

export const StyledTwitterLink = styled(TwitterLink)`
  display: flex;
  align-items: center;
  letter-spacing: 0.04rem;

  .twitter-icon {
    height: 20px;
    width: 20px;
  }
`;
