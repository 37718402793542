import { styled } from '@linaria/react';
import { mediaMin } from '../../../utils/css';
import { HeadlineSemiBoldDarkBlueGrey02AndBlueGrey01 } from '../../Text/Headline.jsx';

export const TopicClusterTitle = props => (
  <HeadlineSemiBoldDarkBlueGrey02AndBlueGrey01 isA="h3" {...props} />
);

export const darkTopicClusterTitleStyles = HeadlineSemiBoldDarkBlueGrey02AndBlueGrey01.darkStyles;

export const StyledTopicClusterTitle = styled(TopicClusterTitle)`
  font-size: 2rem;
  word-wrap: break-word;
  margin-bottom: 0;
  text-align: center;

  ${mediaMin.md`
    font-size: 2.6rem;
  `}

  ${mediaMin.lg`
    font-size: 3.2rem;
  `}
`;
