import { styled } from '@linaria/react';
import classnames from 'classnames';

import {
  aspectRatioAware, mediaMin,
} from '../../../utils/css';

import { contentAreaWidth } from './ContentArea';
import { mainImageWithCaptionFragment } from '../../ResponsiveDwPicture/mainImageWithCaptionFragment';
import { alwaysLandscapePictureConfig, heroPictureConfig } from '../../ResponsiveDwPicture/pictureConfigs';
import { StyledMoveUpImageCaption as MoveUpImageCaption, darkMoveUpImageCaptionStyles } from './MoveUpImageCaption';
import { StyledBasicLayout as BasicLayout } from '../BasicLayout';
import { ultraWideScreenCenterHeroImages, ultraWideScreenHeroStyles } from '../../../utils/ultraWideCustomCss';
import { retrieveLayoutImage } from '../../../utils/imgUtils';
import { isUnifiedProgram } from '../../../utils/contentUtils';
import { StyledHeroImage as HeroImage } from '../../HeroImage/HeroImage.jsx';

export const contentMoveUpLayoutFragment = mainImageWithCaptionFragment;

export const MoveUpImageHero = ({
  content,
  children,
}) => {
  const layoutImage = retrieveLayoutImage(content);
  const canRenderImage = !!layoutImage;
  const target = content?.mainContentImageLink?.target;
  const pictureFormatConfig = isUnifiedProgram(content) ? alwaysLandscapePictureConfig : heroPictureConfig;

  return (
    <>
      {canRenderImage && (
        <div>
          <HeroImage
            image={target}
            alt={layoutImage.alt}
            formatConfig={pictureFormatConfig}
          />
        </div>
      )}
      <div className={classnames('page', {
        'move-up': canRenderImage,
        'has-caption': canRenderImage && (layoutImage.caption || layoutImage.licenserSupplement),
      })}>
        {canRenderImage && (
          <MoveUpImageCaption
            caption={layoutImage.caption}
            licenserSupplement={layoutImage.licenserSupplement}
          />
        )}
        {children}
      </div>
    </>
  );
};

export const ContentMoveUpLayout = ({
  content,
  children,
  className,
}) => (
  <BasicLayout className={className}
    HeroCmp={MoveUpImageHero}
    heroCmpProps={{
      content,
    }}
  >
    {children}
  </BasicLayout>
);

export const darkContentMoveUpLayoutStyles = darkMoveUpImageCaptionStyles;

export const StyledContentMoveUpLayout = styled(ContentMoveUpLayout)`
  .page {
    position: relative;
  }

  .move-up .details-icon {
    margin-top: -45px;
    margin-inline-end: 5px;
  }

  ${mediaMin.lg`
    .move-up {
      margin-top: -11.25%;
      &.has-caption {
        margin-top: calc(-11.25% - 45px);
      }
      .details-icon {
        margin-inline-end: 0;
      }
    }
    .page {
      max-width: ${contentAreaWidth.lg}px;
      margin-inline: auto;
    }
  `}

  ${mediaMin.xl`
    .page {
      max-width: ${contentAreaWidth.xl}px;
    }
  `}


  ${aspectRatioAware.xl.ultraWide`
    ${ultraWideScreenHeroStyles}
    ${ultraWideScreenCenterHeroImages}
    .move-up {
      margin-top: -20vh;
      &.has-caption {
        margin-top: calc(-20vh - 45px);
      }
    }
  `}
`;
