import { styled } from '@linaria/react';
import SpinnerIcon from './svg/spinner.svg?react';
import { mediaMin } from '../../utils/css';

export const StyledSpinnerIcon = styled(SpinnerIcon)`
  width: 54px;
  height: 54px;

  ${mediaMin.md`
    width: 70px;
    height: 70px;
  `}
`;
