import gql from 'graphql-tag';
import { styled } from '@linaria/react';
import { cx } from '@linaria/core';

import { TextEmphasized } from '../../Text';
import { LinkDefault } from '../../Links';
import { makeMemoFragment } from '../../../utils/graphql';

export const authorLinkFragment = makeMemoFragment({
  name: 'AuthorLink',
  fragment() {
    return gql`fragment ${this.name} on Person {
        namedUrl
        fullName
        autoDelivery
      }
    `;
  },
});

export const AuthorLink = ({
  className,
  person: {
    namedUrl = '',
    fullName,
    autoDelivery = 0,
  } = {},
}) => {
  if (!fullName) {
    return null;
  }

  return (
    autoDelivery
      ? (
        <LinkDefault className={`${className} author-link`} to={namedUrl} title={fullName} skipTitleForInternal>
          {fullName}
        </LinkDefault>
      )
      : (
        <TextEmphasized className={`${className} no-link`}>
          {fullName}
        </TextEmphasized>
      )
  );
};

export const darkAuthorLinkStyles = cx(
  LinkDefault.darkStyles,
  TextEmphasized.darkStyles,
);

export const StyledAuthorLink = styled(AuthorLink)`
  &.author-link > *{
    letter-spacing: 0.04rem;
  }
`;
