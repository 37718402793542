import gql from 'graphql-tag';
import { styled } from '@linaria/react';
import { cx } from '@linaria/core';

import { LinkDefaultNarrow } from '../../Links';
import { darkTimeIndicatorStyles, TimeIndicator } from '../../ContentDetailHeader/TimeIndicator.jsx';
import { StyledMediaTag as MediaTag } from '../../MediaTag.jsx';
import { mediaMin } from '../../../utils/css';
import { useContentSpecificAriaLabel } from '../../hooks/useContentSpecificAriaLabel';
import { makeMemoFragment } from '../../../utils/graphql';
import { StyledContentStatusBadge as ContentStatusBadge, contentStatusBadgeFragment } from '../../ContentStatusBadge.jsx';
import { resetBoostrapAndBrowserDefaultLineHeight } from '../../../utils/commons';

export const currentNewsItemFragment = makeMemoFragment({
  name: 'CurrentNewsItem',
  fragment() {
    return gql`fragment ${this.name} on Content {
        ... on ModelAspect {
          id
        }
        ... on UrlAspect {
          namedUrl
        }
        ... on NamedAspect {
          title
        }
        ... on DeliveryAspect {
          contentDate
          localizedContentDate
          localizedContentDateSr
        }
        ...${contentStatusBadgeFragment.name}
      }
      ${contentStatusBadgeFragment.fragment()}
    `;
  },
});

export const CurrentNewsItem = ({ newsItem, className }) => {
  const { title } = newsItem;
  const ariaLabel = useContentSpecificAriaLabel(newsItem.__typename, title);

  return (
    <div className={cx(className)}>
      <div className="news-row">
        <TimeIndicator
          date={newsItem.contentDate}
          localizedDate={newsItem.localizedContentDate}
          localizedDateSr={newsItem.localizedContentDateSr}
          className="timestamp"
        />
        <div className="news-title">
          <ContentStatusBadge className="badge" content={newsItem}/>
          <h3>
            <LinkDefaultNarrow to={newsItem.namedUrl} title={title} skipTitleForInternal aria-label={ariaLabel}>
              <MediaTag contentType={newsItem.__typename} />
              {title}
            </LinkDefaultNarrow>
          </h3>
        </div>
      </div>
    </div>
  );
};

export const darkCurrentNewsItemStyles = cx(
  LinkDefaultNarrow.darkStyles,
  darkTimeIndicatorStyles,
);

export const StyledCurrentNewsItem = styled(CurrentNewsItem)`
  padding-inline: 0;
  flex: 0 0 100%;
  max-width: 100%;

   .news-row {
    display: flex;
    flex-wrap: nowrap;
    margin: 0;
   }

  .timestamp {
    font-size: 1.1rem;
    letter-spacing: 0.04rem;
    text-align: end;
    padding: 0.4em 0 0;
    max-width: 80px;
    width: 100%;
  }

  .badge {
    margin-bottom: 5px;
  }

  .news-title {
    padding: 0;
    padding-inline-end: 15px;
    margin-inline-start: 15px;
    width: 100%;
  }

  h3 {
    ${resetBoostrapAndBrowserDefaultLineHeight}
    margin-bottom: 0;
    font-size: 1.8rem;
    word-break: break-word;
  }

  ${mediaMin.sm`
    .news-title {
      padding-inline-end: 0;
    }
  `}

  ${mediaMin.md`
    .timestamp {
      font-size: 1.2rem;
      letter-spacing: 0.09rem;
      max-width: 90px;
    }
    .badge {
      margin-bottom: 10px;
    }
    h3 {
      font-size: 2rem;
    }
  `}

  ${mediaMin.lg`
    .timestamp{
      max-width: 130px;
    }
  `}

  ${mediaMin.xl`
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
    &:nth-child(3n){
      padding-inline-end: 0;
    }
    .timestamp {
      text-align: start;
      padding: 0 0 10px 0;
      max-width: 100%;
    }
    .news-title {
      margin: 0;
    }

    .news-row {
      flex-wrap: wrap;
      padding-inline-end: 30px;
    }
  `}
`;
