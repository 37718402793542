import { extendWithClassNames } from '../../../utils/extendWithClassNames.jsx';

import {
  StyledTeaserComposition as TeaserComposition,
  extendWithColumnTeaserComposition,
  extendTeaserCompositionWithSmallImageTeaserMixLooks,
} from '../TeaserComposition';

export const StyledColumnSmallImageMixLayout = extendWithClassNames(TeaserComposition)(
  extendWithColumnTeaserComposition,
  extendTeaserCompositionWithSmallImageTeaserMixLooks,
);
