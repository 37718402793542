import { styled } from '@linaria/react';
import { linkedTitleFragment, StyledLinkedTitle as LinkedTitle, darkLinkedTitleStyles } from './Partials/StyledLinkedTitle.jsx';
import { StyledLinkedDescription as LinkedDescription, darkLinkedDescriptionStyles } from './Partials/StyledLinkedDescription.jsx';
import { StyledTeaserFooter as TeaserFooter, teaserFooterFragment, darkTeaserFooterStyles } from './Partials/TeaserFooter';
import { StyledOpinion as Opinion, opinionFragment, darkOpinionStyles } from './Partials/Opinion.jsx';
import { StyledTeaserMainContentArea as TeaserMainContentArea } from './Partials/TeaserMainContentArea.jsx';
import { StyledTeaserData as TeaserData, teaserDataFragment, darkTeaserDataStyles } from './Partials/TeaserData.jsx';
import { StyledImageWrap as ImageWrap, imageWrapFragment } from './Partials/ImageWrap.jsx';
import { contentTeaserSetup, ContentTeaserContext } from './ContentTeaserContext';
import { StyledAVWrap as AVWrap, avWrapFragment, darkAvWrapStyles } from './Partials/AVWrap.jsx';
import { StyledContentStatusTeaserBadge as ContentStatusTeaserBadge, contentStatusTeaserBadgeFragment } from './Partials/ContentStatusTeaserBadge.jsx';
import { makeLinkColorOnActive, makeLinkColorOnHover } from './Partials/commons';

export const ContentTeaser = ({
  className,
  children,
  isHidden = contentTeaserSetup.config.isHidden,
  formatConfig = contentTeaserSetup.config.formatConfig,
  hideKicker = contentTeaserSetup.config.hide.kicker,
  hideDescription = contentTeaserSetup.config.hide.description,
  hideOpinion = contentTeaserSetup.config.hide.opinion,
  hideOpinionMetadata = contentTeaserSetup.config.hide.opinionMetadata,
  hideTimeIndicator = contentTeaserSetup.config.hide.timeIndicator,
  isCurrent = contentTeaserSetup.config.isCurrent,
  isPreloadImage = contentTeaserSetup.config.isPreloadImage,
  content = contentTeaserSetup.content,
  link = contentTeaserSetup.link,
  ...restProps
}) => (
  <ContentTeaserContext.Provider value={{
    config:
        {
          isHidden,
          formatConfig,
          hide: {
            kicker: hideKicker,
            description: hideDescription,
            opinion: hideOpinion,
            opinionMetadata: hideOpinionMetadata,
            timeIndicator: hideTimeIndicator,
          },
          isCurrent,
          isPreloadImage,
        },
    content,
    link,
  }}>
    <div className={`${className} teaser`} {...restProps}>
      {children}
    </div>
  </ContentTeaserContext.Provider>
);

export const partialFragments = {
  teaserDataFragment,
  linkedTitleFragment,
  teaserFooterFragment,
  avWrapFragment,
  imageWrapFragment,
  opinionFragment,
  contentStatusTeaserBadgeFragment,
};

export const darkPartialStyles = {
  darkLinkedDescriptionStyles,
  darkTeaserDataStyles,
  darkLinkedTitleStyles,
  darkTeaserFooterStyles,
  darkAvWrapStyles,
  darkOpinionStyles,
};

export const Partials = {
  LinkedTitle,
  LinkedDescription,
  TeaserFooter,
  AVWrap,
  LinkedImage: ImageWrap,
  MainContentArea: TeaserMainContentArea,
  Data: TeaserData,
  Opinion,
  ContentStatusTeaserBadge,
};

const teaserDataClasses = [
  '.teaser-data',
  '.teaser-data-area',
  '.teaser-data-xs-sm',
];

const teaserDataOrImageSelector = [...teaserDataClasses, '.teaser-image-wrap'];

const selectorToSpreadHover = `&:has(:where(${teaserDataOrImageSelector}) a:hover) :where(${teaserDataClasses}) a`;
const selectorToSpreadActive = `&:has(:where(${teaserDataOrImageSelector}) a:active) :where(${teaserDataClasses}) a`;

export const StyledContentTeaser = styled(ContentTeaser)`
  row-gap: 15px;

  ${makeLinkColorOnHover({ linkCssSelector: selectorToSpreadHover })}
  ${makeLinkColorOnActive({ linkCssSelector: selectorToSpreadActive })}
`;
