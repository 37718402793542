import gql from 'graphql-tag';
import { StyledAVCarousel as AVCarousel, avCarouselFragment } from './AVCarousel.jsx';
import { makeMemoFragment } from '../../../utils/graphql';
import { linkWithArrowFragment, StyledLinkWithArrow as LinkWithArrow } from '../LinkWithArrow.jsx';
import { noop } from '../../../utils/commons';

export const avCarouselGlobalTopicVideoAdapterFragment = avCarouselFragment;

export const avCarouselGlobalTopicVideoAdapterConfigurationFragment = makeMemoFragment({
  name: 'AVCarouselGlobalTopicVideoAdapterConfiguration',
  fragment() {
    return gql`fragment ${this.name} on CompositionComponent {
        configuration {
          sourceAssociations {
            targetId
            target {
              ... on NamedAspect {
                title
               }
              ... on DeliveryAspect {
                autoDelivery
              }
              ...${linkWithArrowFragment.name}
            }
          }
        }
      }
      ${linkWithArrowFragment.fragment()}
    `;
  },
});

const makeBottomLinkFn = autoTopicContent => () => (
  <LinkWithArrow
    content={autoTopicContent}
    linkTranslation="content_block.topic_cluster_automatic.all"
  />
);

export const AVCarouselGlobalTopicVideoAdapter = ({ configuration, contents, ...otherProps }) => {
  const autoTopic = configuration.sourceAssociations[0]?.target;

  return (
    <AVCarousel
      headlineTranslation={autoTopic?.title}
      bottomElementFn={autoTopic.autoDelivery ? makeBottomLinkFn(autoTopic) : noop}
      contents={contents}
      {...otherProps}
    />
  );
};
