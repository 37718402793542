import { styled } from '@linaria/react';
import gql from 'graphql-tag';

import { cx } from '@linaria/core';
import { StyledFooterSectionHeadline as FooterSectionHeadline } from './FooterSectionHeadline.jsx';
import { StyledSocialMediaAccountLink as SocialMediaAccountLink } from './SocialMediaAccountLink';
import { sharedFooterSectionStyles } from './FooterSectionMainGroup.jsx';
import { useTranslation } from '../hooks/useTranslation.jsx';
import { isValidList } from '../../utils/contentUtils';
import { makeMemoFragment } from '../../utils/graphql';
import { ids } from '../../utils/staticElementIds';
import { hideOnPrint } from '../GlobalPrintStyles';

export const footerSectionSocialMediaFragment = makeMemoFragment({
  name: 'FooterSectionSocialMedia',
  fragment() {
    return gql`fragment ${this.name} on Footer {
      linkedSocialMediaAccounts {
        service
        url
      }
    }
  `;
  },
});

export const FooterSectionSocialMedia = ({ className, footer }) => {
  const headline = useTranslation('component.footer.socialmediachannels.headline');

  if (!isValidList(footer.linkedSocialMediaAccounts)) {
    return null;
  }
  return (
    <section className={cx(hideOnPrint, className)} data-tracking-name={ids.footerSectionSocialMedia}>
      <div role="group" aria-labelledby={ids.footerSectionSocialMedia}>
        <FooterSectionHeadline id={ids.footerSectionSocialMedia}>
          {headline}
        </FooterSectionHeadline>
        <ul className="section-list">
          {footer.linkedSocialMediaAccounts.map(socialMediaAccount => (
            <SocialMediaAccountLink key={socialMediaAccount.service} socialMediaAccount={socialMediaAccount} />
          ))}
        </ul>
      </div>
    </section>
  );
};

export const StyledFooterSectionSocialMedia = styled(FooterSectionSocialMedia)`
  ${sharedFooterSectionStyles}

  .section-list {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
  }
`;
