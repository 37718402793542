import gql from 'graphql-tag';
import { css, cx } from '@linaria/core';

import { authorDetailsFragment, darkAuthorDetailsStyles, StyledAuthorDetails as AuthorDetails } from './AuthorDetails.jsx';
import { darkTimeAreaStyles, StyledTimeArea as TimeArea, timeAreaFragment } from './TimeArea.jsx';
import {
  StyledContentDetailKicker as ContentDetailKicker,
  contentDetailKickerFragment,
  darkContentDetailKickerStyles,
} from './ContentDetailKicker.jsx';
import { makeMemoFragment } from '../../utils/graphql';

export const imageGalleryDetailsHeaderFragment = makeMemoFragment({
  name: 'ImageGalleryDetailsHeader',
  fragment() {
    return gql`fragment ${this.name} on ImageGallery {
            ...${authorDetailsFragment.name}
            ...${timeAreaFragment.name}
            ...${contentDetailKickerFragment.name}
        }
        ${authorDetailsFragment.fragment()}
        ${timeAreaFragment.fragment()}
        ${contentDetailKickerFragment.fragment()}
    `;
  },
});

export const ImageGalleryDetailsHeader = ({
  content,
  className,
}) => (
  <header className={className}>
    <ContentDetailKicker content={content} />
    <AuthorDetails content={content} />
    <TimeArea className={spacingBetweenAuthorAndTimeArea} content={content}/>
  </header>
);

export const darkImageGalleryDetailsHeaderStyles = cx(
  darkContentDetailKickerStyles,
  darkAuthorDetailsStyles,
  darkTimeAreaStyles,
);

const spacingBetweenAuthorAndTimeArea = css`margin-top: 5px;`;

export const StyledImageGalleryDetailsHeader = ImageGalleryDetailsHeader;
