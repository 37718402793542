import { styled } from '@linaria/react';
import { StyledCompactMediaItem as CompactMediaItem } from '../MediaItem.jsx';
import { mediaMin } from '../../../../utils/css';
import { isMediaGroupShown } from '../../../../utils/contentUtils';
import { mediaNavigationGroupFragment } from '../MediaNavigationGroup.jsx';

export const mediaGroupFragment = mediaNavigationGroupFragment;

export const MediaGroup = ({ className, navigation }) => {
  if (!isMediaGroupShown(navigation)) {
    return null;
  }
  // eslint-disable-next-line fp/no-mutating-methods
  const reversedLatestMediaChildren = [...navigation.latestMediaChildren].reverse();

  return (
    <div className={className}>
      {reversedLatestMediaChildren.map(mediaNavigation => (
        <CompactMediaItem
          key={mediaNavigation.id}
          mediaNav={mediaNavigation}
        />
      ))}
    </div>
  );
};
// TODO linaria-next: css``
export const mediaGroupStyles = `
  display: none;

  ${mediaMin.md`
    display: inline-flex;
    flex-direction: row-reverse;
    
    column-gap: 4px;
  `}
  ${mediaMin.lg`
    column-gap: 23px;
  `}
`;

export const StyledMediaGroup = styled(MediaGroup)`
  ${mediaGroupStyles}
`;
